<div class="right-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12 text-center">
                <h3>Cash Management</h3>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12 text-center">
                <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-view" role="tab"
                            aria-controls="pills-home" aria-selected="true">View</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-drawer" role="tab"
                            aria-controls="pills-profile" aria-selected="false">Open Drawer</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-cashIn" role="tab"
                            aria-controls="pills-contact" aria-selected="false" (click)="SetAmountZero()">Cash In​</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-cashOut" role="tab"
                            aria-controls="pills-contact" aria-selected="false" (click)="SetAmountZero()">Cash Out</a>
                    </li>
                </ul>
                <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="pills-view" role="tabpanel"
                        aria-labelledby="pills-home-tab">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="shift-review-block">
                                        <h3 class="mt-5">Cash Drawer Report</h3>
                                        <div class="shift-review-table">
                                            <table class="table table-borderless">
                                                <tbody>
                                                    <tr>
                                                        <td>Start Balance</td>
                                                        <td>{{shiftOpeningAMt | currency}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="shift-review-table">
                                            <table class="table table-borderless" style="min-height: 110px;">
                                                <tbody *ngFor="let item of currentShiftTransaction" >
                                                    <tr>
                                                        <td [ngClass]="(item.amount < 0)?'text-danger':''">
                                                            {{item.txCashType}}</td>
                                                        <td [ngClass]="(item.amount < 0)?'text-danger':''">
                                                            {{item.amount | currency}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="shift-review-table">
                                            <table class="table table-borderless">
                                                <tbody>
                                                    <tr>
                                                        <td>Cash Drawer Balance</td>
                                                        <td>{{currentShiftTotals | currency}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 text-center">
                                    <br />
                                    <button class="btn btn-secondary btn-lg" (click)="PrintCurrentShiftCashDetails()">Print</button>
                                </div>
                            </div>                            
                        </div>
                    </div>
                    <div class="tab-pane fade" id="pills-drawer" role="tabpanel" aria-labelledby="pills-profile-tab">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="shift-review-block">
                                        <h3>Cash Management</h3>
                                        <h3 class="mt-5">Cash Drawer Report</h3>
                                        <div class="shift-review-table">
                                            <table class="table table-borderless">
                                                <tbody>
                                                    <tr>
                                                        <td>Cash Balance</td>
                                                        <td>{{currentShiftTotals | currency}}</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="pills-cashIn" role="tabpanel" aria-labelledby="pills-contact-tab">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-sm-4">

                                </div>
                                <div class="col-sm-4">
                                    <div class="opening-count-block">
                                        <h2>Amount</h2>
                                        <div class="count-input form-group">
                                            <input type="number" [(ngModel)]="openingCount" value="0.00"
                                                class="form-control">
                                        </div>
                                        <div class="count-buttons-block">
                                            <button class="btn count-button" (click)="PopulateAmount(1)">1</button>
                                            <button class="btn count-button" (click)="PopulateAmount(2)">2</button>
                                            <button class="btn count-button mr-0" (click)="PopulateAmount(3)">3</button>
                                            <button class="btn count-button" (click)="PopulateAmount(4)">4</button>
                                            <button class="btn count-button" (click)="PopulateAmount(5)">5</button>
                                            <button class="btn count-button mr-0" (click)="PopulateAmount(6)">6</button>
                                            <button class="btn count-button" (click)="PopulateAmount(7)">7</button>
                                            <button class="btn count-button" (click)="PopulateAmount(8)">8</button>
                                            <button class="btn count-button mr-0" (click)="PopulateAmount(9)">9</button>
                                            <button class="btn count-button" (click)="PopulateAmount(0)">0</button>
                                            <button class="btn count-button" (click)="PopulateAmount('.')">.</button>
                                            <button class="btn count-button mr-0" (click)="PopulateAmount('x')"><i
                                                    class="fa fa-times"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                </div>

                            </div>
                            <div class="row text-center">
                                <div class="col-sm-4">

                                </div>
                                <div class="col-sm-4">
                                    <button type="button" class="btn btn-default"
                                        (click)="CheckCurrentShiftBalance('cashIn')">Continue</button>
                                </div>
                                <div class="col-sm-4">

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="pills-cashOut" role="tabpanel" aria-labelledby="pills-contact-tab">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-sm-4">

                                </div>
                                <div class="col-sm-4">
                                    <div class="opening-count-block">
                                        <h2>Amount</h2>
                                        <div class="count-input form-group">
                                            <input type="number" [(ngModel)]="openingCount" value="0.00"
                                                class="form-control">
                                        </div>
                                        <div class="count-buttons-block">
                                            <button class="btn count-button" (click)="PopulateAmount(1)">1</button>
                                            <button class="btn count-button" (click)="PopulateAmount(2)">2</button>
                                            <button class="btn count-button mr-0" (click)="PopulateAmount(3)">3</button>
                                            <button class="btn count-button" (click)="PopulateAmount(4)">4</button>
                                            <button class="btn count-button" (click)="PopulateAmount(5)">5</button>
                                            <button class="btn count-button mr-0" (click)="PopulateAmount(6)">6</button>
                                            <button class="btn count-button" (click)="PopulateAmount(7)">7</button>
                                            <button class="btn count-button" (click)="PopulateAmount(8)">8</button>
                                            <button class="btn count-button mr-0" (click)="PopulateAmount(9)">9</button>
                                            <button class="btn count-button" (click)="PopulateAmount(0)">0</button>
                                            <button class="btn count-button" (click)="PopulateAmount('.')">.</button>
                                            <button class="btn count-button mr-0" (click)="PopulateAmount('x')"><i
                                                    class="fa fa-times"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                </div>

                            </div>
                            <div class="row text-center">
                                <div class="col-sm-4">

                                </div>
                                <div class="col-sm-4">
                                    <button type="button" class="btn btn-default"
                                        (click)="CheckCurrentShiftBalance('cashOut')">Continue</button>
                                </div>
                                <div class="col-sm-4">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<p-confirmDialog acceptLabel="Continue" appendTo="body">
    <ng-template pTemplate="body">
        <span class="ui-confirmdialog-message"></span>
    </ng-template>
</p-confirmDialog>