import { Injectable } from '@angular/core';
import { configConstant } from '../shared/constants/config.constant';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { PrintJobModel } from '../Models/shift.model';

@Injectable({
  providedIn: 'root'
})
export class OpenGiftingService 
{

  private baseurl = configConstant.apibaseurl;
  constructor(private http: HttpClient) {  }
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  errorHandl(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
        // Get client-side error
        errorMessage = error.error.message;
    } else {
        // Get server-side error
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

 GetGiftingInformation(districtId): Observable<any> {
    debugger;
    let CId = localStorage.getItem("Client_Id");
    const headers = new HttpHeaders().set('Client', CId);
        return this.http.get<any>(this.baseurl +'/OpenGifting/GetGiftingInfo/'+ districtId,{headers})
        .pipe(
          retry(1),
          catchError(this.errorHandl)
        );
}



SaveTransaction(transactions): Observable<PrintJobModel> {
  // debugger;
  let CId = localStorage.getItem("Client_Id");
  const headers = new HttpHeaders().set('Client', CId);
  return this.http.post<PrintJobModel>(this.baseurl + '/OpenGifting/SaveTransaction', transactions, {headers})
    .pipe(
      retry(1),
      catchError(this.errorHandl)
    );
}
SaveTransactionExchange(transactions): Observable<PrintJobModel> {
  // debugger;
  let CId = localStorage.getItem("Client_Id");
  const headers = new HttpHeaders().set('Client', CId);
  return this.http.post<PrintJobModel>(this.baseurl + '/OpenGifting/SaveTransactionExchange', transactions, {headers})
    .pipe(
      retry(1),
      catchError(this.errorHandl)
    );
}
RefundTransaction(transactions): Observable<any> {
  debugger;
  let CId = localStorage.getItem("Client_Id");
   const headers = new HttpHeaders().set('Client', CId);
   let res= this.http.post<any>(this.baseurl + '/OpenGifting/RefundTransaction', transactions, {headers})
     .pipe(
       retry(1),
       catchError(this.errorHandl)
     );
     return res;
 }
}

