import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/Services/auth.service';

@Component({
  selector: 'app-select-sites',
  templateUrl: './select-sites.component.html',
  styleUrls: ['./select-sites.component.css']
})
export class SelectSitesComponent implements OnInit {
  SiteSelectionModal: boolean;
  SiteList: any;
  sitedetails;
  Message: any;
  constructor(private router: Router, private authenticationService: AuthService) { }

  ngOnInit(): void {
    this.SiteSelectionModal = true;
    let DistricId = JSON.parse(localStorage.getItem("DistrictId"));
    this.authenticationService.GetSiteListByDistrictId(DistricId)
      .subscribe((res) => {
        this.SiteList = res
      });

    this.sitedetails = "--Select Site--";
  }


  selectSite() {
    debugger
    if (this.sitedetails != "--Select Site--") {
      localStorage.setItem('SiteId', JSON.stringify(this.sitedetails));
      this.router.navigate(['/WorkStationComponents']);

    }
    else {
      this.Message="Please select a site before continuing."
    }
  }

}
