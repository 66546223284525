import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/Services/auth.service';

@Component({
  selector: 'app-selectdistrict',
  templateUrl: './selectdistrict.component.html',
  styleUrls: ['./selectdistrict.component.css']
})
export class SelectdistrictComponent implements OnInit {

  DistrictSelectionModal:boolean;
  DistrictList:any;
  Message:any;
  constructor(private router: Router,private authenticationService: AuthService) { }
  selectedDistrict;
  ngOnInit(): void {
    debugger;
    this.DistrictSelectionModal=true
    this.authenticationService.GetDistrictList()
    .subscribe((res) => {
      debugger;
      this.DistrictList=res
    });

    this.selectedDistrict="--Select District--";
  }


SelectDistrict(){
  debugger;
  if(this.selectedDistrict !="--Select District--"){
    localStorage.setItem('DistrictId', JSON.stringify(this.selectedDistrict));
    this.router.navigate(['/SiteComponents']);
  }
  else{
    this.Message="Please select a district before continuing."
  }

}






}
