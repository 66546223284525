<div class="right-content saleshistory">
    <div class="heading-panel text-left">
        <h1><img src="./assets/Libs/images/TransactionHistory.png" height="16">Refund</h1>
    </div>
    <div class="container-fluid">
        <div class="transaction-details-main transaction-details-maincstm">
            <div class="d-flex mainSec_100">
                <!-- <div class="row">
                    <div class="col-md-12">
                    </div>
                </div> -->
                <div class="transaction-details-left" *ngIf="!isShowCCDetail">
                    <div class="items-list-to-return">
                        <div class="return-item">
                            <div class="avbl-pytm-heading mb-2">
                                <h4 *ngIf="RefundProductsTotal >= 0">Available Payment For Refund</h4>
                                <h4 *ngIf="RefundProductsTotal < 0">Amount Due from Guest</h4>
                            </div>
                            <div class="mb-2">
                                <div class="row refund-row">
                                    <div class="col-lg-5">
                                        <table class="table table-borderless avbl-tbl-styl" *ngIf="RefundProductsTotal >= 0">
                                            <tbody>
                                                <tr *ngIf="getRoomChargeInTX">
                                                    <td class="text-left getRoomChargeTx">Room Charge</td>
                                                    <!-- <td class="text-left">
                                                        <input type="text" [disabled]="siteConfi.editRefundAmt!=true"
                                                            class="form-control" id="txtEditRoomCharge"
                                                            name="txtEditRoomCharge" [(ngModel)]="editRoomChargeAmount"
                                                            placeholder="0.00" (keyup)="valRoomChargeAmount($event)">
                                                    </td> -->
                                                    <td class="text-left">
                                                        <input type="text" [disabled]="siteConfi.editRefundAmt!=true"
                                                            class="form-control" id="txtEditRoomCharge"
                                                            name="txtEditRoomCharge" value="{{editRoomChargeAmount | currency}}"
                                                            placeholder="0.00" (keyup)="valRoomChargeAmount($event)">
                                                    </td>
                                                    <td class="text-center getRoomNumTx">Room No</td>
                                                    <td class="text-left">
                                                        <input type="text" class="form-control"
                                                            id="txteditRoomChargeNum" name="txteditRoomChargeNum"
                                                            [(ngModel)]="editRoomChargeNum">
                                                    </td>
                                                </tr>
                                                <tr *ngIf="getCreditCardInTX">
                                                    <td class="text-left getCreditCardTX">Credit</td>
                                                    <td class="text-left getCreditCardTX-inpt">
                                                        <input type="text" [disabled]="siteConfi.editRefundAmt!=true"
                                                            class="form-control" id="txtEditCC" name="txtEditCC"
                                                            value="{{editCCAmount | currency}}" placeholder="0.00"
                                                            (keyup)="valCCAmount($event)">
                                                    </td>
                                                    <td class="text-left"></td>
                                                    <td class="text-left"></td>
                                                </tr>
                                                <tr *ngIf="getCashInTX">
                                                    <td class="text-left getCashTx">Cash</td>
                                                    <td class="text-left getCashTx-inpt">
                                                        <input [disabled]="siteConfi.editRefundAmt!=true" type="text"
                                                            class="form-control" id="txtEditCash" name="txtEditCash"
                                                            (keyup)="valCashAmount($event)"  value="{{editCashAmount | currency}}"
                                                            placeholder="0.00">
                                                    </td>
                                                    <td class="text-left"></td>
                                                    <td class="text-left"></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <hr />
                                <div class="row">
                                    <div class="col-md-4 rfnd-details">
                                        <table class="table table-borderless">
                                            <tbody>
                                                <tr>
                                                    <td class="text-left getRfnd-info">Refund Total</td>
                                                    <td class="text-left red" *ngIf="RefundProductsTotal >= 0">
                                                        {{RefundProductsTotal | currency}}</td>
                                                    <td class="text-left red " *ngIf="RefundProductsTotal < 0">
                                                        -{{RefundProductsTotal*-1 | currency}}</td>
                                                </tr>
                                                <tr *ngIf="RefundProductsTotal >= 0">
                                                    <td class="text-left getBal-info">Balance to Refund</td>
                                                    <td class="text-left">{{RefundDueBalance | currency}}</td>
                                                </tr>
                                                <tr *ngIf="RefundProductsTotal < 0">
                                                    <td class="text-left getBal-info">Cash Due from Guest</td>
                                                    <td class="text-left">{{RefundProductsTotal*-1 | currency}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Credit Card detail (on Continue click) -->
                <div class="transaction-details-left" *ngIf="isShowCCDetail && (this.editCCAmount > 0)">
                    <div class="items-list-to-return SpaceSec">
                        <div class="return-item">
                            <div class="transdetail-heading mb-2">
                                <h4>Credit Card(s) Refund</h4>
                            </div>
                            <div class="table-responsive table-responsivecstm mb-2">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <td>Selected</td>
                                            <td class="text-left">Account</td>
                                            <td class="text-left">Card</td>
                                            <td class="text-left">Name</td>
                                            <td class="text-left">Available Balance</td>
                                            <td class="text-left">Refund Amount</td>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="creditcardDetailList != ''">
                                        <tr *ngFor="let item of creditcardDetailList; let i=index">
                                            <td class="textcheck">
                                                <input type="checkbox" id="chk_{{item.sNo}}" [checked]="item.isSelected"
                                                    type="checkbox"
                                                    (change)="CheckChangeCCList($event.target.checked, item.sNo)">
                                            </td>
                                            <td class="text-left">{{ item.account }}</td>
                                            <td class="text-left">{{ item.issuer }}</td>
                                            <td class="text-left">{{ item.cardHolder }}</td>
                                            <td class="text-left">{{ item.amount }}</td>
                                            <!-- <td class="text-left" *ngIf="!item.isSelected">{{ item.amount }}</td> -->
                                            <td class="text-left">
                                                <input type="text" class="form-control" [disabled]="!item.isSelected"
                                                    id="txtCCRefund" name="txtCCRefund" style="width:50%"
                                                    [(ngModel)]="item.editCCRefundAmount" placeholder="0.00"
                                                    (keyup)="valCCListAmount($event, item.sNo)">
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div>
                        <span class="boldFont">Credit Card Refund Amount ${{ editCCAmount }}</span>
                        <span class="pull-right boldFont">Total Balance Selected to Refund: ${{ CCCheckedListAmount
                            }}</span>
                    </div>
                </div>
                <!-- Selected Refund products -->
                <div class="transaction-details-right selRefProdPaySection">
                    <div class="card fix-h">
                        <div class="card-body">
                            <div class="item-rfnd-heading text-center mb-2">
                                <h4>Items Selected for Refund</h4>
                            </div>
                            <div class="table-responsive">
                                <!-- Refunding Selected Product -->
                                <div *ngFor="let item of refundProductsGroupList">
                                    <div class="selectrefundheading">{{item.category}}</div>
                                    <div class="right-refundtablescrl mb-3">
                                        <table class="table mb-0" *ngIf="SelectedProducts != ''">
                                            <tbody>
                                                <tr *ngFor="let prod of item.prodList; let i=index">
                                                    <td>
                                                        <span>{{prod.productName}}</span>
                                                    </td>
                                                    <td>
                                                        <span class="products-quantity"></span>
                                                    </td>
                                                    <td class="text-right">
                                                        {{prod.total | currency}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                              <!-- Payment details -->
                            <div>
                                <div class="pymtDetails-heading text-center mb-2">
                                    <h4>Payments</h4>
                                </div>
                                <div class="right-pymtablescrl mb-3">
                                    <table class="table mb-0 my-2" *ngIf="TenderList.length>0">
                                        <tbody>
                                            <tr *ngFor="let item of TenderList; let i=index">
                                                <td>
                                                    <div class="d-flex justify-content-between">
                                                        <span> {{item.tenderTypeName}}</span>
                                                    </div>
                                                </td>
                                                <td class="text-right">
                                                    {{item.originalTenderAmount | currency}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!-- Payment details -->
                        </div>
                    </div>
                </div>
            </div>
            <!-- total calculation -->
        </div>

        <div class="two-action-buttons d-flex justify-content-center align-items-center">
            <span *ngIf="!isShowCCDetail">
                <button class="button button-success" id="bck-btn-rfnd" (click)="RedirectSalesHistory()">Back</button>
            </span>
            <span *ngIf="!isShowCCDetail && (this.editCCAmount > 0)">
                <button (click)="ShowCreditCardDetail()" class="button button-secondary">Continue</button>
            </span>
            <span *ngIf="isShowCCDetail">
                <button class="button button-success" (click)="paymentSelection()">Back</button>
            </span>
            <span *ngIf="isShowCCDetail || (this.editCCAmount <= 0)">
                <button (click)="CompleteRefundTransaction1()"
                    class="button button-secondary fnsh-btn">Continue</button>
            </span>
        </div>
    </div>
</div>