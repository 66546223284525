
<div class="right-content" id="rounded-section">
    <div class="heading-panel text-left">
        <h1><img src="./assets/Libs/images/retal.png" height="16">Open Gifting Order
            <span *ngIf="LastTxNo===true">
                # {{workstation.workStationId}}-{{TxNum}}
            </span>
        </h1>
    </div>

    <div class="retail-order-outer">
        <div class="container-fluid">
            <div class="row justify-content-end">
                <div class="col-md-6 custom-left-section">
                    <div class="leftBg mt-2">
                        <div class="retail-left-topbar gift-top-wrap">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="customIconBox" [ngClass]="{'disableDiv': isTendorDisable}">
                                        <i class="fa fa-user user"></i>
                                        <input type="text" class="form-control pl-35" placeholder="Customer Name"
                                            [(ngModel)]="customerName" [disabled]="true">
                                            <i (click)="OpenModel()" class="fa fa-edit edit" [ngClass]="{'disabled-icon': isExhange}"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="retail-table-block" [ngClass]="{'disableDiv': isTransCancil}">
                                        <div class="tblWrapper">
                                            <table
                                                class="table customTable retail-table-section table-bordered order-retail-table">
                                                <thead>
                                                    <tr>
                                                        <td>Product</td>
                                                        <td>Qty</td>
                                                        <td *ngIf="isSRPShow">SRP</td>
                                                        <td>Price</td>
                                                        <td>Discount</td>
                                                        <td>Total</td>
                                                        <td></td>
                                                    </tr>
                                                </thead>
                                                <tbody *ngIf="SelectedProducts != ''">
                                                    <tr *ngFor="let item of SelectedProducts; let i=index">
                                                        <td>{{item.fastButtonName}}</td>
                                                        <td>{{item.qty}}</td>
                                                        <td *ngIf="isSRPShow">{{item.srp | currency}}
                                                        </td>
                                                        <td>{{item.price | currency}}</td>
                                                        <td class="text-danger"><span *ngIf="item?.discount != 0">-
                                                            </span>{{item?.discount |currency}} <i
                                                                [attr.disabled]="siteConfi.discount!=true"
                                                                [ngClass]="{'disabled-icon': item.type === 'Exchange'}"></i>
                                                        </td>
                                                        <td>{{item.Total | currency}}</td>
                                                        <td class="trashp"><i class="fa fa-trash fa-2x"
                                                                (click)="ChangeValue('0', item)"
                                                                [ngClass]="{'disabled-icon': item.type === 'Exchange'}"></i>
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="retail-left-bottombar pt-3">
                            <div class="row m-0 h-100">
                                <div class="col-md-8">
                                    <div class="retail-total-outer">
                                        <div class="retail-total-wrap w-100 ">
                                        <div class="retail-total-panel">
                                            <ul class="retail-total-Type">
                                                <li *ngIf="isSRPShow">
                                                    <h2> SRP Total:</h2>
                                                    <p>{{cartValues.SRPTotal | currency}}</p>
                                                </li>
                                                <li *ngIf="isDiscountShow">
                                                    <h2> Discounts:</h2>
                                                    <p class="red"><span>- </span> {{cartValues.Discounts | currency }}
                                                    </p>
                                                </li>
                                                <li class="total-sales">
                                                    <h2>Sales Total:</h2>
                                                    <p>{{cartValues.TxTotal | currency}}</p>
                                                </li>
                                                <!-- <hr class="my-0" width="100%" size="1"> -->
                                                <div class="overflow-auto">
                                                    <ul class="tenderType-scroll">
                                                    <li *ngIf="CalculateTotalCashAmount > 0">
                                                        <h2>{{cashTender}} :</h2>
                                                        <p style="color: rgb(209, 9, 9);">
                                                            {{- CalculateTotalCashAmount | currency}}</p>
                                                    </li>
                                                    <li *ngIf="CalculateTotalRoomAmount > 0">
                                                        <h2>{{roomChargeTender}} :</h2>
                                                        <p style="color: rgb(209, 9, 9);">
                                                            {{- CalculateTotalRoomAmount | currency}}</p>
                                                    </li>
                                                    <li *ngFor="let item of CalculateTotalMoneyCertAmounts">
                                                        <h2>{{item.moneyCertName}} :</h2>
                                                        <p style="color: rgb(209, 9, 9);">
                                                            {{- item.tenderAmount | currency}}</p>
                                                    </li>
                                                    <li *ngIf="totalCardCalc > 0">
                                                        <h2>{{creditcardTender}} :</h2>
                                                        <p style="color: rgb(209, 9, 9);">
                                                            {{- totalCardCalc | currency}}</p>
                                                    </li>
                                                    <li *ngIf="isShowCredit">
                                                        <h2>Credit:</h2>
                                                        <p>{{creditAmont | currency}}</p>
                                                    </li>
                                                    </ul>
                                                      <hr class="my-0" width="100%" size="1">
                                                    <ul>
                                                    <li *ngIf="changeDue > 0">
                                                        <h2>Change Due:</h2>
                                                        <p>{{changeDue | currency}}</p>
                                                    </li>
                                                    <li
                                                            *ngIf="cashGiftValue != null || depositRefundValue != null">
                                                        </li>
                                                    <li *ngIf="cashGiftValue != null">
                                                        <h2> Cash Gift:</h2>
                                                        <p><span>- </span> {{ cashGiftValue | currency }}
                                                        </p>
                                                    </li>
                                                    <li *ngIf="depositRefundValue != null">
                                                        <h2> Deposit Refund:</h2>
                                                        <p><span>- </span> {{ depositRefundValue | currency }}
                                                        </p>
                                                    </li>
                                                </ul>
                                                </div>
                                            </ul>

                                        </div>

                                        <div class="retail-balance" *ngIf="cartValues.BalanceDue !== null">
                                            <ul>
                                                <li>
                                                    <hr width="100%" class="my-0" size="2">
                                                </li>
                                                <li *ngIf="cartValues.BalanceDue >=0 || creditAmont <0">
                                                    <h2>Balance Due:</h2>
                                                    <p>{{cartValues.BalanceDue | currency}}</p>
                                                </li>
                                                <li *ngIf="cartValues.BalanceDue < 0 && creditAmont ==0">
                                                    <h2>Pay Out:</h2>
                                                    <p>{{cartValues.BalanceDue | currency}}</p>
                                                </li>
                                                
                                            </ul>
                                        </div>

                                    </div>
                                    </div>
                                </div>
                                <div class="col-md-4 pymt-btn-wrap">
                                    <div class="paymentBtn h-100">
                                        <button type="button" [disabled]="isProcessCancel" class="btn btn-secondary"
                                            (click)="CancelTran()">Cancel</button>
                                        <button type="button" [disabled]="isTransCancil" (click)="pay()"
                                            class="btn mb-0">Payment</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-padding" *ngIf="isShowPSP">
                    <div class="rightBg mt-2 rightsidebar">
                        <div class="addbttn cstm-min-height-unset">
                            <div class="rightSearch">
                                <i class="fa fa-search"></i><input type="search" [(ngModel)]="searchText"
                                (keyup.enter)="Searchproduct()"   class="form-control" placeholder="Search">
                            </div>
                            <div class="cstm-body-card">
                                <div *ngFor="let section of NewProducts; trackBy: trackBySection">
                                    <h1 class="cstm-heading-inner">{{ section.sectionName }}</h1>
                                    <div class="row productlist-height cstm-productlist-height text-center">
                                        <div class="col-md-3 pb-2 btn-prdctSelect" *ngFor="let product of section.products; trackBy: trackByProduct">
                                            <button class="btn btn-secondary lego-btn custombtn"
                                                (click)="ShowProAddCart(product)"
                                                [ngClass]="{'active': product.isActive }">
                                                {{ product.productName}}
                                                <br>
                                                <small class="p-3"> {{product.price | currency}}</small>
                                            </button>
                                            <div class="number qut-box" *ngIf="product.isActive">
                                                <span class="minusqut" (click)="proQty(product,'minus')"
                                                    [ngClass]="{'disableDiv': product.qty <= 0}">
                                                    <!-- <i class="fa fa-minus"></i> -->
                                                    <svg width="16" height="16" viewBox="0 0 20 20" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M10 18.75C5.1675 18.75 1.25 14.8313 1.25 10C1.25 5.16875 5.1675 1.25 10 1.25C14.8325 1.25 18.75 5.16875 18.75 10C18.75 14.8313 14.8325 18.75 10 18.75ZM10 0C4.47688 0 0 4.475 0 10C0 15.525 4.47688 20 10 20C15.5231 20 20 15.525 20 10C20 4.475 15.5231 0 10 0ZM13.75 9.375H6.25C5.90438 9.375 5.625 9.65625 5.625 10C5.625 10.3438 5.90438 10.625 6.25 10.625H13.75C14.0956 10.625 14.375 10.3438 14.375 10C14.375 9.65625 14.0956 9.375 13.75 9.375Z"
                                                            fill="black" />
                                                    </svg>
                                                </span>
                                                <span class="minusqut">{{product.qty}}</span>
                                                <span class="plusqut" (click)="proQty(product,'plus')"
                                                    [ngClass]="{'disableDiv1': product.qty >= 25 || product.totalPQty <= product.qty}">
                                                    <!-- <i class="fa fa-plus"></i> -->
                                                    <svg width="16" height="16" viewBox="0 0 20 20" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M10 18.75C5.1675 18.75 1.25 14.8313 1.25 10C1.25 5.16875 5.1675 1.25 10 1.25C14.8325 1.25 18.75 5.16875 18.75 10C18.75 14.8313 14.8325 18.75 10 18.75ZM10 0C4.47688 0 0 4.475 0 10C0 15.525 4.47688 20 10 20C15.5231 20 20 15.525 20 10C20 4.475 15.5231 0 10 0ZM13.75 9.375H10.625V6.25C10.625 5.90625 10.3456 5.625 10 5.625C9.65438 5.625 9.375 5.90625 9.375 6.25V9.375H6.25C5.90438 9.375 5.625 9.65625 5.625 10C5.625 10.3438 5.90438 10.625 6.25 10.625H9.375V13.75C9.375 14.0938 9.65438 14.375 10 14.375C10.3456 14.375 10.625 14.0938 10.625 13.75V10.625H13.75C14.0956 10.625 14.375 10.3438 14.375 10C14.375 9.65625 14.0956 9.375 13.75 9.375Z"
                                                            fill="black" />
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="col-md-12 text-right cartbttn cstm-unset-pos pt-4">
                                <button class="cstm-add-cart-btn" (click)="AddtoCart()"> Add to Cart </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6 col-padding" *ngIf="isMoneyShow">
                    <div class="calcBg rightBg-psp mt-2">
                        <div class="rgt-amount-bar">
                            <div class="row amout-section">
                                <div class="col-md-12 mb-2">
                                    <div class="card moneyHeaderContent">
                                        <div class="card-body">
                                            <div class="amount" [ngClass]="{'disableDiv': isTendorDisable}">
                                                <input type="number" id="AmountItem" min="0"
                                                    value='{{amountTendered}}' class="form-control"
                                                    style="text-align: center;" (keyup)="isDollarExist($event)">
                                                <img src="./assets/Libs/images/dollar.png" alt="dollar"
                                                    class="Doller-Icon">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="cal-area">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="calculatorPanel cal-btn-style">
                                            <div class="innerRow" [ngClass]="{'disableDiv': isTendorDisable}">
                                                <button *ngFor="let i of [1,2,3]" type="button"
                                                    (click)="PopulateAmount(i)" class="whiteBox">{{i}}</button>
                                                <div id="btn100" class="whiteBox btn green-bg"
                                                    (click)="PopulateAmount(100,true)">$100</div>
                                                <div id="btn100Emt" class="whiteBox  btn green-bg btn-tender"
                                                    style="display:none;">$100</div>

                                            </div>
                                            <div class="innerRow" [ngClass]="{'disableDiv': isTendorDisable}">
                                                <button *ngFor="let i of [4,5,6]" type="button"
                                                    (click)="PopulateAmount(i)" class="whiteBox">{{i}}</button>
                                                <div id="btn50" class="whiteBox  btn green-bg"
                                                    (click)="PopulateAmount(50,true)">$50</div>
                                                <div id="btn50Emt" class="whiteBox  btn green-bg btn-tender"
                                                    style="display:none;">$50</div>

                                            </div>
                                            <div class="innerRow" [ngClass]="{'disableDiv': isTendorDisable}">
                                                <button *ngFor="let i of [7,8,9]" type="button"
                                                    (click)="PopulateAmount(i)" class="whiteBox">{{i}}</button>
                                                <div id="btn20" class="whiteBox btn green-bg"
                                                    (click)="PopulateAmount(20,true)">$20</div>
                                                <div id="btn20Emt" class="whiteBox btn green-bg btn-tender"
                                                    style="display:none;">$20</div>
                                            </div>
                                            <div class="innerRow" [ngClass]="{'disableDiv': isTendorDisable}">
                                                <button type="button" class="whiteBox"
                                                    (click)="PopulateAmount('C')">C</button>
                                                <button type="button" class="whiteBox"
                                                    (click)="PopulateAmount(0)">0</button>
                                                <!-- <button type="button" class="whiteBox"
                                                    (click)="PopulateAmount('.')">.</button> -->
                                                <div class="whiteBox" (click)="PopulateAmount('<')">
                                                    <i class="fa fa-arrow-circle-o-left"></i>
                                                </div>
                                                <div class="whiteBox" style="visibility: hidden;">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="bottomCashPanel">
                                            <div class="row" [ngClass]="{'disableDiv': isTendorDisable}">
                                                <div class="col-md-12 mt-2">
                                                    <p-selectButton class="col-md-4  " [options]="types"
                                                        [disabled]="NoBalanceDue" (click)="BtnSelected()"
                                                        [(ngModel)]="selectedTender" style="font-size: small">
                                                    </p-selectButton>
                                                </div>
                                                <!-- <div class="col-md-4"></div>
                                                <div class="col-md-4"></div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row bottom-three-buttons">
                                <div class="col-md-12 d-flex justify-content-between bck-fnsh-btn">
                                    <button class="back-btn"
                                        [ngClass]="{'disableDiv': isBackDisable}"
                                        (click)="PopulateAmount('B')"><i class="fa fa-arrow-left"
                                            aria-hidden="true"></i>Back</button>
                                    <button class="reset-btn" [disabled]="NoBalanceDue"
                                        (click)="PopulateAmount('x')"><i class="fa fa-refresh"
                                            aria-hidden="true"></i>Reset</button>
                                    <button class="btn fnsh-bg-btn"
                                        [disabled]="isFinish" (click)="FinishTx()">Finish</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6 p-0 bggray" *ngIf="isTxPrinting">
                    <div class="rightSearch">
                        <div class="cashTranscation">
                            <h4 class="mt-3 mb-5">Change:{{change | currency}} </h4>

                            <h4 class="mt-5 mb-3">Select your receipt type:</h4>

                            <button class="btn btn-light text-center" [disabled]="isPrintCopy"><i class="fa fa-print"
                                    aria-hidden="true"></i><br> Print
                                Receipt</button>
                            <button class="btn btn-light text-center" [disabled]="isPrintCopy"><i class="fa fa-print"
                                    aria-hidden="true"></i><br>Print Gift Receipt</button>
                            <button class="btn btn-light text-center" [disabled]="isPrintCopy"><i
                                    class="fa fa-envelope-o" aria-hidden="true"></i><br> Email</button>
                            <button class="btn btn-light text-center" [disabled]="isPrintCopy"><i
                                    class="fa fa-plus-square-o" aria-hidden="true"></i><br> New
                                Sale</button>
                        </div>
                        <div class=" cashTranscation" *ngIf="false">
                            <div col-sm-6>
                                <h3>Printing….</h3>
                            </div>
                            <div col-sm-6>
                                <img src="./assets/Libs/images/dfgdfgdgdg.gif" height="100">
                            </div>

                        </div>
                    </div>
                </div>

                <div class="col-md-6 col-padding" *ngIf="isCCPrinting">
                    <div class="rightBg mt-2 rightSearch">
                        <div class="cashTranscation">
                            <input type="text" min="0" value='{{selectedCCAmount | currency}}' placeholder="0.00"
                                class="form-control" style="text-align: center;">

                            <h4 class="mt-5 mb-3">Swipe or Insert Credit Card</h4>
                            <p><img src="./assets/Libs/images/imageCardSwipe.png"></p>
                        </div>
                        <div class="creditbttn">
                            <button type="submit" id='btnSubmit' class="btn btn-light mr-2">Cancel</button><br>
                            <button type="submit" id='btnSubmit' class="btn btn-light">Manual Entry</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div [style.display]="showModalforName ? 'block' : 'none'" class="modal name-popup" id="imagemodal"
            tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
            <div class="modal-dialog-customtest">
                <div class="modal-content">
                    <div class="modal-header modelHeader">
                        <h4 class="modal-title" id="myModalLabel">Customer Name</h4>
                    </div>
                    <div class="modal-body text-right">
                        <form [formGroup]="registerForm">
                            <div class="form-group">
                                <input autofocus type="text" pattern="[a-zA-Z ]*" placeholder="First Name"
                                    formControlName="FirstName" [(ngModel)]="user.firstName"
                                    (keydown)="keytab($event)" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f.FirstName.errors }" />
                                <div *ngIf="submitted && f.FirstName.errors" class="invalid-feedback">
                                    <div *ngIf="f.FirstName.errors.required">First Name is required</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="text" pattern="[a-zA-Z ]*" placeholder="Last Name"
                                    formControlName="LastName" id='lastName' [(ngModel)]="user.lastName"
                                    (keydown)="keytab($event)"
                                    (keyup.enter)="FocusSubmit($event)" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f.LastName.errors }" />
                                <div *ngIf="submitted && f.LastName.errors" class="invalid-feedback">
                                    <div *ngIf="f.LastName.errors.required">Last Name is required</div>
                                </div>
                            </div>
                            <button type="submit" id='btnSubmit' (click)='onSubmit()'
                                class="btn btn-light text-right">Continue</button>
                        </form>
                    </div>

                </div>
            </div>
        </div>


        <div [style.display]="tourModal ? 'block' : 'none'" class="modal name-popup" id="imagemodal" tabindex="-1"
            role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
            <div class="modal-dialog-customtest">
                <div class="modal-content">
                    <div class="modal-header modelHeader">
                        <h4 class="modal-title" id="myModalLabel">Enter {{ TourIdModel?.aliasName }}<span
                                *ngIf="TourIdModel?.isRequired" style="color: red;"> *</span></h4>
                    </div>
                    <div class="modal-body text-right">
                        <div class="form-group">
                            <input [datoAutoFocus]="isAutofocus" appOnlynumber type="text"
                                placeholder="{{ TourIdModel?.aliasName }}" [(ngModel)]="TourId" class="form-control"
                                maxlength="20" />
                            <div class="text-left mt-2" *ngIf="isLead && TourId==''">
                                <span class="text-danger">{{ TourIdModel?.aliasName }} is required</span>
                            </div>
                        </div>
                        <button type="button" id='btnSubmit' class="btn btn-light text-right"
                            (click)="tourContinue()">Continue</button>
                    </div>

                </div>
            </div>
        </div>



        <div [style.display]="showModal ? 'block' : 'none'" class="modal name-popup" id="imagemodal" tabindex="-1"
            role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
            <div class="modal-dialog-custom">
                <div class="modal-content">
                    <div class="modal-header modelHeader">
                        <h4 class="modal-title" id="myModalLabel">Collect Open Gifting Information</h4>
                        <div class="ml-auto">Page {{currentPage}} of {{totalPages}}</div>
                    </div>
                    <div class="modal-body text-right">
                        <form [formGroup]="form" (ngSubmit)="ongiftingInfoFormSubmit()">
                            <div class="row">
                                <div *ngFor="let control of paginatedItems; let i = index" class="col-lg-6">
                                    <div class="form-group text-left" *ngIf="!control.isLookUp">
                                        <label class="style_lbl">{{ control.aliasName }}<span *ngIf="control.isRequired"
                                                style="color: red;"> *</span></label>
                                        <input [formControlName]="control.giftCodeName" class="form-control"
                                            (input)="onInputChange(control.giftCodeName, $event)">
                                    </div>
                                    <div class="form-group text-left" *ngIf="control.isLookUp">
                                        <label class="style_lbl">{{ control.aliasName }}<span *ngIf="control.isRequired"
                                                style="color: red;"> *</span></label>
                                        <select [formControlName]="control.giftCodeName" class="form-control">
                                            <option *ngFor="let option of control.lookUpValues" [value]="option.value">
                                                {{ option.display }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row action-btn-collect">
                                <div class="col-lg-6 text-left">
                                    <button type="button" id="cancelBtn" (click)="cancelgiftingInfo()"
                                        class="btn btn-light btn-sm mr-2">Cancel</button>
                                    <button type="button" id="resetBtn" (click)="ResetgiftingInfo()"
                                        class="btn btn-light btn-sm">Reset</button>
                                </div>
                                <div class="col-lg-6 text-right">
                                    <button type="button" (click)="prevPage()" [hidden]="currentPage === 1"
                                        class="btn btn-light btn-sm mr-2">Previous</button>
                                    <ng-container *ngIf="currentPage < totalPages; else submitButton">
                                        <button type="button" (click)="nextPage()"
                                            class="btn btn-light btn-sm mr-2">Next</button>
                                    </ng-container>
                                    <ng-template #submitButton>
                                        <button type="submit" id="btnSubmit"
                                            class="btn btn-light btn-sm">Continue</button>
                                    </ng-template>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>


        <div [style.display]="isshowRoomModal ? 'block' : 'none'" class="modal " id="imagemodal" tabindex="-1"
            role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
            <div class="modal-dialog model-dialog-style">
                <div class="modal-content model-style">
                    <div class="col-md-12 p-0">
                        <div class="opening-count-block">
                            <div class="modal-header modal_header align-items-center">
                                <div class="text-center modal_room_title m-0">Enter Room Number</div>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="count-input-room form-group">
                                            <input id="roomChargeId" type="string" [(ngModel)]="roomNumber" (keyup)="valRoomNumber($event)" value="" class="form-control">
                                        </div>
                                    </div>
                                </div>
                            <div class="room-count-block mt-1 mb-2">
                                    <div class="count-buttons-block">
                                        <button class="btn room-count-button" (click)="PopulateRoomNumber(1)">1</button>
                                        <button class="btn room-count-button" (click)="PopulateRoomNumber(2)">2</button>
                                        <button class="btn room-count-button mr-0" (click)="PopulateRoomNumber(3)">3</button>
                                        <button class="btn room-count-button" (click)="PopulateRoomNumber(4)">4</button>
                                        <button class="btn room-count-button" (click)="PopulateRoomNumber(5)">5</button>
                                        <button class="btn room-count-button mr-0" (click)="PopulateRoomNumber(6)">6</button>
                                        <button class="btn room-count-button" (click)="PopulateRoomNumber(7)">7</button>
                                        <button class="btn room-count-button" (click)="PopulateRoomNumber(8)">8</button>
                                        <button class="btn room-count-button mr-0" (click)="PopulateRoomNumber(9)">9</button>
                                        <button class="btn room-count-button" (click)="PopulateRoomNumber('c')">C</button>
                                        <button class="btn room-count-button" (click)="PopulateRoomNumber(0)">0</button>
                                        <button class="btn room-count-button mr-0" (click)="PopulateRoomNumber('x')">
                                            <i class="fa fa-arrow-circle-o-left"></i>
                                        </button>
                                    </div>
                            </div>
                            <div class="row justify-content-center align-items-center">
                                    <button class="btn room-custom-btn activeBTN" (click)="PopulateRoomNumberContinue()">Continue</button>
                                    <!-- <button class="btn btn-primary mt-2 custom-btn" (click)="PopulateRoomNumberCancel()">Cancel</button> -->
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div [style.display]="showKeypadModal ? 'block' : 'none'" class="modal name-popup" id="imagemodal" tabindex="-1"
            role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
            <div class="modal-dialog model-dialog-style">
                <div class="modal-content model-style">
                    <div class="col-md-12 p-0">
                        <div class="opening-count-block">
                            <div class="modal-header modal_header align-items-center">
                                <div class="text-center modal_sold_title m-0">Enter Sold for Amount</div>
                                <button type="button" (click)="SoldForKeypadCross()" class="close" data-dismiss="modal"
                                    aria-label="Close">
                                    <span aria-hidden="true" class="icon-xmark"></span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="form-group count-input-sold">
                                            <input class="form-control calculator-input" [(ngModel)]="SoldForAmount"
                                                style="text-align: center;" id="txtbold" readonly>
                                            <img src="./assets/Libs/images/dollar.png" alt="dollar"
                                                class="Dollar-Icon2">
                                        </div>
                                    </div>
                                </div>
                                <div class="sold-count-block mt-1 mb-2">
                                    <div class="count-buttons-block">
                                        <button class="btn sold-count-button" (click)="handleKeyPress('1')">1</button>
                                        <button class="btn sold-count-button" (click)="handleKeyPress('2')">2</button>
                                        <button class="btn sold-count-button mr-0"
                                            (click)="handleKeyPress('3')">3</button>
                                        <button class="btn sold-count-button" (click)="handleKeyPress('4')">4</button>
                                        <button class="btn sold-count-button" (click)="handleKeyPress('5')">5</button>
                                        <button class="btn sold-count-button mr-0"
                                            (click)="handleKeyPress('6')">6</button>
                                        <button class="btn sold-count-button" (click)="handleKeyPress('7')">7</button>
                                        <button class="btn sold-count-button" (click)="handleKeyPress('8')">8</button>
                                        <button class="btn sold-count-button mr-0"
                                            (click)="handleKeyPress('9')">9</button>
                                        <button class="btn sold-count-button" (click)="handleKeyPress('C')">C</button>
                                        <button class="btn sold-count-button" (click)="handleKeyPress('0')">0</button>
                                        <button class="btn sold-count-button mr-0"
                                            (click)="handleKeyPress('backspace')">
                                            <i class="fa fa-arrow-circle-o-left"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="row justify-content-center align-items-center">
                                    <button class="btn sold-custom-btn" (click)="SoldForContinue()">Continue</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="modal fade" id="tourDepositModel" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content" style="margin-top: 141px;">
                    <div class="modal-header" style="background-color: #5197d8; height: 20px;">
                        <button type="button" class="close" style="margin: -26px -1rem -1rem auto;" data-dismiss="modal"
                            aria-label="Close">
                            <span aria-hidden="true">X</span>
                        </button>
                    </div>
                    <div class="modal-body" style="background-color: rgb(245, 249, 252);margin-top: -1px;">
                        <h3 class="text-center login-panelh1">Enter Tour Id</h3>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <input type="text" [(ngModel)]="tourId" placeholder="Tour Id" class="form-control"
                                        id="tourId">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4 text-left">
                                <div class="form-group">
                                    <button class="btn btn-success form-control" (click)="OpenModel()"
                                        data-dismiss="modal">Next</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>