<div class="right-content" id="rounded-section">
    <div class="heading-panel text-left">
        <h1><img src="./assets/Libs/images/retal.png" height="16"> Retail Order
            <span *ngIf="LastTxNo===true">
                # {{workstation.workStationId}}-{{TxNum}}
            </span>
        </h1>
    </div>

    <div class="retail-order-outer">
        <div class="container-fluid">
            <div class="row justify-content-end">
                <div class="col-md-6 custom-left-section">
                    <div class="leftBg mt-2">
                        <div class="retail-left-topbar ">
                            <div class="row">
                                <div class="col-md-12 cstm-nm">
                                    <div class="customIconBox" [ngClass]="{'disableDiv': isTendorDisable}">
                                        <i class="fa fa-user user"></i>
                                        <input type="text" class="form-control pl-35" placeholder="Customer Name"
                                            [(ngModel)]="customerName" [disabled]="true">
                                            <i (click)="OpenModel('Cust')" class="fa fa-edit edit"[ngClass]="{'disabled-icon': isExhange}"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="retail-table-block" [ngClass]="{'disableDiv': isTransCancil}">
                                        <div class="tblWrapper">
                                            <table
                                                class="table customTable retail-table-section table-bordered order-retail-table">
                                                <thead>
                                                    <tr>
                                                        <td>Product</td>
                                                        <td>Qty</td>
                                                        <td *ngIf="isSRPShow">SRP</td>
                                                        <td>Price</td>
                                                        <td>Discount</td>
                                                        <td>Total</td>
                                                        <td></td>
                                                    </tr>
                                                </thead>
                                                <tbody *ngIf="SelectedProducts != ''">
                                                    <tr *ngFor="let item of SelectedProducts; let i=index">
                                                        <td>{{item.fastButtonName}}</td>
                                                        <td>{{item.qty}}</td>
                                                        <td *ngIf="isSRPShow">{{item.srp | currency}}
                                                        </td>
                                                        <td>{{item.price | currency}}</td>
                                                        <td class="text-danger"><span>- </span>{{item.discount |
                                                            currency}}
                                                            <i [attr.disabled]="siteConfi.discount!=true"
                                                                (click)="OpenModel('Discount',i, item.discount,item.minimumDiscount,item.maximumDiscount)"
                                                                class="fa fa-edit"
                                                                [ngClass]="{'disabled-icon':siteConfi.discount!=true || item.type === 'Exchange' || item.discountable!=true}"></i>
                                                        </td>
                                                        <td>{{item.Total | currency}}</td>
                                                        <td class="trashp"><i class="fa fa-trash fa-2x"
                                                                (click)="ChangeValue('0', item)"
                                                                [ngClass]="{'disabled-icon': item.type === 'Exchange'}"></i>
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="retail-left-bottombar pt-3">

                            <div class="row m-0 h-100">
                                <div class="col-md-8">

                                    <div class="retail-total-outer">
                                        <div class="retail-total-wraps w-100">
                                        <div class="retail-total-panel">
                                            <ul class="retail-total-Type price-details">
                                                <li *ngIf="isSRPShow">
                                                    <h2> SRP Total:</h2>
                                                    <p>{{cartValues.SRPTotal | currency}}</p>
                                                </li>
                                                <li *ngIf="isDiscountShow">
                                                    <h2> Discounts:</h2>
                                                    <p class="red"><span>- </span> {{cartValues.Discounts | currency }}
                                                    </p>
                                                </li>
                                                <li class="total-sales">
                                                    <h2>Sales Total:</h2>
                                                    <p>{{cartValues.TxTotal | currency}}</p>
                                                </li>
                                                <div class="overflow-auto">
                                                    <ul>
                                                    <li *ngIf="CalculateTotalCashAmount > 0">
                                                        <!-- <h2>Cash :</h2> -->
                                                        <h2>{{cashTender}} :</h2>
                                                        <p style="color: rgb(209, 9, 9);">
                                                            {{- CalculateTotalCashAmount | currency}}</p>
                                                    </li>
                                                    <li *ngIf="CalculateTotalRoomAmount > 0">
                                                        <h2>{{roomChargeTender}} :</h2>
                                                        <p style="color: rgb(209, 9, 9);">
                                                            {{- CalculateTotalRoomAmount | currency}}</p>
                                                    </li>
                                                    <li *ngFor="let item of CalculateTotalMoneyCertAmounts">
                                                        <h2>{{item.moneyCertName}} :</h2>
                                                        <p style="color: rgb(209, 9, 9);">
                                                            {{- item.tenderAmount | currency}}</p>
                                                    </li>
                                                    <li *ngIf="totalCardCalc > 0">
                                                        <h2>{{creditcardTender}} :</h2>
                                                        <p style="color: rgb(209, 9, 9);">
                                                            {{- totalCardCalc | currency}}</p>
                                                    </li>
                                                    <li *ngIf="isShowCredit">
                                                        <h2>Credit:</h2>
                                                        <p>{{creditAmont | currency}}</p>
                                                    </li>
                                                    <li *ngIf="cartValues.BalanceDue == 0 && changeDue > 0">
                                                        <h2>Change Due:</h2>
                                                        <p>{{changeDue | currency}}</p>
                                                    </li>
                                                </ul>
                                                </div>
                                            </ul>
                                        </div>
                                        <div class="retail-balance">
                                            <ul>
                                                <li>
                                                    <h2>Balance Due:</h2>
                                                    <p>{{cartValues.BalanceDue |
                                                        currency}}</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="paymentBtn h-100">
                                        <button type="button" class="btn btn-secondary" [disabled]="isTransCancil"
                                            data-target="#tourDepositModel" data-toggle="modal"  (click)="takeDepo()">Take
                                            Deposit</button>
                                        <button type="button" [disabled]="isProcessCancel" (click)="CancelTran()"
                                            class="btn btn-secondary">Cancel</button>
                                        <button type="button" [disabled]="isTransCancil" (click)="pay()"
                                            class="btn mb-0">Payment</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class=" col-md-6 col-padding" *ngIf="isShowPSP">
                    <div class="rightBg mt-2 rightsidebar">
                        <div class="addbttn cstm-min-height-unset">
                            <div class="rightSearch">
                                <i class="fa fa-search"></i><input type="search" [(ngModel)]="searchText"
                                (keyup.enter)="Searchproduct()"   class="form-control" placeholder="Search">
                            </div>
                            <div class="cstm-body-card">
                                <div *ngFor="let section of NewProducts; trackBy: trackBySection">
                                    <h1 class="cstm-heading-inner">{{ section.sectionName }}</h1>
                                    <div class="row productlist-height cstm-productlist-height text-center">
                                        <div class="col-md-3 pb-2 btn-prdctSelect" *ngFor="let product of section.products; trackBy: trackByProduct">
                                            <button class="btn btn-secondary lego-btn custombtn"
                                                (click)="ShowProAddCart(product)"
                                                [ngClass]="{'active': product.isActive }">
                                                {{ product.productName}}
                                                <br>
                                                <small class="p-3"> {{product.price | currency}}</small>
                                            </button>
                                            <div class="number qut-box" *ngIf="product.isActive">
                                                <span class="minusqut" (click)="proQty(product,'minus')"
                                                    [ngClass]="{'disableDiv': product.qty <= 0}">
                                                    <!-- <i class="fa fa-minus"></i> -->
                                                    <svg width="16" height="16" viewBox="0 0 20 20" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M10 18.75C5.1675 18.75 1.25 14.8313 1.25 10C1.25 5.16875 5.1675 1.25 10 1.25C14.8325 1.25 18.75 5.16875 18.75 10C18.75 14.8313 14.8325 18.75 10 18.75ZM10 0C4.47688 0 0 4.475 0 10C0 15.525 4.47688 20 10 20C15.5231 20 20 15.525 20 10C20 4.475 15.5231 0 10 0ZM13.75 9.375H6.25C5.90438 9.375 5.625 9.65625 5.625 10C5.625 10.3438 5.90438 10.625 6.25 10.625H13.75C14.0956 10.625 14.375 10.3438 14.375 10C14.375 9.65625 14.0956 9.375 13.75 9.375Z"
                                                            fill="black" />
                                                    </svg>
                                                </span>
                                                <span class="minusqut">{{product.qty}}</span>
                                                <span class="plusqut" (click)="proQty(product,'plus')"
                                                    [ngClass]="{'disableDiv1': product.qty >= 25 || product.totalPQty <= product.qty}">
                                                    <!-- <i class="fa fa-plus"></i> -->
                                                    <svg width="16" height="16" viewBox="0 0 20 20" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M10 18.75C5.1675 18.75 1.25 14.8313 1.25 10C1.25 5.16875 5.1675 1.25 10 1.25C14.8325 1.25 18.75 5.16875 18.75 10C18.75 14.8313 14.8325 18.75 10 18.75ZM10 0C4.47688 0 0 4.475 0 10C0 15.525 4.47688 20 10 20C15.5231 20 20 15.525 20 10C20 4.475 15.5231 0 10 0ZM13.75 9.375H10.625V6.25C10.625 5.90625 10.3456 5.625 10 5.625C9.65438 5.625 9.375 5.90625 9.375 6.25V9.375H6.25C5.90438 9.375 5.625 9.65625 5.625 10C5.625 10.3438 5.90438 10.625 6.25 10.625H9.375V13.75C9.375 14.0938 9.65438 14.375 10 14.375C10.3456 14.375 10.625 14.0938 10.625 13.75V10.625H13.75C14.0956 10.625 14.375 10.3438 14.375 10C14.375 9.65625 14.0956 9.375 13.75 9.375Z"
                                                            fill="black" />
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="col-md-12 text-right cartbttn cstm-unset-pos pt-4">
                                <button class="cstm-add-cart-btn" (click)="AddtoCart()"> Add to Cart </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6 col-padding" *ngIf="isMoneyShow">
                    <div class="calcBg rightBg-psp mt-2">
                        <div class="rgt-amount-bar">
                            <div class="row amout-section">
                                <div class="col-md-12 mb-2">
                                    <div class="card moneyHeaderContent bm-2">
                                        <div class="card-body">
                                            <div class="amount" [ngClass]="{'disableDiv': isTendorDisable}">
                                                <input type="number" id="AmountItem" min="0"
                                                    value='{{amountTendered}}' class="form-control"
                                                    style="text-align: center;" (keyup)="isDollarExist($event)">
                                                <img src="./assets/Libs/images/dollar.png" alt="dollar"
                                                    class="Doller-Icon">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="cal-area">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="calculatorPanel cal-btn-style calc-row">
                                            <div class="innerRow" [ngClass]="{'disableDiv': isTendorDisable}">
                                                <button *ngFor="let i of [1,2,3]" type="button"
                                                    (click)="PopulateAmount(i)" class="whiteBox">{{i}}</button>
                                                <div id="btn100" class="whiteBox btn green-bg"
                                                    (click)="PopulateAmount(100,true)">$100</div>
                                                <div id="btn100Emt" class="whiteBox  btn green-bg btn-tender"
                                                    style="display:none;">$100</div>

                                            </div>
                                            <div class="innerRow" [ngClass]="{'disableDiv': isTendorDisable}">
                                                <button *ngFor="let i of [4,5,6]" type="button"
                                                    (click)="PopulateAmount(i)" class="whiteBox">{{i}}</button>
                                                <div id="btn50" class="whiteBox  btn green-bg"
                                                    (click)="PopulateAmount(50,true)">$50</div>
                                                <div id="btn50Emt" class="whiteBox  btn green-bg btn-tender"
                                                    style="display:none;">$50</div>

                                            </div>
                                            <div class="innerRow" [ngClass]="{'disableDiv': isTendorDisable}">
                                                <button *ngFor="let i of [7,8,9]" type="button"
                                                    (click)="PopulateAmount(i)" class="whiteBox">{{i}}</button>
                                                <div id="btn20" class="whiteBox btn green-bg"
                                                    (click)="PopulateAmount(20,true)">$20</div>
                                                <div id="btn20Emt" class="whiteBox btn green-bg btn-tender"
                                                    style="display:none;">$20</div>
                                            </div>
                                            <div class="innerRow" [ngClass]="{'disableDiv': isTendorDisable}">
                                                <button type="button" class="whiteBox"
                                                    (click)="PopulateAmount('C')">C</button>
                                                <button type="button" class="whiteBox"
                                                    (click)="PopulateAmount(0)">0</button>
                                                <!-- <button type="button" class="whiteBox"
                                                    (click)="PopulateAmount('.')">.</button> -->
                                                <div class="whiteBox" (click)="PopulateAmount('<')">
                                                    <i class="fa fa-arrow-circle-o-left"></i>
                                                    <!-- fa fa-arrow-left round-fa testing-->
                                                </div>
                                                <div class="whiteBox" style="visibility: hidden;">

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="bottomCashPanel">
                                            <div class="row" [ngClass]="{'disableDiv': isTendorDisable}">
                                                <div class="col-md-12 pymt-option-btn">
                                                    <p-selectButton class="col-md-4  " [options]="types"
                                                        (click)="BtnSelected()" [(ngModel)]="selectedTender"
                                                        style="font-size: small">
                                                    </p-selectButton>
                                                </div>
                                                <div class="col-md-4"></div>
                                                <div class="col-md-4"></div>
                                            </div>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                                <div class="row bottom-three-buttons bck-fnsh-btn">
                                        <div class="col-md-12 d-flex justify-content-between bck-fnsh-btn">
                                            <button class="back-btn"
                                                [ngClass]="{'disableDiv': isBackDisable}"
                                                (click)="PopulateAmount('B')"><i class="fa fa-arrow-left"
                                                    aria-hidden="true"></i>Back</button>
                                            <button class="reset-btn" (click)="PopulateAmount('x')"><i
                                                    class="fa fa-refresh" aria-hidden="true"></i>Reset</button>
                                            <button class="btn fnsh-bg-btn"
                                                [disabled]="isFinish" (click)="FinishTx()">Finish</button>
                                        </div>
                                    </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6 p-0 bggray" *ngIf="isTxPrinting">
                    <div class="rightSearch">
                        <div class="cashTranscation">
                            <h4 class="mt-3 mb-5">Change:{{change | currency}} </h4>

                            <h4 class="mt-5 mb-3">Select your receipt type:</h4>

                            <button class="btn btn-light text-center" (click)="ShowLoader()" [disabled]="isPrintCopy"><i
                                    class="fa fa-print" aria-hidden="true"></i><br> Print
                                Receipt</button>
                            <button class="btn btn-light text-center" [disabled]="isPrintCopy"><i class="fa fa-print"
                                    aria-hidden="true"></i>
                                <br>Print Gift Receipt</button>
                            <button class="btn btn-light text-center" [disabled]="isPrintCopy"><i
                                    class="fa fa-envelope-o" aria-hidden="true"></i><br> Email</button>
                            <button class="btn btn-light text-center" [disabled]="isPrintCopy"><i
                                    class="fa fa-plus-square-o" aria-hidden="true"></i><br> New
                                Sale</button>
                        </div>
                        <div class=" cashTranscation" *ngIf="isPrinting">
                            <div col-sm-6>
                                <h3>Printing….</h3>
                            </div>
                            <div col-sm-6>
                                <img src="./assets/Libs/images/dfgdfgdgdg.gif" height="100">
                            </div>

                        </div>
                    </div>
                </div>

                <div class="col-md-6  col-padding" *ngIf="isCCPrinting">
                    <div class="rightBg mt-2 rightSearch creditCa">
                        <div class="cashTranscation">
                            <input type="text" min="0" value='{{selectedCCAmount | currency}}' placeholder="0.00"
                                class="form-control" style="text-align: center;">

                            <h4 class="mt-5 mb-3">Swipe or Insert Credit Card</h4>
                            <p><img src="./assets/Libs/images/imageCardSwipe.png"></p>
                        </div>
                        <div class="creditbttn">
                            <button type="submit" id='btnSubmit' (click)="cancelCCPayment()"
                                class="btn btn-light mr-2">Cancel</button><br>
                            <button type="submit" id='btnSubmit' (click)="CompleteTransactionCCpayment()"
                                class="btn btn-light">Manual Entry</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div [style.display]="showModal ? 'block' : 'none'" class="modal name-popup" id="imagemodal" tabindex="-1"
        role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
        <div class="modal-dialog-custom">
            <div class="modal-content">
                <div class="modal-header modelHeader">
                    <h4 class="modal-title" id="myModalLabel">Customer Name</h4>
                </div>
                <div class="modal-body text-right">
                    <form [formGroup]="registerForm">
                        <div class="form-group">
                            <input autofocus type="text" pattern="[a-z A-Z ]*" placeholder="First Name"
                                formControlName="FirstName" [(ngModel)]="user.firstName" (keyup.enter)="keytab($event)"
                                (keydown)="keytab($event)"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && f.FirstName.errors }" />
                            <div *ngIf="submitted && f.FirstName.errors" class="invalid-feedback">
                                <div *ngIf="f.FirstName.errors.required">First Name is required</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <input type="text" pattern="[a-zA-Z ]*" placeholder="Last Name" formControlName="LastName"
                                id='lastName' [(ngModel)]="user.lastName" (keyup.enter)="FocusSubmit($event)"
                                (keydown)="keytab($event)"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && f.LastName.errors }" />
                            <div *ngIf="submitted && f.LastName.errors" class="invalid-feedback">
                                <div *ngIf="f.LastName.errors.required">Last Name is required</div>
                            </div>
                        </div>
                        <button type="submit" id='btnSubmit' (click)='onSubmit()'
                            class="btn btn-light text-right">Continue</button>
                    </form>
                </div>

            </div>
        </div>
    </div>

    <!--For SignIn and Password Validations-->

    <!-- ------------------------------------------------------------------------------------------------------------------------------- -->
    <div [style.display]="MySingInModal ? 'block' : 'none'" class="modal name-popup" id="imgmodal" tabindex="-1"
        role="dialog" aria-labelledby="myMLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content signinClass">
                <div class="modal-header SignInHeader">
                    <button type="button" class="close" data-dismiss="modal" (click)="hide('SignInModal')">×</button>
                </div>
                <div class="row col-md-12">
                    <div class="col-md-4 ">
                        <img class="SignInImageIcon" src="../../../../assets/Libs/images/logo-icon.png">
                    </div>
                    <div class="modal-body col-md-8">
                        <h1 class="modal-title text-heading-color" id="myLabel">SIGN IN</h1>
                        <form [formGroup]="LoginAdjuctGroup" (ngSubmit)="AdjustLogin()">
                            <div class="form-group">

                                <input type="text" placeholder="Username" formControlName="UserNameLogin"
                                    class="form-control" [ngClass]="{ 'is-invalid': Login.UserNameLogin.errors }" />
                                <div *ngIf="Login.UserNameLogin.errors" class="invalid-feedback">
                                    <div *ngIf="Login.UserNameLogin.errors.required">UserName is required</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="password" placeholder="Password" formControlName="PasswordLogin"
                                    class="form-control" [ngClass]="{ 'is-invalid': Login.PasswordLogin.errors }" />
                                <div *ngIf="Login.PasswordLogin.errors" class="invalid-feedback">
                                    <div *ngIf="Login.PasswordLogin.errors.required">Password is required</div>
                                </div>
                            </div>
                            <button type="submit" id='btnSubmit' class="btn btn-light  form-control">LOGIN</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- --------------------------------------------------------------------------------------------------------------------------------- -->

    <div [style.display]="isshowDisModal ? 'block' : 'none'" class="modal " id="imagemodal" tabindex="-1" role="dialog"
        aria-labelledby="myModalLabel" aria-hidden="true">
        <div class="modal-dialog model-dialog-style">
            <div class="modal-content model-style">
                <div class="col-md-12 p-0">
                    <div class="opening-count-block">
                        <div class="modal-header modal_header align-items-center">
                            <div  class="text-center modal_discount_title m-0">Enter Discount Amount</div><br />
                            <button type="button" (click)="hide('Discount')" class="close" data-dismiss="modal"
                                aria-label="Close">
                                <span _ngcontent-fdf-c322="" aria-hidden="true" class="icon-xmark"></span>
                            </button>
                        </div>
                    <div class="modal-body">
                            <div class="h-popup text-danger text-center">Minimum {{MinDiscounts| currency}}, Maximum {{MaxDiscounts| currency}}
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="count-input-discount form-group">
                                        <input type="text"
                                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                            id="textDiscount" min="0" [(ngModel)]="discountAmt" (keyup)="CheckCheractor($event)"
                                            class="form-control text-center">
                                            <img _ngcontent-mks-c322="" src="./assets/Libs/images/dollar.png" alt="dollar" class="Dollar-Icon2">
                                    </div>
                                </div>
                            </div>
                            <div class="discount-count-block">
                        <div class="count-buttons-block">
                            <button class="btn discount-count-button" (click)="PopulateDisAmount(1)">1</button>
                            <button class="btn discount-count-button" (click)="PopulateDisAmount(2)">2</button>
                            <button class="btn discount-count-button mr-0" (click)="PopulateDisAmount(3)">3</button>
                            <button class="btn discount-count-button" (click)="PopulateDisAmount(4)">4</button>
                            <button class="btn discount-count-button" (click)="PopulateDisAmount(5)">5</button>
                            <button class="btn discount-count-button mr-0" (click)="PopulateDisAmount(6)">6</button>
                            <button class="btn discount-count-button" (click)="PopulateDisAmount(7)">7</button>
                            <button class="btn discount-count-button" (click)="PopulateDisAmount(8)">8</button>
                            <button class="btn discount-count-button mr-0" (click)="PopulateDisAmount(9)">9</button>
                            <button class="btn discount-count-button" (click)="PopulateDisAmount('c')">C</button>
                            <button class="btn discount-count-button" (click)="PopulateDisAmount(0)">0</button>
                            <button class="btn discount-count-button mr-0" (click)="PopulateDisAmount('x')">
                                <i class="fa fa-arrow-circle-o-left"></i></button>
                        </div>
                    </div>
                        <div class="d-flex justify-content-center w-100">
                            <button class="btn btn-primary mt-2 custom-btn"
                                (click)="PopulateDiscountAmount(discountAmt)">Continue</button>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>

    <!-- <div [style.display]="isTourModelShow ? 'block' : 'none'" class="modal " id="imagemodal" tabindex="-1" role="dialog"
        aria-labelledby="myModalLabel" aria-hidden="true">
        <div class="modal-dialog model-dialog-style new-design">
            <div class="modal-content pt-2 pb-3 model-style">
                <div class="col-md-12 p-0">
                    <div class="opening-count-block text-center">
                        <div class="modal-header">
                            <h2 class="h-popup" style="text-align: center;">Enter Tour Deposit Amount</h2>
                            <button type="button" (click)="hide('TourDeposit')" class="close" data-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="count-input form-group">
                            <input type="number" (paste)="(false)" min="0" [(ngModel)]="tourAmt" class="form-control">
                        </div>
                        <div class="count-buttons-block">
                            <button class="btn count-button" (click)="PopulateTourAmount(1)">1</button>
                            <button class="btn count-button" (click)="PopulateTourAmount(2)">2</button>
                            <button class="btn count-button mr-0" (click)="PopulateTourAmount(3)">3</button>
                            <button class="btn count-button" (click)="PopulateTourAmount(4)">4</button>
                            <button class="btn count-button" (click)="PopulateTourAmount(5)">5</button>
                            <button class="btn count-button mr-0" (click)="PopulateTourAmount(6)">6</button>
                            <button class="btn count-button" (click)="PopulateTourAmount(7)">7</button>
                            <button class="btn count-button" (click)="PopulateTourAmount(8)">8</button>
                            <button class="btn count-button mr-0" (click)="PopulateTourAmount(9)">9</button>
                            <button class="btn count-button" (click)="PopulateTourAmount(0)">0</button>
                            <button class="btn count-button" (click)="PopulateTourAmount('.')">.</button>
                            <button class="btn count-button mr-0" (click)="PopulateTourAmount('x')"><i
                                    class="fa fa-times"></i></button>
                        </div>
                        <div class="d-flex justify-content-center w-100">
                            <button class="btn btn-primary mt-2 custom-btn"
                                (click)="PopulateTourAmt(tourId,tourAmt)">Continue</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <div [style.display]="isTourModelShow ? 'block' : 'none'" class="modal " id="imagemodal" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog model-dialog-style new-design">
        <div class="modal-content model-style">
            <div class="col-md-12 p-0">
                <div class="opening-count-block">
                    <div class="modal-header modal_header align-items-center">
                        <div class="text-center modal_room_title m-0">Enter Tour Deposit Amount</div>
                        <button _ngcontent-awc-c322="" type="button" (click)="SoldForKeypadCross()" data-dismiss="modal" aria-label="Close"class="close">
                            <span _ngcontent-awc-c322="" aria-hidden="true" class="icon-xmark"></span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="count-input-room form-group">
                                    <input id="roomChargeId" type="string" [(ngModel)]="tourAmt"
                                        (keyup)="valRoomNumber($event)" value="" class="form-control" ng-reflect-model="0.00">
                                </div>
                            </div>
                        </div>
                        <div class="room-count-block mt-1 mb-2">
                            <div class="count-buttons-block">
                                <button class="btn room-count-button" (click)="handleKeyPress('1')">1</button>
                                <button class="btn room-count-button" (click)="handleKeyPress('2')">2</button>
                                <button class="btn room-count-button mr-0" (click)="handleKeyPress('3')">3</button>
                                <button class="btn room-count-button" (click)="handleKeyPress('4')">4</button>
                                <button class="btn room-count-button" (click)="handleKeyPress('5')">5</button>
                                <button class="btn room-count-button mr-0" (click)="handleKeyPress('6')">6</button>
                                <button class="btn room-count-button" (click)="handleKeyPress('7')">7</button>
                                <button class="btn room-count-button" (click)="handleKeyPress('8')">8</button>
                                <button class="btn room-count-button mr-0" (click)="handleKeyPress('9')">9</button>
                                <button class="btn room-count-button" (click)="handleKeyPress('C')">C</button>
                                <button class="btn room-count-button" (click)="handleKeyPress('0')">0</button>
                                <button class="btn room-count-button mr-0"  (click)="handleKeyPress('backspace')">
                                    <i class="fa fa-arrow-circle-o-left"></i>
                                </button>
                            </div>
                        </div>
                        <div class="row justify-content-center align-items-center">
                            <button class="btn custom-btn activeBTN"
                            (click)="PopulateTourAmt()">Continue</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

    <!-- <div class="modal fade modal_content_cstm" id="tourDepositModel" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog tour-moda modal-dialog-centered" role="document">
            <div class="modal-content modal_content_cstm">
                <div class="modal-header modal_header align-items-center">
                    <div class="text-center modal_tour_title">Enter Tour ID</div>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true" class="icon-xmark"></span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-sm-12 ">
                            <div class="form-group count-input-tour">
                                <input type="text" [(ngModel)]="tourId" placeholder="Tour Id" class="form-control"
                                    id="tourId">
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="tour-count-block mt-1 mb-2">
                            <div class="count-buttons-block">
                                <button class="btn tour-count-button">1</button>
                                <button class="btn tour-count-button">2</button>
                                <button class="btn tour-count-button mr-0">3</button>
                                <button class="btn tour-count-button">4</button>
                                <button class="btn tour-count-button">5</button>
                                <button class="btn tour-count-button mr-0">6</button>
                                <button class="btn tour-count-button">7</button>
                                <button class="btn tour-count-button">8</button>
                                <button class="btn tour-count-button mr-0">9</button>
                                <button class="btn tour-count-button">C</button>
                                <button class="btn tour-count-button">0</button>
                                <button class="btn tour-count-button mr-0"><i
                                        class="fa fa-arrow-circle-o-left"></i></button>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center align-items-center">
                        <div class="col-sm-5 text-center">
                            <button class="btn btn-cntn form-control">Continue</button>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <div [style.display]="isTourIdModelShow ? 'block' : 'none'" class="modal " id="imagemodal" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog model-dialog-style new-design">
        <div class="modal-content model-style">
            <div class="col-md-12 p-0">
                <div class="opening-count-block">
                    <div class="modal-header modal_header align-items-center">
                        <div class="text-center modal_room_title m-0">Enter Tour ID</div>
                        <button _ngcontent-awc-c322="" type="button" (click)="hide('isTourIdModelShow')" data-dismiss="modal" aria-label="Close"class="close">
                            <span _ngcontent-awc-c322="" aria-hidden="true" class="icon-xmark"></span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="count-input-room form-group">
                                    <input id="roomChargeId" type="string" [(ngModel)]="tourIdInput"
                                        (keyup)="valRoomNumber($event)" value="" class="form-control" ng-reflect-model="0.00">
                                </div>
                            </div>
                        </div>
                        <div class="room-count-block mt-1 mb-2">
                            <div class="count-buttons-block">
                                <button class="btn room-count-button" (click)="PopulatetourId(1)">1</button>
                                <button class="btn room-count-button" (click)="PopulatetourId(2)">2</button>
                                <button class="btn room-count-button mr-0" (click)="PopulatetourId(3)">3</button>
                                <button class="btn room-count-button" (click)="PopulatetourId(4)">4</button>
                                <button class="btn room-count-button" (click)="PopulatetourId(5)">5</button>
                                <button class="btn room-count-button mr-0" (click)="PopulatetourId(6)">6</button>
                                <button class="btn room-count-button" (click)="PopulatetourId(7)">7</button>
                                <button class="btn room-count-button" (click)="PopulatetourId(8)">8</button>
                                <button class="btn room-count-button mr-0" (click)="PopulatetourId(9)">9</button>
                                <button class="btn room-count-button" (click)="PopulatetourId('c')">C</button>
                                <button class="btn room-count-button" (click)="PopulatetourId(0)">0</button>
                                <button class="btn room-count-button mr-0" (click)="PopulatetourId('x')">
                                    <i class="fa fa-arrow-circle-o-left"></i>
                                </button>
                            </div>
                        </div>
                        <div class="row justify-content-center align-items-center">
                            <button class="btn custom-btn activeBTN"
                                (click)="ContinueForTourAmount()">Continue</button>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

    <div [style.display]="isshowRoomModal ? 'block' : 'none'" class="modal " id="imagemodal" tabindex="-1" role="dialog"
        aria-labelledby="myModalLabel" aria-hidden="true">
        <div class="modal-dialog model-dialog-style new-design">
            <div class="modal-content model-style">
                <div class="col-md-12 p-0">
                    <div class="opening-count-block">
                        <div class="modal-header modal_header align-items-center">
                            <div class="text-center modal_room_title m-0">Enter Room Number</div>
                            <button _ngcontent-awc-c322="" type="button" data-dismiss="modal" aria-label="Close" (click)="hide('RoomCharge')"
                                class="close">
                                <span _ngcontent-awc-c322="" aria-hidden="true" class="icon-xmark"></span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="count-input-room form-group">
                                        <input id="roomChargeId" type="string" [(ngModel)]="roomNumber"
                                            (keyup)="valRoomNumber($event)" value="" class="form-control" ng-reflect-model="0.00">
                                        <!-- <img _ngcontent-kbn-c322="" src="./assets/Libs/images/dollar.png" alt="dollar" class="Dollar-Icon2"> -->
                                    </div>
                                </div>
                            </div>
                            <div class="room-count-block mt-1 mb-2">
                                <div class="count-buttons-block">
                                    <button class="btn room-count-button" (click)="PopulateRoomNumber(1)">1</button>
                                    <button class="btn room-count-button" (click)="PopulateRoomNumber(2)">2</button>
                                    <button class="btn room-count-button mr-0" (click)="PopulateRoomNumber(3)">3</button>
                                    <button class="btn room-count-button" (click)="PopulateRoomNumber(4)">4</button>
                                    <button class="btn room-count-button" (click)="PopulateRoomNumber(5)">5</button>
                                    <button class="btn room-count-button mr-0" (click)="PopulateRoomNumber(6)">6</button>
                                    <button class="btn room-count-button" (click)="PopulateRoomNumber(7)">7</button>
                                    <button class="btn room-count-button" (click)="PopulateRoomNumber(8)">8</button>
                                    <button class="btn room-count-button mr-0" (click)="PopulateRoomNumber(9)">9</button>
                                    <button class="btn room-count-button" (click)="PopulateRoomNumber(0)">0</button>
                                    <button class="btn room-count-button" (click)="PopulateRoomNumber('.')">.</button>
                                    <button class="btn room-count-button mr-0" (click)="PopulateRoomNumber('x')">
                                        <i class="fa fa-arrow-circle-o-left"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="row justify-content-center align-items-center">
                                <button class="btn custom-btn activeBTN"
                                    (click)="PopulateRoomNumberContinue()">Continue</button>
                                <!-- <button class="btn btn-primary mt-2 custom-btn"
                                    (click)="PopulateRoomNumberCancel()">Cancel</button> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>