import { Component, OnInit, Optional } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ShiftService } from 'src/app/Services/shift.service';
import { AuthService } from 'src/app/Services/auth.service';
import { ConfirmationService } from 'primeng/api';
//import { MiddleWare } from 'src/app/Services/middleware.service';
import Swal from 'sweetalert2';
import * as $ from 'jquery';
import { NavigationComponent } from '../navigation.component';
import { InventoryService } from 'src/app/Services/inventory.service';
import { ToastrService } from 'ngx-toastr';
import { TTCheckbox } from 'primeng';
import { InventoryModel } from '../inventory-model.model';
import { StringHelper } from 'src/app/shared/helpers/string.helper';
import { PortExeMsgHelper } from 'src/app/shared/helpers/port-exe-msg.helper';
@Component({
  selector: 'app-shift-opening',
  templateUrl: './shift-opening.component.html',
  styleUrls: ['./shift-opening.component.css']
})
export class ShiftOpeningComponent implements OnInit {
  isChangeTotal = false;
  message: any;
  isnotPreview = true;
  isPreview: boolean = false;
  isInventory: boolean = false;
  isOut = false;
  inventoryOutage: Array<InventoryModel> = [];
  confirmWidth: string = '40vw';
  openingCount: any = '';
  prevShiftAmountt: any;
  workstation: any;
  currentUser: any;
  printJobId = 0;
  changeTotal = 0;
  hunders = 0;
  fifities = 0;
  twnties = 0;
  viewData: any = [];
  viewTransferData: any = [];
  tens: number = 0;
  fives: number = 0;
  once = 0;
  SessionOutFlag: any;
  messagestring: string;
  messageShowed: boolean;
  isCashScreen: boolean = true;
  siteConfi: any;
  TotalCash: number | string = this.changeTotal + this.hunders + this.fifities + this.twnties + this.tens + this.fives;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private _shiftService: ShiftService,
    private authenticationService: AuthService,
    private confirmationService: ConfirmationService,
    private invService: InventoryService,
   // private _middlewareService: MiddleWare,
     @Optional() private nav: NavigationComponent, private toastr: ToastrService) { }

  CancilOpeningCount() {
    this.router.navigate(['/LoginComponent'], { relativeTo: this.route });
  }

  ngOnInit() {
    debugger;
    this.currentUser = this.authenticationService.currentUserValue;
    this.workstation = JSON.parse(localStorage.getItem('workstation'));
    this.siteConfi = JSON.parse(localStorage.getItem("siteConfivalidated"))
    this.SessionOutFlag = JSON.parse(localStorage.getItem("StartSessionOutShift"))
    console.log("sessionoutflag",this.SessionOutFlag);
    localStorage.getItem('')
    this.nav.isdisabledNav = true;
    localStorage.setItem("isdisabledNavBar", 'true');
    localStorage.setItem("IsNavigationShow", 'true')
    console.log('Navvalues', this.nav)
    this.invService.GetInventoryCount(this.workstation.workStationId, false, this.currentUser.districtNum)
      .subscribe((viewInvetory) => {
        this.viewData = this.viewTransferData = viewInvetory;
        console.log(viewInvetory);
      });
    this.nav.isdisabledNav = false;
    localStorage.setItem("isdisabledNavBar", 'false');
    this.SkipCashBalance();


    // this._shiftService.GetPrinterStatus(this.workstation.workStationId).subscribe(() => {
    // });
  }


  CalButton(){
    debugger;
    var active=0;
    console.log("I Am Working");
    $('.cash-calculator').toggleClass('active').promise().done(function () {
      if ($(this).hasClass('active')) {
        active=1;
        $('.pin-tab').addClass('open');
        $('.note-tab').addClass('content-crd');
      } 
      else {
        $('.pin-tab').removeClass('open');
        $('.note-tab').removeClass('content-crd');
        active=1;
       
      }
    });

//     if(active==0){
// this.cidNote=true;
//     }
//     else{
//       this.cidNote=false;
//     }

  }

  ShowCashAndProductCofirmation() {
    debugger
    this.message = "<p class='worstation-img'><img src='./assets/Libs/images/confirmation.png'></p> <p class='assignstyle cstm-strt-style text-justify'> When balancing inventory and cash, always count and/or verify the entry you are making. Always enter the exact number or amount counted or checked.<span>Never force balance any balancing screen.</span>​All entries made by you are logged and reviewed by the accounting department. Falsification of company records can result in disciplinary action up to and including suspension pending separation of employment.</p>"
    this.confirmationService.confirm({
      message: this.message,
      header: 'Confirmation',
      rejectVisible: false,
      acceptLabel: 'OK',
      acceptButtonStyleClass: 'acceptBtn',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        $('.ui-dialog-mask-scrollblocker').css('display', 'none');
      }
    });
  }

  PopulateAmount(value) {
    debugger;
    if (value === 'x') {
      let openingBal = "" + this.openingCount
      this.openingCount = openingBal.slice(0, -1);
      this.openingCount = this.openingCount || "0";
    } else {
      this.openingCount = "" + this.openingCount + value;
    }
    this.openingCount = StringHelper.CashAmountInputValidator(this.openingCount);
  }

  valOpeningCount(event) {
    debugger;
    this.openingCount = StringHelper.CashAmountInputValidator(event.target.value);
  }

  onChange(value, item) {
    debugger;
    if (value !== '') {
      this.TotalCash = (((+this.hunders) * 100) + ((+this.fifities) * 50) + ((+this.twnties) * 20) + ((+this.tens) * 10) + ((+this.fives) * 5) + ((+this.once) * 1))
      var result = (this.changeTotal / 100);
      this.TotalCash = (this.TotalCash + result).toFixed(2);
      this.openingCount = this.TotalCash;

    } else {
      this.TotalCash = (((+this.hunders) * 100) + ((+this.fifities) * 50) + ((+this.twnties) * 20) + ((+this.tens) * 10) + ((+this.fives) * 5) + ((+this.once) * 1))
      this.openingCount = this.TotalCash.toFixed(2);
    }
  }

  ResetOpeningCount() {
    this.openingCount = 0;
    this.TotalCash = this.openingCount;
    this.hunders = this.fifities = this.twnties = this.tens = this.fives = this.once = this.openingCount = this.changeTotal = 0;
  }

  SkipCashBalance() {
    debugger;
    this.isnotPreview = false;
    this._shiftService.GetPreviousShiftBalence(this.workstation.workStationId + "-" + this.workstation.lastShiftNum)
      .subscribe((res) => {
        this.prevShiftAmountt = parseFloat(res) || 0;
        let message = '';
        this.invService.GetInventoryCount(this.workstation.workStationId, false, this.currentUser.districtNum)
          .subscribe(async (viewInvetory) => {
            let inventoryProduct;
            inventoryProduct = viewInvetory;
            console.log('prevShiftAmountt: ', this.prevShiftAmountt)
            console.log('inventoryProduct length: ', inventoryProduct.length)
            debugger;
            if (this.prevShiftAmountt === 0 && inventoryProduct.length === 0) {
              console.log('call condition 1');
              if (this.SessionOutFlag) {
                this.StartSessionOutShift()
              }
              else {
                await this.saveShiftDetaisAsync();
                this.isPreview = false;
                this.isInventory = false;
              }

            }
            else if (this.prevShiftAmountt === 0 && inventoryProduct.length > 0) {
              if (this.SessionOutFlag) {
                this.StartSessionOutShift()
              }
              else {
                console.log('call condition 2');
                this.confirmWidth = '20vw';
                this.isInventory=true;
                this.ShowCashAndProductCofirmation();
               // this.saveShiftDetais();
              }
            }
            else if (this.prevShiftAmountt > 0  && inventoryProduct.length === 0) {
              if (this.SessionOutFlag) {
                this.StartSessionOutShift()
              }
              else {
                console.log('call condition 2');
                this.confirmWidth = '20vw';
                this.isnotPreview = true;
                this.ShowCashAndProductCofirmation();
              //  this.saveShiftDetais();
              }
            }
            else {
              if (this.SessionOutFlag) {
                this.StartSessionOutShift()
              }
              else {
                this.isInventory = false;
                this.isnotPreview = true;
                console.log('call condition 3');
                this.ShowCashAndProductCofirmation();
                return false;
              }

            }
          });


      });
  }

  StartSessionOutShift() {
    this.nav.isdisabledNav = false;
    localStorage.setItem("isdisabledNavBar", 'false');
    this.OpenPrimaryTrasactionType(this.siteConfi.primaryTxType)

  }
  async SubmitOpeningCount() {
    debugger;
    let inventoryProduct;
    if (this.openingCount === '') {
      this.toastr.error('Enter cash balance before continue!');
    }
    else {
      //else{
      this._shiftService.GetPreviousShiftBalence(this.workstation.workStationId + "-" + this.workstation.lastShiftNum)
        .subscribe((res) => {
          this.prevShiftAmountt = parseFloat(res);
          let message = '';
          inventoryProduct = this.viewData;
          if ((this.openingCount === '' ? 0 : parseFloat(this.openingCount)) === this.prevShiftAmountt) {
            
            if (inventoryProduct.length === 0) {
              this.saveShiftDetaisAsync();
              this.confirmWidth = '20vw';
            }
            else {
              this.confirmWidth = '20vw';
             // this.saveShiftDetais();
             this.isnotPreview = false;
             this.isPreview = false;
             this.isInventory = true;
            }
          }
          else {
            debugger
            message = "<p class='worstation-img'><img src='./assets/Libs/images/confirmation.png'></p> <p class='assignstyle'> <h3 class='pop-head text-danger'>OUT OF BALANCE! </h3></p> <p class='assignstyle'>Your manager will be notified. Do you want to continue?</p>";
            this.confirmWidth = '40vw'
            this.confirmationService.confirm({
              message: message,
              header: 'Confirmation',
              rejectLabel: 'No',
              acceptLabel: 'Yes',
              rejectButtonStyleClass: 'outShiftBtn',
              icon: 'pi pi-exclamation-triangle',
              accept: () => {
                $('.ui-dialog-mask-scrollblocker').css('display', 'none');

                if (inventoryProduct.length === 0) {
                this.saveShiftDetais();
                }
                else{
                  this.isnotPreview = false;
                  this.isPreview = false;
                  this.isInventory = true;
                }
              },
              reject: () => {
                $('.ui-dialog-mask-scrollblocker').css('display', 'none');
              }
            });
          }

        });
    }

  }

  SaveInventory(falg?: number) {
    // debugger;
    if (falg == 1) {
      
      this.SaveInventoryItem();
    }
    else {
      if (this.viewData.filter(x => x.checkQTY !== x.totalProdQTY).length !== 0) {
        this.isPreview = false;
        this.isInventory = true;
      }
      else {
        if ((this.openingCount === '' ? 0 : parseFloat(this.openingCount)) === this.prevShiftAmountt) {
          this.SaveShiftReport();
          this.isPreview = false;
          this.isInventory = false;
        }
        else {
          this.SaveShiftReport();
          this.isPreview = false;
          this.isInventory = false;
        }

      }
    }

  }

  SaveInventoryItem() {
debugger
    if (this.viewData.filter(x => x.checkQTY !== x.totalProdQTY).length !== 0) {
      setTimeout(() => {
        this.confirmWidth = '40vw'
        this.confirmationService.confirm({
          message: "<p class='worstation-img'><img src='./assets/Libs/images/confirmation.png'></p> <p class='assignstyle'> <h3 class='pop-head text-danger'>OUT OF BALANCE! </h3></p> <p class='assignstyle'>Your manager will be notified. Do you want to continue?</p>",
          header: 'Confirmation',
          acceptLabel: 'Yes',
          rejectVisible: true,
          accept: () => {
            $('.ui-dialog-mask-scrollblocker').css('display', 'none');
            this.SaveShiftReport();
            this.isPreview = false;
            this.isInventory = false;
            this.saveShiftDetais();
          },
          reject: () => {
            $('.ui-dialog-mask-scrollblocker').css('display', 'none');
            this.isPreview = false;
            this.isInventory = true;
          }
        });
      }, 500);
    }
    else {
      // if ((this.openingCount === '' ? 0 : parseFloat(this.openingCount)) === this.prevShiftAmountt) {
      //   this.SaveShiftReport();
      //   this.isPreview = false;
      //   this.isInventory = false;
      // }
      // else {
      //   this.SaveShiftReport();
      //   this.isPreview = false;
      //   this.isInventory = false;
      // }
       this.saveShiftDetais();
       this.SaveShiftReport();
       this.isPreview = false;
       this.isInventory = false;

    }

  }


  saveShiftDetais() {
    debugger;
    this.messageShowed = false;
    let shiftDetails = {
      WorkstationId: this.workstation.workStationId,
      Cidtotal: Number(this.openingCount === '' ? 0 : this.openingCount),
      ShiftNum: this.workstation.workStationId + "-" + this.workstation.lastShiftNum,
      UserId: this.currentUser.userId
    }
    this._shiftService.SaveOpenShiftDetails(shiftDetails)
      .subscribe((res) => {
        this.isnotPreview = false;
        this.isPreview = false;
        this.isInventory = false;
        this.SaveInventory();
      });
  }


  saveShiftDetaisAsync() {
    console.log('call saveShiftDetaisAsync');
    debugger;
    return new Promise((resolve) => {
      this.messageShowed = false;
      let shiftDetails = {
        WorkstationId: this.workstation.workStationId,
        Cidtotal: Number(this.openingCount === '' ? 0 : this.openingCount),
        ShiftNum: this.workstation.workStationId + "-" + this.workstation.lastShiftNum,
        UserId: this.currentUser.userId
      }
      this._shiftService.SaveOpenShiftDetails(shiftDetails)
        .subscribe((res) => {
          this.isnotPreview = false;
          this.isPreview = false;
          this.isInventory = false;
          resolve('saveShift complete');
          this.SaveShiftReport();
        });
    });
  }
  saveShiftDetaisSessionOutAsync() {
    console.log('call saveShiftDetaisAsync');
    debugger;
    return new Promise((resolve) => {
      this.messageShowed = false;
      let shiftDetails = {
        WorkstationId: this.workstation.workStationId,
        Cidtotal: Number(this.openingCount === '' ? 0 : this.openingCount),
        ShiftNum: this.workstation.workStationId + "-" + this.workstation.lastShiftNum,
        UserId: this.currentUser.userId,
        SeesionOutFlag: true
      }
      this._shiftService.SaveOpenShiftDetails(shiftDetails)
        .subscribe((res) => {
          this.isnotPreview = false;
          this.isPreview = false;
          this.isInventory = false;
          resolve('saveShift complete');
          //this.SaveShiftReport();
        });
    });
  }


  SaveShiftReport() {
    this.SaveOutageData();
    let setthis = this;
    debugger;
    let shiftReport = {
      Wsid: this.workstation.workStationId,
      WsName: this.workstation.workStationName,
      UserId: this.currentUser.userId,
      Total: Number(this.openingCount === '' ? 0 : this.openingCount),
      CurrentShiftNum: this.workstation.workStationId +"-"+ (parseInt(this.workstation.lastShiftNum) + 1),
      IvnOutage: setthis.inventoryOutage
    }
    this.nav.currentShiftNum = this.workstation.workStationId +"-"+ (parseInt(this.workstation.lastShiftNum) + 1);
    this._shiftService.SaveOpenShiftReport(shiftReport)
      .subscribe((res) => {
        this.printJobId = res;
        this.nav.isdisabledNav = false;
        localStorage.setItem("isdisabledNavBar", 'false');
        //this.PrintReport();
        setTimeout(() => {
          this.OpenPrimaryTrasactionType(this.siteConfi.primaryTxType)
        }, 250);
      });
  }

  OpenPrimaryTrasactionType(type: number) {
    debugger;
    
    if (type === 1) {
      this.router.navigate(['../ProductCart'], { relativeTo: this.route });
      localStorage.setItem('Transtype', JSON.stringify(type));
    }
    else if (type === 2) {
      this.router.navigate(['../PregiftingCardProduct'], { relativeTo: this.route });
      localStorage.setItem('Transtype', JSON.stringify(type));
    }
    else if (type === 3) {
      this.router.navigate(['../GiftingCardProduct'], { relativeTo: this.route });
      localStorage.setItem('Transtype', JSON.stringify(type));
    }
    else if (type === 4) {
      this.router.navigate(['../OpenGiftingCardProduct'], { relativeTo: this.route });
      localStorage.setItem('Transtype', JSON.stringify(type));
    }

  }




  SaveShiftReportAsync() {
    debugger;
    this.SaveOutageData();
    let setthis = this;
    debugger;
    let shiftReport = {
      Wsid: this.workstation.workStationId,
      WsName: this.workstation.workStationName,
      UserId: this.currentUser.userId,
      Total: Number(this.openingCount === '' ? 0 : this.openingCount),
      CurrentShiftNum: this.workstation.workStationId +"-"+ (parseInt(this.workstation.lastShiftNum) + 1),
      IvnOutage: setthis.inventoryOutage
    }
    this.nav.currentShiftNum = this.workstation.workStationId +"-"+ (parseInt(this.workstation.lastShiftNum) + 1);
    this._shiftService.SaveOpenShiftReport(shiftReport)
      .subscribe((res) => {
        this.printJobId = res;
        this.nav.isdisabledNav = false;
        localStorage.setItem("isdisabledNavBar", 'false');
        //this.PrintReport();
        setTimeout(() => {
          //this.router.navigate(['../ProductCart'], { relativeTo: this.route });
          this.OpenPrimaryTrasactionType(this.siteConfi.primaryTxType)
        }, 250);
      });
  }

  CheckKeys(events) {
    debugger;
    if (events.code == 'KeyE' || events.keyCode === 45) {
      events.preventDefault();
      return;
    }
    if (events.key === "Tab" || events.key === "Enter") {
      var self = $(this),form = self.parents('form:eq(0)'),focusable, next;    
      focusable = form.find('input,a,select,button,textarea');
      next = focusable.eq(focusable.index(this) + 1);
      if (next.length) {
        next.focus();
      } else {
        form.submit();
      }
    }

  }

  CheckOutBal(event) {
    debugger
    if (event.totalProdQTY !== event.checkQTY) {
      event.isOut = true;
    } else {
      event.isOut = false;
    }
  }

  BackToCashBal() {
    this.isPreview = false;
    this.isInventory = false;
    this.isnotPreview = true;

    this.GetBackToLogoutZeroPreShiftAmt();
  }

  GetBackToLogoutZeroPreShiftAmt() {
    this._shiftService.GetPreviousShiftBalence(this.workstation.workStationId + "-" + this.workstation.lastShiftNum)
      .subscribe((res) => {
        let result = parseFloat(res) || 0;
        if (result === 0) {
          this.CancilOpeningCount();
        }
      });
  }

  ResetInventory() {
    this.invService.GetInventoryCount(this.workstation.workStationId, false, this.currentUser.districtNum)
      .subscribe((viewInvetory) => {
        this.viewData = this.viewTransferData = viewInvetory;
        console.log(viewInvetory);
      });
  }

  // PrintReport() {
  //   let midResponse = this._middlewareService.ShiftOpenReport(this.printJobId, 'ShiftStartReport');
  //   midResponse = PortExeMsgHelper.GetPortStatusMessage(midResponse);
  //   setTimeout(() => {
  //     if (midResponse.includes('success')) {
  //       const messages = '<img src="./assets/Libs/images/WarnQues.png" class="imgconfQues" /><p>Do want another copy of the Start Day Report?</p>';
  //       this.confirmationService.confirm({
  //         message: messages,
  //         header: 'Print another copy?',
  //         rejectVisible: true,
  //         acceptLabel: 'Yes',
  //         icon: 'pi pi-exclamation-triangle',
  //         accept: () => {
  //           this._middlewareService.ShiftOpenReport(this.printJobId, 'ShiftStartReport');
  //           setTimeout(() => {
  //             this.router.navigate(['../ProductCart'], { relativeTo: this.route });
  //           }, 250);

  //         },
  //         reject: () => {
  //           setTimeout(() => {
  //             this.router.navigate(['../ProductCart'], { relativeTo: this.route });
  //           }, 250);
  //         }
  //       });
  //     } 
  //     else if (midResponse.includes('not Connected')) {
  //       Swal.fire({
  //         icon: 'warning',
  //         title: 'Warning!',
  //         text: midResponse,
  //       });
  //     }
  //     else {
  //       Swal.fire({
  //         icon: 'error',
  //         title: 'Error!',
  //         text: 'Error while Printing!',
  //       });
  //     }
  //   }, 500);
  // }

  SaveOutageData() {
    const ts = this;
    this.viewData.forEach(function (value) {
      var outageList = {
        WorkstationID: ts.workstation.workStationId,
        ShiftNum: ts.workstation.workStationId + '-' + (parseInt(ts.workstation.lastShiftNum) + 1),
        ProductID: value.productId,
        ReportedQty: value.checkQTY,
        SystemQty: value.totalProdQTY,
        ActionName: 'ShiftStart'
      }
      ts.inventoryOutage.push(outageList);
    });

    this.invService.SaveInventoryOutageData(this.inventoryOutage)
      .subscribe((result) => {

      });
  }
}
