import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/Services/auth.service';

@Component({
  selector: 'app-selectworkstation',
  templateUrl: './selectworkstation.component.html',
  styleUrls: ['./selectworkstation.component.css']
})
export class SelectworkstationComponent implements OnInit {
  WorkstationSelectionModal:boolean;
  WorstationList:any;
  WorkstationDetails
  LoginFlg:boolean;
  Message:any;
  WorkstationAlreadyAssociatedModel:boolean;
  FinishWorkStationSelection:boolean;
  checkFlag: boolean=false;
  constructor(private router: Router,private authenticationService: AuthService,private toastr: ToastrService) { }

  ngOnInit(): void {
    debugger;
    this.WorkstationSelectionModal=true
    let SiteId = JSON.parse(localStorage.getItem("SiteId"));
    this.authenticationService.GetWorkstationListBySiteId(SiteId)
    .subscribe((res) => {
      this.WorstationList=res;
    });

    this.WorkstationDetails="--Select Workstation--";
  }



CheckWorkstationAssociatedAlready(){
  debugger;
  
  if(this.WorkstationDetails !="--Select Workstation--"){
    this.authenticationService.CheckWorkstationAssociated(this.WorkstationDetails)
    .subscribe((res) => {
      if(res){
        localStorage.setItem('WsId', JSON.stringify(this.WorkstationDetails));
        this.WorkstationSelectionModal=false
        this.FinishWorkStationSelection=true;
      }
      else{
        this.WorkstationAlreadyAssociatedModel=true;
      }

    });
  }
  else{
    this.Message="Please select a workstation before continuing."
  }
  
}
ReplaceGUID(){
  this.WorkstationAlreadyAssociatedModel=false;
  let decodeGUID = localStorage.getItem("MacAddress");
  let MacAddress=atob(decodeGUID)
  if(this.WorkstationDetails!=null){
    this.authenticationService.UpdateGUIDFieldbyWsId(this.WorkstationDetails,MacAddress)
    .subscribe((res) => {
      if(res){
        localStorage.setItem('WsId', JSON.stringify(this.WorkstationDetails));
        this.WorkstationSelectionModal=false
        this.FinishWorkStationSelection=true;
        this.checkFlag=true;
      }
      else{
        this.toastr.error("This workstation has open shift,can not assign for now.")
      }
    });
  }
  
}
generate_uuidv4(): any {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g,
    function (c) {
      var uuid = Math.random() * 16 | 0, v = c == 'x' ? uuid : (uuid & 0x3 | 0x8);
      return uuid.toString(16);
    });
}

RedirectWorkstationSelectionScreen(){
  window.location.reload();
}

FinishSetup(){
  if(!this.checkFlag){
  let decodeGUID = localStorage.getItem("MacAddress");
  let MacAddress=atob(decodeGUID)
    if(this.WorkstationDetails!=null){
      this.authenticationService.UpdateGUIDFieldbyWsId(this.WorkstationDetails,MacAddress)
      .subscribe((res) => {
        if(res){
          localStorage.setItem('WsId', JSON.stringify(this.WorkstationDetails));
          this.router.navigate(['/Login']);
        }
      });
    }
  }
  else{
    this.router.navigate(['/Login']);
  }
}

}
