<p-confirmDialog acceptLabel="Continue" [style]="{width: confirmWidth}" appendTo="body">
    <ng-template pTemplate="body">
        <span class="ui-confirmdialog-message">{{message}}</span>
    </ng-template>
</p-confirmDialog>
<div class="right-content sub-header" *ngIf="isnotPreview">
    <div class="heading-panel text-left">
        <h1><i class="fa fa-power-off pr-1"></i> End of Day</h1>
    </div>

    <div>
        <!-- Room Charge Screen Start -->
        <div class="container-fluid" *ngIf="isRoomChargeScreen">
            <div class="row">
                <div class="col-sm-12 text-center">
                    <h1 class="body-heading">Enter Total Room Charge Payments</h1>
                </div>
            </div>
            <div class="cash-screen">
                <div class="text-center">
                    <div class="container-fluid">
                        <div class="d-flex justify-content-center">
                            <div class="opening-count-block cash-calculator">
                                <div class="right-panel">
                                    <button class="img-btn cashcalcbtn"><img src="./assets/Libs/images/Calculator.png"
                                            alt="Calculator" title="Calculator"></button>
                                    <button class="img-btn"><img src="./assets/Libs/images/cash-register.png"
                                            alt="Register" title="Open Cash Drawer"></button>
                                </div>
                                <div class="count-input form-group">
                                    <input type="text" [(ngModel)]="editRoomAmt" value="0.00"
                                        (keyup)="valRoomAmt($event)" class="form-control inputTextHeight" min="0">
                                    <img src="./assets/Libs/images/dollar.png">
                                </div>
                                <div>
                                    <div class="opening-count-block">
                                        <div class="count-buttons-block mt-4">
                                            <button class="btn count-button" (click)="rcPopulateAmount(1)">1</button>
                                            <button class="btn count-button" (click)="rcPopulateAmount(2)">2</button>
                                            <button class="btn count-button mr-0"
                                                (click)="rcPopulateAmount(3)">3</button>
                                            <button class="btn count-button" (click)="rcPopulateAmount(4)"> 4</button>
                                            <button class="btn count-button" (click)="rcPopulateAmount(5)">5</button>
                                            <button class="btn count-button mr-0"
                                                (click)="rcPopulateAmount(6)">6</button>
                                            <button class="btn count-button" (click)="rcPopulateAmount(7)">7</button>
                                            <button class="btn count-button" (click)="rcPopulateAmount(8)">8</button>
                                            <button class="btn count-button mr-0"
                                                (click)="rcPopulateAmount(9)">9</button>
                                            <button class="btn count-button" (click)="rcPopulateAmount(0)">0</button>
                                            <button class="btn count-button" (click)="rcPopulateAmount('.')">.</button>
                                            <button class="btn count-button mr-0" (click)="rcPopulateAmount('x')">
                                                <i class="fa fa-arrow-circle-o-left"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="pin-tab">
                                    <form>
                                        <div class="tab-form">
                                            <div class="form-group row">
                                                <label for="input" class="col-form-label">$100's :</label>
                                                <div class="col-sm-7">
                                                    <input type="number" class="form-control" id="inputtext" min="0"
                                                        name="hunders" [(ngModel)]="hunders"
                                                        (input)="onChange($event,'100')" min="0" placeholder="0">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="input" class="col-form-label">$50's :</label>
                                                <div class="col-sm-7">
                                                    <input type="number" class="form-control" id="inputtext" min="0"
                                                        name="fifities" [(ngModel)]="fifities"
                                                        (input)="onChange($event,'50')" min="0" placeholder="0">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="input" class="col-form-label">$20's :</label>
                                                <div class="col-sm-7">
                                                    <input type="number" class="form-control" id="inputtext" min="0"
                                                        name="twnties" [(ngModel)]="twnties"
                                                        (input)="onChange($event,'20')" min="0" placeholder="0">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="input" class="col-form-label">$10's :</label>
                                                <div class="col-sm-7">
                                                    <input type="number" class="form-control" id="inputtext" min="0"
                                                        name="tens" [(ngModel)]="tens" (input)="onChange($event,'10')"
                                                        min="0" placeholder="0">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="input" class="col-form-label">$5's :</label>
                                                <div class="col-sm-7">
                                                    <input type="number" class="form-control" id="inputtext" min="0"
                                                        name="fives" [(ngModel)]="fives" (input)="onChange($event,'5')"
                                                        min="0" placeholder="0">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="input" class="col-form-label">$1's :</label>
                                                <div class="col-sm-7">
                                                    <input type="number" class="form-control" id="inputtext" min="0"
                                                        name="once" [(ngModel)]="once" (input)="onChange($event,'1')"
                                                        min="0" placeholder="0">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="input" class="col-form-label">Change Total :</label>
                                                <div class="col-sm-7">
                                                    <input type="text" class="form-control" id="inputtext"
                                                        name="changeTotal" [(ngModel)]="changeTotal" maxlength="2"
                                                        min="0" (input)="onChange($event,'0')" placeholder="0">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="total-cash">
                                            <div class="form-group row mb-0">
                                                <label for="input" class="col-form-label">Total :</label>
                                                <div class="col-sm-7">
                                                    <input type="text" class="form-control" id="inputtext"
                                                        name="TotalRoom" [(ngModel)]="TotalRoom" placeholder="0.00">
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="action-buttons text-center mt-4 cstm_txt_btn">
                <button class="button button-secondary" (click)="RedirectToHome()">Cancel</button>&nbsp;
                <button class="button button-secondary" (click)="ResetRoomAmount()"><i class="fa fa-rotate-left"></i>
                    Reset</button>&nbsp;
                <button class="button button-secondary" (click)="CheckForRoomOutBalance()">Continue</button>
            </div>
        </div>
        <!-- Room Charge Screen END -->

        <!-- Money Cert Screen Start -->
        <div class="container-fluid" *ngIf="isMoneyCertScreen">
            <div class="row">
                <div class="col-sm-12 text-center">
                    <h1 class="body-heading">Enter Total Money Certificate Payments</h1>
                </div>
            </div>
            <!-- Calculator Screen -->
            <div class="cash-screen">
                <div class="text-center">
                    <div class="container-fluid">
                        <div class="d-flex justify-content-center">
                            <div class="opening-count-block cash-calculator">
                                <div class="right-panel">
                                    <button class="img-btn cashcalcbtn"><img src="./assets/Libs/images/Calculator.png"
                                            alt="Calculator" title="Calculator"></button>
                                    <button class="img-btn"><img src="./assets/Libs/images/cash-register.png"
                                            alt="Register" title="Open Cash Drawer"></button>
                                </div>
                                <!-- <h2>Enter Amount</h2> -->
                                <div class="count-input form-group">
                                    <input type="text" [(ngModel)]="editMoneyAmt" value="0.00"
                                        (keyup)="valMoneyAmt($event)" class="form-control inputTextHeight" min="0">
                                    <img src="./assets/Libs/images/dollar.png">
                                </div>
                                <div>
                                    <div class="opening-count-block">
                                        <div class="count-buttons-block mt-4">
                                            <button class="btn count-button" (click)="mcPopulateAmount(1)">1</button>
                                            <button class="btn count-button" (click)="mcPopulateAmount(2)">2</button>
                                            <button class="btn count-button mr-0"
                                                (click)="mcPopulateAmount(3)">3</button>
                                            <button class="btn count-button" (click)="mcPopulateAmount(4)"> 4</button>
                                            <button class="btn count-button" (click)="mcPopulateAmount(5)">5</button>
                                            <button class="btn count-button mr-0"
                                                (click)="mcPopulateAmount(6)">6</button>
                                            <button class="btn count-button" (click)="mcPopulateAmount(7)">7</button>
                                            <button class="btn count-button" (click)="mcPopulateAmount(8)">8</button>
                                            <button class="btn count-button mr-0"
                                                (click)="mcPopulateAmount(9)">9</button>
                                            <button class="btn count-button" (click)="mcPopulateAmount(0)">0</button>
                                            <button class="btn count-button" (click)="mcPopulateAmount('.')">.</button>
                                            <button class="btn count-button mr-0" (click)="mcPopulateAmount('x')">
                                                <i class="fa fa-arrow-circle-o-left"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="pin-tab">
                                    <form>
                                        <div class="tab-form">
                                            <div class="form-group row">
                                                <label for="input" class="col-form-label">$100's :</label>

                                                <div class="col-sm-7">
                                                    <input type="number" class="form-control" id="inputtext" min="0"
                                                        name="hunders" [(ngModel)]="hunders"
                                                        (input)="onChange($event,'100')" min="0" placeholder="0">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="input" class="col-form-label">$50's :</label>

                                                <div class="col-sm-7">
                                                    <input type="number" class="form-control" id="inputtext" min="0"
                                                        name="fifities" [(ngModel)]="fifities"
                                                        (input)="onChange($event,'50')" min="0" placeholder="0">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="input" class="col-form-label">$20's :</label>
                                                <div class="col-sm-7">
                                                    <input type="number" class="form-control" id="inputtext" min="0"
                                                        name="twnties" [(ngModel)]="twnties"
                                                        (input)="onChange($event,'20')" min="0" placeholder="0">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="input" class="col-form-label">$10's :</label>
                                                <div class="col-sm-7">
                                                    <input type="number" class="form-control" id="inputtext" min="0"
                                                        name="tens" [(ngModel)]="tens" (input)="onChange($event,'10')"
                                                        min="0" placeholder="0">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="input" class="col-form-label">$5's :</label>
                                                <div class="col-sm-7">
                                                    <input type="number" class="form-control" id="inputtext" min="0"
                                                        name="fives" [(ngModel)]="fives" (input)="onChange($event,'5')"
                                                        min="0" placeholder="0">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="input" class="col-form-label">$1's :</label>
                                                <div class="col-sm-7">
                                                    <input type="number" class="form-control" id="inputtext" min="0"
                                                        name="once" [(ngModel)]="once" (input)="onChange($event,'1')"
                                                        min="0" placeholder="0">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="input" class="col-form-label">Change Total :</label>
                                                <div class="col-sm-7">
                                                    <input type="text" class="form-control" id="inputtext"
                                                        name="changeTotal" [(ngModel)]="changeTotal" maxlength="2"
                                                        min="0" (input)="onChange($event,'0')" placeholder="0">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="total-cash">
                                            <div class="form-group row mb-0">
                                                <label for="input" class="col-form-label">Total :</label>
                                                <div class="col-sm-7">
                                                    <input type="text" class="form-control" id="inputtext"
                                                        name="TotalMoney" [(ngModel)]="TotalMoney" placeholder="0.00">
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <!-- <div class="pin-tab">
                                <form>
                                    <div class="tab-form">
                                        <div class="form-group row">
                                            <label for="input" class="col-sm-3 col-form-label">$100's</label>
                                            <label for="input" class="col-sm-2 col-form-label">:</label>
                                            <div class="col-sm-7">
                                                <input type="number" class="form-control" id="inputtext" min="0"
                                                    name="hunders" [(ngModel)]="hunders"
                                                    (input)="onChange($event,'100')" min="0" placeholder="0">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="input" class="col-sm-3 col-form-label">$50's</label>
                                            <label for="input" class="col-sm-2 col-form-label">:</label>
                                            <div class="col-sm-7">
                                                <input type="number" class="form-control" id="inputtext" min="0"
                                                    name="fifities" [(ngModel)]="fifities"
                                                    (input)="onChange($event,'50')" min="0" placeholder="0">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="input" class="col-sm-3 col-form-label">$20's</label>
                                            <label for="input" class="col-sm-2 col-form-label">:</label>
                                            <div class="col-sm-7">
                                                <input type="number" class="form-control" id="inputtext" min="0"
                                                    name="twnties" [(ngModel)]="twnties" (input)="onChange($event,'20')"
                                                    min="0" placeholder="0">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="input" class="col-sm-3 col-form-label">$10's</label>
                                            <label for="input" class="col-sm-2 col-form-label">:</label>
                                            <div class="col-sm-7">
                                                <input type="number" class="form-control" id="inputtext" min="0"
                                                    name="tens" [(ngModel)]="tens" (input)="onChange($event,'10')"
                                                    min="0" placeholder="0">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="input" class="col-sm-3 col-form-label">$5's</label>
                                            <label for="input" class="col-sm-2 col-form-label">:</label>
                                            <div class="col-sm-7">
                                                <input type="number" class="form-control" id="inputtext" min="0"
                                                    name="fives" [(ngModel)]="fives" (input)="onChange($event,'5')"
                                                    min="0" placeholder="0">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="input" class="col-sm-3 col-form-label">$1's</label>
                                            <label for="input" class="col-sm-2 col-form-label">:</label>
                                            <div class="col-sm-7">
                                                <input type="number" class="form-control" id="inputtext" min="0"
                                                    name="once" [(ngModel)]="once" (input)="onChange($event,'1')"
                                                    min="0" placeholder="0">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="input" class="col-sm-3 col-form-label">Change Total</label>
                                            <label for="input" class="col-sm-2 col-form-label">:</label>
                                            <div class="col-sm-7">
                                                <input type="text" class="form-control" id="inputtext"
                                                    name="changeTotal" [(ngModel)]="changeTotal" maxlength="2" min="0"
                                                    (input)="onChange($event,'0')" placeholder="0">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="total-cash">
                                        <div class="form-group row mb-0">
                                            <label for="input" class="col-sm-3 col-form-label">Total</label>
                                            <label for="input" class="col-sm-2 col-form-label">:</label>
                                            <div class="col-sm-7">
                                                <input type="text" class="form-control" id="inputtext" name="TotalMoney"
                                                    [(ngModel)]="TotalMoney" placeholder="0.00">
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="action-buttons text-center mt-4 cstm_txt_btn">
                <button class="button button-secondary" (click)="RedirectToHome()">Cancel</button>&nbsp;
                <button class="button button-secondary" (click)="ResetMoneyAmount()"><i class="fa fa-rotate-left"></i>
                    Reset</button>&nbsp;
                <button class="button button-secondary" (click)="CheckForMoneyOutBalance()">Continue</button>
            </div>
        </div>
        <!-- Money Cert Screen End -->

        <!-- Cash Screen Start *ngIf="isCashScreen">-->
        <div class="container-fluid" *ngIf="isCashScreen">
            <div class="row">
                <div class="col-sm-12 text-center">
                    <h1 class="body-heading">Enter Cash Balance</h1>
                </div>
            </div>
            <div class="cash-screen">
                <div class="text-center">
                    <div class="container-fluid">
                        <div class="shiftClose-calc d-flex justify-content-center">
                            <div class="opening-count-block cash-calculator">
                                <div class="right-panel">
                                    <button class="img-btn cashcalcbtn"><img src="./assets/Libs/images/Calculator.png"
                                            alt="Calculator" title="Calculator"></button>
                                    <button class="img-btn"><img src="./assets/Libs/images/cash-register.png"
                                            alt="Register" title="Open Cash Drawer"></button>
                                </div>
                                <!-- <h2>Enter Amount</h2> -->
                                <div class="ShiftClose-body-calc">
                                    <div class="count-input form-group">
                                        <input type="text" [(ngModel)]="openingCount" value="0.00"
                                            (keyup)="valOpeningCount($event)" class="form-control inputTextHeight"
                                            min="0">
                                        <img src="./assets/Libs/images/dollar.png" alt="dollar">
                                    </div>
                                    <div>
                                        <div class="opening-count-block">
                                            <div class="count-buttons-block mt-4">
                                                <button class="btn count-button" (click)="PopulateAmount(1)">1</button>
                                                <button class="btn count-button" (click)="PopulateAmount(2)">2</button>
                                                <button class="btn count-button mr-0"
                                                    (click)="PopulateAmount(3)">3</button>
                                                <button class="btn count-button" (click)="PopulateAmount(4)"> 4</button>
                                                <button class="btn count-button" (click)="PopulateAmount(5)">5</button>
                                                <button class="btn count-button mr-0"
                                                    (click)="PopulateAmount(6)">6</button>
                                                <button class="btn count-button" (click)="PopulateAmount(7)">7</button>
                                                <button class="btn count-button" (click)="PopulateAmount(8)">8</button>
                                                <button class="btn count-button mr-0"
                                                    (click)="PopulateAmount(9)">9</button>
                                                <button class="btn count-button" (click)="PopulateAmount(0)">0</button>
                                                <button class="btn count-button"
                                                    (click)="PopulateAmount('.')">.</button>
                                                <button class="btn count-button mr-0" (click)="PopulateAmount('x')">
                                                    <i class="fa fa-arrow-circle-o-left"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="pin-tab">
                                        <form>
                                            <div class="tab-form">
                                                <div class="form-group row">
                                                    <label for="input" class=" col-form-label">$100's :</label>

                                                    <div class="col-sm-7">
                                                        <input type="number" class="form-control" id="inputtext" min="0"
                                                            name="hunders" [(ngModel)]="hunders"
                                                            (input)="onChange($event,'100')" min="0" placeholder="0">
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label for="input" class=" col-form-label">$50's :</label>

                                                    <div class="col-sm-7">
                                                        <input type="number" class="form-control" id="inputtext" min="0"
                                                            name="fifities" [(ngModel)]="fifities"
                                                            (input)="onChange($event,'50')" min="0" placeholder="0">
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label for="input" class=" col-form-label">$20's :</label>
                                                    <div class="col-sm-7">
                                                        <input type="number" class="form-control" id="inputtext" min="0"
                                                            name="twnties" [(ngModel)]="twnties"
                                                            (input)="onChange($event,'20')" min="0" placeholder="0">
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label for="input" class=" col-form-label">$10's :</label>
                                                    <div class="col-sm-7">
                                                        <input type="number" class="form-control" id="inputtext" min="0"
                                                            name="tens" [(ngModel)]="tens"
                                                            (input)="onChange($event,'10')" min="0" placeholder="0">
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label for="input" class=" col-form-label">$5's :</label>
                                                    <div class="col-sm-7">
                                                        <input type="number" class="form-control" id="inputtext" min="0"
                                                            name="fives" [(ngModel)]="fives"
                                                            (input)="onChange($event,'5')" min="0" placeholder="0">
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label for="input" class=" col-form-label">$1's :</label>
                                                    <div class="col-sm-7">
                                                        <input type="number" class="form-control" id="inputtext" min="0"
                                                            name="once" [(ngModel)]="once"
                                                            (input)="onChange($event,'1')" min="0" placeholder="0">
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label for="input" class=" col-form-label">Change Total </label>
                                                    <div class="col-sm-7">
                                                        <input type="number" class="form-control" id="inputtext"
                                                            name="changeTotal" [(ngModel)]="changeTotal" maxlength="2"
                                                            min="0" (input)="onChange($event,'0')" placeholder="0">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="total-cash">
                                                <div class="form-group row mb-0">
                                                    <label for="input" class=" col-form-label">Total :</label>
                                                    <div class="col-sm-7">
                                                        <input type="number" class="form-control" id="inputtext"
                                                            name="TotalCash" [(ngModel)]="TotalCash" placeholder="0.00">
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="action-buttons text-center mt-4 cstm_txt_btn">
                <button class="button button-secondary" (click)="RedirectToHome()">Cancel</button>&nbsp;
                <button class="button button-secondary" (click)="ResetOpeningCount()"><i class="fa fa-rotate-left"></i>
                    Reset</button>&nbsp;
                <button class="button button-secondary" (click)="CheckForOutBalance()">Continue</button>
            </div>
        </div>
        <!-- Cash Screen End -->
    </div>

</div>


<div class="right-content sub-header" *ngIf="isInventory">
    <div class="heading-panel text-left">
        <h1><i class="fa fa-power-off pr-1"></i> End of Day</h1>
    </div>

    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12 text-center">
                <h1 class="body-heading">Inventory Balance</h1>
            </div>
        </div>
        <div class="inv-balanced">
            <div class="row justify-content-center">
                <!-- <div class="col-sm-2"></div> -->
                <div class="col-xl-6 col-lg-8 col-md-10">
                    <div *ngIf="viewData != ''">
                        <div class="shift-review-block">
                            <div class="shift-review-table">
                                <table class="table table-borderless">
                                    <thead class="tblProDetails">
                                        <tr class="cstm_txt_hd">
                                            <th class="text-left">Product</th>
                                            <th style="width: 22%; text-align: center;">Quantity</th>
                                            <th style="width: 20%;">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="viewData != ''" class="cstm_txt">
                                        <tr *ngFor="let item of viewData; let i=index">
                                            <td class="text-left">{{item.productName}}</td>
                                            <td class="text-left">
                                                <input type="number" [(ngModel)]="item.checkQTY" min="0" id="item"
                                                    [ngClass]="{'border': item.checkQTY ===''|| item.checkQTY === null}"
                                                    class="form-control" (keydown)="CheckKeys($event)"
                                                    (keyup)="CheckOutBal(item)" [tabindex]="i">
                                            </td>
                                            <td class="text-left"><span *ngIf="item.checkQTY === item.totalProdQTY"
                                                    class="text-success">In
                                                    Balance</span> <span *ngIf="item.isOut && item.checkQTY !== null"
                                                    class="text-danger">Out Of Balance</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-sm-2"></div> -->
            </div>
            <div class="row mt-4">
                <div class="col-sm-12">
                    <div class="action-buttons text-center cstm_txt_btn">
                        <button class="button button-secondary" (click)="BackToCashBal()">Back
                        </button>&nbsp;
                        <button class="button button-secondary" (click)="ResetInventory()"><i
                                class="fa fa-rotate-left"></i>
                            Reset</button>&nbsp;
                        <button class="button button-success" (click)="SaveInventory()">Continue
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="right-content sub-header" *ngIf="isCashDeposit">
    <div class="heading-panel text-left">
        <h1><i class="fa fa-power-off pr-1"></i> End of Day</h1>
    </div>

    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12 text-center">
                <h1 class="body-heading">Enter Cash Deposit</h1>
            </div>
        </div>
        <div class="cash-screen">
            <div class="text-center">
                <div class="container-fluid">
                    <div class="d-flex justify-content-center">
                        <div class="opening-count-block cash-calculator cash-deposite">
                            <div class="right-panel">
                                <button class="img-btn cashcalcbtn"><img src="./assets/Libs/images/Calculator.png"
                                        alt="Calculator"></button>
                                <button class="img-btn"><img src="./assets/Libs/images/cash-register.png"
                                        alt="Register"></button>
                            </div>
                            <div class="count-input form-group">
                                <input type="text" [(ngModel)]="cashDeposit" value="0.00"
                                    (keyup)="checkForward($event,'')" class="form-control inputTextHeight">
                                <img src="./assets/Libs/images/dollar.png" alt="dollar">
                            </div>
                            <div>
                                <div class="opening-count-block">
                                    <div class="count-buttons-block mt-4">
                                        <button class="btn count-button" (click)="PopulatecashDeposit(1)">1</button>
                                        <button class="btn count-button" (click)="PopulatecashDeposit(2)">2</button>
                                        <button class="btn count-button mr-0"
                                            (click)="PopulatecashDeposit(3)">3</button>
                                        <button class="btn count-button" (click)="PopulatecashDeposit(4)"> 4</button>
                                        <button class="btn count-button" (click)="PopulatecashDeposit(5)">5</button>
                                        <button class="btn count-button mr-0"
                                            (click)="PopulatecashDeposit(6)">6</button>
                                        <button class="btn count-button" (click)="PopulatecashDeposit(7)">7</button>
                                        <button class="btn count-button" (click)="PopulatecashDeposit(8)">8</button>
                                        <button class="btn count-button mr-0"
                                            (click)="PopulatecashDeposit(9)">9</button>
                                        <button class="btn count-button" (click)="PopulatecashDeposit(0)">0</button>
                                        <button class="btn count-button" (click)="PopulatecashDeposit('.')">.</button>
                                        <button class="btn count-button mr-0" (click)="PopulatecashDeposit('x')"><i
                                                class="fa fa-arrow-circle-o-left"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div class="pin-tab">
                                <form>
                                    <div class="tab-form">
                                        <div class="form-group row">
                                            <label for="input" class="col-form-label">$100's :</label>
                                            <div class="col-sm-7">
                                                <input type="text" class="form-control" id="inputtext" name="hunders"
                                                    [(ngModel)]="hunders" (input)="onChange($event,'100')" min="0"
                                                    placeholder="0">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="input" class="col-form-label">$50's :</label>
                                            <div class="col-sm-7">
                                                <input type="text" class="form-control" id="inputtext" name="fifities"
                                                    [(ngModel)]="fifities" (input)="onChange($event.target.value,'50')"
                                                    min="0">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="input" class="col-form-label">$20's :</label>
                                            <div class="col-sm-7">
                                                <input type="text" class="form-control" id="inputtext" name="twnties"
                                                    [(ngModel)]="twnties" (input)="onChange($event.target.value,'20')"
                                                    min="0">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="input" class="col-form-label">$10's :</label>
                                            <div class="col-sm-7">
                                                <input type="text" class="form-control" id="inputtext" name="tens"
                                                    [(ngModel)]="tens" (input)="onChange($event.target.value,'10')"
                                                    min="0">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="input" class="col-form-label">$5's :</label>
                                            <div class="col-sm-7">
                                                <input type="text" class="form-control" id="inputtext" name="fives"
                                                    [(ngModel)]="fives" (input)="onChange($event.target.value,'5')"
                                                    min="0">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="input" class="col-form-label">$1's :</label>
                                            <div class="col-sm-7">
                                                <input type="text" class="form-control" id="inputtext" name="once"
                                                    [(ngModel)]="once" (input)="onChange($event.target.value,'1')"
                                                    min="0">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="input" class="col-form-label">Change Total :</label>
                                            <div class="col-sm-7">
                                                <input type="text" class="form-control" id="inputtext"
                                                    name="changeTotal" [(ngModel)]="changeTotal" min="0"
                                                    (input)="onChange($event.target.value,'0')">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="total-cash">
                                        <div class="form-group row mb-0">
                                            <label for="input" class="col-form-label">Total :</label>
                                            <div class="col-sm-7">
                                                <input type="text" class="form-control" id="inputtext" name="TotalCash"
                                                    [(ngModel)]="TotalCash" placeholder="0.00">
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <!-- <div class="pin-tab">
                            <form>
                                <div class="tab-form">
                                    <div class="form-group row">
                                        <label for="input" class="col-sm-3 col-form-label">$100's</label>
                                        <label for="input" class="col-sm-2 col-form-label">:</label>
                                        <div class="col-sm-7">
                                            <input type="text" class="form-control" id="inputtext" name="hunders"
                                                [(ngModel)]="hunders" (input)="onChange($event,'100')" min="0"
                                                placeholder="0">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="input" class="col-sm-3 col-form-label">$50's</label>
                                        <label for="input" class="col-sm-2 col-form-label">:</label>
                                        <div class="col-sm-7">
                                            <input type="text" class="form-control" id="inputtext" name="fifities"
                                                [(ngModel)]="fifities" (input)="onChange($event.target.value,'50')"
                                                min="0">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="input" class="col-sm-3 col-form-label">$20's</label>
                                        <label for="input" class="col-sm-2 col-form-label">:</label>
                                        <div class="col-sm-7">
                                            <input type="text" class="form-control" id="inputtext" name="twnties"
                                                [(ngModel)]="twnties" (input)="onChange($event.target.value,'20')"
                                                min="0">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="input" class="col-sm-3 col-form-label">$10's</label>
                                        <label for="input" class="col-sm-2 col-form-label">:</label>
                                        <div class="col-sm-7">
                                            <input type="text" class="form-control" id="inputtext" name="tens"
                                                [(ngModel)]="tens" (input)="onChange($event.target.value,'10')" min="0">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="input" class="col-sm-3 col-form-label">$5's</label>
                                        <label for="input" class="col-sm-2 col-form-label">:</label>
                                        <div class="col-sm-7">
                                            <input type="text" class="form-control" id="inputtext" name="fives"
                                                [(ngModel)]="fives" (input)="onChange($event.target.value,'5')" min="0">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="input" class="col-sm-3 col-form-label">$1's</label>
                                        <label for="input" class="col-sm-2 col-form-label">:</label>
                                        <div class="col-sm-7">
                                            <input type="text" class="form-control" id="inputtext" name="once"
                                                [(ngModel)]="once" (input)="onChange($event.target.value,'1')" min="0">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="input" class="col-sm-3 col-form-label">Change Total</label>
                                        <label for="input" class="col-sm-2 col-form-label">:</label>
                                        <div class="col-sm-7">
                                            <input type="text" class="form-control" id="inputtext" name="changeTotal"
                                                [(ngModel)]="changeTotal" min="0"
                                                (input)="onChange($event.target.value,'0')">
                                        </div>
                                    </div>
                                </div>
                                <div class="total-cash">
                                    <div class="form-group row mb-0">
                                        <label for="input" class="col-sm-3 col-form-label">Total</label>
                                        <label for="input" class="col-sm-2 col-form-label">:</label>
                                        <div class="col-sm-7">
                                            <input type="text" class="form-control" id="inputtext" name="TotalCash"
                                                [(ngModel)]="TotalCash" placeholder="0.00">
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="action-buttons text-center mt-4 cstm_txt_btn">
            <button class="button button-secondary" (click)="RedirectToHome()">Cancel</button>&nbsp;
            <button class="button button-secondary" (click)="PopulatecashDeposit('x')"><i class="fa fa-rotate-left"></i>
                Reset</button>&nbsp;
            <button class="button button-secondary" (click)="CheckCashForwardNew()">Continue</button>
        </div>
    </div>
</div>

<div class="right-content sub-header" *ngIf="isPreview">
    <div class="heading-panel text-left">
        <h1><i class="fa fa-power-off pr-1"></i>End of Day</h1>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12 text-center">
                <h1 class="body-heading">End of Day Review</h1>
            </div>
        </div>
        <div class="endofdayreview">
            <div class="row">
                <div class="col-sm-12">
                    <div class="shift-review-block-EOD cstmclsaddedblc">
                        <div class="shift-review-table-EOD cstm-tblR-EOD">
                            <table class="table table-borderless">
                                <!-- <table class="table table-borderless"></table> -->
                                <tbody>
                                    
                                    <ng-container *ngFor="let item of tenderActiveList; let i = index">
                                        <tr
                                            *ngIf="tenderActiveList[i].tenderActive && tenderActiveList[i].TenderTypeId==1  && isCashTender===true">
                                            <!-- <td>Ending Cash in Drawer</td> -->
                                            <td>Cash</td>
                                            <td><span *ngIf="cashForward ==currentShiftTotals" class="text-success">In Balance</span> 
                                                <span *ngIf="cashForward != currentShiftTotals" class="text-danger">Out Of Balance</span></td>
                                        </tr>
                                    </ng-container>
                                    <tr class="deposit_balancerow spaceUnder">
                                        <td>Bank Deposit</td>
                                        <td *ngIf="cashDeposit >0"
                                        class="text-danger">{{-cashDeposit | currency}}</td>
                                        <td *ngIf="cashDeposit==0"
                                           >{{-cashDeposit | currency}}</td>
                                    </tr>
                                    <!-- <ng-container *ngFor="let item of tenderActiveList; let i = index"> -->
                                        <!-- <tr
                                            *ngIf="tenderActiveList[i].tenderActive && tenderActiveList[i].TenderTypeId==2">
                                        </tr> -->
                                        <!-- <tr *ngIf="tenderActiveList[i].tenderActive && tenderActiveList[i].TenderTypeId==3 && isRoomChargeTender===true">
                                            <td>Room Charge</td>
                                            <td><span
                                                    *ngIf="editRoomAmt == this.currentShiftTenderBalance.totalRoomChargeBalance"
                                                    class="text-success">In Balance</span> <span
                                                    *ngIf="editRoomAmt != this.currentShiftTenderBalance.totalRoomChargeBalance"
                                                    class="text-danger">
                                                    Out Of Balance</span>
                                            </td>
                                        </tr>
                                        <tr *ngIf="tenderActiveList[i].tenderActive && tenderActiveList[i].TenderTypeId==4  && isMoneyCertTender===true" class="spaceUnder">
                                            <td>Money Cert</td>                                           
                                            <td><span
                                                    *ngIf="editMoneyAmt == this.currentShiftTenderBalance.totalMoneyCertBalance"
                                                    class="text-success">In Balance</span>
                                                     <span
                                                    *ngIf="editMoneyAmt != this.currentShiftTenderBalance.totalMoneyCertBalance"
                                                    class="text-danger">
                                                    Out Of Balance</span>
                                            </td>
                                        </tr> -->

                                    <!-- </ng-container> -->

                                    <tr *ngIf= "isRoomChargeTender===true">
                                        <td>Room Charge</td>
                                        <td><span
                                                *ngIf="editRoomAmt == this.currentShiftTenderBalance.totalRoomChargeBalance"
                                                class="text-success">In Balance</span> <span
                                                *ngIf="editRoomAmt != this.currentShiftTenderBalance.totalRoomChargeBalance"
                                                class="text-danger">
                                                Out Of Balance</span>
                                        </td>
                                    </tr>
                                    <tr *ngIf="isMoneyCertTender===true" class="spaceUnder">
                                        <td>Money Cert</td>                                           
                                        <td><span
                                                *ngIf="editMoneyAmt == this.currentShiftTenderBalance.totalMoneyCertBalance"
                                                class="text-success">In Balance</span>
                                                 <span
                                                *ngIf="editMoneyAmt != this.currentShiftTenderBalance.totalMoneyCertBalance"
                                                class="text-danger">
                                                Out Of Balance</span>
                                        </td>
                                    </tr>
                               
                                    <tr *ngIf="viewData != ''">
                                        <td>Inventory</td>
                                        <td *ngIf="isInvetoryBalance==true"  class="text-success">In Balance</td>
                                        <td *ngIf="isInvetoryBalance==false"  class="text-danger">Out Of Balance</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                  
                </div>
            </div>
        </div>
        <!-- <br/> -->
        <!-- <br/> -->
        <div class="notify-EOD-msg text-center ">
            <h3>{{EndOfShiftMessage}}</h3>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="two-action-buttons d-flex justify-content-center align-items-center">
                    <button class="button button-secondary cncl-btn-EOD" (click)="GoBackToPrevoius()" >Cancel</button>
                    <!-- <button class="button button-secondary" [disabled]="isPreview"><i class="fa fa-rotate-left"></i>
                        Reset</button>&nbsp; -->
                    <button class="button button-success cntn-btn-EOD" (click)="SaveCloseShiftReport()">Continue</button>
                </div>
            </div>
        </div>
    </div>
</div>