<!-- <div class="login-container" style="background: url(./assets/Libs/images/login-background.png);"> -->
    <div class="login-container" style="background-color: #0082D2;">
        <div class="container d-flex justify-content-center" style="height: 95vh;">
            <div class="row align-items-center justify-content-center">
                <div class="col-sm-12">
                    <div class="mb-4 text-center">
                        <img src="./assets/Libs/images/GiftBoxLogo.png" alt="" class="logo-size">
                    </div>
                    <div class="login-box">
                        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
    
                            <div class="form-box d-flex align-items-center mb-4">
                                <div class="grp-style">
                                    <div style="width: 10%;">
                                        <img src="./assets/Libs/images/user-icon-2.png" class="icon-size" />
                                    </div>
                                    <fieldset class="form-group w-100 mb-0">
                                        <input class="input-rk" type="text" autocomplete="off" readonly
                                            onfocus="this.removeAttribute('readonly');" formControlName="username"
                                            [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
                                            placeholder="Username">
                                        <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                            <div *ngIf="f.username.errors.required">Username is required</div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                            <div class="form-box d-flex align-items-center mb-4">
                                <div class="grp-style">
                                    <div style="width: 10%;">
                                        <img src="./assets/Libs/images/lock-icon-new.png" class="icon-size" />
                                    </div>
                                    <fieldset class="form-group w-100 mb-0">
                                        <input class="input-rk" [type]="passwordTextType" autocomplete="off" readonly
                                            onfocus="this.removeAttribute('readonly');" formControlName="password"
                                            [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                                            placeholder="Password">
                                        <div *ngIf=" submitted && f.password.errors" class="invalid-feedback">
                                            <div *ngIf="f.password.errors.required">Password is required</div>
                                        </div>
                                    </fieldset>
                                    <img *ngIf="eyeShow == true" src="./assets/Libs/images/eye-open-2.png"
                                        (click)="eyeShowHide()" class="icon-size">
                                    <img *ngIf="eyeShow == false" src="./assets/Libs/images/eye-close-2.png"
                                        (click)="eyeShowHide()" class="icon-size">
                                </div>
                            </div>
                            <fieldset class="text-right my-4">
                                <a href="/ForgotPassword" class="forget-new">Forgot Password?</a>
                            </fieldset>
                            <fieldset>
                                <button class="btn btn-signup">SIGN IN</button>
                            </fieldset>
                            <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="container d-flex justify-content-center" style="height: 5vh;">
            <div class="row align-items-center justify-content-center">
                <div class="col-sm-12">
                    <div class="text-center">
                        <span class="Copyright-text">Copyright © 2024 GiftBox v1.08.00 All rights reserved.</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div [style.display]="AlreadyUseWorkstation? 'block' : 'none'" class="modal name-popup loginAlerModal" id="imgmodal"
        tabindex="-1" role="dialog" aria-labelledby="myMLabel" aria-hidden="true">
        <div class="modaldialog m-auto modal-dialog-centered">
            <div class="signinClass">
                <div class="modal-body bodyClass p-0">
                    <div class="modalWrapperBox p-3">
                        <div class="imgBox">
                            <img src="./assets/Libs/images/warninguserImage.png" height="160">
                        </div>
                        <div class="formClass p-0">
                            <!-- <img src="./assets/Libs/images/warninguserImage.png" height="16"> -->
                            <h2>{{UseWorkstationclerkName}} has an open shift on this workstation.</h2>
                            <div class="cstmModal_btnBox">
                                <button (click)="Reload()" class="btn btn-light btnSubmit CheckWorkstation">Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div [style.display]="UserForceClose? 'block' : 'none'" class="modal name-popup loginAlerModal" id="imgmodal"
        tabindex="-1" role="dialog" aria-labelledby="myMLabel" aria-hidden="true">
        <div class="modaldialog m-auto modal-dialog-centered">
            <div class="signinClass">
                <div class="modal-body bodyClass p-0">
                    <div class="modalWrapperBox p-3">
                        <div class="imgBox">
                            <img src="./assets/Libs/images/WarnQues.png" height="160">
                        </div>
    
                        <div class="formClass p-0">
                            <h2 class="mb-0">{{UseWorkstationclerkName}} has an open shift on this workstation.</h2>
                            <h2>Would you like to force close the shift in progress?</h2>
    
                            <div class="cstmModal_btnBox">
                                <button (click)="ForceCloseShift()" class="btn btn-light CheckWorkstation">Yes</button>
                                <button (click)="Reload()" class="btn btn-light CheckWorkstation CancelBtn">No</button>
                            </div>
                        </div>
                    </div>
    
    
                </div>
            </div>
        </div>
    </div>
    
    <div [style.display]="SuccessfullyEndShift? 'block' : 'none'" class="modal name-popup loginAlerModal sucessAlertModal"
        id="imgmodal" tabindex="-1" role="dialog" aria-labelledby="myMLabel" aria-hidden="true">
        <div class="modaldialog m-auto modal-dialog-centered">
            <div class="signinClass">
                <div class="modal-body bodyClass p-0">
                    <div class="modalWrapperBox p-3 pb0">
                        <div class="imgBox">
                            <img src="./assets/Libs/images/RightIcon.PNG" height="125">
                        </div>
                        <div class="formClass p-0">
                            <!-- <img src="./assets/Libs/images/warninguserImage.png" height="16"> -->
                            <h2 class="mb-0">End of Day completed successfully!</h2>
                        </div>
                    </div>
    
                    <div class="modalWrapperBox btmBTN_MainBox px-3 pb-3">
                        <div class="imgBox boxHide">
                            <img src="./assets/Libs/images/RightIcon.PNG" height="125">
                        </div>
                        <div class="cstmModal_btnBox w100">
                            <button (click)="Reload()" class="btn btn-light btnSubmit CheckWorkstation">Ok</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>