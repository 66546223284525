<div class="login-container" style="background-color: #0082D2;">
    <!-- <div class="login-container" style="background-color: #0082D2;"> -->
</div>

<div [style.display]="DistrictSelectionModal? 'block' : 'none'" class="modal name-popup" id="imgmodal" tabindex="-1"
    role="dialog" aria-labelledby="myMLabel" aria-hidden="true">
    <div class="modaldialog">
        <div class="signinClass">
            <div class="modal-header SignInHeader">
                <h4><img class="giftImage" src="./assets/Libs/images/HeaderLogo.png" height="16">GiftBox Setup Wizard
                </h4>
            </div>
            <div class="modal-body bodyClass p-0">
                <div class="secodHeader ">
                    <img class="GiftBoxLogo" src="./assets/Libs/images/GiftLog.png" height="16">
                </div>
                <div class="formClass cstm_mainFormBox">
                    <h2>Please select the district you would like to assign to this device.</h2>

                    <div class="cstmInputBox">
                        <select class="form-control formdata" [(ngModel)]="selectedDistrict">
                            <option value="--Select District--">--Select District--</option>
                            <option *ngFor="let dist of DistrictList" [value]="dist.districtId">
                                {{dist.districtName}}
                            </option>
                        </select>
                    </div>
                    <div>
                        <span class="ErrorText" >{{Message}}</span>
                    </div>
                    <div class="modal_BTNBox">
                        <button (click)="SelectDistrict()" class="btn btn-light btnSubmit">Continue</button>
                    </div>
                </div>
            </div>
            <!-- <div class="row">
            </div> -->
        </div>
    </div>
</div>