export class StringHelper {
    public static CashAmountInputValidator(input: string){
        debugger;
        let amount = this.NumberValidator(input);
        let amountVal = this.RemoveExtraDecimals(amount);
        let amountDec = this.TwoDecimalPlaces(amountVal);
        // if(amountDec == "0"){
        //     amountDec="";
        // }
        return amountDec;
    }

    public static NumberValidator(input: string) {
        let newValue = input.replace(/(^0+)|([^\d.]+)/g, '');
        newValue = "" + (newValue || "0");
        return newValue.replace(/(^\.)/g, '0.');
    }

    public static RemoveExtraDecimals(input: string) 
    { 
        let index = input.indexOf('.'); 
        if (index > -1) 
        { 
          input = input.substring( 0, index + 1 ) + input.slice( index ).replace(/\./g, '');
        } 
        return input;
    }

    public static TwoDecimalPlaces(input: string){
        let newValue = input.replace(/^(\d+.?\d{0,2})\d*$/g, "$1");
        return newValue;
    }
}