import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { AuthService } from 'src/app/Services/auth.service';
import { IPOSService } from 'src/app/Services/i-pos.service';
import { InventoryService } from 'src/app/Services/inventory.service';
//import { MiddleWare } from 'src/app/Services/middleware.service';
import { ShiftService } from 'src/app/Services/shift.service';
import Swal from 'sweetalert2';
import { AddInventorygrid } from '../add-inventorygrid.model';
import { InventoryModel } from '../inventory-model.model';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery';
import { PortExeMsgHelper } from 'src/app/shared/helpers/port-exe-msg.helper';

@Component({
  selector: 'app-view-transfer',
  templateUrl: './view-transfer.component.html',
  styleUrls: ['./view-transfer.component.css']
})
export class ViewTransferComponent implements OnInit {
  position: string;
  workstationList: any = [];
  vendorList: any = [];
  currentUser: any;
  viewData: any = [];
  viewTransferData: any = [];
  viewProdDescData: any = [];
  viewProdTransferData: any = [];
  SelectedProduct = 0;
  viewInventoryTransfer: any;
  newInventoryTansfer: any;
  transferId: any;
  transferDate: any;
  isTransferProd = false;
  isProdSelect = true;
  totalSelectedQTY: any;
  selectedOrderId: any;
  printJobId: 0;
  onHandId = 0;
  TransferToWsid: any;
  dynamicArray: Array<AddInventorygrid> = [];
  transferDetails: Array<InventoryModel> = [];
  newDynamic: any = {};
  InventoryList: any = {};
  transferProdId: any;
  transferQty = "";
  SelectedTransferId: any;
  SelectedFromWsid: any;
  SelectedToWsid: any;
  workstation = JSON.parse(localStorage.getItem('workstation'));

  constructor(private route: ActivatedRoute,
    private router: Router,
    private _shiftService: ShiftService,
    private authenticationService: AuthService,
    private confirmationService: ConfirmationService,
    //private _middlewareService: MiddleWare,
    private posSerive: IPOSService,
    private invService: InventoryService, private toastr: ToastrService) { }

  ngOnInit() {
    debugger;

    this.CheckPrinter();
    // Getting Current Shift Details
    this.currentUser = this.authenticationService.currentUserValue;

    debugger;
    this.GetNewTransferList();

    $(".navigation ul li a").removeClass("active");
    $(".envSec").addClass("active");
    $(".salesSec, .transaSec, .cashSec").removeClass("active");

  }

  GetNewTransferList() {
    this.invService.GetViewTransfer(this.workstation.workStationId)
      .subscribe((viewInv) => {

        var newArr = []
        viewInv.forEach((item, index) => {
          if (newArr.findIndex(i => i.transferId == item.transferId) === -1) {
            newArr.push(item)
          }

        });
        this.viewTransferData = newArr;
        localStorage.setItem('viewTransferRecord', JSON.stringify(this.viewTransferData));
        console.log(viewInv);

      })

  }

  getProdForTransfer(workststionId) {
    debugger;
    this.isTransferProd = true;
    this.TransferToWsid = workststionId;

    this.invService.GetInventoryCount(this.workstation.workStationId,false,this.currentUser.districtNum)
      .subscribe((viewInvetory) => {
        this.viewProdTransferData = viewInvetory;
        console.log(viewInvetory);
      });

    this.newDynamic = { SerialNo: 1, ProductId: 0, Quantity: "" };
    this.dynamicArray.push(this.newDynamic);
  }

  ChangeStatus(transferId, toWs) {
    debugger;
    const messages = '<img src="./assets/Libs/images/QuestionCircle.png" class="imgconfQues" /><div class="cnfQues-info"><h2>Confirmation</h2><p>This will add inventory to your workstation. Do you want to continue?</p></div>';
    this.confirmationService.confirm({
      message: messages,
      header: 'Transfer',
      rejectVisible: true,
      acceptLabel: 'Yes',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        // Du here update operation
        $('.ui-dialog-mask-scrollblocker').css('display', 'none');
        this.ChangeTransferStstus(transferId);
      },
      reject: () => {
        $('.ui-dialog-mask-scrollblocker').css('display', 'none');
        //this.msgs = [{ severity: 'info', summary: 'Rejected', detail: 'You have rejected' }];
      }
    });
  }

  ChangeTransferStstus(transferId) {
  
    let updateStatus = {
      transferId:transferId,
      status:'COMPLETED',
      currentShiftNum:this.workstation.workStationId +'-'+(parseInt(this.workstation.lastShiftNum) + 1),
      districtNum:this.currentUser.districtNum,
      workId:this.workstation.workStationId,
      userId:this.currentUser.userId

    }

    //this.invService.ChangeInventoryTransferStstus(transferId, 'COMPLETED', this.workstation.workStationId +'-'+(parseInt(this.workstation.lastShiftNum) + 1), this.currentUser.districtNum,this.workstation.workStationId,this.currentUser.userId)
    this.invService.ChangeInventoryTransferStstus(updateStatus)
      .subscribe((res) => {
        debugger;
        if (res.message === 'Success') {
          this.printJobId = res.printJobId;
          this.toastr.success('Transfer has been accepted successfully.');
          //this.PrintReport();
          this.GetNewTransferList();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: 'Something Went Wromg!'
          });
        }
      });
  }

  DeleteTransfer(transferId, toWs) {
    debugger;
    const messages = '<img src="./assets/Libs/images/QuestionCircle.png" class="imgconfQues" /><div class="trnsfr-pop-txt"><p>By cancelling this transfer, the inventory will be added back to the onhand counts for your workstation.​</p>​<p>​You are responsible for all inventory transfers from your workstation until they are accepted by the receiving location.​</p>​<p>​Do you want to continue?​​</p></div>';
    this.confirmationService.confirm({
      message: messages,
      header: 'Transfer',
      rejectVisible: true,
      acceptLabel: 'Yes',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        $('.ui-dialog-mask-scrollblocker').css('display', 'none');
        // Du here update operation
        this.DeleteTransferStatus(transferId);
      },
      reject: () => {
        $('.ui-dialog-mask-scrollblocker').css('display', 'none');
      }
    });
  }

  DeleteTransferStatus(transferId) {
    debugger;
    this.invService.DeleteInventoryTransferStstus(transferId)
      .subscribe((res) => {
        debugger;
        if (res.message === 'Success') {
          this.toastr.success('Transfer has been cancelled successfully.');
          this.GetNewTransferList();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: 'Something Went Wromg!'
          });
        }
        this.GetNewTransferList();
      });
  }

  ViewTransferStatus(transferId) {
    debugger;
    this.invService.ViewProdDescription(transferId, this.workstation.workStationId)
      .subscribe((viewProd) => {
        this.viewProdDescData = viewProd;
        console.log(viewProd);
        this.SelectedTransferId = transferId;
        this.SelectedFromWsid = this.viewProdDescData[0].transferFromWsName;
        this.SelectedToWsid = this.viewProdDescData[0].transferToWsName;

      });
    
  }

  // PrintReport() {
  //   let midResponse = this._middlewareService.ShiftOpenReport(this.printJobId, 'Inventory');
  //   midResponse = PortExeMsgHelper.GetPortStatusMessage(midResponse);
    
  //   setTimeout(() => {
  //     if (midResponse.includes('success')) {
  //       const messages = '<img src="./assets/Libs/images/WarnQues.png" class="imgconfQues" /><p>Do you want another copy of the Transfer Report?</p>';
  //       this.confirmationService.confirm({
  //         message: messages,
  //         header: 'Transfer',
  //         rejectVisible: true,
  //         acceptLabel: 'Yes',
  //         icon: 'pi pi-exclamation-triangle',
  //         accept: () => {
  //           $('.ui-dialog-mask-scrollblocker').css('display', 'none');
  //           this._middlewareService.ShiftOpenReport(this.printJobId, 'Inventory');
  //         },
  //         reject: () => {
  //           $('.ui-dialog-mask-scrollblocker').css('display', 'none');
  //         }
  //       });
  //     } else if (midResponse.includes('not Connected')) {
  //       Swal.fire({
  //         icon: 'warning',
  //         title: 'Warning!',
  //         text: midResponse,
  //       });
  //     }
  //     else {
  //       Swal.fire({
  //         icon: 'error',
  //         title: 'Error!',
  //         text: 'Error while Printing!',
  //       });
  //     }
  //   }, 500);
  // }

  CheckPrinter()
  {
    this._shiftService.GetPrinterStatus(this.workstation.workStationId).subscribe((res) => {
      debugger;
      if(res!=3 && res!=0)
      {
      let printerMessage=  this.authenticationService.GetPrinterMessgae(res);
        Swal.fire({
          icon: 'warning',
          title: 'warning!',
          text: printerMessage,
          timer: 10000, // 10 seconds
          timerProgressBar: true,
        })
      }
    });
  }

}
