<div class="right-content">
    <div class="heading-panel text-left">
        <h1><img src="./assets/Libs/images/enventry.png" height="16"> Inventory</h1>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <h1 class="text-center body-heading">Adjustments</h1>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="invent-back">
            <div class="row">
                <div class="col-sm-12">
                    <div class="table-responsive">
                        <table class="table table-bordered table-striped ">
                            <thead>
                                <tr>
                                    <th style="width:5%"></th>
                                    <th style="width:14%">Action</th>
                                    <th style="width:58%">Product</th>
                                    <th style="width:16%">Quantity</th>
                                    <th style="width:7%"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let dynamic of dynamicArray; let i = index;">
                                    <td></td>
                                    <td>
                                        <select class="form-control"
                                            [ngClass]="{'border border-danger': dynamicArray[i].AddOrRemove=='0'}"
                                            [(ngModel)]="dynamicArray[i].AddOrRemove" (ngModelChange)="SelectAddOrRemove($event,dynamicArray[i]);"ngDefaultControl>
                                            <option value="0">-Select-</option>
                                            <option value="1">Add</option>
                                            <option value="2">Subtract</option>
                                        </select>
                                    </td>                            
                                    <td >
                                        <select class="form-control" [(ngModel)]="dynamicArray[i].ProductId" ngDefaultControl
                                            [ngClass]="{'border border-danger': dynamicArray[i].ProductId=='0'}"
                                            (ngModelChange)="selectProdForTransfer($event,dynamicArray[i]);" #selectProd>
                                            <option value="0">-Select Product-</option>
                                            <option *ngFor="let item of viewProdTransferData" [ngValue]="item">
                                                {{item.pluCode}} - {{item.productName}}
                                            </option>
                                        </select>
                                    </td>
                                    <td>
                                        <input type="number" [(ngModel)]="dynamicArray[i].Quantity" ngDefaultControl
                                            [ngClass]="{'border border-danger': dynamicArray[i].Quantity===''||dynamicArray[i].Quantity === null}"
                                             class="form-control  text-center" (ngModelChange)="CheckQuanity(dynamicArray[i].Quantity);">
                                    </td>
                                    <td class="text-danger text-center"> <i class="fa fa-trash cstm-invtAdt-trshIcon" ngDefaultControl
                                            (click)="deleteRow(i)"></i></td>
                                </tr>
                                <tr>
                                    <td class="text-success">
                                        <i class="fa fa-plus-circle add-icon-invtAdt" ngDefaultControl (click)="addFieldValue()"></i>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="row my-4">
            <div class="col-sm-12">
                <div class="action-buttons text-center">
                    <button  class="button button-secondary"
                        (click)="SaveAdjustmentTransaction($event,dynamicArray)">Save
                    </button>&nbsp;
                    <button class="button button-secondary resetbtn" (click)="ResetTransaction()"><i class="fa fa-rotate-left"></i>
                    Reset</button>
                    <button class="button button-secondary" (click)="CancelTransaction()">
                        Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- ------------------------------------------------------------------------------------------------------------------------------- -->
<!-- <div [style.display]="MySingInModal? 'block' : 'none'" class="modal name-popup" id="imgmodal" tabindex="-1"
role="dialog" aria-labelledby="myMLabel" aria-hidden="true">
<div class="modal-dialog">
    <div class="modal-content signinClass">
        <div class="modal-header SignInHeader">
            <button type="button" class="close"data-dismiss="modal" (click)="hide('SignInModal')">×</button>
        </div>
        <div class="row col-md-12" >
            <div class="col-md-4 ">
                <img class="SignInImageIcon" src="../../../../assets/Libs/images/logo-icon.png">
            </div>
            <div class="modal-body col-md-8" >
                <h1 class="modal-title text-heading-color" id="myLabel">SIGN IN</h1>
                <form [formGroup]="LoginAdjuctInvent"  (ngSubmit)="AdjustLogin()">
                    <div class="form-group">
                        <input type="text" placeholder="Username" formControlName="UserNameInv"
                            class="form-control" [ngClass]="{ 'is-invalid':issubmitted && Login.UserNameInv.errors }" />
                            <div *ngIf="issubmitted && Login.UserNameInv.errors" class="invalid-feedback">
                                <div *ngIf="Login.UserNameInv.errors.required">UserName is required</div>
                            </div>
                    </div>
                    <div class="form-group">
                        <input type="password" placeholder="Password" formControlName="PasswordLoginInv"
                            class="form-control" [ngClass]="{ 'is-invalid':issubmitted && Login.PasswordLoginInv.errors }" />
                            <div *ngIf="issubmitted && Login.PasswordLoginInv.errors" class="invalid-feedback">
                                <div *ngIf="Login.PasswordLoginInv.errors.required">Password is required</div>
                            </div>
                    </div>
                    <button type="submit" id='btnSubmit'  class="btn btn-light  form-control"
                        >LOGIN</button>
                </form>
            </div>
        </div>
    </div>
</div>
</div> -->

<div [style.display]="MySingInModal? 'block' : 'none'" class="modal name-popup" id="imgmodal" tabindex="-1"
    role="dialog" aria-labelledby="myMLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-cstmSign">
        <div class="modal-content signinClass">
            <div class="modal-header SignInHeader">
                <h4 class="modal-title" id="myLabel">Authorization Required</h4>
            </div>
            <div class="modal-body text-right">      
                <form [formGroup]="LoginAdjuctInvent" (ngSubmit)="AdjustLogin()">
                    <div class="form-group">

                        <input type="text" placeholder="Username" formControlName="UserNameInv" autocomplete="off"
                            class="form-control"
                            [ngClass]="{ 'is-invalid':issubmitted && Login.UserNameInv.errors }" />
                        <div *ngIf="issubmitted && Login.UserNameInv.errors" class="invalid-feedback">
                            <div *ngIf="Login.UserNameInv.errors.required">UserName is required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <input type="password" placeholder="Password" formControlName="PasswordLoginInv" autocomplete="off"
                            class="form-control"
                            [ngClass]="{ 'is-invalid':issubmitted && Login.PasswordLoginInv.errors }" />
                        <div *ngIf="issubmitted && Login.PasswordLoginInv.errors" class="invalid-feedback">
                            <div *ngIf="Login.PasswordLoginInv.errors.required">Password is required</div>
                        </div>
                    </div>
                    <div class="signX-buttons d-flex justify-content-between align-items-center">
                        <button type="button" class="close btnsignX" data-dismiss="modal"
                            (click)="hide('SignInModal')">Cancel</button>
                        <button type="submit" id='btnSubmit' class="btn btnsignN  form-control">Sign In</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- --------------------------------------------------------------------------------------------------------------------------------- -->

<p-confirmDialog acceptLabel="Continue" appendTo="body">
    <ng-template pTemplate="body">
        <span class="ui-confirmdialog-message"></span>
    </ng-template>
</p-confirmDialog>