// import { NgModule } from '@angular/core';
// import { Routes, RouterModule } from '@angular/router';
// import { LoginComponent } from './Components/login/login.component';
// import { ShiftClosingComponent } from './Components/navigation/shift-closing/shift-closing.component';
// import { SelectSitesComponent } from './Components/select-sites/select-sites.component';
// import { SelectdistrictComponent } from './Components/selectdistrict/selectdistrict.component';
// import { SelectworkstationComponent } from './Components/selectworkstation/selectworkstation.component';
// import { WorkstationsComponent } from './Components/workstations/workstations.component';
// //let setGUID = localStorage.setItem("SystemGUID", "1");

// let GetGUID = JSON.parse(localStorage.getItem("SystemGUID"));
// let RoutDeatils



// if(GetGUID!=null){
//   const routes: Routes = [
//     { path: '', component: LoginComponent },
//     // { path: 'WorkstationList', component: WorkstationsComponent },
//     {
//       path: 'Home',
//       loadChildren: () => import('./Components/navigation/navigation.module').then(m => m.NavigationModule)},
//     { path: '**', redirectTo: '' }
//   ];
//   RoutDeatils=routes;
// }
// else{
//   const routes: Routes = [
//     { path: '', component: SelectdistrictComponent },
//     { path: 'SiteComponents', component: SelectSitesComponent },
//     { path: 'WorkStationComponents', component: SelectworkstationComponent },
//     { path: 'Login', component: LoginComponent },
//     {
//       path: 'Home',
//       loadChildren: () => import('./Components/navigation/navigation.module').then(m => m.NavigationModule)},
//     { path: '**', redirectTo: '' }
//   ];
//   RoutDeatils=routes;
// }
// @NgModule({
//   imports: [RouterModule.forRoot(RoutDeatils, { relativeLinkResolution: 'legacy' })],
//   exports: [RouterModule]
// })
// export class AppRoutingModule { }



import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './Components/login/login.component';
import { ShiftClosingComponent } from './Components/navigation/shift-closing/shift-closing.component';
import { SelectSitesComponent } from './Components/select-sites/select-sites.component';
import { SelectdistrictComponent } from './Components/selectdistrict/selectdistrict.component';
import { SelectworkstationComponent } from './Components/selectworkstation/selectworkstation.component';
import { WorkstationsComponent } from './Components/workstations/workstations.component';
import { ResetPasswordComponent } from './Components/reset-password/reset-password.component';
//let setGUID = localStorage.setItem("SystemGUID", "1");
import { AuthService } from 'src/app/Services/auth.service';
import { LoadComponentComponent } from './Components/load-component/load-component.component';
import { ForgotPasswordComponent } from './Components/forgot-password/forgot-password.component';

let GetGUID = JSON.parse(localStorage.getItem("SystemGUID"));
let RoutDeatils
// if(GetGUID!=null){
//   debugger;
//   const routes: Routes = [
//     { path: '', component: LoginComponent },
//     // { path: 'WorkstationList', component: WorkstationsComponent },
//     {
//       path: 'Home',
//       loadChildren: () => import('./Components/navigation/navigation.module').then(m => m.NavigationModule)},
//     { path: '**', redirectTo: '' }
//   ];
//   RoutDeatils=routes;
// }
//else{
  debugger;
  const routes: Routes = [
    { path: '', component: LoadComponentComponent },
    { path: 'DistrictComponent', component: SelectdistrictComponent },
    { path: 'SiteComponents', component: SelectSitesComponent },
    { path: 'WorkStationComponents', component: SelectworkstationComponent },
    { path: 'ForgotPassword', component: ForgotPasswordComponent },
    { path: 'ResetPassword', component: ResetPasswordComponent },
    { path: 'Login', component: LoginComponent },
    {
      path: 'Home',
      loadChildren: () => import('./Components/navigation/navigation.module').then(m => m.NavigationModule)},
    { path: '**', redirectTo: '' }
  ];
 // RoutDeatils=routes;
//}
@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
 }
