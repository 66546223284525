import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ShiftService } from 'src/app/Services/shift.service';
import { AuthService } from 'src/app/Services/auth.service';
import { ConfirmationService } from 'primeng/api';
// import { MiddleWare } from 'src/app/Services/middleware.service';
import Swal from 'sweetalert2';
import { InventoryService } from 'src/app/Services/inventory.service';
import { ToastrService } from 'ngx-toastr';
import { InventoryModel } from '../inventory-model.model';
import { StringHelper } from 'src/app/shared/helpers/string.helper';
import * as $ from 'jquery';
import { PortExeMsgHelper } from 'src/app/shared/helpers/port-exe-msg.helper';
import { ShiftTenderBalance } from 'src/app/Models/shift.model';
import { IPOSService } from 'src/app/Services/i-pos.service';
@Component({
  selector: 'app-shift-closing',
  templateUrl: './shift-closing.component.html',
  styleUrls: ['./shift-closing.component.css']
})
export class ShiftClosingComponent implements OnInit {
  message: any;
  isnotPreview: boolean = true;
  isCashDeposit = false;
  isPreview: boolean = false;
  isOutOfBalance: boolean = true;
  isInventory: boolean = false;
  isRoomChargeScreen: boolean = false;
  isMoneyCertScreen: boolean = false;
  isCashScreen: boolean = false;
  isTenderTypeScreen: string = '';
  messagetext: string = 'Out of Balance';
  inventoryOutage: Array<InventoryModel> = [];
  confirmWidth: string = '40vw';
  currentUser: any;
  cashinDrwer: any;
  workstation: any;
  currentShiftTotals: any = '';
  currentShiftTenderBalance: ShiftTenderBalance;
  enterScreenTenderCase: string;
  cashForward: 0;
  printJobId = 0;
  openingCount: any = '';
  editRoomAmt: any = '';
  editMoneyAmt: any = '';
  viewData: any = [];
  viewTransferData: any = [];
  cashDeposit: any = '';
  isChangeTotal = false;
  changeTotal = 0;
  hunders = 0;
  fifities = 0;
  twnties = 0;
  tens: number = 0;
  fives: number = 0;
  once = 0;
  TotalCash: number | string = this.changeTotal + this.hunders + this.fifities + this.twnties + this.tens + this.fives;
  TotalRoom: number | string;
  TotalMoney: number | string;
  RoomChargeNet: number;
  MoneyCertNet: number;
  RoomChargeReported: number;
  MoneyCertReported: number;
  CashForwardBalance: any;
  isPendingView: boolean = false;
  TenderList: any[];
  tenderActiveList: any[];
  EndShiftBalanceId: any;
  siteConfi: any;
  EndOfShiftMessage: string;
  CashForwarddata: number;
  isRoomChargeTender: boolean = false;
  isMoneyCertTender: boolean = false;
  isCashTender: boolean = false;
  isInvetoryBalance:boolean;
  constructor(private route: ActivatedRoute,
    private _posSerive: IPOSService,
    private router: Router,
    private _shiftService: ShiftService,
    private authenticationService: AuthService,
    private invService: InventoryService,
    private confirmationService: ConfirmationService,
    //private _middlewareService: MiddleWare, 
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    debugger;
    this.currentUser = this.authenticationService.currentUserValue;
    this.workstation = JSON.parse(localStorage.getItem('workstation'));
    this.siteConfi = JSON.parse(localStorage.getItem("siteConfivalidated"));
    this.GetNewTransferList();
    this._shiftService.GetCurrentShiftTenderBalance(this.workstation.workStationId + "-" + (parseInt(this.workstation.lastShiftNum) + 1), this.workstation.workStationId)
      .subscribe((res) => {
        debugger;
        const shiftBalance = res;
        this.currentShiftTenderBalance = shiftBalance;
        this.showTenderScreen();
      });

    this._shiftService.GetCurrentShiftTotals(this.workstation.workStationId + "-" + (parseInt(this.workstation.lastShiftNum) + 1), this.workstation.workStationId)
      .subscribe((res) => {
        debugger;
        this.currentShiftTotals = res;
      });
    $(document).on('click', ".cashcalcbtn", function () {
      console.log("I Am Working");
      $('.cash-calculator').toggleClass('active').promise().done(function () {
        if ($(this).hasClass('active')) {
          $('.pin-tab').addClass('open');
        } else {
          $('.pin-tab').removeClass('open');
        }
      });
    });

    this._posSerive.GetTendersDetail(this.workstation.siteId).subscribe((res) => {
      debugger;
      this.tenderActiveList = [];
      if (res != 0) {
        this.TenderList = res;
        for (let i = 0; i < this.TenderList.length; i++) {
          let tenderTypeValue = { tenderActive: this.TenderList[i].isActive, TenderTypeId: this.TenderList[i].tenderTypeId }

          this.tenderActiveList.push(tenderTypeValue)
          console.log('localdata', this.tenderActiveList);

        }
      }
    });
    this.ShowCashAndProductCofirmation();
  }



  showHideScreenByTender(tenderTypeScreen) {
    if (tenderTypeScreen == "ROOM") {
      this.isRoomChargeScreen = true;
      this.isMoneyCertScreen = false;
      this.isCashScreen = false;
      this.isRoomChargeTender = true

    }
    else if (tenderTypeScreen == "MONEY") {
      this.isRoomChargeScreen = false;
      this.isMoneyCertScreen = true;
      this.isCashScreen = false;
      this.isMoneyCertTender = true
    }
    else if (tenderTypeScreen == "CASH") {
      this.isRoomChargeScreen = false;
      this.isMoneyCertScreen = false;
      this.isCashScreen = true;
      this.isCashTender = true;
    }
    else {
      this.isRoomChargeScreen = false;
      this.isMoneyCertScreen = false;
      this.isCashScreen = false;

      this.isCashDeposit = false;
      this.isChangeTotal = false;
      this.isnotPreview = false;
      this.CheckCashForward();
    }
  }



  showTenderScreen() {
    debugger;
    if (this.currentShiftTenderBalance.totalRoomChargeBalance > 0) {
      this.isTenderTypeScreen = "ROOM";
      this.showHideScreenByTender("ROOM");
    }
    else if (this.currentShiftTenderBalance.totalMoneyCertBalance > 0) {
      this.isTenderTypeScreen = "MONEY";
      this.showHideScreenByTender("MONEY");
    }
    else if (this.currentShiftTenderBalance.totalCashBalance >= 0) {
      this._shiftService.GetCashActivityCount(this.workstation.workStationId + "-" + (parseInt(this.workstation.lastShiftNum) + 1))
        .subscribe((res) => {
          if (res) {
            this.isTenderTypeScreen = "CASH";
            this.showHideScreenByTender("CASH");
          }
          else {
            this.CheckScreen()
          }
        })
    }
    else {
      this.showHideScreenByTender("");
    }
  }
  RedirectToHome() {
    this.GoBackToPrevoius();
  }

  PopulateAmount(value) {
    //debugger;
    if (value === 'x') {
      let openingBal = "" + this.openingCount
      this.openingCount = openingBal.slice(0, -1);
      this.openingCount = this.openingCount || "0";
    } else {
      this.openingCount = "" + this.openingCount + value;
    }
    this.openingCount = StringHelper.CashAmountInputValidator(this.openingCount);
  }

  rcPopulateAmount(value) {
    debugger;
    if (value === 'x') {
      let openingBal = "" + this.editRoomAmt
      this.editRoomAmt = openingBal.slice(0, -1);
      this.editRoomAmt = this.editRoomAmt || "0";
    } else {
      this.editRoomAmt = "" + this.editRoomAmt + value;
    }
    this.editRoomAmt = StringHelper.CashAmountInputValidator(this.editRoomAmt);
  }

  mcPopulateAmount(value) {
    if (value === 'x') {

      let openingBal = "" + this.editMoneyAmt
      this.editMoneyAmt = openingBal.slice(0, -1);
      this.editMoneyAmt = this.editMoneyAmt || "0";
    } else {
      this.editMoneyAmt = "" + this.editMoneyAmt + value;
    }
    this.editMoneyAmt = StringHelper.CashAmountInputValidator(this.editMoneyAmt);
  }

  ResetOpeningCount() {
    this.openingCount = 0;
    this.TotalCash = this.openingCount;
    this.hunders = this.fifities = this.twnties = this.tens = this.fives = this.once = this.openingCount = this.changeTotal = 0;
  }

  ResetRoomAmount() {
    this.editRoomAmt = 0;
    this.TotalRoom = this.editRoomAmt;
  }

  ResetMoneyAmount() {
    this.editMoneyAmt = 0;
    this.TotalMoney = this.editMoneyAmt;
  }

  PopulatecashDeposit(value) {
    debugger;
    if (this.cashDeposit === null) {
      this.cashDeposit = '';
    }
    if (value === 'x') {
      this.cashDeposit = '';
      this.hunders = this.fifities = this.twnties = this.tens = this.fives = this.once = this.TotalCash = this.openingCount;
    } else {
      this.cashDeposit = "" + this.cashDeposit + value;
    }
    this.cashDeposit = StringHelper.CashAmountInputValidator(this.cashDeposit);
    this.checkForward(this.cashDeposit);
  }

  CheckForOutBalance() {
    debugger;
    if (this.openingCount == '') {
      this.toastr.error("Enter your cash balance before continue!")
    }
    else {
      this.checkCashBalance(this.openingCount)
      if (this.messagetext === 'Out of Balance') {
        this.confirmWidth = '40vw'
        this.confirmationService.confirm({
          message: "<p class='worstation-img'><img src='./assets/Libs/images/confirmation.png'></p> <h3 class='pop-head text-danger'>OUT OF BALANCE! </h3> <p class='assignstyle'>Your manager will be notified. Do you want to continue?</p>",
          header: 'Confirmation',
          acceptLabel: 'Yes',
          rejectVisible: true,
          accept: () => {
            this.EndOfShiftMessage = 'Notify your manager immediately if any of your  totals are out of balance!';
            $('.ui-dialog-mask-scrollblocker').css('display', 'none');
            this.isChangeTotal = false;
            if (this.siteConfi.cashParBalance === true) {
              this.checkForward(this.cashDeposit, "cashParBalance");
              this.CheckCashForward();
            }
            else if (this.siteConfi.depositAll == true) {
              this.checkForward(this.cashDeposit, "depositAll");
              this.CheckCashForwardNew();

            }
            else {
              if(this.currentShiftTotals!=0){
                this.isChangeTotal = false;
                this.isCashDeposit = true;       
                this.isnotPreview = false;
                }
                else{
                  this.CheckCashForward();
                }
            }
            this.isnotPreview = false;

          },
          reject: () => {
            $('.ui-dialog-mask-scrollblocker').css('display', 'none');
          }
        });
      }
      else {

        if (this.siteConfi.cashParBalance === true) {
          this.checkForward(this.cashDeposit, "cashParBalance");
          this.CheckCashForward();
        }
        else if (this.siteConfi.depositAll == true) {
          debugger;
          this.checkForward(this.cashDeposit, "depositAll");
          this.CheckCashForwardNew();
        }
        else {
          if(this.currentShiftTotals!=0){
          this.isChangeTotal = false;
          this.isCashDeposit = true;       
          this.isnotPreview = false;
          }
          else{
            this.CheckCashForward();
          }
        }
        this.isnotPreview = false;

      }
    }
  }

  CheckScreen() {
    debugger;
    if (this.siteConfi.cashParBalance === true) {
      this.checkForward(this.cashDeposit, "cashParBalance");
      this.CheckCashForward();
    }
    else if (this.siteConfi.depositAll == true) {
      this.checkForward(this.cashDeposit, "depositAll");
      this.CheckCashForwardNew();
    }
    else {
      this.isChangeTotal = false;
      this.isCashDeposit = true;
      this.isnotPreview = false;
    }
    this.isnotPreview = false;

  }








  CheckForRoomOutBalance() {
    debugger;
    let moneyBal = this.currentShiftTenderBalance.totalMoneyCertBalance || 0;
    let cashBal = this.currentShiftTenderBalance.totalCashBalance || 0;
    let roomBal = this.currentShiftTenderBalance.totalRoomChargeBalance || 0;
    if (this.editRoomAmt == '') {
      this.toastr.error("Enter your room charge Balance before continue!")
    }
    else {
      if (roomBal != parseFloat(this.editRoomAmt)) {
        this.confirmWidth = '40vw'
        this.confirmationService.confirm({
          message: "<p class='worstation-img'><img src='./assets/Libs/images/confirmation.png'></p> <h3 class='pop-head text-danger'>OUT OF BALANCE! </h3> <p class='assignstyle'>Your manager will be notified. Do you want to continue?</p>",
          header: 'Confirmation',
          acceptLabel: 'Yes',
          rejectVisible: true,
          accept: () => {
            $('.ui-dialog-mask-scrollblocker').css('display', 'none');
            this.EndOfShiftMessage = 'Notify your manager immediately if any of your  totals are out of balance!';
            if (moneyBal == 0) {
              if (cashBal == 0) {
                this.showHideScreenByTender("");
              }
              else {
                this.showHideScreenByTender("CASH");
              }

            }
            else {
              this.showHideScreenByTender("MONEY");
            }
          },
          reject: () => {
            $('.ui-dialog-mask-scrollblocker').css('display', 'none');
          }
        });
      } else {
        if (moneyBal == 0) {
          if (cashBal == 0) {
            this.CheckCashForward();
          }
          else {
            this.showHideScreenByTender("CASH");
          }
        }
        else {
          this.showHideScreenByTender("MONEY");
        }
      }
    }
  }

  CheckForMoneyOutBalance() {
    debugger;
    let moneyBal = this.currentShiftTenderBalance.totalMoneyCertBalance || 0;
    let cashBal = this.currentShiftTenderBalance.totalCashBalance || 0;
    if (this.editMoneyAmt == '') {
      this.toastr.error("Enter your money cert balance before continue!")
    }
    else {
      if (moneyBal != parseFloat(this.editMoneyAmt)) {
        this.confirmWidth = '40vw'
        this.confirmationService.confirm({
          message: "<p class='worstation-img'><img src='./assets/Libs/images/confirmation.png'></p> <h3 class='pop-head text-danger'>OUT OF BALANCE! </h3> <p class='assignstyle'>Your manager will be notified. Do you want to continue?</p>",
          header: 'Confirmation',
          acceptLabel: 'Yes',
          rejectVisible: true,
          accept: () => {
            $('.ui-dialog-mask-scrollblocker').css('display', 'none');
            this.EndOfShiftMessage = 'Notify your manager immediately if any of your  totals are out of balance!';
            this.showHideScreenByTender("CASH");
          },
          reject: () => {
            $('.ui-dialog-mask-scrollblocker').css('display', 'none');
          }
        });
      } else {
        this.toastr.success("Money Cert In Balance!")
        if (cashBal > 0) {
          this.showHideScreenByTender("CASH");
        }
        else {
          this.CheckCashForward()
        }
      }
    }
  }
  CheckCashForward() {
    debugger;
    this.isInventory = false;
    this.isPreview = false;
    this.isCashDeposit = false;
    this.isnotPreview = false;
    this.isChangeTotal = false;
   // this.invService.GetInventoryCount(this.workstation.workStationId, false, this.currentUser.districtNum,this.workstation.workStationId + "-" + (parseInt(this.workstation.lastShiftNum) + 1))
   this.invService.GetSoldInventoryDetails(this.workstation.workStationId + "-" + (parseInt(this.workstation.lastShiftNum) + 1),this.workstation.workStationId,)
      .subscribe((viewInvetory) => {
        console.log(viewInvetory);
        console.log('viewData ', this.viewData);
        if (viewInvetory.length == 0) {

          // this.invService.GetSoldInventoryDetails(this.workstation.workStationId + "-" + (parseInt(this.workstation.lastShiftNum) + 1),this.workstation.workStationId,)
          // .subscribe((productDetails) => {
          //   debugger;
          //   if (productDetails.length>0) {
          //     this.viewData = this.viewTransferData = productDetails;
          //     this.isInventory = true;
          //   }
          //   else {
          //     this.SaveInventory();
          //   }
          // })
          this.SaveInventory();
        }
        else {
          debugger;
          this.invService.GetInventoryActivityCount(this.workstation.workStationId + "-" + (parseInt(this.workstation.lastShiftNum) + 1),this.workstation.workStationId,)
            .subscribe((inveFlag) => {
              if (inveFlag) {
                this.viewData = this.viewTransferData = viewInvetory;
                this.isInventory = true;
              }
              else {
                this.SaveInventory();
              }
            })

        }
      });
  }

  CheckCashForwardNew() {
    debugger;
    if(this.cashDeposit)
    {
    if (this.cashDeposit == '') {
      this.toastr.error("Enter your bank deposit amount before continue!")
    }
    else {

      if(this.CashForwardBalance==undefined)
      {
        this.CashForwardBalance=0;
      }
      if (parseFloat(this.CashForwardBalance) >= 0) {
        this.CheckCashForward();

      }
      else {

        this.toastr.error("Cash deposit can not be greater than cash in balance!")
      }
    }
  }
  else{
    if (parseFloat(this.CashForwardBalance) >= 0) {
      this.CheckCashForward();

    }
  }
  }

  BackToCashBal() {
    debugger;
    this.isInventory = false;
    this.isPreview = false;
    this.isnotPreview = true;
    if(this.openingCount !='' && this.openingCount !=null)
    {
    
  // this.isCashDeposit = true;
    this.isCashScreen=true;
    this.isCashTender = true;
    }
    else if(this.editMoneyAmt!='' && this.editMoneyAmt !=null)
    { 
      this.isMoneyCertScreen=true;
      this.isCashScreen=false;
      this.isRoomChargeScreen=false;
    }
    else if(this.editRoomAmt!='' && this.editRoomAmt !=null)
    {
      this.isRoomChargeScreen=true;
      this.isCashScreen=false;
      this.isMoneyCertScreen=false;
    }
    else
    {
      this.GoBackToPrevoius();
    }
  }

  ResetInventory() {
    this.invService.GetInventoryCount(this.workstation.workStationId, false, this.currentUser.districtNum)
      .subscribe((viewInvetory) => {
        this.viewData = this.viewTransferData = viewInvetory;
        console.log(viewInvetory);
      });
  }


  CheckKeys(events) {
    debugger
    // tabvalue=tabvalue+1;
    // //alert(events.keyCode);
    // if (events.key === 45) {
    //   events.preventDefault();    
    //   return;
    // }
    // if (events.key === "Tab") {
    //   $("#inventoryItem").find('[tabindex=' + tabvalue + ']').focus();
    // // let currentitem= document.querySelector("input[tabindex=${indexnumber+1}]");

    //  //arrayOfElmnts.focus();
    //   //events.preventDefault();    
    //   return;
    // }

    if (events.code == 'KeyE' || events.keyCode === 45) {
      events.preventDefault();
      return;
    }
    if (events.key === "Tab" || events.key === "Enter") {
      var self = $(this),form = self.parents('form:eq(0)'),focusable, next;    
      focusable = form.find('textarea');
      next = focusable.eq(focusable.index(this) + 1);
      if (next.length) {
        next.focus();
      } else {
        form.submit();
      }
    }

  }



  CheckOutBal(event) {
    debugger;
    if (event.totalProdQTY !== event.checkQTY) {
      event.isOut = true;
    } else {
      event.isOut = false;
    }
  }

  SaveInventory() {
    debugger;
    if (this.viewData.filter(x => x.checkQTY === null || x.checkQTY === undefined || x.checkQTY === '').length === 0) {
      if (this.viewData.filter(x => x.checkQTY !== x.totalProdQTY).length !== 0) {
        setTimeout(() => {
          this.confirmWidth = '40vw'
          this.confirmationService.confirm({
            message: "<p class='worstation-img'><img src='./assets/Libs/images/confirmation.png'></p> <h3 class='pop-head text-danger'>OUT OF BALANCE! </h3> <p class='assignstyle'>Your manager will be notified. Do you want to continue?</p>",
            header: 'Confirmation',
            acceptLabel: 'Yes',
            rejectVisible: true,
            accept: () => {
              $('.ui-dialog-mask-scrollblocker').css('display', 'none');
              this.isInventory = false;
              this.isPreview = true;
              this.isCashDeposit = false;
              this.isnotPreview = false;
            },
            reject: () => {
              $('.ui-dialog-mask-scrollblocker').css('display', 'none');
            }
          });
        }, 500);
      }
      else {
        this.messagetext = "In Balance";
        this.isInventory = false;
        this.isPreview = true;
        this.isCashDeposit = false;
        this.isnotPreview = false;

      }
    } else {
      this.toastr.error("Enter your Inventory Balance before continuing.");
    }
this.CheckInvetoryBalanced();
    this.GetNewTransferList();
  }


  SaveCloseShift() {
    debugger;
    let saveShiftObj = {
      currentShiftNum: this.workstation.workStationId + "-" + (parseInt(this.workstation.lastShiftNum) + 1),
      WorkstationId: this.workstation.workStationId,
      Cidtotal: this.currentShiftTotals,
      BankDeposit: Number(this.cashDeposit),
      ShiftNum: this.workstation.workStationId + "-" + (parseInt(this.workstation.lastShiftNum) + 1),
      UserId: this.currentUser.userId,
      RoomChargeNet: this.currentShiftTenderBalance.totalRoomChargeBalance,
      RoomChargeReported: +this.editRoomAmt,
      MoneyCertNet: this.currentShiftTenderBalance.totalMoneyCertBalance,
      MoneyCertReported: +this.editMoneyAmt,
      CashReported: this.cashForward != null ? +this.cashForward : this.cashForward,
    }
    this._shiftService.SaveCloseShiftDetails(saveShiftObj)
      .subscribe((res) => {
        this.isPreview = true;
        this.isnotPreview = false;
        this.EndShiftBalanceId = res;
        this.SaveEndShiftReport();
      });
  }
  checkCashBalance(e) {
    debugger;
    this.CashForwardBalance = this.currentShiftTotals
    if (this.currentShiftTotals == parseFloat(e)) {
      this.messagetext = 'In Balance';
      this.isOutOfBalance = false;
      this.cashForward = e;
      this.CashForwardBalance = Number(this.currentShiftTotals) - Number(this.cashDeposit);
      this.cashinDrwer = this.cashForward;
    }
    else {
      this.messagetext = 'Out of Balance';
      this.isOutOfBalance = true;
      this.cashForward = e;
      this.cashinDrwer = this.CashForwardBalance;
    }
  }

  checkRoomChargeBalance(e) {
    debugger;
    if (this.currentShiftTenderBalance.totalRoomChargeBalance == parseFloat(e)) {
      this.messagetext = 'In Balance';
    }
    else {
      this.messagetext = 'Out of Balance';
    }
  }

  checkMoneyCertBalance(e) {
    if (this.currentShiftTenderBalance.totalMoneyCertBalance == parseFloat(e)) {
      this.messagetext = 'In Balance';
    }
    else {
      this.messagetext = 'Out of Balance';
    }
  }

  valOpeningCount(event) {
    debugger;
    this.openingCount = StringHelper.CashAmountInputValidator(event.target.value);

  }
  valRoomAmt(event) {
    debugger;
    this.editRoomAmt = StringHelper.CashAmountInputValidator(event.target.value);
  }
  valMoneyAmt(event) {
    debugger;
    this.editMoneyAmt = StringHelper.CashAmountInputValidator(event.target.value);
  }

  checkForward(e, type?) {
    debugger;
    if (type == "cashParBalance") {
      if (this.siteConfi.cashParBalanceAmount >= this.currentShiftTotals) {
        this.CashForwarddata = Number(this.currentShiftTotals);
        this.CashForwardBalance = this.CashForwarddata
      }
      else {
        this.CashForwarddata = Number(this.currentShiftTotals) - Number(this.siteConfi.cashParBalanceAmount);
        this.cashDeposit = this.CashForwarddata.toString();
        this.CashForwardBalance = this.siteConfi.cashParBalance;
      }
    }
    else if (type == '') {
      this.cashDeposit = StringHelper.CashAmountInputValidator(e.target.value);
      this.CashForwardBalance = Number(this.currentShiftTotals) - Number(this.cashDeposit);
      this.cashinDrwer = this.CashForwardBalance;
    }
    else if (type == 'depositAll') {
      this.CashForwardBalance = Number(this.currentShiftTotals);
      this.cashDeposit = this.CashForwardBalance;
      this.CashForwardBalance = 0;
    }
    else if (type == 'DisabledperpetualCash') {
      this.CashForwardBalance = Number(this.currentShiftTotals);
      this.CashForwardBalance;
    }
  }

  onChange(value, item) {
    debugger;
    if (value !== '') {
      this.TotalCash = (((+this.hunders) * 100) + ((+this.fifities) * 50) + ((+this.twnties) * 20) + ((+this.tens) * 10) + ((+this.fives) * 5) + ((+this.once) * 1))
      let result = (this.changeTotal / 100);
      this.TotalCash = (this.TotalCash + result).toFixed(2);
      this.openingCount = this.TotalCash;

    } else {
      this.TotalCash = (((+this.hunders) * 100) + ((+this.fifities) * 50) + ((+this.twnties) * 20) + ((+this.tens) * 10) + ((+this.fives) * 5) + ((+this.once) * 1))
      this.openingCount = this.TotalCash.toFixed(2);
    }
  }


  GoBackToPrevoius() {

    debugger;
    this.isPreview = false;
    this.isnotPreview = true;
    localStorage.setItem("isdisabledNavBar", 'false');
    if (this.siteConfi.primaryTxType === 1) {
      this.router.navigate(['../ProductCart'], { relativeTo: this.route });
    }
    else if (this.siteConfi.primaryTxType === 2) {
      this.router.navigate(['../PregiftingCardProduct'], { relativeTo: this.route });
    }
    else if (this.siteConfi.primaryTxType === 3) {
      this.router.navigate(['../GiftingCardProduct'], { relativeTo: this.route });
    }
    else if (this.siteConfi.primaryTxType === 4) {
      this.router.navigate(['../OpenGiftingCardProduct'], { relativeTo: this.route });
    }

  }

  SaveOutageData() {
    debugger;
    const ts = this;
    this.viewData.forEach(function (value) {
      var outageList = {
        WorkstationID: ts.workstation.workStationId,
        ShiftNum: ts.workstation.workStationId + "-" + (parseInt(ts.workstation.lastShiftNum) + 1),
        ProductID: value.productId,
        ReportedQty: value.checkQTY,
        SystemQty: value.totalProdQTY,
        ActionName: 'ShiftClose'
      }
      ts.inventoryOutage.push(outageList);
    });

    this.invService.SaveInventoryOutageData(this.inventoryOutage)
      .subscribe((result) => {

      });
  }

  SaveCloseShiftReport() {
    debugger;
    let setthis = this;
    
    if (setthis.currentShiftTotals >= 0) {
      this.confirmWidth = '40vw'
      // this.confirmationService.confirm({
      //   message: "<p class='worstation-img'><img src='./assets/Libs/images/confirmation.png'></p> <p class='assignstyle'>Are you sure to continue?</p>",
      //   header: 'Confirmation',
      //   rejectVisible: true,
      //   acceptLabel: 'Yes',
      //   icon: 'pi pi-exclamation-triangle',
      //   accept: () => {
      //     $('.ui-dialog-mask-scrollblocker').css('display', 'none');
      //     // if (this.isPendingView) {
      //     //   this.router.navigate(['../ViewTransfer'], { relativeTo: this.route });
      //     //   this.toastr.error("You have pending transfers that must be received or canceled before proceeding to End of Day.");
      //     // }
      //     //else {
      //     this.SaveOutageData();
      //     this.SaveCloseShift();
      //     //}
      //   },
      //   reject: () => {

      //   }
      // });
      this.SaveOutageData();
      this.SaveCloseShift();
    }
    else {
      this.toastr.error("CID total is negative,can not close the shift.")
    }
  }
  SaveEndShiftReport() {
    let setthis = this;
    let shiftReport = {
      Wsid: this.workstation.workStationId,
      WsName: this.workstation.workStationName,
      UserId: this.currentUser.userId,
      Total: parseFloat(this.currentShiftTotals === '' ? 0 : this.currentShiftTotals),
      CurrentShiftNum: this.workstation.workStationId + "-" + (parseInt(this.workstation.lastShiftNum) + 1),
      IvnOutage: setthis.inventoryOutage
    }

    this._shiftService.SaveCloseShiftReport(shiftReport)
      .subscribe((res) => {
        debugger;
        this.printJobId = res;
        // if (this.printJobId != 0) {
        //   this.PrintReport();
        // }
        // else {
        //   Swal.fire({
        //     icon: 'success',
        //     title: 'Success!',
        //     text: 'You don’t balance End of Shift, So it doesn’t print!',
        //   })
        //     .then(() => {
        //       this.router.navigate(['/LoginComponent'], { relativeTo: this.route });
        //       localStorage.setItem('ShiftStartFlag', JSON.stringify(false))
        //       localStorage.setItem('OutSessionShift', JSON.stringify(false));
        //     });
        // }

        localStorage.setItem('ShiftStartFlag', JSON.stringify(false))
        localStorage.setItem('OutSessionShift', JSON.stringify(false));
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Shift closed successfully.'
        });
        this.router.navigate(['/LoginComponent'], { relativeTo: this.route });
        
      });
  }

  // PrintReport() {
  //   debugger;
  //   let midResponse = this._middlewareService.ShiftEndReport(this.printJobId, this.EndShiftBalanceId || 0, 'ShiftEndReport');
  //   midResponse = PortExeMsgHelper.GetPortStatusMessage(midResponse);
  //   setTimeout(() => {
  //     if (midResponse.includes('success')) {
  //       this.openingCount = '';
  //       Swal.fire({
  //         icon: 'success',
  //         title: 'Success!',
  //         text: midResponse,
  //       })
  //         .then(() => {
  //           this.router.navigate(['/LoginComponent'], { relativeTo: this.route });
  //           localStorage.setItem('ShiftStartFlag', JSON.stringify(false))
  //           localStorage.setItem('OutSessionShift', JSON.stringify(false));
  //         });
  //     }
  //     else if (midResponse.includes('not connected')) {
  //       Swal.fire({
  //         icon: 'warning',
  //         title: 'Warning!',
  //         text: midResponse,
  //       });
  //       this.router.navigate(['/LoginComponent'], { relativeTo: this.route });
  //       localStorage.setItem('ShiftStartFlag', JSON.stringify(false))
  //       localStorage.setItem('OutSessionShift', JSON.stringify(false));
  //     }
  //     else {
  //       Swal.fire({
  //         icon: 'error',
  //         title: 'Error!',
  //         text: 'Error while Printing!',
  //       });
  //       localStorage.setItem('ShiftStartFlag', JSON.stringify(false))
  //       localStorage.setItem('OutSessionShift', JSON.stringify(false));
  //       this.router.navigate(['/LoginComponent'], { relativeTo: this.route });
  //     }
  //   }, 500);
  // }


  GetNewTransferList() {
    this.invService.GetViewTransfer(this.workstation.workStationId)
      .subscribe((viewInv) => {
        console.log('viewInv', viewInv);
        const pendingView = viewInv.filter(x => x.status == 'PENDING');
        if (pendingView.length > 0) {

          this.isPendingView = true;

        } else {
          this.isPendingView = false;
        }
      });
  }


  CheckPrinter()
  {
    this._shiftService.GetPrinterStatus(this.workstation.workStationId).subscribe((res) => {
      debugger;
      if(res!=3 && res!=0)
      {
      let printerMessage=  this.authenticationService.GetPrinterMessgae(res);
        Swal.fire({
          icon: 'warning',
          title: 'warning!',
          text: printerMessage,
          timer: 10000, // 10 seconds
          timerProgressBar: true,
        })
      }
    });
  }


  CheckInvetoryBalanced(){
    if(this.viewData.filter(x => x.checkQTY !== x.totalProdQTY).length !== 0){
      this.isInvetoryBalance=false;
    }
    else{
      this.isInvetoryBalance=true;
    }
  }

  ShowCashAndProductCofirmation() {
    debugger
    this.message = "<p class='worstation-img'><img src='./assets/Libs/images/confirmation.png'></p> <p class='assignstyle cstm-strt-style text-justify'> When balancing inventory and cash, always count and/or verify the entry you are making. Always enter the exact number or amount counted or checked.<span>Never force balance any balancing screen.</span>​All entries made by you are logged and reviewed by the accounting department. Falsification of company records can result in disciplinary action up to and including suspension pending separation of employment.</p>"
    this.confirmationService.confirm({
      message: this.message,
      header: 'Confirmation',
      rejectVisible: false,
      acceptLabel: 'OK',
      acceptButtonStyleClass: 'acceptBtn',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        $('.ui-dialog-mask-scrollblocker').css('display', 'none');
      }
    });
  }
}
