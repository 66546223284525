<div class="right-content">
    <div class="heading-panel text-left">

        <h1><img src="./assets/Libs/images/Cashmanagement.png" height="16">Cash</h1>
        <!-- <h2>Home</h2> -->
    </div>

    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12 text-center">
                <h1 class="body-heading">Adjust Cash Balance</h1>
            </div>
        </div>
        <!-- Calculator Screen -->
        <!-- <div class="cash-screen">
            <div class="text-center">
                <div class="container-fluid">
                    <div class="d-flex justify-content-center">
                        <div class="opening-count-block cash-calculator">
                            <div class="right-panel">
                                <button class="img-btn cashcalcbtn"><img src="./assets/Libs/images/Calculator.png" alt="Calculator" title="Calculator"></button>
                                <button class="img-btn"><img src="./assets/Libs/images/cash-register.png" alt="Register" title="Open Cash Drawer"></button>

                            </div>
                            <div class="count-input form-group">
                                <input type="text" [(ngModel)]="openingCount" value="0.0"
                                    (keyup)="valOpeningCount($event)"
                                    class="form-control">
                                <img src="./assets/Libs/images/dollar.png" alt="dollar">
                            </div>
                            <div class="count-buttons-block mt-4">
                                <button class="btn count-button" (click)="PopulateAmount(1)">1</button>
                                <button class="btn count-button" (click)="PopulateAmount(2)">2</button>
                                <button class="btn count-button mr-0" (click)="PopulateAmount(3)">3</button>
                                <button class="btn count-button" (click)="PopulateAmount(4)">4</button>
                                <button class="btn count-button" (click)="PopulateAmount(5)">5</button>
                                <button class="btn count-button mr-0" (click)="PopulateAmount(6)">6</button>
                                <button class="btn count-button" (click)="PopulateAmount(7)">7</button>
                                <button class="btn count-button" (click)="PopulateAmount(8)">8</button>
                                <button class="btn count-button mr-0" (click)="PopulateAmount(9)">9</button>
                                <button class="btn count-button" (click)="PopulateAmount(0)">0</button>
                                <button class="btn count-button" (click)="PopulateAmount('.')">.</button>
                                <button class="btn count-button mr-0" (click)="PopulateAmount('x')"><i
                                        class="fa fa-arrow-circle-o-left"></i></button>
                            </div>
                        </div>
                        <div class="pin-tab">
                            <form>
                                <div class="tab-form">
                                    <div class="form-group row">
                                        <label for="input" class="col-form-label">$100's</label>
                                        <div class="col-sm-7">
                                            <input type="number" class="form-control" id="inputtext" min="0"
                                                name="hunders" [(ngModel)]="hunders"
                                                (input)="onChange($event,'100')" placeholder="0">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="input" class="col-form-label">$50's :</label>
                                        <div class="col-sm-7">
                                            <input type="number" class="form-control" id="inputtext" min="0"
                                                name="fifities" [(ngModel)]="fifities"
                                                (input)="onChange($event,'50')" placeholder="0">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="input" class="col-form-label">$20's :</label>
                                        <div class="col-sm-7">
                                            <input type="number" class="form-control" id="inputtext" min="0"
                                                name="twnties" [(ngModel)]="twnties" (input)="onChange($event,'20')"
                                                placeholder="0">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="input" class="col-form-label">$10's :</label>
                                        <div class="col-sm-7">
                                            <input type="number" class="form-control" id="inputtext" min="0"
                                                name="tens" [(ngModel)]="tens" (input)="onChange($event,'10')"
                                                placeholder="0">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="input" class="col-form-label">$5's :</label>
                                        <div class="col-sm-7">
                                            <input type="number" class="form-control" id="inputtext" min="0"
                                                name="fives" [(ngModel)]="fives" (input)="onChange($event,'5')"
                                                placeholder="0">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="input" class="col-form-label">$1's :</label>
                                        <div class="col-sm-7">
                                            <input type="number" class="form-control" id="inputtext" min="0"
                                                name="once" [(ngModel)]="once" (input)="onChange($event,'1')"
                                                placeholder="0">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="input" class="col-form-label">Change Total :</label>
                                        <div class="col-sm-7">
                                            <input type="text" class="form-control" id="inputtext"
                                                name="changeTotal" [(ngModel)]="changeTotal" maxlength="2"
                                                (input)="onChange($event,'0')" placeholder="0">
                                        </div>
                                    </div>
                                </div>
                                <div class="total-cash">
                                    <div class="form-group row mb-0">
                                        <label for="input" class="col-form-label">Total :</label>
                                        <div class="col-sm-7">
                                            <input type="text" class="form-control" id="inputtext" name="TotalCash"
                                                [(ngModel)]="TotalCash" placeholder="0.00">
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
       
        <div class="cash-screen">
            <div class="text-center">
                <div class="container-fluid">
                    <div class="d-flex justify-content-center">
                        <div class="opening-count-block cash-calculator">
                            <div class="right-panel">
                                <button class="img-btn cashcalcbtn"><img src="./assets/Libs/images/Calculator.png"
                                        alt="Calculator" title="Calculator"></button>
                                <button class="img-btn"><img src="./assets/Libs/images/cash-register.png" alt="Register"
                                        title="Open Cash Drawer"></button>

                            </div>
                            <div class="count-input form-group">
                                <input type="text" [(ngModel)]="openingCount" value="0.0"
                                    (keyup)="valOpeningCount($event)" class="form-control">
                                <img src="./assets/Libs/images/dollar.png" alt="dollar">
                            </div>
                            <div class="count-buttons-block mt-4">
                                <button class="btn count-button" (click)="PopulateAmount(1)">1</button>
                                <button class="btn count-button" (click)="PopulateAmount(2)">2</button>
                                <button class="btn count-button mr-0" (click)="PopulateAmount(3)">3</button>
                                <button class="btn count-button" (click)="PopulateAmount(4)">4</button>
                                <button class="btn count-button" (click)="PopulateAmount(5)">5</button>
                                <button class="btn count-button mr-0" (click)="PopulateAmount(6)">6</button>
                                <button class="btn count-button" (click)="PopulateAmount(7)">7</button>
                                <button class="btn count-button" (click)="PopulateAmount(8)">8</button>
                                <button class="btn count-button mr-0" (click)="PopulateAmount(9)">9</button>
                                <button class="btn count-button" (click)="PopulateAmount(0)">0</button>
                                <button class="btn count-button" (click)="PopulateAmount('.')">.</button>
                                <button class="btn count-button mr-0" (click)="PopulateAmount('x')"><i
                                        class="fa fa-arrow-circle-o-left"></i></button>
                            </div>

                            <div class="pin-tab">
                                <form>
                                    <div class="tab-form">
                                        <div class="form-group row">
                                            <label for="input" class="col-form-label">$100's</label>
                                            <div class="col-sm-7">
                                                <input type="number" class="form-control" id="inputtext" min="0"
                                                    name="hunders" [(ngModel)]="hunders"
                                                    (input)="onChange($event,'100')" placeholder="0">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="input" class="col-form-label">$50's :</label>
                                            <div class="col-sm-7">
                                                <input type="number" class="form-control" id="inputtext" min="0"
                                                    name="fifities" [(ngModel)]="fifities"
                                                    (input)="onChange($event,'50')" placeholder="0">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="input" class="col-form-label">$20's :</label>
                                            <div class="col-sm-7">
                                                <input type="number" class="form-control" id="inputtext" min="0"
                                                    name="twnties" [(ngModel)]="twnties" (input)="onChange($event,'20')"
                                                    placeholder="0">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="input" class="col-form-label">$10's :</label>
                                            <div class="col-sm-7">
                                                <input type="number" class="form-control" id="inputtext" min="0"
                                                    name="tens" [(ngModel)]="tens" (input)="onChange($event,'10')"
                                                    placeholder="0">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="input" class="col-form-label">$5's :</label>
                                            <div class="col-sm-7">
                                                <input type="number" class="form-control" id="inputtext" min="0"
                                                    name="fives" [(ngModel)]="fives" (input)="onChange($event,'5')"
                                                    placeholder="0">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="input" class="col-form-label">$1's :</label>
                                            <div class="col-sm-7">
                                                <input type="number" class="form-control" id="inputtext" min="0"
                                                    name="once" [(ngModel)]="once" (input)="onChange($event,'1')"
                                                    placeholder="0">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="input" class="col-form-label">Change Total :</label>
                                            <div class="col-sm-7">
                                                <input type="text" class="form-control" id="inputtext"
                                                    name="changeTotal" [(ngModel)]="changeTotal" maxlength="2"
                                                    (input)="onChange($event,'0')" placeholder="0">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="total-cash">
                                        <div class="form-group row mb-0">
                                            <label for="input" class="col-form-label">Total :</label>
                                            <div class="col-sm-7">
                                                <input type="text" class="form-control" id="inputtext" name="TotalCash"
                                                    [(ngModel)]="TotalCash" placeholder="0.00">
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
       
       
        <div class="action-buttons d-flex justify-content-center align-items-center my-4">
            <button id="btnadd" type="button" class="button button-secondary"
                (click)="CheckCurrentShiftBalance('cashIn')">Add</button>
                <button  id="btnRemove" type="button" class="button button-secondary"
                (click)="CheckCurrentShiftBalance('cashOut')">Remove</button>
        </div>
        <!-- Calculator  Screen End-->
    </div>
</div>
<!-- ------------------------------------------------------------------------------------------------------------------------------- -->
     <!-- <div [style.display]="LoginModal? 'block' : 'none'" class="modal name-popup" id="imgmodal" tabindex="-1"
        role="dialog" aria-labelledby="myMLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content signinClass">
                <div class="modal-header SignInHeader">
                    <button type="button" class="close"data-dismiss="modal" (click)="hide('SignInModal')">×</button>
                </div>
                <div class="row col-md-12" >
                    <div class="col-md-4 ">
                        <img class="SignInImageIcon" src="../../../../assets/Libs/images/logo-icon.png">
                    </div>
                    <div class="modal-body col-md-8" >
                        <h1 class="modal-title text-heading-color" id="myLabel">SIGN IN</h1>
                        <form [formGroup]="LoginAdjuctGroup"  (ngSubmit)="AdjustLogin()">
                            <div class="form-group">

                                <input type="text" placeholder="Username" formControlName="UserNameLogin"
                                    class="form-control" [ngClass]="{ 'is-invalid':issubmitted && Login.UserNameLogin.errors }" />
                                    <div *ngIf="issubmitted && Login.UserNameLogin.errors" class="invalid-feedback">
                                        <div *ngIf="Login.UserNameLogin.errors.required">UserName is required</div>
                                    </div>
                            </div>
                            <div class="form-group">
                                <input type="password" placeholder="Password" formControlName="PasswordLogin"
                                    class="form-control" [ngClass]="{ 'is-invalid':issubmitted && Login.PasswordLogin.errors }" />
                                    <div *ngIf="issubmitted && Login.PasswordLogin.errors" class="invalid-feedback">
                                        <div *ngIf="Login.PasswordLogin.errors.required">Password is required</div>
                                    </div>
                            </div>
                            <button type="submit" id='btnSubmit'  class="btn btn-light  form-control"
                                >LOGIN</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <div [style.display]="LoginModal? 'block' : 'none'" class="modal name-popup" id="imgmodal" tabindex="-1"
    role="dialog" aria-labelledby="myMLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-cstmSign">
        <div class="modal-content signinClass">
            <div class="modal-header SignInHeader">
                <h4 class="modal-title" id="myLabel">Authorization Required</h4>
            </div>
            <div class="modal-body text-right">      
                <form [formGroup]="LoginAdjuctGroup" (ngSubmit)="AdjustLogin()">
                    <div class="form-group">

                        <input type="text" placeholder="Username" formControlName="UserNameLogin" autocomplete="off"
                            class="form-control"
                            [ngClass]="{ 'is-invalid':issubmitted && Login.UserNameLogin.errors }" />
                        <div *ngIf="issubmitted && Login.UserNameLogin.errors" class="invalid-feedback">
                            <div *ngIf="Login.UserNameLogin.errors.required">UserName is required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <input type="password" placeholder="Password" formControlName="PasswordLogin" autocomplete="off"
                            class="form-control"
                            [ngClass]="{ 'is-invalid':issubmitted && Login.PasswordLogin.errors }" />
                        <div *ngIf="issubmitted && Login.PasswordLogin.errors" class="invalid-feedback">
                            <div *ngIf="Login.PasswordLogin.errors.required">Password is required</div>
                        </div>
                    </div>
                    <div class="signX-buttons d-flex justify-content-between align-items-center">
                        <button type="button" class="close btnsignX" data-dismiss="modal"
                            (click)="hide('SignInModal')">Cancel</button>
                        <button type="submit" id='btnSubmit' class="btn btnsignN  form-control">Sign In</button>
                    </div>
                </form>
            </div>
        </div>
    
    </div>
</div>


<!-- --------------------------------------------------------------------------------------------------------------------------------- -->

<p-confirmDialog acceptLabel="Continue" appendTo="body">
    <ng-template pTemplate="body">
        <span class="ui-confirmdialog-message"></span>
    </ng-template>
</p-confirmDialog>