<div class="right-content">
    <div class="heading-panel text-left">

        <h1><img src="./assets/Libs/images/Cashmanagement.png" height="16"> Cash</h1>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12 text-center">
                <h1 class="body-heading">Remove Cash</h1>
            </div>
        </div>
    </div>
    <div class="cash-screen mt-4">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12 text-center">
                    <div class="container-fluid">
                        <div class="d-flex justify-content-center">
                            <div class="opening-count-block cash-calculator">
                                <div class="count-input form-group">
                                    <input type="text" [(ngModel)]="openingCount" value="0.0" 
                                        (keyup)="valOpeningCount($event)"
                                        class="form-control">
                                    <img src="./assets/Libs/images/dollar.png" alt="dollar">
                                </div>
                                <div class="count-buttons-block mt-4">
                                    <button class="btn count-button" (click)="PopulateAmount(1)">1</button>
                                    <button class="btn count-button" (click)="PopulateAmount(2)">2</button>
                                    <button class="btn count-button mr-0" (click)="PopulateAmount(3)">3</button>
                                    <button class="btn count-button" (click)="PopulateAmount(4)">4</button>
                                    <button class="btn count-button" (click)="PopulateAmount(5)">5</button>
                                    <button class="btn count-button mr-0" (click)="PopulateAmount(6)">6</button>
                                    <button class="btn count-button" (click)="PopulateAmount(7)">7</button>
                                    <button class="btn count-button" (click)="PopulateAmount(8)">8</button>
                                    <button class="btn count-button mr-0" (click)="PopulateAmount(9)">9</button>
                                    <button class="btn count-button" (click)="PopulateAmount(0)">0</button>
                                    <button class="btn count-button" (click)="PopulateAmount('.')">.</button>
                                    <button class="btn count-button mr-0" (click)="PopulateAmount('x')"><i
                                            class="fa fa-times"></i></button>
                                </div>
                            </div>
                        </div>
                        <div class="action-buttons my-4 text-center">
                            <button type="button" class="button button-secondary"
                                (click)="CheckCurrentShiftBalance('cashOut')">Continue</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<p-confirmDialog acceptLabel="Continue" appendTo="body">
    <ng-template pTemplate="body">
        <span class="ui-confirmdialog-message"></span>
    </ng-template>
</p-confirmDialog>
