import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { AuthService } from 'src/app/Services/auth.service';
import { IPOSService } from 'src/app/Services/i-pos.service';
import { InventoryService } from 'src/app/Services/inventory.service';
//import { MiddleWare } from 'src/app/Services/middleware.service';
import { PortExeMsgHelper } from 'src/app/shared/helpers/port-exe-msg.helper';
import { ShiftService } from 'src/app/Services/shift.service';

import Swal from 'sweetalert2';
import { VendListByProducts } from '../add-inventorygrid.model';

@Component({
  selector: 'app-view-on-hand',
  templateUrl: './view-on-hand.component.html',
  styleUrls: ['./view-on-hand.component.css']
})
export class ViewOnHandComponent implements OnInit {
  workstationList: any = [];
  vendorList: any = []; 
  currentUser: any;
  viewData: any = [];
  viewTransferData: any = [];
  transferId: any;
  selectedVendor = 0;
  printJobId = 0;
  SearchOnHandVal = '';
  workstation = JSON.parse(localStorage.getItem('workstation'));
  constructor(private route: ActivatedRoute,
    private authenticationService: AuthService,
   // private _middlewareService: MiddleWare,
    private posSerive: IPOSService,
    private invService: InventoryService, private toastr: ToastrService,
    private _shiftService: ShiftService) { }

  ngOnInit(): void {
    debugger;
    // Getting Current Shift Details
    this.currentUser = this.authenticationService.currentUserValue;

    //Get Inventory Workstation
    this.posSerive.GetWorkstations(this.currentUser.districtNum,this.workstation.workStationId)
      .subscribe((res) => {

        this.workstationList = res.filter(x => x.workStationName !== 'Corporate Virtual Safe');
        console.log(res);
      });
    this.invService.GetInventoryCount(this.workstation.workStationId,false,this.currentUser.districtNum)
      .subscribe((viewInvetory) => {
        let listdata=[]
        this.viewData = this.viewTransferData = viewInvetory;
        this.vendorList.length=0;
        for(let item of this.viewData){
          listdata.push(item.catagoryName)
        }
        this.vendorList=listdata.filter((value,index)=>{
          return listdata.indexOf(value)===index;
        })

        console.log(viewInvetory);
      });

      this.CheckPrinter();
      this.CheckReceiptsType();
      $(".navigation ul li a").removeClass("active");
      $(".envSec").addClass("active");
      $(".salesSec, .transaSec, .cashSec").removeClass("active");
  }

  getOnHandCount(workststionId) {
    debugger;
    let listData=[]
    this.invService.GetInventoryCount(workststionId,false,this.currentUser.districtNum)
      .subscribe((viewInvetory) => {
        this.viewData = this.viewTransferData = viewInvetory;
        this.vendorList.length=0;
        for(let item of this.viewData){      
          listData.push(item.catagoryName)       
        }
        this.vendorList=listData.filter((value,index)=>{
          return listData.indexOf(value)===index;
        })

        console.log(viewInvetory);
      });
    this.selectedVendor = 0;
  }

  getOnBasedOnVendor(vendorName) {
    debugger;
    if(vendorName=='0'){
      this.viewData = this.viewTransferData;
    }
    else{
      this.viewData = this.viewTransferData.filter(x => x.catagoryName === vendorName);
    }
    
  }

  SearchOnHand(searchValue) {
    debugger;
    this.viewData = this.viewTransferData.filter(x => {
      return x.catagoryName.toLowerCase().includes(searchValue.target.value.toLowerCase()) || x.productName.toLowerCase().includes(searchValue.target.value.toLowerCase()) || x.pluCode.toLowerCase().includes(searchValue.target.value.toLowerCase()) || (x.totalProdQTY !== null ? x.totalProdQTY.toString().includes(searchValue.target.value) : '');
    });
  }

  PrintOnHand() {
    this.invService.PrintOnHandReceipt(this.workstation.workStationId, this.currentUser.clerkName)
      .subscribe((res) => {
        this.printJobId = res.printJobId;
       // this.PrintReport();
      // this.toastr.success("On Hand Receipt Printed Successfully!");
      });
  }

  // PrintReport() {
  //   let midResponse = this._middlewareService.ShiftOpenReport(this.printJobId, 'Inventory');
  //   midResponse = PortExeMsgHelper.GetPortStatusMessage(midResponse);
  //   if (midResponse.includes('success')) {
  //     this.toastr.success("On Hand Receipt Printed Successfully!")
  //   } else if (midResponse.includes('not Connected')) {
  //     Swal.fire({
  //       icon: 'warning',
  //       title: 'Warning!',
  //       text: midResponse,
  //     });
  //   }
  //   else {
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'Error!',
  //       text: 'Error while Printing!',
  //     });
  //   }
  // }


  CheckPrinter()
  {
    this._shiftService.GetPrinterStatus(this.workstation.workStationId).subscribe((res) => {
      debugger;
      if(res!=3 && res!=0)
      {
      let printerMessage=  this.authenticationService.GetPrinterMessgae(res);
        Swal.fire({
          icon: 'warning',
          title: 'warning!',
          text: printerMessage,
          timer: 10000, // 10 seconds
          timerProgressBar: true,
        })
      }
    });
  }

  CheckReceiptsType()
  {
    debugger;
    this._shiftService.GetReceiptsType(this.workstation.workStationId).subscribe((res) => {
      debugger;
      if(res!= true)
      {
        $('#btn_PrintOnHand').prop('disabled', true);  
      }
    });
  }

}
