import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IPOSService } from 'src/app/Services/i-pos.service';
import { AuthService } from 'src/app/Services/auth.service';
import { ConfirmationService } from 'primeng/api';
import { Message } from 'primeng/api';
import Swal from 'sweetalert2';
import { CommonService } from 'src/app/Services/common.service';

@Component({
  selector: 'app-workstations',
  templateUrl: './workstations.component.html',
  styleUrls: ['./workstations.component.css']
})
export class WorkstationsComponent implements OnInit {
  position: string;
  workstationList: any = [];
  currentUser: any;
  message: any;
  siteConfival: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private posSerive: IPOSService,
    private authenticationService: AuthService,
    private confirmationService: ConfirmationService,
    private _commonService: CommonService,
  ) { }

  ngOnInit() {
    debugger;
    this.currentUser = this.authenticationService.currentUserValue;
    this.posSerive.GetWorkstations(this.currentUser.districtNum,'0')
      .subscribe((res) => {
        this.workstationList = res.filter(x => x.workStationName !== 'Corporate Virtual Safe');
        console.log(res);
      });
  }
  OpenHome(workstationObj) {
    debugger;
    let checkStatus;
    let workstid=JSON.stringify(workstationObj.workStationId);
    workstid=workstid.replace(/"/g, "");
    this.posSerive.GetWorkstationsOnID(workstid)
    .subscribe((res) => {
      debugger;
     const workstationDetails= res;
     checkStatus = workstationDetails.isInUse;
    if(checkStatus)
    {
    Swal.fire({
      icon: 'error',
      title: 'Warning!',
      text: 'This workstation is already in use!',
    });
    this.posSerive.GetWorkstations(this.currentUser.districtNum,'0')
          .subscribe((res) => {
            this.workstationList = res.filter(x => x.workStationName !== 'Corporate Virtual Safe');
          });
    }
    else
    {
      debugger;
      this._commonService.GetSiteConfigrationValidated(workstationObj.siteId,workstationObj.districtId)
      .subscribe((res) => {
        this.siteConfival=res
        localStorage.setItem('siteConfivalidated',JSON.stringify(res));
      });
      localStorage.setItem('workstation', JSON.stringify(workstationObj));

      this.message = "<p class='worstation-img'><img src='./assets/Libs/images/confirmation.png'></p><p class='assignstyle'>Assign this workstation to <br/> <b class='workName'>" + workstationObj.workStationName + '?</b><br/>Use only the cash drawer associated with this register in order to keep your closing count and reports accurate.</p>';

      this.confirmationService.confirm({
        message: this.message,      
        acceptButtonStyleClass:'acceptBtn',
        rejectButtonStyleClass:'rejectBtn',
        accept: () => {
          setTimeout(() => {
            this.router.navigate(['/Home']);
        }, 250);     

        },
        reject: () => {

        }
      
      });
    }
     console.log(checkStatus);
    });

  }

}
