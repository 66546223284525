import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/Services/auth.service';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  SetPassword:FormGroup;
  submitted:boolean=false;
  error:any;
  eyeShow: boolean = false;
  passwordTextType: string = 'password';
  userId: string = 'password';
  clientId: string = 'password';


  constructor(private formBuilder: FormBuilder,private authenticationService: AuthService,private toastr: ToastrService,private router: Router,private route: ActivatedRoute, ) { }

  ngOnInit() {
    console.log('origin',location.origin);
debugger;
    this.SetPassword = new FormGroup({
      newpassword1: new FormControl('',Validators.required),
      newpassword2: new FormControl('',Validators.required)
    });

    let url = new URL(window.location.href);
     this.userId = url.searchParams.get("userId");
     this.clientId = url.searchParams.get("clientId");
  }



  SetNewPassword()
{
  debugger;
  this.submitted=true;
  this.error='';
  if (this.SetPassword.invalid) {
    return;
  }
let setpassword=this.f.newpassword1.value;
let confirmpassword=this.f.newpassword2.value;

if(setpassword!=confirmpassword)
{
  this.error='Password and Confirm password do not match.'
  return;
}

if(!this.PasswordValidation()){
  this.error='Password should be strong and includes at least 1 Number, 1 special character, 1 small and 1 capital letter.It should contains Min 8 Char & Max 14 Char.'
  return;
}
var clientIdDetails=localStorage.getItem("Client_Id");
if(this.clientId!=clientIdDetails){
  this.toastr.error('Something went wrong.Please try again later.');
  return;
}
  let GetGUIDchrowm = localStorage.getItem("MacAddress");
  let decodeGUID=atob(GetGUIDchrowm)
  let CId = localStorage.getItem("Client_Id");
 var userObj = {
  Password: this.f.newpassword1.value,
  ConfirmPassword: this.f.newpassword2.value,
  UserId:this.userId,
  ClientId:Number(this.clientId),
  }
  this.authenticationService.ResetPassword(userObj)
  .pipe(first())
  .subscribe(
    data => {
      
      this.toastr.success('Password has been changed successfully.Please again with the new password.');
      this.submitted=false;
      setInterval(() => {
      //  location.reload();
      this.router.navigate(['../Login'], { relativeTo: this.route });
      }, 2000);
      
    },
    error => {
      debugger;
      this.error = error.error.message;
    
    });
}


eyeShowHide() {
  this.eyeShow = !this.eyeShow;
  if (this.eyeShow === true) {
    this.passwordTextType = 'text';
  }
  else {
    this.passwordTextType = 'password';
  }
}

get f() { 
  return this.SetPassword.controls; }



  PasswordValidation() :boolean {
    debugger;
    var psw = this.f.newpassword1.value;
    if (psw.length >= 8 && psw.length <= 14) {
      var p = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).{3,}$/;
    if (p.test(psw)) {
      var sp = /^(?=.*?[#!@@$%^&*-]).{1,}$/;
    if (sp.test(psw)) {
      return true;
    }
    }
    }
    else{
      return false;
    }
   
};

}
