import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/Services/auth.service';
import { NavigationComponent } from '../navigation.component';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  siteAddress: any;
  address: string;
  constructor(private nav: NavigationComponent,) { }
  ngOnInit() {
    this.nav.isdisabledNav = false;
    localStorage.setItem("isdisabledNavBar", 'false');
    let workstation = JSON.parse(localStorage.workstation);
    this.address = workstation.address;
    this.siteAddress = 'Holiday Inn Club Vacations' + workstation.city + workstation.state + workstation.zipCode;
    
  };
}