import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { NavigationComponent } from './navigation.component';
import { HomeComponent } from './home/home.component';
import { ProductsCartComponent } from './products-cart/products-cart.component';
import { ViewSalesComponent } from './view-sales/view-sales.component';
import { RefundPageComponent } from './refund-page/refund-page.component';
import { ShiftOpeningComponent } from './shift-opening/shift-opening.component';
import { ShiftClosingComponent } from './shift-closing/shift-closing.component';
import { ExchangeComponent } from './exchange/exchange.component';
import { CashManagementComponent } from './cash-management/cash-management.component';
import { InventorymanagementComponent } from './inventorymanagement/inventorymanagement.component';
import { VewCashComponent } from './vew-cash/vew-cash.component';
import { AddCashComponent } from './add-cash/add-cash.component';
import { RemoveCashComponent } from './remove-cash/remove-cash.component';
import { ViewOnHandComponent } from './view-on-hand/view-on-hand.component';
import { ViewTransferComponent } from './view-transfer/view-transfer.component';
import { NewTransferComponent } from './new-transfer/new-transfer.component';
import { InventoryAdjustmentComponent } from './inventory-adjustment/inventory-adjustment.component';
import { GiftCardProductsComponent } from './gift-card-products/gift-card-products.component';
import { PregiftingCardProductsComponent } from './pregifting-card-products/pregifting-card-products.component';
import { OpengiftingCardProductsComponent } from './opengifting-card-products/opengifting-card-products.component';

debugger;
const routes: Routes = [
    {
    
        path: '',
        component: NavigationComponent,
        children: [
            { path: '', redirectTo: 'ShiftOpening' },
            { path: 'DashBoard', component: HomeComponent },
            { path: 'ProductCart', component: ProductsCartComponent },
            { path: 'SalesHistory', component: ViewSalesComponent },
            { path: 'Refund', component: RefundPageComponent },
            { path: 'ShiftOpening', component: ShiftOpeningComponent },
            { path: 'ShiftClosing', component: ShiftClosingComponent },
            { path: 'ExchangeProduct', component: ExchangeComponent },  
            { path: 'CashManagement', component: CashManagementComponent },
            { path: 'InventoryManagement', component: InventorymanagementComponent },
            { path: 'VewCash', component: VewCashComponent },
            { path: 'AddCash', component: AddCashComponent },
            { path: 'RemoveCash', component: RemoveCashComponent },
            { path: 'ViewOnHand', component: ViewOnHandComponent },
            { path: 'ViewTransfer', component: ViewTransferComponent },
            { path: 'NewTransfer', component: NewTransferComponent },
            { path: 'InventoryAdjust', component: InventoryAdjustmentComponent },
            { path: 'GiftingCardProduct', component: GiftCardProductsComponent },
            { path: 'PregiftingCardProduct', component: PregiftingCardProductsComponent },
            { path: 'OpenGiftingCardProduct', component: OpengiftingCardProductsComponent }
        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class NavigationRoutingModule { }


