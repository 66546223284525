import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NavigationComponent } from './navigation.component';
import { NavigationRoutingModule } from './navigation.routing.module';
import { HomeComponent } from './home/home.component';
import { ProductsCartComponent } from './products-cart/products-cart.component';
import { ReactiveFormsModule } from '@angular/forms';

import { CarouselModule } from 'primeng/carousel';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { MessagesModule } from 'primeng/messages';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

// import {TableModule} from 'primeng/table';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ViewSalesComponent } from './view-sales/view-sales.component';
import { RefundPageComponent } from './refund-page/refund-page.component';
import { ShiftOpeningComponent } from './shift-opening/shift-opening.component';
import { ShiftClosingComponent } from './shift-closing/shift-closing.component';
import { ExchangeComponent } from './exchange/exchange.component';
import { CashManagementComponent } from './cash-management/cash-management.component';
import { InventorymanagementComponent } from './inventorymanagement/inventorymanagement.component';
import { VewCashComponent } from './vew-cash/vew-cash.component';
import { AddCashComponent } from './add-cash/add-cash.component';
import { RemoveCashComponent } from './remove-cash/remove-cash.component';
import { ViewOnHandComponent } from './view-on-hand/view-on-hand.component';
import { ViewTransferComponent } from './view-transfer/view-transfer.component';
import { NewTransferComponent } from './new-transfer/new-transfer.component';
import { InventoryAdjustmentComponent } from './inventory-adjustment/inventory-adjustment.component';
import { MaterialModule } from '../../material.module';
import { GiftCardProductsComponent } from './gift-card-products/gift-card-products.component';
import { OpengiftingCardProductsComponent } from './opengifting-card-products/opengifting-card-products.component';
import { PregiftingCardProductsComponent } from './pregifting-card-products/pregifting-card-products.component';
 import { AutoFocusDirective } from './directives/autofocus.directive';
import { OnlynumberDirective } from './directives/onlynumber.directive';



@NgModule({
  imports: [
    CommonModule,
    NavigationRoutingModule,
    ReactiveFormsModule,
    // NgbModule,
    CarouselModule,
    FormsModule,
    DialogModule,
    ButtonModule,
    MessagesModule,
    ConfirmDialogModule,
    SelectButtonModule,
    MaterialModule,
    FormsModule,
  ],
  declarations: [
    NavigationComponent,
    HomeComponent,
    ProductsCartComponent,
    ViewSalesComponent,
    RefundPageComponent,
    ShiftOpeningComponent,
    ShiftClosingComponent,
    ExchangeComponent,
    CashManagementComponent,
    InventorymanagementComponent,
    VewCashComponent,
    AddCashComponent,
    RemoveCashComponent,
    ViewOnHandComponent,
    ViewTransferComponent,
    NewTransferComponent,
    InventoryAdjustmentComponent,
    GiftCardProductsComponent,
    PregiftingCardProductsComponent,
    OpengiftingCardProductsComponent,
    AutoFocusDirective,
    OnlynumberDirective
  ]
})
export class NavigationModule { }