import { Component, HostListener, OnInit } from '@angular/core';
import { IPOSService } from 'src/app/Services/i-pos.service';
import { DomSanitizer } from '@angular/platform-browser';
import Swal from 'sweetalert2';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ICustomer, CartValues } from 'src/app/Models/Shared/Product.Cart';
import { AuthService } from 'src/app/Services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/Services/data.service';
//import { MiddleWare } from 'src/app/Services/middleware.service';
import * as $ from 'jquery';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { NavigationComponent } from '../navigation.component';
import { ConfirmationService } from 'primeng';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import * as CustomEnums from './../../../shared/constants/custom-enum.constant';
import { PortExeMsgHelper } from 'src/app/shared/helpers/port-exe-msg.helper';
import { StringHelper } from 'src/app/shared/helpers/string.helper';
import { PrintJobModel } from 'src/app/Models/shift.model';
import { ShiftService } from 'src/app/Services/shift.service';
import { stringify } from 'querystring';
import { CommonService } from 'src/app/Services/common.service';
import { ProductMenuDetails } from 'src/app/Models/Shared/GiftCard.Product';
@Component({
  selector: 'app-products-cart',
  templateUrl: './products-cart.component.html',
  styleUrls: ['./products-cart.component.css']
})
export class ProductsCartComponent implements OnInit {
  NewProducts: ProductMenuDetails[];
  responsiveOptions: any[];
  QuickMenus: any = [];
  VendorMenu: any = [];
  TxTotal: any;
  productCategories: any = [];
  SelectedProducts: any = [];
  SelectProducts: any = [];
  SplitTransaction: any = [];
  customerName = "";
  showModal: boolean;
  ShowModalPopup: boolean;
  registerForm: FormGroup;
  submitted: boolean = false;
  confirmWidth: string = '40vw';
  user = {} as ICustomer;
  types: any = [];
  tenderTypeName: string = '';
  productSubCategories: any = [];
  ProductList: any = [];
  EmailAddress: any;
  //isBtnProductEnabled:boolean = true;
  isShowPSP = true;
  isSRPShow = true;
  isDiscountShow = true;
  isShowPrCount = false;
  isMoneyShow = false;
  isTxPrinting = false;
  isCashPrinting = false;
  isShowProdLink = false;
  isTransCancil = false;
  isTransCal = false;
  isCCPrinting = false;
  isTendorDisable = false;
  isBackDisable = false;
  isShowPreviousPaidAmountCash = false;
  isShowPreviousPaidAmountCreditCard = false;
  amountTendered: any = 0;
  change: any = 0;
  tourId = 0;
  selectedTender: any;
  selectedType: string;
  selectedTenderId: any;
  ExchangeTenderType: any;
  selectedCashTenderId: any;
  selectedCCTenderId: any;
  selectedRoomTenderId: any;
  tenderFlg: boolean = false;
  TxNum = 0;
  discountFlag: boolean = false;
  cartValues = {} as CartValues;
  currentUser: any;
  workstation: any;
  catagoryName: any;
  changeDue: number;
  subCatagoryName: any;
  isnotCashAndCredit = true;
  isCashAndCredit = false;
  cashAmount: any = 0;
  creditAmont: any = 0;
  currentProdQty = 0;
  TendorType: string = '';
  payTotal: any = 0;
  ProdQty = 0;
  isDesibleQM = false;
  CustId = 0;
  selecteditemForDiscount = 0;
  discountAmt: any = '';
  roomNumber: any = '';
  tourIdInput: any = '';
  tourAmt: string = '0.00';
  isSlpitTX: boolean;
  //txandPrintId: string;
  selectTenderFlag: boolean = false;
  exchangeAmout: any;
  txandPrintId: PrintJobModel
  isTourDepo: boolean;
  isshowDisModal = false;
  isTourModelShow = false;
  isTourIdModelShow:boolean = false;
  isshowRoomModal = false;
  CreditAmt: any = 0;
  CalculateTotalCCAmount: any = 0;
  CalculateTotalCashAmount: any = 0;
  CalculateTotalRoomAmount: any = 0;
  CalculateTotalMoneyCertAmount: any = 0;
  CalculateTotalMoneyCertAmounts: any = [];
  selectedCCAmount = 0;
  totalCardCalc = 0;
  isExhange = false;
  isShowCredit = false;
  isShowProdList = false;
  isQuickShow = true;
  isvendorShow = true;
  isPrinting = false;
  isPrintCopy = false;
  iscatShow = true;
  isProduct = false;
  productData;
  isSubCatShow = false;
  isFinish = true;
  isCustomer = true;
  isCustomerAdd = false;
  searchText: string;
  exchangeDetails: any = [];
  isProcessCancel = false;
  TenderList: any = [];
  TenderListForExchange: any = [];
  siteConfi: any;
  MySingInModal: boolean;
  LastTxNo: boolean = false;
  editCashAmount: string | number = 0;
  editCCAmount: string | number = 0;
  editRoomChargeAmount: string | number = 0;
  editRoomChargeNum: string = '';
  public MinDiscounts: number;
  public MaxDiscounts: number;
  OptionSelect: any;
  Trantype: number =1;
  UserAdvancSetting: any;
  sumitted: boolean;
  TenderId: any;
  TourDepositDetails: any;
  cashTender:any;
  roomChargeTender:any;
  creditcardTender:any;
  exchangeProductsDetails:any=[];
  ListOfMediaNum:any;
  LoginUserProduct = new FormGroup({
    UserNameLogin: new FormControl(''),
    PasswordLogin: new FormControl(''),
  });
  LoginAdjuctGroup: FormGroup;
  errormsg: any;
  constructor(
    private _posSerive: IPOSService,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private authenticationService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private _dataService: DataService,
    private _commonService: CommonService,
    private _dataSerive: DataService,
    private nav: NavigationComponent,
    private toastr: ToastrService,
    private _shiftService: ShiftService,
  ) {

  }

  ngOnInit() {
    debugger;
    $(".navigation ul li a").removeClass("active");
    $(".transaSec").addClass("active");
    $(".salesSec, .cashSec, .envSec").removeClass("active");

    // this.productTypeTicket = CustomEnums.ProductTypes.Ticket;
    // this.ticketTypeDigital = CustomEnums.TicketType.Digital;

    this.currentUser = this.authenticationService.currentUserValue;
    this.workstation = JSON.parse(localStorage.getItem('workstation'));
    this.SetDynamicValues();
    localStorage.setItem('OutSessionShift', JSON.stringify(true));
    this.UserAdvancSetting = JSON.parse(localStorage.getItem("Useradvancsetting"));

    this._dataSerive.getbehaviorListData().subscribe(res => this.exchangeDetails = res);
    this._dataService.getbehaviorObjData().subscribe(profile => this.OptionSelect = profile.OptionSelect);
    this._commonService.UpdateLastTXID(this.workstation.workStationId, this.workstation.lastTxId, this.submitted)
      .subscribe((res) => {
        this.TxNum = res + 1;
        this._dataService.setbehaviorObjData({ TxNum: this.TxNum });
        this._dataService.setbehaviorListData([]);
        this._dataService.getbehaviorObjData().subscribe(profile => this.TxNum = profile.TxNum);
      });


    if (this.SelectedProducts.length <= 0) {
      this.OpenModel('Cust');
    }

    if (this.OptionSelect == 'Exchange') {
      this.hide('Cust');
    


      this.exchangeDetails.forEach(currentRow => {
        currentRow.fastButtonId = 0;
        this.ListOfMediaNum+=","+currentRow.mediaNumber;
        
        const index = this.SelectedProducts.findIndex(pro => ((pro.plucode === undefined || pro.plucode === null) ? pro.productId === currentRow.productId : pro.plucode === currentRow.plucode));
        if (index < 0) {
          this.SelectedProducts.push(currentRow);
          currentRow.Total = currentRow.total;
          
        }
        else {
          this.SelectedProducts.forEach(latestRow => {
            if (latestRow.productId == currentRow.productId) {
              
            // latestRow.qty += latestRow.qty || latestRow.qty; commented by Humraaz
              latestRow.qty += currentRow.qty || currentRow.qty;
              latestRow.mediaNumber+=","+currentRow.mediaNumber;
              if (latestRow.srp < latestRow.total) {
                latestRow.Total = (latestRow.total * latestRow.qty) - (Math.abs(latestRow.discount) * latestRow.qty);

              }
              else {
                latestRow.Total = (latestRow.srp * latestRow.qty) - (Math.abs(latestRow.discount) * latestRow.qty);

              }
            }

          });

        }

      });
   
      if (this.SelectedProducts.length > 0) {
        this.customerName = this.SelectedProducts[0].customerName;
        this.CustId = this.SelectedProducts[0].custId;
        this.cartValues.TxTotal = 0;
        this.cartValues.BalanceDue = 0;
        this.cartValues.SRPTotal = 0;
        this.isShowCredit = true;
        this.LastTxNo = true;
        for (let i = 0; i < this.SelectedProducts.length; i++) {
          this.SelectedProducts[i].fastButtonName = this.SelectedProducts[i].productName;
          this.SelectedProducts[i].price = -this.SelectedProducts[i].total;
          this.SelectedProducts[i].srp = -this.SelectedProducts[i].srp;
          this.SelectedProducts[i].discount = -0;
          this.SelectedProducts[i].type = 'Exchange';
          this.SelectedProducts[i].qty = -this.SelectedProducts[i].qty;
          this.SelectedProducts[i].Total = -this.SelectedProducts[i].Total;

          this.SelectedProducts[i].minimumDiscount = -this.SelectedProducts[i].minimumDiscount;
          this.SelectedProducts[i].maximumDiscount = -this.SelectedProducts[i].maximumDiscount;
          //this.cartValues.TxTotal = this.cartValues.TxTotal + this.SelectedProducts[i].total;
          this.cartValues.BalanceDue = this.cartValues.BalanceDue + this.SelectedProducts[i].Total;
          //this.cartValues.SRPTotal = this.cartValues.SRPTotal + this.SelectedProducts[i].srp;
          this.creditAmont = this.creditAmont + this.SelectedProducts[i].Total
        }
        //this.Populate(this.SelectedProducts );

        this.payTotal = 0;
        //this.cartValues.SRPTotal = -Math.abs(this.cartValues.SRPTotal);
        this.cartValues.Discounts = 0;
        this.isExhange = true;
        //this.cartValues.TxTotal = -Math.abs(this.cartValues.TxTotal);
        //this.cartValues.BalanceDue = -Math.abs(this.cartValues.TxTotal);
        this.cartValues.BalanceDue = -Math.abs(this.creditAmont);
        this.payTotal = this.cartValues.TxTotal;
       
      }
    }
    else {
      this.cartValues = {
        BalanceDue: 0,
        Discounts: 0,
        SRPTotal: 0,
        TxTotal: 0
      };
      
    }




    // this.types = [
    //   { label: 'Cash       ', value: 'Cash' },
    //   { label: ' ' },
    //   { label: ' ' },
    //   { label: 'Credit Card', value: 'CreditCard' },
    //   { label: ' ' },
    //   { label: ' ' }
    // ];

    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
      }
    ];
    this.registerForm = this.formBuilder.group({
      FirstName: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      LastName: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),]]
    });
    this._posSerive.GetQuickMenus(this.workstation.districtId)
      .subscribe((res) => {
        this.QuickMenus = res;
      });
    this._posSerive.GetVendorMenus()
      .subscribe((res) => {
        this.VendorMenu = res;
      
      });
    this._posSerive.GetProductCategories().subscribe((res) => {
      this.productCategories = res;
 
    });
    this.nav.isdisabledNav = false;
    localStorage.setItem("isdisabledNavBar", 'false');

    this.LoginAdjuctGroup = this.formBuilder.group({
      UserNameLogin: ['', [Validators.required, /*Validators.email*/]],
      PasswordLogin: ['', [Validators.required,]]
    });


    // this._posSerive.GetTenderList(this.SelectedProducts[0].txid).subscribe((res) => {
    //   debugger;
    //   this.TenderListForExchange = res;
    // });
    this._posSerive.GetTenderList(this.SelectedProducts[0].txid).subscribe((res) => {
      this.TenderListForExchange = res;
      this.showCashCCByTenderType();
      //this.creditAmont = res[0].tenderAmount;
      let selectProdAmt = this.SelectedProducts.reduce((a, { total }) => a + total, 0);
      let tendorAmt = this.TenderListForExchange.reduce((a, { tenderAmount }) => a + tenderAmount, 0);
      if (this.TenderListForExchange[0].tenderType === 1) {
        this.TendorType = 'Cash';
      }
      else if (this.TenderListForExchange[0].tenderType === 2) {
        this.TendorType = 'CreditCard';
      }
      else if (this.TenderListForExchange[0].tenderType === 3) {
        this.TendorType = 'Room Charge';
      }
      else if (this.TenderListForExchange[0].tenderType === 4) {
        this.TendorType = 'Money Cert';
      }
      //this.TendorType = this.TenderListForExchange[0].tenderType === 1 ? 'Cash' : 'CreditCard';
      if (selectProdAmt === tendorAmt) {
        this.payTotal = tendorAmt;
      } else {
        this.payTotal = selectProdAmt;
      }
      for (let i = 0; i < this.TenderListForExchange.length; i++) {
        this.TenderListForExchange[i].isSelect = true;
        this.TenderListForExchange[i].Amount = this.payTotal;
      }
    });
    

  }

  
  trackBySection(index: number, section: any): string {
    return section.sectionName; // or another unique identifier for the section
  }
  
  trackByProduct(index: number, product: any): string {
    return product.productId; // or another unique identifier for the product
  }
  
  GetProductListNew() {
    debugger;
    this._commonService.GetProductsListNew(this.workstation.workStationId, this.siteConfi.maxDiscountFactor, this.siteConfi.displaySRP).subscribe((res) => {
      this.NewProducts = res;
      console.log('ProductList',this.NewProducts);
      this.NewProducts.forEach(row => {
        row.products.forEach(currentRow => {
          const index = this.SelectProducts.findIndex(pro => ((pro.plucode === undefined || pro.plucode === null) ? pro.productId === currentRow.productId : pro.plucode === currentRow.pLUCode));
          if (index > -1) {
            currentRow.totalPQty = this.SelectProducts[index].prodTotalQty = this.SelectProducts[index].qty;
          }
        })

      });
    })
  }

  transform(byteimge) {
    return this.sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + byteimge);

  }

  AdjustLogin() {
    debugger;

    if (this.LoginAdjuctGroup.invalid) {
      return;
      //this.toastr.error("This workstation is not access to you, Please Enter the Valid Credentials");
    }
    
    let userObj = {
      UserName: this.LoginAdjuctGroup.controls.UserNameLogin.value,
      Password: this.LoginAdjuctGroup.controls.PasswordLogin.value,
      districtId: this.workstation.districtId,
      siteId: this.workstation.siteId,
      workstationId: this.workstation.id
    }
    this.authenticationService.login(userObj)
      .subscribe(
        data => {

          window.location.reload();
        },
        error => {
          debugger;
          this.errormsg = error.error.message;
          if (this.errormsg == 'Not Use This Workstation') {
            this.toastr.error("This workstation is not access to you, Please Enter the Valid Credentials");
          };
        });
    //this.authenticationService.login(userObj)
  }
  get Login() { return this.LoginAdjuctGroup.controls; }


  //Increase Or Decrease Prod QTY
  proQty(item, oprator: string) {
    debugger;
    if (item.productType === 1) {
      this.voucharValidation(item, oprator);
    }
    else {
      const i = this.SelectProducts.findIndex(pro => ((pro.plucode === undefined || pro.plucode === null) ? pro.productId === item.productId : pro.plucode === item.plucode));

      if (oprator === 'minus') {
        if (this.SelectProducts[i].qty > 0) {
          //this.SelectProducts[i].prodTotalQty = this.SelectProducts[i].prodTotalQty - (item.qty = item.qty - 1);
          item.qty = item.qty - 1
          this.SelectProducts[i].prodTotalQty = this.SelectProducts[i].prodTotalQty - 1;
        }
        if (item.qty === 0) {
          this.SelectProducts[i].prodTotalQty = item.qty = item.qty = null;
          var index = this.SelectProducts.indexOf(item);
          this.SelectProducts.splice(index, 1);
          item.isActive = false;
        }
      }
      else {
        //if(boolflg===true){
        let cartTotalQuntities = this.SelectProducts.map(x => {
          return x.prodTotalQty || x.qty;
        }).reduce((a, qty) => a + parseInt(qty), 0);
        // let cartTotalQuntities = this.SelectedProducts.map(x => {
        //   return x.prodTotalQty || x.qty;
        // }).reduce((a, qty) => a + parseInt(qty), 0);

        // const totalQTY = pspTotalQuntities +cartTotalQuntities
        //alert(cartTotalQuntities);

        // if ((this.SelectProducts[i].totalPQty <= this.SelectProducts[i].qty && this.SelectProducts[i].inventoryItem === true) || (this.SelectProducts[i].totalPQty <= this.SelectProducts[i].prodTotalQty && this.SelectProducts[i].inventoryItem === true)) {
        //   Swal.fire({
        //     icon: 'error',
        //     title: 'Warning!',
        //     //text: 'You can not sale more than, you have onhand quantities',
        //     text: 'Cannot sell more than the onhand quantity for this product.',
        //   });
        // }
        const matchedProducts = this.SelectedProducts.filter(pro => pro.productId === item.productId && pro.inventoryItem === true);
        const QtyOfMatchedProducts = matchedProducts.reduce((sum, prod) => sum + prod.qty, 0);
        const totalQtyofproduct = item.qty +QtyOfMatchedProducts;
        if ( item.inventoryItem === true && totalQtyofproduct >= item.totalPQty) 
         { 
         Swal.fire({
           icon: 'error',
           title: 'Warning!',
           //text: 'You can not sale more than, you have onhand quantities',
           text: 'Cannot sell more than the onhand quantity for this product.',
         });
       }
        else if (this.SelectProducts[i].qty >= 25 || item.qty >= 25 || this.SelectProducts[i].prodTotalQty >= 25 || cartTotalQuntities >= 25 || cartTotalQuntities >= 25) {
          Swal.fire({
            icon: 'error',
            title: 'Warning!',
            text: 'Can not add more than 25 product quantities.',
          });
        }
        else {
          item.qty = item.qty + 1
          this.SelectProducts[i].prodTotalQty = this.SelectProducts[i].prodTotalQty + 1;
        }

        this.SelectProducts[i].isActive = true;
        this.isShowPrCount = true;
      }
    }

  }



  DoubleproQty(item, oprator: string) {
    debugger;
    if (item.productType === 1) {
      this.DoubleClickvoucharValidation(item, oprator);
    }
    else {
      const i = this.SelectProducts.findIndex(pro => ((pro.plucode === undefined || pro.plucode === null) ? pro.productId === item.productId : pro.plucode === item.plucode));

      if (oprator === 'minus') {
        if (this.SelectProducts[i].qty > 0) {
          //this.SelectProducts[i].prodTotalQty = this.SelectProducts[i].prodTotalQty - (item.qty = item.qty - 1);
          item.qty = item.qty = 0
          this.SelectProducts[i].prodTotalQty = this.SelectProducts[i].prodTotalQty - 1;
        }
        if (item.qty === 0) {
          this.SelectProducts[i].prodTotalQty = item.qty = item.qty = null;
          var index = this.SelectProducts.indexOf(item);
          this.SelectProducts.splice(index, 1);
          item.isActive = false;
        }
      }
      else {
        //if(boolflg===true){
        let cartTotalQuntities = this.SelectProducts.map(x => {
          return x.prodTotalQty || x.qty;
        }).reduce((a, qty) => a + parseInt(qty), 0);
        //alert(cartTotalQuntities);

        if ((this.SelectProducts[i].totalPQty <= this.SelectProducts[i].qty && this.SelectProducts[i].inventoryItem === true) || (this.SelectProducts[i].totalPQty <= this.SelectProducts[i].prodTotalQty && this.SelectProducts[i].inventoryItem === true)) {
          Swal.fire({
            icon: 'error',
            title: 'Warning!',
            //text: 'You can not sale more than, you have onhand quantities',
            text: 'Cannot sell more than the onhand quantity for this product.',
          });
        }
        else if (this.SelectProducts[i].qty >= 25 || item.qty >= 25 || this.SelectProducts[i].prodTotalQty >= 25 || cartTotalQuntities >= 25 || cartTotalQuntities >= 25) {
          Swal.fire({
            icon: 'error',
            title: 'Warning!',
            text: 'Can not add more than 25 product quantities.',
          });
        }
        else {
          item.qty = item.qty + 1
          this.SelectProducts[i].prodTotalQty = this.SelectProducts[i].prodTotalQty + 1;
        }

        this.SelectProducts[i].isActive = true;
        this.isShowPrCount = true;
      }
    }

  }
  showCashCCByTenderType() 
  {
    debugger;
    const selectedRefundProds = this.SelectedProducts;
    const refundTotal = selectedRefundProds.reduce((a, { total }) => a + parseFloat(total), 0);
    this.exchangeAmout = refundTotal
    let ttlRefund = refundTotal;

    const cashPay = this.TenderListForExchange.filter(x => x.tenderType == 1 || x.tenderType == 4);
    const ccPay = this.TenderListForExchange.filter(x => x.tenderType == 2);
    const roomChargePay = this.TenderListForExchange.filter(x => x.tenderType == 3);
    const moneyCertPay = this.TenderListForExchange.filter(x => x.tenderType == 4);

    const cashPaySum = cashPay.length;
    const ccPaySum = ccPay.length;
    const roomChargePaySum = roomChargePay.length;
    const moneyCertSum = moneyCertPay.length;

    const cashAmount = cashPay.reduce((a, { tenderAmount }) => a + tenderAmount, 0);
    const ccAmount = ccPay.reduce((a, { tenderAmount }) => a + tenderAmount, 0);
    const roomChargeAmount = roomChargePay.reduce((a, { tenderAmount }) => a + tenderAmount, 0);
    const moneyCertAmount = moneyCertPay.reduce((a, { tenderAmount }) => a + tenderAmount, 0);


    if (ccPaySum > 0) {
      // If tender type is CC only (cash amount is 0)
      if (ttlRefund < ccAmount) {
        this.editCCAmount = ttlRefund;
        ttlRefund = 0;
      }
      else {
        this.editCCAmount = ccAmount;
        ttlRefund = ttlRefund - ccAmount;
      }
      //this.editCashAmount = cashAmount;
      //this.isEditAmountSelect = false;
      //this.getCashInTX = true;
      //this.getCreditCardInTX = true;
      //this.getCashInTX = true;
      //this.isCcCheck=true;
      this.tenderTypeName = 'CC'
    }
    if (roomChargePaySum > 0) {
      // If tender type is Room Charge (cash amount is 0)
      if (ttlRefund < roomChargeAmount) {
        this.editRoomChargeAmount = ttlRefund;
        ttlRefund = 0;
      }
      else {
        this.editRoomChargeAmount = roomChargeAmount;
        ttlRefund = ttlRefund - roomChargeAmount;
      }


      this.editRoomChargeNum = roomChargePay[0].roomNum;
    }
    if (cashPaySum > 0 || moneyCertAmount > 0) {
      // If tender type is Cash only (CC amount is 0)
      if (ttlRefund < cashAmount) {
        this.editCashAmount = ttlRefund;
        ttlRefund = 0;
      }
      else {
        this.editCashAmount = cashAmount;
        ttlRefund = ttlRefund - cashAmount;
      }
      //this.editCCAmount = 0;
     

      this.tenderTypeName = 'CASH';

    }

  }
  voucharValidation(item: any, oprator: string) {
    debugger;
    const i = this.SelectProducts.findIndex(pro => ((pro.plucode === undefined || pro.plucode === null) ? pro.productId === item.productId : pro.plucode === item.plucode));
    const machinmultiIdVoucher = this.SelectProducts.filter(pro => pro.productId === item.productId || pro.consolidatedId === item.consolidatedId)
    if (oprator === 'minus') {
      if (this.SelectProducts[i].qty > 0) {
        item.qty = item.qty - 1
        this.SelectProducts[i].prodTotalQty = this.SelectProducts[i].prodTotalQty - 1;
      }
      if (item.qty === 0) {
        this.SelectProducts[i].prodTotalQty = item.qty = item.qty = null;
        var index = this.SelectProducts.indexOf(item);
        this.SelectProducts.splice(index, 1);
        item.isActive = false;
      }
      return true;
    }
    else {
      //let totalcout = machinmultiIdVoucher.map(x => {return x.prodTotalQty || x.qty; }).reduce((a, qty) => a + parseInt(qty), 0);
      let totalcout = machinmultiIdVoucher.map(x => {return x.qty; }).reduce((a, qty) => a + parseInt(qty), 0);
      if(machinmultiIdVoucher.length>0){
      if (totalcout < machinmultiIdVoucher[0].maxAddmissionCount) {
        item.qty = item.qty + 1
        this.SelectProducts[i].prodTotalQty = this.SelectProducts[i].prodTotalQty + 1;
        this.SelectProducts[i].isActive = true;
        this.isShowPrCount = true;
        return true;
      } else if (machinmultiIdVoucher[0].maxAddmissionCount === 0) {
        item.qty = item.qty + 1
        this.SelectProducts[i].prodTotalQty = this.SelectProducts[i].prodTotalQty + 1;
        this.SelectProducts[i].isActive = true;
        this.isShowPrCount = true;
        return true;
      } 
      else {
        let maxcount = machinmultiIdVoucher[0].maxAddmissionCount;
        Swal.fire({
          icon: 'warning',
          title: 'Warning!',
          text: "You can not select more than" + " " + maxcount + " " + "quantity for this item.",
        });
        return false;
      }
      }

    }
  }

  DoubleClickvoucharValidation(item: any, oprator: string) {
    debugger;
    const i = this.SelectProducts.findIndex(pro => ((pro.plucode === undefined || pro.plucode === null) ? pro.productId === item.productId : pro.plucode === item.plucode));
    const machinmultiIdVoucher = this.SelectProducts.filter(pro => pro.productId === item.productId || pro.consolidatedId === item.consolidatedId)
    if (oprator === 'minus') {
      if (this.SelectProducts[i].qty > 0) {
        item.qty = item.qty = 0
        this.SelectProducts[i].prodTotalQty = this.SelectProducts[i].prodTotalQty - 1;
        var index = this.SelectProducts.indexOf(item);
        this.SelectProducts.splice(index, 1);
        item.isActive = false;
      }
      if (item.qty === 0) {
        // this.SelectProducts[i].prodTotalQty = item.qty = item.qty = null;
        // var index = this.SelectProducts.indexOf(item);
        // this.SelectProducts.splice(index, 1);
        // item.isActive = false;
      }
    }
    else {
      let totalcout = machinmultiIdVoucher.map(x => {
        return x.prodTotalQty || x.qty;
      }).reduce((a, qty) => a + parseInt(qty), 0);
      if (totalcout < machinmultiIdVoucher[0].maxAddmissionCount) {
        item.qty = item.qty + 1
        this.SelectProducts[i].prodTotalQty = this.SelectProducts[i].prodTotalQty + 1;
        this.SelectProducts[i].isActive = true;
        this.isShowPrCount = true;
      } else if (machinmultiIdVoucher[0].maxAddmissionCount === 0) {
        item.qty = item.qty + 1
        this.SelectProducts[i].prodTotalQty = this.SelectProducts[i].prodTotalQty + 1;
        this.SelectProducts[i].isActive = true;
        this.isShowPrCount = true;
      } else {
        let maxcount = machinmultiIdVoucher[0].maxAddmissionCount;
        Swal.fire({
          icon: 'warning',
          title: 'Warning!',
          text: "You can not select more than a quantity of" + " " + maxcount + " " + "for this item",
        });
      }

    }
  }


  ChangeValue(value, item) {
    debugger;
    let refundProducts = this.SelectedProducts.filter(function (element, index) {
      return (element.type == 'Exchange');
    });
    if (refundProducts.length > 0) {
      this.ExchangeProductChangeValue(value, item)
    }
    else {
      this.NormalProductChangeValue(value, item)
    }
  }



  NormalProductChangeValue(value, item) {
    debugger;
    const i = this.SelectedProducts.findIndex(pro => ((pro.plucode === undefined || pro.plucode === null) ? pro.productId === item.productId : pro.plucode === item.plucode));
    const index = this.SelectProducts.findIndex(pro => ((pro.plucode === undefined || pro.plucode === null) ? pro.productId === item.productId : pro.plucode === item.plucode));
    if (parseInt(this.getTotalQty()) > 25 && value !== "0") {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'You can not save more than 25 product quantities.',
      });
    }
    else {
      //item.Total = (item.price * item.qty) - (item.discount * item.price);
      // Check current Row QTy.
      if (value === '0') {
        this.SelectedProducts[i].qty = this.SelectedProducts[i].totalPQty;
      }

      if (value === '0') {
        if (i !== -1) {
          this.SelectedProducts.splice(i, 1);
        }
        if (index !== -1) {
          this.SelectProducts.splice(index, 1)
        }
      }
      let summaryTotal = 0, summarySRPTotal = 0, summaryDue = 0, summaryDiscount = 0;

      this.SelectedProducts.forEach(currentRow => {
        if (currentRow.srp < currentRow.price) {
          summaryTotal += (currentRow.price - currentRow.discount) * currentRow.qty;
          summarySRPTotal += (currentRow.price * currentRow.qty);
          summaryDiscount += currentRow.discount * currentRow.qty;
          summaryDue += (currentRow.price - currentRow.discount) * currentRow.qty;
        }
        else {
          summaryTotal += (currentRow.srp - currentRow.discount) * currentRow.qty;
          summarySRPTotal += (currentRow.srp * currentRow.qty);
          summaryDiscount += currentRow.discount * currentRow.qty;
          summaryDue += (currentRow.srp - currentRow.discount) * currentRow.qty;
        }
      });

      let refundProducts = this.SelectedProducts.filter(function (element, index) {
        return (element.type != 'Exchange');
      });
      if (refundProducts.length > 0) {
        this.cartValues.TxTotal = summaryTotal;
        this.cartValues.BalanceDue = summaryDue;
        this.cartValues.SRPTotal = summarySRPTotal;
        this.cartValues.Discounts = summaryDiscount;
        this.creditAmont = -summaryTotal;
      }
      else {
        this.cartValues.TxTotal = 0;
        this.cartValues.BalanceDue = summaryDue;
        this.cartValues.SRPTotal = 0;
        this.cartValues.Discounts = summaryDiscount;
        this.creditAmont = -summaryTotal;
      }
    }
  }



  ExchangeProductChangeValue(value, item) {
    debugger;
    const i = this.SelectedProducts.findIndex(pro => ((pro.plucode === undefined || pro.plucode === null) ? pro.productId === item.productId : pro.plucode === item.plucode) && pro.type == item.type);
    const index = this.SelectProducts.findIndex(pro => ((pro.plucode === undefined || pro.plucode === null) ? pro.productId === item.productId : pro.plucode === item.plucode) && pro.type == item.type);
    if (parseInt(this.getTotalQty()) > 25 && value !== "0") {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'You can not save more than 25 product quantities.',
      });
    }
    else {
      if (value === '0') {
        this.SelectedProducts[i].qty = this.SelectedProducts[i].totalPQty;
      }

      if (value === '0') {
        if (i !== -1) {
          this.SelectedProducts.splice(i, 1);
        }
        if (index !== -1) {
          this.SelectProducts.splice(index, 1)
        }
      }
      let summaryTotal = 0, summarySRPTotal = 0, summaryDue = 0, summaryDiscount = 0;

      this.SelectProducts.forEach(currentRow => {
        if (currentRow.srp < currentRow.price) {
          summaryTotal += (currentRow.price - currentRow.discount) * currentRow.qty;
          summarySRPTotal += (currentRow.price * currentRow.qty);
          summaryDiscount += currentRow.discount * currentRow.qty;
          summaryDue += (currentRow.price - currentRow.discount) * currentRow.qty;
        }
        else {
          summaryTotal += (currentRow.srp - currentRow.discount) * currentRow.qty;
          summarySRPTotal += (currentRow.srp * currentRow.qty);
          summaryDiscount += currentRow.discount * currentRow.qty;
          summaryDue += (currentRow.srp - currentRow.discount) * currentRow.qty;
        }
      });

      let refundProducts = this.SelectedProducts.filter(function (element, index) {
        return (element.type != 'Exchange');
      });
      if (refundProducts.length > 0) {
        this.cartValues.TxTotal = summaryTotal;
        //this.cartValues.BalanceDue = summaryDue;
        this.cartValues.BalanceDue = this.cartValues.TxTotal+this.creditAmont;
        this.cartValues.SRPTotal = summarySRPTotal;
        this.cartValues.Discounts = summaryDiscount;
        //this.creditAmont = -summaryTotal;
      }
      else {
        this.cartValues.TxTotal = 0;
       // this.cartValues.BalanceDue = -summaryDue;
       this.cartValues.BalanceDue = this.cartValues.TxTotal+this.creditAmont;
        this.cartValues.SRPTotal = 0;
        this.cartValues.Discounts = summaryDiscount;
       // this.creditAmont = -summaryTotal;
      }
     
    }
  }


  ChangecartValue(value, item) {
    debugger;

    if (parseInt(this.getTotalQty()) > 25 && value !== "0") {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'You can not save more than 25 product quantities.',
      });
    } else {
      item.Total = (item.price * item.qty) - (item.discount * item.price);
    }
  }

  ShowProAddCart(data): void {
    
    this.PushAndAddProduct(data);
  }
  RemoveProAddCart(removedata): void {
    debugger;
    this.SelectProducts
    var index = this.SelectProducts.indexOf(removedata);
    this.SelectProducts.splice(index, 1);
    removedata.qty = null;
    removedata.isActive = false;
  }
  Populate(data: any) {
    debugger;
    this.nav.isdisabledNav = true;
    localStorage.setItem("isdisabledNavBar", 'true');
    if (this.customerName === '') {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Customer Required!',
      })
    } else {
      this.isProduct = false;
      this.isQuickShow = true;
      this.isvendorShow = true;
      this.iscatShow = true;
      if (this.isExhange) {
        debugger;
        this.isSRPShow = true;
        this.isDiscountShow = true;
        const index = this.SelectedProducts.findIndex(pro => ((pro.plucode === undefined || pro.plucode === null) ? pro.productId === data.productId : pro.plucode === data.plucode)&&  pro.qty >0);
        let qtyPerRow = 1;
        if (index > -1) {
          if (this.currentProdQty > this.SelectedProducts[index].qty) {
            qtyPerRow = parseInt(this.SelectedProducts[index].qty) + 1;
            this.SelectedProducts[index].qty = qtyPerRow;
            data.Total = (data.price * qtyPerRow) - (data.discount * qtyPerRow);
          } else {
            this.isDesibleQM = true;
            data.isProductDisable = true;
          }
        } else {
          this.currentProdQty = data.qty < 0 ? 0 : data.qty;
          if (this.currentProdQty > 0) {
            data.qty = 1;//data.qty === 0 ? parseInt(data.qty) + 1 : data.qty;
            data.Total = data.price * (parseInt(data.qty));
            this.SelectedProducts.push(data);
          } else {
            this.isDesibleQM = true;
            data.isProductDisable = true;
          }

        }
        if (!this.isShowCredit) {
          var credAmt = -Math.abs(this.cartValues.BalanceDue);
          this.creditAmont = credAmt;
        }
        this.ChangeValue(qtyPerRow, data);
        this.isShowCredit = true;
        data.type = 'Sale';

      }
      else {
        const cartQty = parseInt(this.getTotalQty());
        // data.qty = 1; // change in future
        if (cartQty >= 25) {
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: 'You can not save more than 25 product quantities.',
          })
        }
        else {
          data.type = 'Sale';

          if (data.productName) {
            data.fastButtonName = data.productName;
            data.discount = 0;
          }

          const index = this.SelectedProducts.findIndex(pro => ((pro.plucode === undefined || pro.plucode === null) ? pro.productId === data.productId : pro.plucode === data.plucode));
          let qtyPerRow = 1;
          let prodQTY = 1; //Change dynamacally
          if (index > -1) {
            if (this.currentProdQty > this.SelectedProducts[index].qty) {
              qtyPerRow = parseInt(this.SelectedProducts[index].qty) + 1;///parseInt(this.SelectedProducts[index].qty) + 1;//parseInt(this.SelectedProducts[index].qty) + 1;//parseInt(data.qty);
              this.SelectedProducts[index].qty = qtyPerRow;
              data.Total = (data.price * qtyPerRow) - (data.discount * qtyPerRow);
            } else {
              this.isDesibleQM = true;
              data.isProductDisable = true;
            }
          } else {
            data.fastButtonId = data.fastButtonId;
            this.currentProdQty = data.qty < 0 ? 0 : data.qty;
            if (this.currentProdQty > 0) {
              this.currentProdQty = data.qty;
              data.Total = data.price * (parseInt(data.qty));
              data.qty = 1;//data.qty === 0 ? parseInt(data.qty) + 1 : data.qty;
              this.SelectedProducts.push(data);
            } else {
              this.isDesibleQM = true;
              data.isProductDisable = true;
            }
          }
          this.ChangeValue(qtyPerRow, data);
        }
      }
    }
  }
  AddtoCart() {
    debugger;
    var sthis = this;
    let ExchangeProductCount = sthis.SelectedProducts.filter(x => x.type == "Exchange");
    if (ExchangeProductCount.length > 0) {
      this.AddTrnasactionProductWithExchange()
    }
    else {
      this.AddNormalTrnasactionProduct()
    }

  }


  // Add normal product for transaction history Module .....-------------------------------------
  AddNormalTrnasactionProduct() {
    debugger;
    var sthis = this;
    this.searchText = "";
    let summaryTotal = 0, summarySRPTotal = 0, summaryDue = 0, summaryDiscount = 0;
    this.SelectProducts.forEach(currentRow => {

      currentRow.fastButtonId = 0;
      const index = this.SelectedProducts.findIndex(pro => ((pro.plucode === undefined || pro.plucode === null) ? pro.productId === currentRow.productId : pro.plucode === currentRow.plucode));
      if (index < 0) {
        if(!this.siteConfi.displaySRP)
        {
          //currentRow.srp=currentRow.price;
        }
        sthis.SelectedProducts.push(currentRow);
      }

      if (index >= 0) {
        this.SelectedProducts[index].qty += currentRow.qty;
        if (currentRow.srp < currentRow.price) {
          // currentRow.qty = currentRow.prodTotalQty || currentRow.qty;
          // currentRow.Total = (currentRow.price * currentRow.qty) - (currentRow.discount * currentRow.qty);
          this.SelectedProducts[index].Total = this.SelectedProducts[index].price * this.SelectedProducts[index].qty - this.SelectedProducts[index].discount * this.SelectedProducts[index].qty ;
             
        }
        else {
          //currentRow.qty = currentRow.prodTotalQty || currentRow.qty;
          if(!this.siteConfi.displaySRP && currentRow.discount==0)
          {
            this.SelectedProducts[index].Total = this.SelectedProducts[index].srp * this.SelectedProducts[index].qty - this.SelectedProducts[index].discount * this.SelectedProducts[index].qty ;
               
            //currentRow.srp=currentRow.price;
            //currentRow.Total = (currentRow.price * currentRow.qty) - (currentRow.discount * currentRow.qty);
          }
          else{
            this.SelectedProducts[index].Total = this.SelectedProducts[index].srp * this.SelectedProducts[index].qty - this.SelectedProducts[index].discount * this.SelectedProducts[index].qty ;
               
            //currentRow.Total = (currentRow.srp * currentRow.qty) - (currentRow.discount * currentRow.qty);

          }
  
        }

        //this.SelectedProducts[index].Total = this.SelectedProducts[index].price * this.SelectedProducts[index].qty;
      }


      if (currentRow.srp < currentRow.price) {
        currentRow.qty = currentRow.prodTotalQty || currentRow.qty;
        currentRow.Total = (currentRow.price * currentRow.qty) - (currentRow.discount * currentRow.qty);

      }
      else {
        currentRow.qty = currentRow.prodTotalQty || currentRow.qty;
        if(!this.siteConfi.displaySRP && currentRow.discount==0)
        {
          //currentRow.srp=currentRow.price;
          currentRow.Total = (currentRow.price * currentRow.qty) - (currentRow.discount * currentRow.qty);
        }
        else{
          currentRow.Total = (currentRow.srp * currentRow.qty) - (currentRow.discount * currentRow.qty);
        }

      }
      // currentRow.qty = currentRow.prodTotalQty || currentRow.qty;
      // //currentRow.Total = (currentRow.price * currentRow.qty) - (currentRow.discount * currentRow.qty);

    });
    this.SelectedProducts.forEach(currentRow => {
      currentRow.fastButtonId = 0;
      //if (currentRow.type != "Exchange") {
      //summaryTotal += (currentRow.srp - currentRow.discount) * currentRow.qty;
      if (currentRow.srp < currentRow.price) {
        summaryTotal += (currentRow.price - currentRow.discount) * currentRow.qty;
        summarySRPTotal += (currentRow.price * currentRow.qty);
        summaryDiscount += currentRow.discount * currentRow.qty;
        summaryDue += (currentRow.price - currentRow.discount) * currentRow.qty;
      }
      else if (currentRow.srp == '-0') {
        summaryTotal += (currentRow.price - currentRow.discount) * currentRow.qty;
        summarySRPTotal += (currentRow.price * currentRow.qty);
        summaryDiscount += currentRow.discount * currentRow.qty;
        summaryDue += (currentRow.price - currentRow.discount) * currentRow.qty;
      }

      else if (!this.siteConfi.displaySRP && currentRow.discount==0) {
        summaryTotal += (currentRow.price - currentRow.discount) * currentRow.qty;
        summarySRPTotal += (currentRow.price * currentRow.qty);
        summaryDiscount += currentRow.discount * currentRow.qty;
        summaryDue += (currentRow.price - currentRow.discount) * currentRow.qty;
      }
      else {
        summaryTotal += (currentRow.srp - currentRow.discount) * currentRow.qty;
        summarySRPTotal += (currentRow.srp * currentRow.qty);
        summaryDiscount += currentRow.discount * currentRow.qty;
        summaryDue += (currentRow.srp - currentRow.discount) * currentRow.qty;
      }
      //}


    });

    if (this.isExhange) {
      this.cartValues.TxTotal = summaryTotal;
      this.cartValues.BalanceDue = this.cartValues.TxTotal;
      //this.cartValues.BalanceDue = this.cartValues.TxTotal-this.creditAmont;
      //this.creditAmont = -this.cartValues.TxTotal;
    } else {
      this.cartValues.TxTotal = summaryTotal;
      //this.cartValues.BalanceDue = summaryDue + this.creditAmont;
      this.cartValues.BalanceDue = summaryDue;
    }

    this.cartValues.SRPTotal = summarySRPTotal;
    this.cartValues.Discounts = summaryDiscount;
    this.isShowProdList = false;
    this.isShowPSP = true;
    this.isTransCancil = false;
    this.isQuickShow = true;
    this.catagoryName = "";
    this.subCatagoryName = "";
    this.isvendorShow = true;
    this.iscatShow = true;
    this.isSubCatShow = false;
    this.isShowPrCount = false;
    this.GetProductListNew();
    this.SelectProducts = [];
  }


  // Add Exchange product for transaction history.................--------------------
  AddTrnasactionProductWithExchange() {
    debugger;
    var sthis = this;
    this.searchText = "";
    let summaryTotal = 0, summarySRPTotal = 0, summaryDue = 0, summaryDiscount = 0;
       this.SelectProducts.forEach(currentRow => {
      currentRow.fastButtonId = 0;
      let flagval = 0;
      const index = this.SelectedProducts.findIndex(pro => ((pro.plucode === undefined || pro.plucode === null) ? pro.productId === currentRow.productId : pro.plucode === currentRow.plucode ) &&  pro.qty >0);
      if (index <= 0 || index > 0) {
        sthis.SelectedProducts.forEach(addRow => {
          if (addRow.productId == currentRow.productId && addRow.qty > 0) 
          {
            this.SelectedProducts[index].qty += currentRow.qty;
             //this.SelectedProducts[index].Total = this.SelectedProducts[index].price * this.SelectedProducts[index].qty;
            //addRow.qty = addRow.prodTotalQty || addRow.qty;
            if (addRow.srp < addRow.price) 
            {
              this.SelectedProducts[index].Total = this.SelectedProducts[index].price * this.SelectedProducts[index].qty - this.SelectedProducts[index].discount * this.SelectedProducts[index].qty ;
              //addRow.Total = (addRow.price * addRow.qty) - (addRow.discount * addRow.qty);

            }
            else 
            {

              if(!this.siteConfi.displaySRP)
              {
                //addRow.srp=addRow.price;
                //addRow.Total = (addRow.srp * addRow.qty) - (addRow.discount * addRow.qty);
                this.SelectedProducts[index].Total = this.SelectedProducts[index].srp * this.SelectedProducts[index].qty - this.SelectedProducts[index].discount * this.SelectedProducts[index].qty ;
              }
              else{
                this.SelectedProducts[index].Total = this.SelectedProducts[index].srp * this.SelectedProducts[index].qty - this.SelectedProducts[index].discount * this.SelectedProducts[index].qty ;
                //addRow.Total = (addRow.srp * addRow.qty) - (addRow.discount * addRow.qty);
              }
              
            }
            ++flagval
          }
        });
        if (flagval == 0) {

          if(!this.siteConfi.displaySRP && currentRow.discount==0)
          {
            //currentRow.srp=currentRow.price;
            currentRow.Total = (currentRow.price * currentRow.qty) - (currentRow.discount * currentRow.qty);
          }
          else{
            currentRow.Total = (currentRow.srp * currentRow.qty) - (currentRow.discount * currentRow.qty);
          }
          
          sthis.SelectedProducts.push(currentRow);
        }
      }
    });
    this.SelectedProducts.forEach(currentRow => {
      currentRow.fastButtonId = 0;
      if (currentRow.type != "Exchange") {
        //summaryTotal += (currentRow.srp - currentRow.discount) * currentRow.qty;
        if (currentRow.srp < currentRow.price) {
          summaryTotal += (currentRow.price - currentRow.discount) * currentRow.qty;
          summarySRPTotal += (currentRow.price * currentRow.qty);
          summaryDiscount += currentRow.discount * currentRow.qty;
          summaryDue += (currentRow.price - currentRow.discount) * currentRow.qty;
        }
        else if (currentRow.srp == '-0') {
          summaryTotal += (currentRow.price - currentRow.discount) * currentRow.qty;
          summarySRPTotal += (currentRow.price * currentRow.qty);
          summaryDiscount += currentRow.discount * currentRow.qty;
          summaryDue += (currentRow.price - currentRow.discount) * currentRow.qty;
        }
        else if(!this.siteConfi.displaySRP && currentRow.discount==0)
        {
          summaryTotal += (currentRow.price - currentRow.discount) * currentRow.qty;
          summaryDiscount += currentRow.discount * currentRow.qty;
          summaryDue += (currentRow.price - currentRow.discount) * currentRow.qty;
        }
        else {
          summaryTotal += (currentRow.srp - currentRow.discount) * currentRow.qty;
          summarySRPTotal += (currentRow.srp * currentRow.qty);
          summaryDiscount += currentRow.discount * currentRow.qty;
          summaryDue += (currentRow.srp - currentRow.discount) * currentRow.qty;
        }
      }


    });

    if (this.isExhange) {
      this.cartValues.TxTotal = summaryTotal;
      //this.cartValues.BalanceDue = this.cartValues.TxTotal;
      let valueCredite = Math.abs(this.creditAmont)
      this.cartValues.BalanceDue = this.cartValues.TxTotal - valueCredite;
      //this.creditAmont = -this.cartValues.TxTotal;
    } else {
      this.cartValues.TxTotal = summaryTotal;
      //this.cartValues.BalanceDue = summaryDue + this.creditAmont;
      this.cartValues.BalanceDue = summaryDue;
    }

    this.cartValues.SRPTotal = summarySRPTotal;
    this.cartValues.Discounts = summaryDiscount;
    this.isShowProdList = false;
    this.isShowPSP = true;
    this.isTransCancil = false;
    this.isQuickShow = true;
    this.catagoryName = "";
    this.subCatagoryName = "";
    this.isvendorShow = true;
    this.iscatShow = true;
    this.isSubCatShow = false;
    this.isShowPrCount = false;
    this.GetProductListNew();
    this.SelectProducts = [];
  }

  PushAndAddProduct(data: any) {
    debugger;
    if (data.inventoryItem == true) 
    {
      this.PushAndAddInventoryProducts(data);
    }
    else
     {

      if (data.qty == null || data.qty == 0) 
      {

        this.nav.isdisabledNav = true;
        localStorage.setItem("isdisabledNavBar", 'true');
        if (this.customerName === '')
         {
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: 'Customer Required!',
          })
        } 
        // else if(!this.voucharValidation(data,"Plus") && data.productType==1)
        // {
        //     //  condition for multi voucher count check
            
        //     this.DoubleClickvoucharValidation(data,"minus");
        //     // 
        // }
        else
         {
          if (this.isExhange) 
          {

            const index = this.SelectProducts.findIndex(pro => ((pro.plucode === undefined || pro.plucode === null) ? pro.productId === data.productId : pro.plucode === data.plucode));
            const i = this.SelectedProducts.findIndex(pro => (pro.productId === data.productId));

            if (i > -1) {
              this.isShowPrCount = false;
            }

            if (index > -1) {
              this.SelectProducts[index].prodTotalQty = this.SelectProducts[index].qty;
              data.qty = 1;
              this.SelectProducts[index].prodTotalQty = this.SelectProducts[index].prodTotalQty + data.qty;
              this.isShowPrCount = false;
              // data.qty = 1;
              // this.SelectProducts[index].prodTotalQty = this.SelectProducts[index].prodTotalQty + data.qty;
              // this.isShowPrCount = false;
            } else {
              this.currentProdQty = data.qty
              data.qty = 1;
              data.isActive = true;
              data.fastButtonId = 0;
              data.Total = data.price * (parseInt(data.qty));
              this.SelectProducts.push(data);
              this.isShowPrCount = true;
            }
            data.isActive = true;
            if (index > -1 || this.SelectProducts.length > 0) {
              this.isShowPrCount = true;
            } else {
              this.isShowPrCount = false;
            }

            const cartQty = parseInt(this.getTotalQty());

            // data.qty = 1; // change in future
            if ((cartQty + parseInt(data.qty)) > 50) {
              Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'You can not save more than 25 product quantities.',
              })
            } else {
              data.type = 'Sale';

              if (data.productName) {
                data.fastButtonName = data.productName;
                //data.discount = 0;
              }
            }
          } 
          else

           {
            const index = this.SelectProducts.findIndex(pro => ((pro.plucode === undefined || pro.plucode === null) ? pro.productId === data.productId : pro.plucode === data.plucode));
            const i = this.SelectedProducts.findIndex(pro => (pro.productId === data.productId));
            const machinmultiIdVoucher = this.SelectProducts.filter(pro => pro.productId === data.productId || pro.consolidatedId === data.consolidatedId)
           
            let totalcout = machinmultiIdVoucher.map(x => {return x.prodTotalQty; }).reduce((a, qty) => a + parseInt(qty), 0);
            
            if (i > -1) 
            {
              // this.SelectedProducts[i].qty = this.SelectProducts[index].qty
              this.isShowPrCount = false;
            }
            if (data.totalPQty === 0 && data.inventoryItem === true)
             {
              Swal.fire({
                icon: 'error',
                title: 'Warning!',
                //text: 'You can not sale more than, you have onhand quantities',
                text: 'Cannot sell more than the onhand quantity for this product.',
              });
              return false;
            }
          
            if (index > -1) 
            {
              let giftCartTotalQty = this.SelectProducts.map(x => x.qty).reduce((a, qty) => a + qty);
              this.SelectProducts[index].prodTotalQty = this.SelectProducts[index].qty;
              if ((this.SelectProducts[index].totalPQty <= this.SelectProducts[index].prodTotalQty) && this.SelectProducts[index].inventoryItem === true) {
                Swal.fire({
                  icon: 'error',
                  title: 'Warning!',
                  text: 'Products are not available in stocks.',
                });
                return false;
              }
              else if (this.SelectProducts[index].qty >= 25 || giftCartTotalQty >= 25) 
              {
                Swal.fire({
                  icon: 'error',
                  title: 'Warning!',
                  text: 'Can not add more than 25 product quantities.',
                });
                return false;
              }

           // condition for multi voucher count check
            //   if (data.productType==1) {
            //   if(!this.voucharValidation(data,"Plus"))  
            //   {
            //     //this.DoubleClickvoucharValidation(data,"minus");
            //  // alert("2");
            //   return false;
            //   }
            //   }

            if (data.productType==1 && machinmultiIdVoucher.length>0) {
              if(totalcout>=machinmultiIdVoucher[0].maxAddmissionCount)
            {

              Swal.fire({
                icon: 'warning',
                title: 'Warning!',
                text: "You can not select more than" + " " + machinmultiIdVoucher[0].maxAddmissionCount + " " + "quantity for this item.",
              });
              return false;
              }
            }

              data.qty = 1;
              this.SelectProducts[index].prodTotalQty = this.SelectProducts[index].prodTotalQty + data.qty;
              this.isShowPrCount = false;
              
              // data.qty = 1;
              // this.SelectProducts[index].prodTotalQty = this.SelectProducts[index].prodTotalQty + data.qty;
              // this.isShowPrCount = false;
            }
             else 
             {

              if (data.productType==1 && machinmultiIdVoucher.length>0) {
                if(totalcout>=machinmultiIdVoucher[0].maxAddmissionCount)
              {

                Swal.fire({
                  icon: 'warning',
                  title: 'Warning!',
                  text: "You can not select more than" + " " + machinmultiIdVoucher[0].maxAddmissionCount + " " + "quantity for this item.",
                });
                return false;
                }
              }
              this.currentProdQty = data.qty
              data.qty = 1;
              data.isActive = true;
              data.fastButtonId = 0;
              data.Total = data.price * (parseInt(data.qty));
              this.SelectProducts.push(data);
              this.isShowPrCount = true;

             


            }
            data.isActive = true;
            if (index > -1 || this.SelectProducts.length > 0) 
            {
              this.isShowPrCount = true;
            } 
            else
             {
              this.isShowPrCount = false;
            }

            const cartQty = parseInt(this.getTotalQty());
            // data.qty = 1; // change in future
            if ((cartQty + parseInt(data.qty)) > 50)
             {
              Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'You can not save more than 50 product quantities.',
              })
            } 
            else 
            {
                
                  data.type = 'Sale';

                  if (data.productName) {
                    data.fastButtonName = data.productName;
                    //data.discount = 0;
                  } 
            
            }
           }
      }
      }
      else {
        //this.RemoveProAddCart(data);
        //this.proQty()
        this.DoubleproQty(data, 'minus')
        //data.qty=null;
        //data.isActive=false;
      }



    }

  }



  //Add Product for Inventory Products Function.....
  PushAndAddInventoryProducts(data: any) {
    if (data.qty == null || data.qty == 0) {
      this.nav.isdisabledNav = true;
      localStorage.setItem("isdisabledNavBar", 'true');
      if (this.customerName === '') {
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'Customer Required!',
        })
      } else {
        if (this.isExhange) {

          const index = this.SelectProducts.findIndex(pro => ((pro.plucode === undefined || pro.plucode === null) ? pro.productId === data.productId : pro.plucode === data.plucode));
          const i = this.SelectedProducts.findIndex(pro => (pro.productId === data.productId));
          const matchedProducts = this.SelectedProducts.filter(pro => pro.productId === data.productId && pro.inventoryItem === true);
          const totalQtyOfMatchedProducts = matchedProducts.reduce((sum, prod) => sum + prod.qty, 0);
          if (i > -1) {
            this.isShowPrCount = false;
          }
          if ( data.inventoryItem === true&& totalQtyOfMatchedProducts >= data.totalPQty) {
            Swal.fire({
              icon: 'error',
              title: 'Warning!',
              //text: 'There is no inventory available for selected product.',
              text: 'Cannot sell more than the onhand quantity for this product.'
            });
            return false;
          }
          if (index > -1) {
            this.SelectProducts[index].prodTotalQty = this.SelectProducts[index].qty;
            data.qty = 1;
            this.SelectProducts[index].prodTotalQty = this.SelectProducts[index].prodTotalQty + data.qty;
            this.isShowPrCount = false;
            // data.qty = 1;
            // this.SelectProducts[index].prodTotalQty = this.SelectProducts[index].prodTotalQty + data.qty;
            // this.isShowPrCount = false;
          } else {
            this.currentProdQty = data.qty
            data.qty = 1;
            data.isActive = true;
            data.fastButtonId = 0;
           // data.discount=data.cost-data.price;
            data.Total = data.price * (parseInt(data.qty));
            this.SelectProducts.push(data);
            this.isShowPrCount = true;
          }
          data.isActive = true;
          if (index > -1 || this.SelectProducts.length > 0) {
            this.isShowPrCount = true;
          } else {
            this.isShowPrCount = false;
          }

          const cartQty = parseInt(this.getTotalQty());

          // data.qty = 1; // change in future
          if ((cartQty + parseInt(data.qty)) > 50) {
            Swal.fire({
              icon: 'error',
              title: 'Error!',
              text: 'You can not save more than 25 product quantities.',
            })
          } else {
            data.type = 'Sale';

            if (data.productName) {
              data.fastButtonName = data.productName;
              //data.discount = 0;
            }
          }
        } else {
          const index = this.SelectProducts.findIndex(pro => ((pro.plucode === undefined || pro.plucode === null) ? pro.productId === data.productId : pro.plucode === data.plucode));
          const i = this.SelectedProducts.findIndex(pro => (pro.productId === data.productId));
          const matchedProducts = this.SelectedProducts.filter(pro => pro.productId === data.productId && pro.inventoryItem === true);
          const totalQtyOfMatchedProducts = matchedProducts.reduce((sum, prod) => sum + prod.qty, 0);
          if (i > -1) {
            this.isShowPrCount = false;
          }
          if ( data.inventoryItem === true&& totalQtyOfMatchedProducts >= data.totalPQty) {
            Swal.fire({
              icon: 'error',
              title: 'Warning!',
              text: 'Cannot sell more than the onhand quantity for this product.'
            });
            return false;
          }
          if (i > -1) {
            // this.SelectedProducts[i].qty = this.SelectProducts[index].qty
            this.isShowPrCount = false;
          }
        
          if (index > -1) {
            let giftCartTotalQty = this.SelectProducts.map(x => x.qty).reduce((a, qty) => a + qty);
            this.SelectProducts[index].prodTotalQty = this.SelectProducts[index].qty;
            if ((this.SelectProducts[index].totalPQty <= this.SelectProducts[index].prodTotalQty) && this.SelectProducts[index].inventoryItem === true) {
              Swal.fire({
                icon: 'error',
                title: 'Warning!',
                text: 'products are not available in stocks.',
              });
              return false;
            }
            else if (this.SelectProducts[index].qty >= 25 || giftCartTotalQty >= 25) {
              Swal.fire({
                icon: 'error',
                title: 'Warning!',
                text: 'Can not add more than 25 product quantities.',
              });
              return false;
            }
            data.qty = 1;
            this.SelectProducts[index].prodTotalQty = this.SelectProducts[index].prodTotalQty + data.qty;
            this.isShowPrCount = false;
          } else {
            this.currentProdQty = data.qty
            data.qty = 1;
            data.isActive = true;
            data.fastButtonId = 0;
            data.Total = data.price * (parseInt(data.qty));
            this.SelectProducts.push(data);
            this.isShowPrCount = true;
          }
          data.isActive = true;
          if (index > -1 || this.SelectProducts.length > 0) {
            this.isShowPrCount = true;
          } else {
            this.isShowPrCount = false;
          }

          const cartQty = parseInt(this.getTotalQty());
          // data.qty = 1; // change in future
          if ((cartQty + parseInt(data.qty)) > 50) {
            Swal.fire({
              icon: 'error',
              title: 'Error!',
              text: 'You can not save more than 25 product quantities.',
            })
          } else {
            data.type = 'Sale';

            if (data.productName) {
              data.fastButtonName = data.productName;
              //data.discount = 0;
            }
          }
        }
      }
    }
    else {
      this.DoubleproQty(data, 'minus')

    }
  }

  isDollarExist(event: any) {
    debugger;
    let payamountkey = event.key;
    let Amountvalue = Number(payamountkey);
    if (payamountkey == "Backspace") {
      this.PopulateAmount('<');
    }
    else if (payamountkey == "ArrowLeft" || payamountkey == "ArrowRight" || payamountkey == "Enter") {

    } else if (payamountkey == ".") {
      //this.PopulateAmount(payamountkey);
    }
    else if (Amountvalue >= 0) {
      this.PopulateAmount(Amountvalue);
    }
    else {
    }
    //}

  }

  IsdollarSignExistBefaur(Value) {
    for (var i = 0; i < Value.length; i++) {
      if (Value.charAt(i) === '$')
        return true;
    }
    return false;
  }

  showProductMenu(event: any) {
    this.productData = event;
    this.isProduct = true;
    this.isQuickShow = false;
    this.isvendorShow = false;
    this.iscatShow = false;
    this.isSubCatShow = false;
    this.isShowProdList = false;
    this.isShowPrCount = false;
  }

  keytab(event) {
    debugger;
    const key = event.key;
   if(!this.RestrictNumbers(Number(key)))
   {
    event.preventDefault();
   }
    if (event.keyCode == 13) {
      $('#lastName').focus();
    }
  }

  FocusSubmit(event) {
    debugger;
    if (event.keyCode == 13) {
      $('#btnSubmit').focus();
    }
  }

  getTotalQty() {
    return this.SelectedProducts.reduce((a, { qty }) => a + parseInt(qty), 0);
  }

  show(type: string) {
    debugger;
    if (type === 'Cust') {
      this.showModal = true; // Show-Hide Modal Check
      //this.isshowRoomModal = true;
    }
    else if (type === 'Discount') {
      this.discountFlag=true;
      this.isshowDisModal = true;
      setTimeout(() => { // this will make the execution after the above boolean has changed
        $("#textDiscount").focus();
      }, 500);

    }
    else if (type === 'TourDeposit') {
      this.isTourModelShow = true;
    }
    else if (type === 'RoomCharge') {
      this.isshowRoomModal = true;

    }
    // else if(type ==='Sign'){
    //   this.MySingInModal=true;
    // }

  }
  //Bootstrap Modal Close event
  hide(type: string) {
    if (type === 'Cust') {
      this.showModal = false; // Show-Hide Modal Check
    }
    else if (type === 'Discount') {
      this.isshowDisModal = false;
    }
    else if (type === 'TourDeposit') {
      this.isTourModelShow = false;
    }
    else if (type === 'RoomCharge') {
      this.isshowRoomModal = false;
      this.roomNumber = "";
    }
    else if (type === 'SignInModal') {
      this.MySingInModal = false;
    }
    else if (type === 'isTourIdModelShow') {
      
      this.isTourIdModelShow = false;
      this.tourIdInput = "";
    }
  }
// Tourdeposit Amount popup work 

SoldForKeypadCross(): void {
  this.isTourModelShow = false;
  this.tourAmt = '0.00';
}
@HostListener('document:keydown', ['$event'])

handleKeyboardEvent(event: KeyboardEvent) {
  debugger;
  const key = event.key;
  if (key === 'Enter') {
    //this.PopulateTourAmt();
  } else if (key === 'Backspace') {
    this.handleKeyPress('backspace');
  } else if (key === 'Escape') {
    this.SoldForKeypadCross();
  } else if (!isNaN(Number(key))) {
   // this.handleKeyPress(key);
   // this.RestrictNumbers(Number(key))
  } else if (key === 'c' || key === 'C') {
    this.handleKeyPress('C');
  }

}

handleKeyPress(key: string) {
  debugger;
  if (key === 'C') {
    this.tourAmt = '0.00';
  } else if (key === 'backspace') {
    // Handle backspace
    let amount = this.tourAmt.replace('.', '').slice(0, -1);
    this.tourAmt = this.formatAmount(amount);
  } else {
    let amount = this.tourAmt.replace('.', '') + key;
    this.tourAmt = this.formatAmount(amount);
    this.user.firstName=  StringHelper.NumberValidator(key);

    
  }
}

formatAmount(amount: string): string {
  while (amount.length < 3) {
    amount = '0' + amount;
  }
  let beforePoint = amount.slice(0, -2);
  let afterPoint = amount.slice(-2);
  return `${parseInt(beforePoint, 10)}.${afterPoint}`;
}

  onSubmit() {
    debugger
    this.submitted = false;
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      this.submitted = true;
      return;
    }
    if (!this.submitted) {     

      if(this.customerName==""){
      this.CheckPrinter();
      this.SetDynamicValues();
      this.isShowProdList=false;
      this.LastTxNo = true;
      this._commonService.UpdateLastTXID(this.workstation.workStationId, this.workstation.lastTxId, this.submitted)
        .subscribe((res) => {
          this.TxNum = res + 1;
          //this._dataService.setbehaviorObjData({ TxNum: res });
          //this._dataService.setbehaviorListData([]);

        });
      }
      debugger;
     // if (true) {
        this.showModal = false;
        this.customerName = this.user.firstName + ' ' + this.user.lastName;
        this.nav.isdisabledNav = true;
      //}
    }
  }

  valRoomNumber(event) {
    debugger;
    this.roomNumber = event.target.value;
  }
  PopulateRoomNumber(roomNo) {
    if (roomNo === 'x') {
      let roomNums = "" + this.roomNumber;
      this.roomNumber = roomNums.slice(0, -1);
      this.roomNumber = this.roomNumber;
    }else if(roomNo === 'c'){
      this.roomNumber = "";
    }
     else {
      this.roomNumber = "" + this.roomNumber + roomNo;
    }
  }
  takeDepo()
  {
    this.isTourIdModelShow =true;
  }
  PopulatetourId(tourid) {
    debugger;
    if (tourid === 'x') {
      let tourids = "" + this.tourIdInput;
      this.tourIdInput = tourids.slice(0, -1);
      this.tourIdInput = this.tourIdInput;
    }else if(tourid === 'c'){
      this.tourIdInput = "";
    }
     else {
      this.tourIdInput = "" + this.tourIdInput + tourid;
    }
  }
  ContinueForTourAmount()
  {
    debugger;
    //this.tourId = parseInt(this.tourIdInput);
    this.tourId = this.tourIdInput;
    this.tourAmt = '0.00'
    this.tourIdInput = "";
    this.isTourIdModelShow =false;
    this.isTourModelShow = true;
  }

  PopulateRoomNumberContinue() {
    debugger;
    if (this.roomNumber === '') {
      this.PopulateAmount('x')
      this.isshowRoomModal = false;
    }
    else {
      this.RoomChargeBtn()
      this.isshowRoomModal = false;


    }
  }

  RoomChargeBtn() {
    let balanceDue = this.cartValues.BalanceDue - parseFloat(this.amountTendered);
    this.selectedRoomTenderId = this.selectedTenderId;
    //this.isshowRoomModal = true;
    let calculateAmt = this.totalCardCalc; // this.SplitTransaction.reduce((accumulator, current) => accumulator + current.TenderAmount, 0);
    this.CalculateTotalRoomAmount = parseFloat(this.CalculateTotalRoomAmount) + parseFloat(this.amountTendered);

    //this.CalculateTotalMoneyCertAmount = +moneyCertAmt;
    this.changeDue = this.cartValues.TxTotal - (+this.CalculateTotalCashAmount + +this.CalculateTotalRoomAmount + +this.CalculateTotalMoneyCertAmount + calculateAmt);
    
    let ExchangeProductCount = this.SelectedProducts.filter(x => x.type == "Exchange");
    if (ExchangeProductCount.length > 0) {
      this.changeDue=this.changeDue+this.creditAmont;
      this.changeDue = Math.abs(this.changeDue);
    }
    else
    {
      this.changeDue = Math.abs(this.changeDue);
    }
   
    this.amountTendered = 0;
    this.cartValues.BalanceDue = balanceDue;
    if (this.cartValues.BalanceDue <= 0) {
      this.isFinish = false;
      this.isTendorDisable = true;
      this.cartValues.BalanceDue = 0;
    } else {
      this.isFinish = true;
      this.isTendorDisable = false;
    }
    this.isShowPSP = false;
    this.isSRPShow = false;
    this.isDiscountShow = false;
    this.isMoneyShow = true;
    this.isTxPrinting = false;
    this.isTransCancil = true;
  }

  PopulateRoomNumberCancel() {
    this.isshowRoomModal = false;
    this.roomNumber = '';
    this.PopulateAmount('x')

  }
  PopulateDiscountAmount(amt) {
    debugger;
    this.MinDiscounts
    this.MaxDiscounts
    //this.discountFlag = false;
    if (amt < this.MinDiscounts) {
      this.toastr.error("Please Enter Values between Minimum and Maximum Range")
    }
    else if (amt > this.MaxDiscounts) {
      this.toastr.error("Please Enter Values between Minimum and Maximum Range")
    }
    else {
      if (parseFloat(amt) === 0) {
        let currItem = this.SelectedProducts[this.selecteditemForDiscount];
        this.cartValues.Discounts = parseFloat(amt);
        this.SelectedProducts[this.selecteditemForDiscount].Total = (currItem.price * currItem.qty) - (parseFloat(amt) * currItem.qty)
        this.SelectedProducts[this.selecteditemForDiscount].discount = parseFloat(amt);
      }
       else {
        let currItem = this.SelectedProducts[this.selecteditemForDiscount];

        if ((currItem.price * currItem.qty) > (parseFloat(amt) * currItem.qty)) {
          if (currItem.srp < currItem.price) {
            this.SelectedProducts[this.selecteditemForDiscount].Total = (currItem.price * currItem.qty) - (parseFloat(amt) * currItem.qty)
          }
          else {
            this.SelectedProducts[this.selecteditemForDiscount].Total = (currItem.srp * currItem.qty) - (parseFloat(amt) * currItem.qty)
          }
          //this.SelectedProducts[this.selecteditemForDiscount].Total = (currItem.price * currItem.qty) - (parseFloat(amt) * currItem.qty)

          this.SelectedProducts[this.selecteditemForDiscount].discount = parseFloat(amt);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: 'You cannot add discount more than total Price!',
          })
        }
      }
      let summaryTotal: number = 0, summaryDiscount: number = 0, summaryDue: number = 0;


      let ExchangeProduct = this.SelectedProducts.filter(x => x.type == "Exchange");
      if (ExchangeProduct.length > 0) {
        this.SelectedProducts.forEach(currentRow => {
          if(currentRow.type !="Exchange")
          {
          if (currentRow.srp < currentRow.price) {
            summaryTotal += (currentRow.price - currentRow.discount) * currentRow.qty;
            summaryDiscount += currentRow.discount * currentRow.qty;
            summaryDue += (currentRow.price - currentRow.discount) * currentRow.qty;
          }
          else if(!this.siteConfi.displaySRP && currentRow.discount==0)
          {
            summaryTotal += (currentRow.price - currentRow.discount) * currentRow.qty;
            summaryDiscount += currentRow.discount * currentRow.qty;
            summaryDue += (currentRow.price - currentRow.discount) * currentRow.qty;
          }
          else {
            summaryTotal += (currentRow.srp - currentRow.discount) * currentRow.qty;
            summaryDiscount += currentRow.discount * currentRow.qty;
            //summaryDue += (currentRow.srp - currentRow.discount) * currentRow.qty;
            summaryDue += (currentRow.srp - currentRow.discount) * currentRow.qty;
          }
        }
  
        });
      this.cartValues.TxTotal = summaryTotal;
      this.cartValues.Discounts = summaryDiscount;
      this.cartValues.BalanceDue = this.cartValues.TxTotal + this.creditAmont;
      
      }
      else {
        this.SelectedProducts.forEach(currentRow => {
          if (currentRow.srp < currentRow.price) {
            summaryTotal += (currentRow.price - currentRow.discount) * currentRow.qty;
            summaryDiscount += currentRow.discount * currentRow.qty;
            summaryDue += (currentRow.price - currentRow.discount) * currentRow.qty;
          }
          else if(!this.siteConfi.displaySRP && currentRow.discount==0)
          {
            summaryTotal += (currentRow.price - currentRow.discount) * currentRow.qty;
            summaryDiscount += currentRow.discount * currentRow.qty;
            summaryDue += (currentRow.price - currentRow.discount) * currentRow.qty;
          }
          else {
            summaryTotal += (currentRow.srp - currentRow.discount) * currentRow.qty;
            summaryDiscount += currentRow.discount * currentRow.qty;
            summaryDue += (currentRow.srp - currentRow.discount) * currentRow.qty;
          }
          //summaryTotal += (currentRow.price - currentRow.discount) * currentRow.qty;
  
        });

      this.cartValues.TxTotal = summaryTotal;//this.cartValues.TxTotal - this.cartValues.Discounts;
      this.cartValues.BalanceDue = summaryDue;//this.cartValues.TxTotal - this.cartValues.Discounts;
      this.cartValues.Discounts = summaryDiscount;
      }

      //commented this code by humraaz on 12 Feb 2024
      // this.SelectedProducts.forEach(currentRow => {
      //   if (currentRow.srp < currentRow.price) {
      //     summaryTotal += (currentRow.price - currentRow.discount) * currentRow.qty;
      //     summaryDiscount += currentRow.discount * currentRow.qty;
      //     summaryDue += (currentRow.price - currentRow.discount) * currentRow.qty;
      //   }
      //   else {
      //     summaryTotal += (currentRow.srp - currentRow.discount) * currentRow.qty;
      //     summaryDiscount += currentRow.discount * currentRow.qty;
      //     summaryDue += (currentRow.srp - currentRow.discount) * currentRow.qty;
      //   }
      //   //summaryTotal += (currentRow.price - currentRow.discount) * currentRow.qty;

      // });



      // this.cartValues.TxTotal = summaryTotal;//this.cartValues.TxTotal - this.cartValues.Discounts;
      // this.cartValues.BalanceDue = summaryDue;//this.cartValues.TxTotal - this.cartValues.Discounts;
      // this.cartValues.Discounts = summaryDiscount;
      this.hide('Discount');
      this.discountFlag = false;
    }
  }

  PopulateTourAmt() {
    debugger;
    this._posSerive.GetTourDepositproductDetails('DEPO')
      .subscribe((res) => {
        debugger;
        this.TourDepositDetails = res
        let tourObj = {
          plucode: null,
          // fastButtonName: "$"+' '+amt+' '+ this.TourDepositDetails.productName,
          //fastButtonName: "$" +  this.tourAmt + ' ' + this.TourDepositDetails.productName,
          fastButtonName: "$" +this.tourAmt+ ' ' + this.TourDepositDetails.productName,
          productId: this.TourDepositDetails.productId,
          qty: 1,
          srp: parseFloat( this.tourAmt),
          price: parseFloat( this.tourAmt),
          discount: 0,
          wholesaleCost: parseFloat( this.tourAmt),
          discountable:false

        };
        this.Populate(tourObj);

      });
    this.isTourModelShow = false;
     
  }

  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }
  OpenModel(type: string, id?: number, val?: number, minidis?: number, maxdis?: number) {
    if (this.UserAdvancSetting.applyDiscount === 1 || type === 'Cust') {
      this.MinDiscounts = minidis;
      this.MaxDiscounts = maxdis;
      this.show(type);
      this.selecteditemForDiscount = id;
      this.discountAmt = val;
      this.isCustomer = false;
      this.isCustomerAdd = true;
    }
    else {
      this.ValidationPopup();
    }
  }
  RedirectToProductHome() {
    this.isSubCatShow = false;
    this.isShowProdList = false;
    this.isQuickShow = true;
    this.isvendorShow = true;
    this.iscatShow = true;
    this.subCatagoryName = "";
  }

  GetSubCategory(category) {
    //debugger;
    this._posSerive.GetProductSubCategories(category.categoryId).subscribe((res) => {
      this.productSubCategories = res;
      this.catagoryName = "/" + category.categoryName;
      this.isShowProdLink = true;
      if (this.productSubCategories.length > 0) {
        this.isSubCatShow = true;
      } else {
        debugger;
        this.isSubCatShow = false;
        var productsDetails = {
          reportCategoryId: category.categoryId,
          subCategoryId: 0
        }
        this.GetProducts(productsDetails);
        this.subCatagoryName = '';
      }
     
    })
  }


  ValidationPopup() {
    Swal.fire({
      text: 'You do not have permission to perform this function. Would you like to enter alternate credentials?​',
      icon: 'warning',
      title:'Warning!',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        debugger;
        this.MySingInModal = true;
        //Swal.fire('Removed!', 'Product removed successfully.', 'success');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        debugger;
        //Swal.fire('Cancelled', 'Product still in our database.)', 'error');
      }
    });
  }




  GetProducts(data) {
    debugger;
    this.subCatagoryName = "/" + data.subCategory;
    this._posSerive.GetProductsList(data.reportCategoryId, data.subCategoryId, '1', this.workstation.workStationId, this.siteConfi.maxDiscountFactor, this.siteConfi.displaySRP).subscribe((res) => {
      this.ProductList = res;
     
      if (this.ProductList.length === 0) {
        Swal.fire({
          icon: 'warning',
          title: 'Warning!',
          text: 'Product Count is 0 for selected item.',
        })
      }
      else {
        this.isShowProdList = true;
        this.isQuickShow = false;
        this.isvendorShow = false;
        this.iscatShow = false;
        this.isSubCatShow = false;

        this.ProductList.forEach(currentRow => {
          const index = this.SelectProducts.findIndex(pro => ((pro.plucode === undefined || pro.plucode === null) ? pro.productId === currentRow.productId : pro.plucode === currentRow.plucode));
          if (index > -1) {
            // currentRow.isActive = true;
            currentRow.prodTotalQty = this.SelectProducts[index].prodTotalQty = this.SelectProducts[index].qty;
          }
        });
      }
     
    })
  }

  Searchproduct() {
    debugger;

    if(this.searchText !=""){
    this._posSerive.GetProductsListByName(this.workstation.workStationId, this.searchText, this.siteConfi.maxDiscountFactor,this.siteConfi.displaySRP).subscribe((res) => {
      //this.ProductList = res;
      this.NewProducts = res;
      console.log('OldProduct',res);
      if (res[0].products.length === 0) {
        Swal.fire({
          icon: 'error',
          title: 'Warning!',
          text: 'Product Count is 0 for searched item.',
        })
      }
      else {
        this.isShowProdList = true;
        this.isQuickShow = false;
        this.isvendorShow = false;
        this.iscatShow = false;
        this.isSubCatShow = false;

        // this.ProductList.forEach(currentRow => {
        //   const index = this.SelectProducts.findIndex(pro => ((pro.plucode === undefined || pro.plucode === null) ? pro.productId === currentRow.productId : pro.plucode === currentRow.plucode));
        //   if (index > -1) {
        //     // currentRow.isActive = true;
        //     currentRow.prodTotalQty = this.SelectProducts[index].prodTotalQty = this.SelectProducts[index].qty;
        //   }
        // });
        this.NewProducts.forEach(row => {
          row.products.forEach(currentRow => {
            const index = this.SelectProducts.findIndex(pro => ((pro.plucode === undefined || pro.plucode === null) ? pro.productId === currentRow.productId : pro.plucode === currentRow.pLUCode));
            if (index > -1) {
              currentRow.totalPQty = this.SelectProducts[index].prodTotalQty = this.SelectProducts[index].qty;
            }
          })
  
        });
      } 
     
    })
  }
  else{
    this.GetProductListNew();
  }
  }
  PaymentTypeCreditCard() {
    this.isShowPreviousPaidAmountCreditCard = true;
    this.isShowPreviousPaidAmountCash = false;
  }

  PaymentTypeCash() {
    this.isShowPreviousPaidAmountCash = true;
    this.isShowPreviousPaidAmountCreditCard = false;
  }

  ShowRemainingPayment() {
    if (this.selectedType === 'CreditCard') {
      this.PaymentTypeCreditCard();
    } else {
      this.PaymentTypeCash();
    }
  }


  BtnSelected() {
    debugger;
    if (this.isExhange) {
      this.NormalExchange()
    }
    else {
      this.selectedType = this.selectedTender.tenderType;
      this.selectedTenderId = this.selectedTender.tenderId;
      const tenderName = this.selectedTender.tenderName;
      this.amountTendered = (this.amountTendered === 0 ? this.cartValues.BalanceDue : this.amountTendered);
      let balanceDue = this.cartValues.BalanceDue - parseFloat(this.amountTendered);
      this.isFinish = true;
      if (this.selectedType === "Credit Card") {
        this.selectedCCTenderId = this.selectedTenderId;
        this.selectedCCAmount = this.amountTendered;
        if (balanceDue <= 0) {

          this.isFinish = false;
          this.cartValues.BalanceDue = 0;
        }
        this.isCCPrinting = true;
        this.isBackDisable = false;
        this.isProcessCancel = true;
      }
      else if (this.selectedType === "Cash") {
        this.selectedCashTenderId = this.selectedTenderId;
        let calculateAmt = this.totalCardCalc;
        this.CalculateTotalCashAmount = parseFloat(this.CalculateTotalCashAmount) + parseFloat(this.amountTendered);
        this.changeDue = this.cartValues.TxTotal - (+this.CalculateTotalCashAmount + +this.CalculateTotalRoomAmount + +this.CalculateTotalMoneyCertAmount + calculateAmt);
        let ExchangeProductCount = this.SelectedProducts.filter(x => x.type == "Exchange");
    if (ExchangeProductCount.length > 0) {
      this.changeDue=this.changeDue + this.creditAmont;
      this.changeDue = Math.abs(this.changeDue);
    }
    else{
      this.changeDue = Math.abs(this.changeDue);
    }
        
        
        this.amountTendered = 0;
        this.cartValues.BalanceDue = balanceDue;
        if (this.cartValues.BalanceDue <= 0) {
          this.isFinish = false;
          this.isTendorDisable = true;
          this.cartValues.BalanceDue = 0;
        } else {
          this.isFinish = true;
          this.isTendorDisable = false;
        }
        this.isShowPSP = false;
        this.isSRPShow = false;
        this.isDiscountShow = false;
        this.isMoneyShow = true;
        this.isTxPrinting = false;
        this.isTransCancil = true;
        this.isProcessCancel = false;
      }
      else if (this.selectedType === "Room Charge") {
        this.isshowRoomModal = true;
        setTimeout(() => { // this will make the execution after the above boolean has changed
          $("#roomChargeId").focus();
        }, 500);
      }
      else if (this.selectedType === "Money Cert") {
        let calculateAmt = this.totalCardCalc;
        let moneyCertAmt = parseFloat(this.CalculateTotalMoneyCertAmount) + parseFloat(this.amountTendered);

        if (+moneyCertAmt > 0) {
          let mod = +moneyCertAmt % 1;
          if (+this.amountTendered > this.cartValues.BalanceDue) {
            this.toastr.error(`Cannot accept a money certificate(s) for more than the amount due.
          The total face value of the certificate(s) must be less than or equal to the amount due.`);
            this.amountTendered = 0;
            return;
          }
          else if (mod != 0) {
            this.toastr.error(`The amount tendered of ${moneyCertAmt} is invalid. Money certificates must be a whole dollar amount.`);
            this.amountTendered = 0;
            return;
          }
        }
        let objMoneyCert = { tenderId: this.selectedTenderId, moneyCertName: tenderName, tenderAmount: +this.amountTendered };
        this.upsert(this.CalculateTotalMoneyCertAmounts, objMoneyCert);
        this.CalculateTotalMoneyCertAmount = +moneyCertAmt;
        this.changeDue = this.cartValues.TxTotal - (+this.CalculateTotalCashAmount + +this.CalculateTotalRoomAmount + +this.CalculateTotalMoneyCertAmount + calculateAmt);
        this.changeDue = Math.abs(this.changeDue);
        this.amountTendered = 0;
        this.cartValues.BalanceDue = balanceDue;
        if (this.cartValues.BalanceDue <= 0) {
          this.isFinish = false;
          this.isTendorDisable = true;
          this.cartValues.BalanceDue = 0
        } else {
          this.isFinish = true;
          this.isTendorDisable = false;
        }
        this.isShowPSP = false;
        this.isSRPShow = false;
        this.isDiscountShow = false;
        this.isMoneyShow = true;
        this.isTxPrinting = false;
        this.isTransCancil = true;
        this.isProcessCancel = false;
      }
      this.showHideBtnCancelProcess();
    }
  }



  NormalExchange(flag?: number) {
    debugger;
    this.selectTenderFlag = true;
    let tenderName = ''
    if (this.selectedTender == undefined) {
      this.selectedType = this.TendorType;
      this.selectedTenderId = this.TenderListForExchange[0].tenderId;
      this.roomNumber = this.TenderListForExchange[0].roomNum;
      tenderName = this.TenderListForExchange[0].tenderTypeName;
      this.ExchangeTenderType = this.TendorType
    }
    else if (this.selectedTender.tenderType != this.TendorType || this.selectedTender.tenderType == this.TendorType) {
      this.selectedType = this.selectedTender.tenderType;
      this.selectedTenderId = this.selectedTender.tenderId;
      tenderName = this.selectedTender.tenderType;
      this.ExchangeTenderType = this.TendorType
    }

    this.amountTendered = (this.amountTendered === 0 ? this.cartValues.BalanceDue : this.amountTendered);
    let balanceDue = this.cartValues.BalanceDue - parseFloat(this.amountTendered);
    this.isFinish = true;
    if (this.selectedType === "Credit Card") {
      this.selectedCCTenderId = this.selectedTenderId;
      this.selectedCCAmount = this.amountTendered;
      if (balanceDue <= 0) {
        this.isFinish = false;
      }
      this.isCCPrinting = true;
      this.isBackDisable = false;
      this.isProcessCancel = true;
    }
    else if (this.selectedType === "Cash") {
      this.selectedCashTenderId = this.selectedTenderId;
      let calculateAmt = this.totalCardCalc;
      this.CalculateTotalCashAmount = parseFloat(this.CalculateTotalCashAmount) + parseFloat(this.amountTendered);
      // this.changeDue = this.cartValues.TxTotal - (+this.CalculateTotalCashAmount + +this.CalculateTotalRoomAmount + +this.CalculateTotalMoneyCertAmount + calculateAmt);
      this.changeDue = this.cartValues.TxTotal - (+this.CalculateTotalCashAmount + +this.CalculateTotalRoomAmount + +this.CalculateTotalMoneyCertAmount + calculateAmt);
      this.changeDue=this.changeDue+this.creditAmont;
      this.changeDue = Math.abs(this.changeDue);
      this.amountTendered = 0;
      this.cartValues.BalanceDue = balanceDue;
      if (this.cartValues.BalanceDue <= 0) {
        this.isFinish = false;
        this.isTendorDisable = true;
      } else {
        this.isFinish = true;
        this.isTendorDisable = false;
      }
      this.isShowPSP = false;
      this.isSRPShow = false;
      this.isDiscountShow = false;
      this.isMoneyShow = true;
      this.isTxPrinting = false;
      this.isTransCancil = true;
      this.isProcessCancel = false;
    }

    else if (this.selectedType === "Room Charge") {
      this.isshowRoomModal = true;
      setTimeout(() => { // this will make the execution after the above boolean has changed
        $("#roomChargeId").focus();
      }, 500);

    }
    else if (this.selectedType === "Money Cert") {

      let calculateAmt = this.totalCardCalc;
      let moneyCertAmt = parseFloat(this.CalculateTotalMoneyCertAmount) + parseFloat(this.amountTendered);

      if (+moneyCertAmt > 0) {
        let mod = +moneyCertAmt % 1;
        if (+this.amountTendered > this.cartValues.BalanceDue) {
          this.toastr.error(`Cannot accept a money certificate(s) for more than the amount due.
          The total face value of the certificate(s) must be less than or equal to the amount due.`);
          this.amountTendered = 0;
          return;
        }
        else if (mod != 0) {
          this.toastr.error(`The amount tendered of ${moneyCertAmt} is invalid. Money certificates must be a whole dollar amount.`);
          this.amountTendered = 0;
          return;
        }
      }

      let objMoneyCert = { tenderId: this.selectedTenderId, moneyCertName: tenderName, tenderAmount: +this.amountTendered };
      this.upsert(this.CalculateTotalMoneyCertAmounts, objMoneyCert);
      this.CalculateTotalMoneyCertAmount = +moneyCertAmt;
      this.changeDue = this.cartValues.TxTotal - (+this.CalculateTotalCashAmount + +this.CalculateTotalRoomAmount + +this.CalculateTotalMoneyCertAmount + calculateAmt);
      this.changeDue=this.changeDue+this.creditAmont;
      this.changeDue = Math.abs(this.changeDue);
      this.amountTendered = 0;
      this.cartValues.BalanceDue = balanceDue;
      if (this.cartValues.BalanceDue <= 0) {
        this.isFinish = false;
        this.isTendorDisable = true;
      } else {
        this.isFinish = true;
        this.isTendorDisable = false;
      }
      this.isShowPSP = false;
      this.isSRPShow = false;
      this.isDiscountShow = false;
      this.isMoneyShow = true;
      this.isTxPrinting = false;
      this.isTransCancil = true;
      this.isProcessCancel = false;
    }
    this.showHideBtnCancelProcess();

  }





  ExchangeRoomCharge() {

  }

  upsert(array, element) {
    const i = array.findIndex(_element => _element.tenderId === element.tenderId);
    if (i > -1) {
      array[i].tenderName = element.tenderName;
      array[i].tenderAmount = array[i].tenderAmount + element.tenderAmount;
    }
    else array.push(element);
  }



  saveCreditCardPayment() {
    this.FinishTrasaction();
    this.isCCPrinting = false;
    this.isMoneyShow = true;
    let paymentHestory = {
      TenderType: "Credit Card",
      TenderAmount: this.amountTendered
    }
    this.SplitTransaction.push(paymentHestory);
  }

  pay() {
    debugger;
    if (this.SelectedProducts.length === 0) {
      Swal.fire({
        icon: 'warning',
        title: 'Warning!',
        text: 'Please add products to cart.',
      })
    //   this.confirmationService.confirm({
    //     message:  'Please add products to cart.',
    //     header: 'Confirmation',
    //     rejectVisible: false,
    //     acceptLabel: 'Ok',
    //     icon: 'pi pi-exclamation-triangle',
    //     accept: () => {
    //      // location.reload();
    //     },
    //     reject: () => {
    //     }
    // })
    }
    else if (this.cartValues.BalanceDue < 0) {
      this.toastr.error("Cannot finalize the transaction with a negative amount.")
    }
    else {
      if (this.cartValues.BalanceDue > 0) {
        this.isFinish = true;
      }
      else {
        this.isFinish = false;
        this.isTendorDisable = true;
      }
      this.GetTenders();
      this.isShowPSP = false;
      this.isSRPShow = false;
      this.isDiscountShow = false;
      this.isMoneyShow = true;
      this.isTxPrinting = false;
      this.isTransCancil = true;
      this.isProcessCancel = false;
      setTimeout(() => { // this will make the execution after the above boolean has changed
        $("#AmountItem").focus();
      }, 500);

      // this.types.forEach(childObj => {
      //   if (childObj != "Cash") {
      //     this.tenderFlg = true;
      //   }
      // });

    }
  }

  PopulateAmount(value, flag?) {
    debugger;

    let calculateAmt = this.totalCardCalc;
    if (value === 'x') {

      let exchangeProducts = this.SelectedProducts.filter(function (element, index) {
        return (element.type === 'Exchange');
      })

      if(exchangeProducts.length>0)
      {
        this.roomNumber = '';
        this.isTendorDisable = false;
        this.isFinish = true;
        this.amountTendered = 0;
        this.change = 0;
        this.changeDue = 0
        this.cartValues.BalanceDue = this.cartValues.TxTotal + this.creditAmont;
        this.CalculateTotalCashAmount = 0;
        this.CalculateTotalRoomAmount = 0;
        this.CalculateTotalMoneyCertAmount = 0;
        this.CalculateTotalMoneyCertAmounts.length = 0;
      }
      else{
      this.roomNumber = '';
      this.isTendorDisable = false;
      this.isFinish = true;
      this.amountTendered = 0;
      this.change = 0;
      this.changeDue = 0
      this.cartValues.BalanceDue = this.cartValues.TxTotal;
      this.CalculateTotalCashAmount = 0;
      this.CalculateTotalRoomAmount = 0;
      this.CalculateTotalMoneyCertAmount = 0;
      this.CalculateTotalMoneyCertAmounts.length = 0;
      this.totalCardCalc=0;
      this.isShowCredit=false;
      }
    } else if (value === 'C') {
      this.amountTendered = 0;
      this.change = 0;
    }

    else if (value == 'B') {
      this.isTendorDisable = false;
      this.roomNumber = '';
      this.isFinish = true;
      this.amountTendered = 0;
      this.change = 0;
      this.changeDue = 0
      this.totalCardCalc=0;
      this.isShowCredit=false;
      let valu = this.cartValues.TxTotal + this.creditAmont;
      let refundProducts = this.SelectedProducts.filter(function (element, index) {
      return (element.type == 'Exchange');
      });
      // if (refundProducts.length == 0 || valu > 0) {
      //   this.cartValues.BalanceDue = valu;
  
      // }
      if (refundProducts.length > 0) {
        this.cartValues.BalanceDue = valu;
       }
      else{
        this.cartValues.BalanceDue = this.cartValues.TxTotal;
      }
      this.CalculateTotalCashAmount = 0;
      this.CalculateTotalRoomAmount = 0;
      this.CalculateTotalMoneyCertAmount = 0;
      this.CalculateTotalMoneyCertAmounts.length = 0;
      this.isMoneyShow = false;
      this.isShowPSP = true;
      this.isTransCancil = false;
      this.isTendorDisable = false;
      this.isSRPShow = true;
      this.isDiscountShow = true;
    }
    else if (value === 100 && this.tenderFlg == false) {
      this.selectedType = 'Cash';
      this.selectedTenderId = this.TenderId;
      const tenderName = 'Cash';
     
      this.cartValues.BalanceDue = this.cartValues.BalanceDue - 100;
      this.CalculateTotalCashAmount = ((this.CalculateTotalCashAmount || 0) + 100);
      this.changeDue = this.cartValues.TxTotal - (+this.CalculateTotalCashAmount + +this.CalculateTotalRoomAmount + +this.CalculateTotalMoneyCertAmount + calculateAmt);
      this.changeDue = Math.abs(this.changeDue);
      if (this.cartValues.BalanceDue <= 0) {
        this.isFinish = false;
        this.isTendorDisable = true;
        this.cartValues.BalanceDue = 0;
      } else {
        this.isFinish = true;
        this.isTendorDisable = false;
      }
      this.isShowPSP = false;
      this.isSRPShow = false;
      this.isDiscountShow = false;
      this.isMoneyShow = true;
      this.isTxPrinting = false;
      this.isTransCancil = true;
    }
    else if (value === 50 && this.tenderFlg == false) {
      this.selectedType = 'Cash';
      this.selectedTenderId = this.TenderId;
      const tenderName = 'Cash';
      this.cartValues.BalanceDue = this.cartValues.BalanceDue - 50;
      this.CalculateTotalCashAmount = ((this.CalculateTotalCashAmount || 0)) + 50;
      this.changeDue = this.cartValues.TxTotal - (+this.CalculateTotalCashAmount + +this.CalculateTotalRoomAmount + +this.CalculateTotalMoneyCertAmount + calculateAmt);; //+ parseFloat(this.CalculateTotalCashAmount + calculateAmt || 0);
      this.changeDue = Math.abs(this.changeDue);
      if (this.cartValues.BalanceDue <= 0) {
        this.isFinish = false;
        this.isTendorDisable = true;
        this.cartValues.BalanceDue = 0;
      } else {
        this.isFinish = true;
        this.isTendorDisable = false;
      }
      this.isShowPSP = false;
      this.isSRPShow = false;
      this.isDiscountShow = false;
      this.isMoneyShow = true;
      this.isTxPrinting = false;
      this.isTransCancil = true;
    }
    else if (value === 20 && this.tenderFlg == false) {
      this.selectedType = 'Cash';
      this.selectedTenderId = this.TenderId;
      const tenderName = 'Cash';
      this.cartValues.BalanceDue = this.cartValues.BalanceDue - 20;
      this.CalculateTotalCashAmount = ((this.CalculateTotalCashAmount || 0) + 20);
      this.changeDue = this.cartValues.TxTotal - (+this.CalculateTotalCashAmount + +this.CalculateTotalRoomAmount + +this.CalculateTotalMoneyCertAmount + calculateAmt);// + parseFloat(this.CalculateTotalCashAmount + calculateAmt || 0);
      this.changeDue = Math.abs(this.changeDue);
      if (this.cartValues.BalanceDue <= 0) {
        this.isFinish = false;
        this.isTendorDisable = true;
        this.cartValues.BalanceDue = 0;
      } else {
        this.isFinish = true;
        this.isTendorDisable = false;
      }
      this.isShowPSP = false;
      this.isSRPShow = false;
      this.isDiscountShow = false;
      this.isMoneyShow = true;
      this.isTxPrinting = false;
      this.isTransCancil = true;
    }
    else if (value > 9 && !flag) {
      this.amountTendered = parseFloat(this.amountTendered) + parseFloat(value);
      this.change = parseFloat(this.cartValues.BalanceDue) < parseFloat(this.amountTendered) ? parseFloat(this.cartValues.BalanceDue) - parseFloat(this.amountTendered) : 0.00;
    }
    else if (value === '<') {
      const intNum = this.amountTendered.toString().split('.');
      let amount = 0
      if (intNum.length === 1) {
        amount = intNum[0];
        amount = Number(amount.toString().slice(0, amount.toString().length - 1));
      }
      if (intNum.length === 2) {
        if (intNum[1] === "") {
          amount = Number(amount.toString().slice(0, amount.toString().length - 2));
        }
        else {
          let position = intNum[0].length;
          const lent = position - 1;
          intNum[1] = intNum[1].substring(0, intNum[1].length - 1);
          if (intNum[1].length == 1) {

            // intNum[0]=[intNum[0].slice(0, lent), '.', intNum[0].slice(position)].join('');
            const next: string = [intNum[0].slice(0, lent), '.', intNum[0].slice(lent)].join('');

            this.amountTendered = next + intNum[1];
          }

          //amount = Number(this.amountTendered.toString().slice(0, this.amountTendered.toString().length - 1));
        }
      }
      //this.amountTendered = amount;

    }
    else {
      let record;
      if (!flag) {
        if (this.amountTendered == 0) {
          this.amountTendered = value / 100;
          this.amountTendered = this.amountTendered.toString();
          this.change = parseFloat(this.cartValues.BalanceDue) < parseFloat(this.amountTendered) ? parseFloat(this.cartValues.BalanceDue) - parseFloat(this.amountTendered) : 0.00;
        }
        else {
          if(value!=0)
          {
          this.amountTendered = this.amountTendered + value.toString();
          this.amountTendered=this.amountTendered.replace(/[^\w\s]/gi, "");
          this.amountTendered=this.amountTendered/100;
          this.amountTendered=parseFloat(this.amountTendered);
          this.change=parseFloat(this.cartValues.BalanceDue) < parseFloat(this.amountTendered) ? parseFloat(this.cartValues.BalanceDue) - parseFloat(this.amountTendered) : 0.00;
          }
          else{
            //this.amountTendered = this.amountTendered + value.toString(); 
            let mainpart=this.amountTendered.toString();
            let amt = mainpart.split('.');
            mainpart=mainpart+value.toString(); 
            this.amountTendered=mainpart.replace(/[^\w\s]/gi, "");
            this.amountTendered=this.amountTendered/100;
            let checkDecimal=this.amountTendered.toString().split('.');
            if(checkDecimal.length==1)
            {
              this.amountTendered=this.amountTendered.toString()+".00";
            }
            else{

              this.amountTendered=this.amountTendered.toString()+"0";
            }

            // if(amt[0].length==1)
            // {
            //   // this.amountTendered = this.amountTendered * 10; 
            //   // this.amountTendered = this.amountTendered + value.toString(); 
            //   mainpart=mainpart+value.toString(); 
            //   this.amountTendered=mainpart.replace(/[^\w\s]/gi, "");
            //   this.amountTendered=this.amountTendered/100;
            //   this.amountTendered=this.amountTendered.toString()+"0";
            // }
            // else{
            //   mainpart=mainpart+value.toString(); 
            //   this.amountTendered=mainpart.replace(/[^\w\s]/gi, "");
            //   this.amountTendered=this.amountTendered/100;
            //   let checkDecimal=this.amountTendered.toString().split('.');
            //   if(checkDecimal.length==1)
            //   {
            //     this.amountTendered=this.amountTendered.toString()+".00";
            //   }
            //   else{

            //     this.amountTendered=this.amountTendered.toString()+"0";
            //   }
            // }
            //this.amountTendered=unit1+amt[1];

            //this.amountTendered = this.amountTendered * 10;   

            
            
          }
        //   let amt = this.amountTendered.split('.');
        //   if (amt[1] >= "100") {
        //     record = amt[1].replace("", "");
        //   }
        //   else {
        //     record = amt[1].replace("0", "");
        //   }
        //   this.amountTendered = record / 100;
        //   if (value == 0) {

        //     if (this.amountTendered >= 1) {
        //       this.amountTendered = this.amountTendered.toString() + ".00";
        //     }
        //     else if (amt[1] == "000") {
        //       record = amt[1].replace("0", "");
        //       this.amountTendered = amt[0] + this.amountTendered + "." + record;
        //     }
        //     else {
        //       this.amountTendered = this.amountTendered.toString() + "0";
        //     }
        //   }
        //   else if (amt[0] == "0") {

        //     this.amountTendered = this.amountTendered.toString();
        //   }
        //   else {
        //     this.amountTendered = amt[0] + this.amountTendered.toString();
        //   }
        //   this.amountTendered = this.amountTendered.toString();
        //   this.change = parseFloat(this.cartValues.BalanceDue) < parseFloat(this.amountTendered) ? parseFloat(this.cartValues.BalanceDue) - parseFloat(this.amountTendered) : 0.00;
         }

      }
    }
  }

  PopulateDisAmount(value) {
    debugger;
    if (!this.discountFlag) {
      if (value === 'x') {
        this.discountAmt = 0.00;
      }
      else if (value === '.') {
        if (String(this.discountAmt).includes('.')) {
          return false;
        }
        else {
          this.discountAmt = '' + this.discountAmt + value;
          return false;
        }
      }
    }
    else {
      if (value === 'x') {
        this.discountAmt = 0.00;
      }
      else if (value === '.') {
        if (String(this.discountAmt).includes('.')) {
          return false;
        }
        else {
          this.discountAmt = '' + this.discountAmt + value;
          return false;
        }
      }
      else{
        this.discountAmt = '' + this.discountAmt + value;
        return true;
      }
    }
  }



  CheckCheractor(event) {
    debugger;
    let diskey = event.key;
    let Discountvalue = Number(diskey);
    if (!this.discountFlag) {
      this.discountAmt = '';
      if (diskey == "Backspace") {
        //this.PopulateDisAmount('>');
      }
      else if (diskey == "ArrowLeft" || diskey == "ArrowRight") {

      } else if (diskey == ".") {
        this.PopulateDisAmount(diskey);
      }
      else {
        //this.PopulateDisAmount(Discountvalue);
        this.discountAmt.replace(/[#_ : QWERTYUIOPASDFGHJKLZXCVBNMqwertyuiopasdfghjklzxcvbnm , /]/g, '');
        if ((event.keyCode >= 97 || event.keyCode >= 122) || (event.keyCode >= 65 || event.keyCode >= 90)) {
          this.discountAmt = '' + this.discountAmt + Discountvalue;
          this.discountFlag = true;
          event.preventDefault();
          return;
        }
        else {
          this.discountFlag = true;
        }

      }
    }
    else {
      //this.discountAmt.replace(/[#_ : QWERTYUIOPASDFGHJKLZXCVBNMqwertyuiopasdfghjklzxcvbnm , /]/g, '');
      if (diskey == "Backspace") {
        //this.PopulateDisAmount('>');
      }
      else if (diskey == "ArrowLeft" || diskey == "ArrowRight") {

      } else if (diskey == ".") {
        this.PopulateDisAmount(diskey);
      }
      else {
        //this.PopulateDisAmount(Discountvalue);
        this.discountAmt.replace(/[#_ : QWERTYUIOPASDFGHJKLZXCVBNMqwertyuiopasdfghjklzxcvbnm , /]/g, '');
        if ((event.keyCode >= 97 || event.keyCode >= 122) || (event.keyCode >= 65 || event.keyCode >= 90)) {
          this.discountFlag = true;
          event.preventDefault();
          return;
        }
        else {
          this.discountFlag = true;
        }
      }

    }
    //this.discountAmt;

  }

  // PopulateTourAmount(value) {
  //   debugger;
  //   if (value === 'x') {
  //     this.tourAmt = 0;
  //   } else {

  //     this.tourAmt = "" + this.tourAmt + value;
  //   }
  // }
  AssignSelectType() {
    this.selectedType = 'CashAndCreditCard';
  }
  DepositPopUp() {
    debugger;
    this.isTourDepo = true;
    if (this.customerName === '') {
      Swal.fire({
        icon: 'error',
        title: 'Customer Required!',
      })
    } else {
      Swal.mixin({
        input: 'text',
        confirmButtonText: 'Next &rarr;',
        showCancelButton: true,
      }).queue([
        'Enter Tour ID',
        'Enter Amount',
      ]).then((result) => {
        let swalValues: any = result;
        if (swalValues) {
          let tourObj = {
            plucode: null,
            fastButtonName: "Tour Deposit " + swalValues.value[0],
            qty: 1,
            srp: parseInt(swalValues.value[1]),
            price: parseInt(swalValues.value[1]),
            discount: 0,
            wholesaleCost: parseInt(swalValues.value[1]),
          };
          this.Populate(tourObj);
          let tourdepo = {
            TourId: swalValues.value[0],
            TourAmount: parseInt(swalValues.value[1])
          }
          this._posSerive.TourDeposit(tourdepo).subscribe((res) => {
            this.tourId = parseInt(res.result)
          });
        }
      });
    }
  }

  // PrintQuote() {
  //   debugger;
  //   let setthis = this;
  //   let printQuote = {
  //     WorkstationID: this.workstation.workStationId,
  //     WSIDTxID: this.TxNum,
  //     UserID: this.currentUser.userId,
  //     GuestName: this.customerName,
  //     TotalPrice: this.cartValues.BalanceDue,
  //     ShiftNum: parseInt(this.workstation.lastShiftNum),
  //     prodcutsLists: this.GetSelectedProducts()
  //   }
  //   if (printQuote != null) {
  //     this._posSerive.SavePrintQuote(printQuote).subscribe((res) => {
  //       //let midResponse = this.middlewareService.ShiftOpenReport(res, 'PrintQuote');
  //       midResponse = PortExeMsgHelper.GetPortStatusMessage(midResponse);
  //       if (midResponse.includes('successful')) {
  //         Swal.fire({
  //           icon: 'success',
  //           title: 'Success!',
  //           text: midResponse,
  //         }).then(function () {
  //         });
  //       } else if (midResponse.includes('not Connected')) {
  //         Swal.fire({
  //           icon: 'warning',
  //           title: '',
  //           text: midResponse,
  //         }).then(function () {
  //         });
  //       }
  //     });
  //   }
  // }

  FinishPayment() {
    debugger;
    this.isMoneyShow = true;
    this.isPrinting = true;
    this.isPrintCopy = true;
    //this.PrintReport();
  }

  ShowLoader() {
    this.isPrinting = true;
    this.isPrintCopy = true;
    setTimeout(() => {
     // this.PrintReport();
    }, 1000);
  }

  // PrintReport() {
  //   debugger;
  //   let setthis = this;
  //   let midResponse = this.middlewareService.CompleteTransaction(this.txandPrintId, this.selectedType, (this.amountTendered === 0 ? this.cartValues.BalanceDue : this.amountTendered));
  //   midResponse = PortExeMsgHelper.GetPortStatusMessage(midResponse);
  //   if (midResponse.includes('not Connected')) {
  //     Swal.fire({
  //       icon: 'warning',
  //       title: 'Warning!',
  //       text: 'Printer is not connected',
  //     }).then(function () {
  //       location.reload();
  //     });

  //   }
  //   else if (midResponse.includes('success')) {
  //     this.isPrinting = true;
  //     this.isPrintCopy = true;
  //     Swal.fire({
  //       icon: 'success',
  //       title: 'Success!',
  //       text: midResponse,
  //     }).then(function () {
  //       location.reload();
  //     });
  //   }
  //   else {
  //     this.isPrinting = true;
  //     this.isPrintCopy = true;
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'Error!',
  //       text: 'Error while Printing!',
  //     }).then(function () {
  //       location.reload();
  //     });
  //   }

  //   this.ShowHideLoader();
  // }

  ShowHideLoader() {
    setTimeout(() => {
      this.isPrinting = false;
      this.isPrintCopy = false;
    }, 500);
  }

  FinishTrasaction() {
    this.isTxPrinting = true;
    if (this.selectedType == 'Cash') {
      this.isTxPrinting = true;
      this.isCCPrinting = false;
    } else {
      this.isCCPrinting = true;
      this.isTxPrinting = false;
    }
    this.nav.isdisabledNav = false;
    localStorage.setItem("isdisabledNavBar", 'false');
    debugger;
    let setthis = this;
    debugger;
    if (this.isSlpitTX && this.cartValues.BalanceDue <= 0) {
      //let midResponse = this.middlewareService.CompleteTransaction(this.txandPrintId, this.selectedType, (this.amountTendered === 0 ? this.cartValues.BalanceDue : this.amountTendered));
     
      if (true) {
        this.isCCPrinting = false;
      }

    } else {
      if (this.isExhange) {
        this.isCCPrinting = false;
        this.CompleteRefund();

      }
      else {
        this.CompleteSale();
      }
    }
  }

  // ShowMessages(midResponse) {
  //   let setthis = this;
  //   setTimeout(() => {
  //     if (midResponse.includes('successful')) {
  //       Swal.fire({
  //         icon: 'success',
  //         title: 'Success!',
  //         text: midResponse
  //       }).then(function () {
  //         setthis.router.navigate(['../DashBoard'], { relativeTo: setthis.route });
  //       });
  //     } else if (midResponse.includes('not Connected')) {
  //       Swal.fire({
  //         icon: 'warning',
  //         title: 'Warning!',
  //         text: midResponse,
  //       }).then(function () {
  //         setthis.router.navigate(['../DashBoard'], { relativeTo: setthis.route });
  //       });
  //     } else if (midResponse.includes('not Connected')) {
  //       Swal.fire({
  //         icon: 'warning',
  //         title: '',
  //         text: midResponse,
  //       }).then(function () {
  //         setthis.router.navigate(['../DashBoard'], { relativeTo: setthis.route });
  //       });
  //     }
  //     else {
  //       Swal.fire({
  //         icon: 'error',
  //         title: 'Error!',
  //         text: midResponse,
  //       }).then(function () {
  //         setthis.isShowPSP = false;
  //         setthis.isTxPrinting = false;
  //         setthis.isMoneyShow = true;
  //         setthis.isCCPrinting = false;
  //       });
  //     }
  //   }, 500);
  // }

  CancelTran() {
    debugger;
    if (this.SelectedProducts.length > 0) {
      const messages = `<p class='worstation-img'><img src="./assets/Libs/images/confirmation.png" /></p><h3 class="pop-head">Warning!</h3><p class="assignstyle">Are you sure that you would like to cancel this transaction?</p>`
      this.confirmationService.confirm({
        message: messages,
        header: 'Confirmation',
        rejectVisible: true,
        acceptLabel: 'Continue',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          location.reload();
        },
        reject: () => {
        }
      });

      // Swal.fire({
      //   icon: 'warning',
      //   title: 'Warning!',
      //   text: 'Are you sure that you would like to cancel this transaction?',
      //   showCancelButton: true,
      //   confirmButtonColor: '#3085d6',
      //   cancelButtonColor: '#d33',
      //   cancelButtonText: 'NO',
      //   confirmButtonText: 'YES'
      // }).then((result) => {
      //   if (result.value) {
      //     location.reload();
      //   }
      // })
    } 
    else 
    {
      location.reload();
    }
  }




  GetSelectedProducts() {
    debugger;
    let saleProducts = this.SelectedProducts.filter(function (element, index) {
      return (element.type === 'Sale');
    });
    let ProdList = [];
    for (let i = 0; i < saleProducts.length; i++) {
      let prodObj = {
        ProductId: saleProducts[i].plucode === null || saleProducts[i].plucode === undefined ? saleProducts[i].productId : saleProducts[i].plucode,
        ProductName: saleProducts[i].fastButtonName,
        SRP: saleProducts[i].srp,
        Qty: parseInt(saleProducts[i].qty),
        Cost: saleProducts[i].cost,
        SoldFor: saleProducts[i].price,
        Discount: parseFloat(saleProducts[i].discount || 0.0),
        WholesaleCost: parseFloat(saleProducts[i].wholesaleCost || 0.0),
        TourId: this.tourId === 0 ? null : this.tourId,
        FastButtonId: saleProducts[i].fastButtonId,
      }
      ProdList.push(prodObj);
    }
    return ProdList;
  }


  CompleteSale() {
    debugger;
    let setthis = this;
    let transactionInfo = {
      WorkstationID: this.workstation.workStationId,
      WSIDTxID: this.TxNum,
      UserID: this.currentUser.userId,
      GuestName: this.customerName,
      CustId: this.CustId,
      Amount: parseFloat(this.amountTendered),
      TotalPrice: this.cartValues.BalanceDue,
      ShiftNum: parseInt(this.workstation.lastShiftNum) + 1,
      TendorType: this.selectedType,
      prodcutsLists: this.GetSelectedProducts()
    }
    if (transactionInfo != null) {
      this.isMoneyShow = false;
      this._posSerive.SaveTransaction(transactionInfo).subscribe((res) => {
        debugger;
        this.txandPrintId = res;
        if (this.isTourDepo) {
          // //let midResponse = this.middlewareService.CompleteTransaction(res, this.selectedType, (this.cartValues.BalanceDue));
          // //midResponse = PortExeMsgHelper.GetPortStatusMessage(midResponse);
          // debugger;
          // if (midResponse.includes('not Connected')) {
          //   Swal.fire({
          //     icon: 'warning',
          //     title: 'warning!',
          //     text: midResponse,
          //   }).then(function () {
          //     setthis.isTourDepo = false;
          //     setthis.router.navigate(['../DashBoard'], { relativeTo: setthis.route });
          //   });
          // } else {
          //   Swal.fire({
          //     icon: 'success',
          //     title: '',
          //     text: 'Tour Deposit Completed',
          //   }).then(function () {
          //     setthis.isTourDepo = false;
          //     setthis.router.navigate(['../DashBoard'], { relativeTo: setthis.route });
          //   });
          // }
        }
        else {
          if (res != null) {
            this.txandPrintId = res;
            let midResponse: any;
            if (parseFloat(this.amountTendered) > 0) {
              if (this.cartValues.BalanceDue > 0) {
                this.isTendorDisable = false;
                // midResponse = this.middlewareService.CompleteTransaction(res, this.selectedType, (this.amountTendered));
                // midResponse = PortExeMsgHelper.GetPortStatusMessage(midResponse);
                this.isShowPreviousPaidAmountCash = false;
                this.isShowPreviousPaidAmountCreditCard = false;
                this.isSlpitTX = true;
                this.isMoneyShow = true;
                this.isTxPrinting = false;
                this.cartValues.BalanceDue = this.cartValues.BalanceDue - parseFloat(this.amountTendered);
                this.amountTendered = 0;
                this.change = 0;
              }
              else {
                if (this.selectedType == "CreditCard") {
                  // midResponse = this.middlewareService.CompleteTransaction(res, this.selectedType, (this.cartValues.BalanceDue));
                  // midResponse = PortExeMsgHelper.GetPortStatusMessage(midResponse);
                  setthis.isFinish = false;
                  if (midResponse.includes('successful')) {
                    Swal.fire({
                      icon: 'success',
                      title: 'Success!',
                      text: midResponse
                    }).then(function () {
                      setthis.isCCPrinting = false;
                      setthis.isShowPSP = false;
                      setthis.isSRPShow = false;
                      setthis.isMoneyShow = true;
                      setthis.isTxPrinting = false;
                      setthis.isTransCancil = true;
                    });
                  } else if (midResponse.includes('not Connected')) {
                    Swal.fire({
                      icon: 'warning',
                      title: 'Warning!',
                      text: midResponse,
                    }).then(function () {
                      setthis.router.navigate(['../DashBoard'], { relativeTo: setthis.route });
                    });
                  } else if (midResponse.includes('not Connected')) {
                    Swal.fire({
                      icon: 'warning',
                      title: '',
                      text: midResponse,
                    }).then(function () {
                      setthis.router.navigate(['../DashBoard'], { relativeTo: setthis.route });
                    });
                  }
                  else {
                    Swal.fire({
                      icon: 'error',
                      title: 'Error!',
                      text: midResponse,
                    }).then(function () {
                      setthis.isShowPSP = false;
                      setthis.isTxPrinting = false;
                      setthis.isMoneyShow = true;
                    });
                  }
                }
                else {
                  Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'Transaction Completed Successfully!'
                  }).then(function () {
                    setthis.isFinish = false;
                  });
                }
              }
            }
          }
        }
      });
    }
  }

  showHideBtnCancelProcess() {
    if (this.totalCardCalc > 0) {
      this.isProcessCancel = true;
    }
    else {
      this.isProcessCancel = false;
    }
  }
  setCreditCardSuccess() {
    let balanceDue = this.cartValues.BalanceDue - parseFloat('' + this.selectedCCAmount);
    this.cartValues.BalanceDue = balanceDue;

    this.totalCardCalc = parseFloat('' + this.totalCardCalc) + parseFloat('' + this.selectedCCAmount);
    this.changeDue = this.cartValues.TxTotal - (+this.CalculateTotalCashAmount + +this.CalculateTotalRoomAmount + +this.CalculateTotalMoneyCertAmount + this.totalCardCalc);
    this.changeDue = Math.abs(this.changeDue);
    this.amountTendered = 0;
  }
  cancelCCPayment() {
    debugger;
    this.isShowPSP = false;
    this.isSRPShow = false;
    this.isDiscountShow = false;
    this.isCCPrinting = false;
    this.isMoneyShow = true;
    this.isTxPrinting = false;
    this.isTransCancil = true;
    this.isTendorDisable = false;
    this.selectedCCAmount = 0;
    this.amountTendered = 0;
    this.showHideBtnCancelProcess();
    if (this.cartValues.BalanceDue <= 0) {
      this.isFinish = false;
      this.isTendorDisable = true;
    }
    else {
      this.isFinish = true;
      this.isTendorDisable = false;
    }
  }

  CompleteTransactionCCpayment() {
    debugger;
    this.isProcessCancel = true;
    var MTxId = this.workstation.workStationId + this.TxNum + ',' + '0';
    this.selectedType = this.selectedType.replace(' ', '');
    // let midResponse = this.middlewareService.CompleteTransaction(MTxId, this.selectedType, (+this.selectedCCAmount));
    // midResponse = PortExeMsgHelper.GetPortStatusMessage(midResponse);
    if (true) {
      debugger;
      this.amountTendered = 0;
      this.isCCPrinting = false;
      this.setCreditCardSuccess();
      this.showHideBtnCancelProcess();
      if (this.changeDue <= 0) {
        this.FinishTx();
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        //text: midResponse,
      }).then(function () {
        this.cartValues.BalanceDue = this.cartValues.BalanceDue + this.amountTendered,
          this.amountTendered = 0;
        this.isCCPrinting = false;
      });
    }

  }

  OpenTrantType(tratype: number) {
    Swal.fire({
      text: 'You do not have permission to perform this function. Would you like to enter alternate credentials?​',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: '<i class="fa fa-check"></i>Yes',
      cancelButtonText: '<i class="fa-thin fa-xmark"></i>No',
    }).then((result) => {
      if (result.value) {
        debugger;
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        debugger;
      }
    });
  }

  UpdateLasttxId(Flag: boolean) {
    debugger
    this._commonService.UpdateLastTXID(this.workstation.workStationId, this.workstation.lastTxId, Flag)
      .subscribe((res) => {
        //this.TxNum = res + 1;
        this.TxNum = res;
      });
  }
  FinishTx() {
    debugger;
    this.ShowModalPopup = false;
    //this.Trantype = JSON.parse(localStorage.getItem("Transtype"));
    if (this.isExhange) {
      this.ExchangeProducts()
    }
    else {
      this.NormalTransaction();
    }
  }


  ExchangeProducts() {
    if (this.cartValues.BalanceDue == 0) {
      debugger;
      let calculateAmt = this.SplitTransaction.reduce((accumulator, current) => accumulator + current.TenderAmount, 0);
      this.changeDue = this.changeDue || 0
      if (!this.selectTenderFlag) {
        this.NormalExchange();
        this.ExchangeProductTx();
      }
      else {
        this.ExchangeProductTx();
      }

     // this.NormalExchange();
     // this.ExchangeProductTx();
    }
    else {
      this.toastr.error("Cannot finalize transaction with a negative amount.")
    }
  }

  ExchangeProductTx() {
    debugger;
   // this.router.navigate(['../SalesHistory'], { relativeTo: this.route });
    let setthis = this;
    //this.Trantype = JSON.parse(localStorage.getItem("Transtype"));
    let prodcutsLists = this.SelectedProducts.filter(function (element, index) {
      return (element.type == 'Exchange');
    });

    for (let data of this.exchangeProductsDetails) {
      prodcutsLists.push(data);
    }


    let ExchnageProduct = this.GetSelectedProducts()
    for (let data of ExchnageProduct) {
      prodcutsLists.push(data);
    }
    let totalPrice;
    if (this.CalculateTotalMoneyCertAmounts.length > 0) {
      totalPrice = this.CalculateTotalCashAmount + this.CalculateTotalRoomAmount + this.CalculateTotalMoneyCertAmounts[0].tenderAmount + this.totalCardCalc;
    }
    else {
      totalPrice = this.CalculateTotalCashAmount + this.CalculateTotalRoomAmount + this.totalCardCalc;
    }
    var latestUser= localStorage.getItem("latestUser");
    let transactionInfo={};
     if(latestUser!=null && latestUser!='' && latestUser!="null")
     {
     transactionInfo = {
      WorkstationID: this.workstation.workStationId,
      WSIDTxID: this.TxNum,
      UserID: latestUser,
      GuestName: this.customerName,
      CustId: this.CustId,
      Amount: parseFloat(this.CalculateTotalCashAmount) - this.changeDue,
      TotalPrice: totalPrice,
      ShiftNum: parseInt(this.workstation.lastShiftNum) + 1,
      TendorType: this.selectedType,
      ExchangeTenderType: this.ExchangeTenderType,
      RoomNum: this.roomNumber,
      TransType: this.Trantype,
      TenderId: this.selectedTenderId,
      TotalCCChargeAmount: this.totalCardCalc,
      TotalRoomChargeAmount: this.CalculateTotalRoomAmount,
      TotalCashAmount: this.CalculateTotalCashAmount - this.changeDue,
      CCTenderId: this.selectedCCTenderId,
      RoomTenderId: this.selectedRoomTenderId,
      prodcutsLists: prodcutsLists,
      moneyCertTenders: this.CalculateTotalMoneyCertAmounts,
      TxID: this.TenderListForExchange[0].txId
    }
  }
  else{
    transactionInfo = {
      WorkstationID: this.workstation.workStationId,
      WSIDTxID: this.TxNum,
      UserID: this.currentUser.userId,
      GuestName: this.customerName,
      CustId: this.CustId,
      Amount: parseFloat(this.CalculateTotalCashAmount) - this.changeDue,
      TotalPrice: totalPrice,
      ShiftNum: parseInt(this.workstation.lastShiftNum) + 1,
      TendorType: this.selectedType,
      ExchangeTenderType: this.ExchangeTenderType,
      RoomNum: this.roomNumber,
      TransType: this.Trantype,
      TenderId: this.selectedTenderId,
      TotalCCChargeAmount: this.totalCardCalc,
      TotalRoomChargeAmount: this.CalculateTotalRoomAmount,
      TotalCashAmount: this.CalculateTotalCashAmount - this.changeDue,
      CCTenderId: this.selectedCCTenderId,
      RoomTenderId: this.selectedRoomTenderId,
      prodcutsLists: prodcutsLists,
      moneyCertTenders: this.CalculateTotalMoneyCertAmounts,
      TxID: this.TenderListForExchange[0].txId

  }
}
    if (transactionInfo != null) {
      this.isMoneyShow = true;
      ///
      this._posSerive.SaveTransactionExchange(transactionInfo).subscribe((res) => {
        debugger;
        this.txandPrintId = res;
        if (res != null) {
          this.UpdateLasttxId(true);
        }
        if (this.isTourDepo) {
          this.selectedType = 'FUllAmount' + this.selectedType;
          debugger;
          Swal.fire({
            icon: 'success',
            title: '',
            text: 'Tour Deposit Completed',
          }).then(() => {
            setthis.isTourDepo = false;
            location.reload();
            //this.OpenPrimarytxType();
          });
        }
        else {
          if (res != null) {
            this.txandPrintId = res;
            let midResponse: any;
            this.isTendorDisable = false;
            if (this.CalculateTotalCashAmount > 0 && this.SplitTransaction.length > 0) {
              this.selectedType = 'Split'
            }
            this.selectedType = 'FUllAmount' + this.selectedType;
            Swal.fire({
              icon: 'success',
              title: 'Success!',
              text: 'Transaction successfully completed!'
            }).then(() => {
              if(latestUser!=null && latestUser!='' && latestUser!="null")
                {
                 localStorage.setItem('latestUser','');
                 this.nav.isdisabledNav=false;
                 this.router.navigate(['../SalesHistory'], { relativeTo: this.route });
                }
                else{
              location.reload();
                }
              //this.OpenPrimarytxType();
            });
          }
        }
      });
    }
    //}
    else {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Something went wrong!',
      })
    }
  }

  NormalTransaction() {
    debugger;
    if (this.Trantype === null) {
      this.Trantype = 1;
    }
    //else{
    let calculateAmt = this.SplitTransaction.reduce((accumulator, current) => accumulator + current.TenderAmount, 0);
    this.changeDue = this.changeDue || 0
    let transactionInfo = this.SelectedProducts.filter(function (element, index) {
      return (element.type !== 'Exchange');
    });
    let setthis = this;
    var latestUser= localStorage.getItem("latestUser");
    if(latestUser!=null && latestUser!='' && latestUser!="null")
    {
      transactionInfo = {
        WorkstationID: this.workstation.workStationId,
        WSIDTxID: this.TxNum,
        UserID: latestUser,
        GuestName: this.customerName,
        CustId: this.CustId,
        Amount: parseFloat(this.CalculateTotalCashAmount) - this.changeDue,
        TotalPrice: parseFloat(this.cartValues.TxTotal),
        ShiftNum: parseInt(this.workstation.lastShiftNum) + 1,
        TendorType: this.selectedType,
        TotalCashAmount: this.CalculateTotalCashAmount - this.changeDue,
        TotalCCChargeAmount: this.totalCardCalc,
        TotalRoomChargeAmount: this.CalculateTotalRoomAmount,
        RoomNum: this.roomNumber,
        TenderId: this.selectedTenderId,
        CashTenderId: this.selectedCashTenderId,
        CCTenderId: this.selectedCCTenderId,
        RoomTenderId: this.selectedRoomTenderId,
        prodcutsLists: this.GetSelectedProducts(),
        moneyCertTenders: this.CalculateTotalMoneyCertAmounts,
        TransType: this.Trantype
      }
    }
else
{
    transactionInfo = {
      WorkstationID: this.workstation.workStationId,
      WSIDTxID: this.TxNum,
      UserID: this.currentUser.userId,
      GuestName: this.customerName,
      CustId: this.CustId,
      Amount: parseFloat(this.CalculateTotalCashAmount) - this.changeDue,
      TotalPrice:parseFloat( this.cartValues.TxTotal),
      ShiftNum: parseInt(this.workstation.lastShiftNum) + 1,
      TendorType: this.selectedType,
      TotalCashAmount: this.CalculateTotalCashAmount - this.changeDue,
      TotalCCChargeAmount: this.totalCardCalc,
      TotalRoomChargeAmount: this.CalculateTotalRoomAmount,
      RoomNum: this.roomNumber,
      TenderId: this.selectedTenderId,
      CashTenderId: this.selectedCashTenderId,
      CCTenderId: this.selectedCCTenderId,
      RoomTenderId: this.selectedRoomTenderId,
      prodcutsLists: this.GetSelectedProducts(),
      moneyCertTenders: this.CalculateTotalMoneyCertAmounts,
      TransType: this.Trantype
    }
  }
    if (transactionInfo != null) {
      this.isMoneyShow = true;
      this._posSerive.SaveTransaction(transactionInfo).subscribe((res) => {
        debugger;
        this.txandPrintId = res;
        if (res != null) {
          this.submitted = true;
          this._commonService.UpdateLastTXID(this.workstation.workStationId, this.workstation.lastTxId, this.submitted)
            .subscribe((responce) => {
              this.TxNum = responce;
            });

        }
        
        if (this.isTourDepo) {
          // this.selectedType = this.selectedType.replace(' ', '');
          // //this.selectedType = this.selectedType;
          // // let midResponse = this.middlewareService.CompleteTransaction(res, this.selectedType, (this.cartValues.BalanceDue));
          // // midResponse = PortExeMsgHelper.GetPortStatusMessage(midResponse);
          // debugger;
          // if (midResponse.includes('not Connected')) {
          //   Swal.fire({
          //     icon: 'warning',
          //     title: 'warning!',
          //     text: midResponse,
          //   }).then(() => {
          //     setthis.isTourDepo = false;
          //     //location.reload();
          //     this.OpenPrimarytxType();
          //   });
          // } 
          // else {
          //   Swal.fire({
          //     icon: 'success',
          //     title: '',
          //     text: 'Tour Deposit Completed',
          //   }).then(() => {

          //     setthis.isTourDepo = false;
          //     //location.reload();
          //     this.OpenPrimarytxType();
          //   });
          // }
        }
        else {
          if (res != null) {
            this.txandPrintId = res;
            let midResponse: any;
            this.isTendorDisable = false;
            this.selectedType = this.selectedType.replace(' ', '');
            if (this.CalculateTotalCashAmount > 0 && this.SplitTransaction.length > 0) {
              this.selectedType = 'Split'
            }
            this.selectedType = 'FUllAmount' + this.selectedType;
            // midResponse = this.middlewareService.CompleteTransaction(res, this.selectedType, (this.amountTendered));
            // midResponse = PortExeMsgHelper.GetPortStatusMessage(midResponse);
            // if (midResponse.includes('successful')) {
            //   Swal.fire({
            //     icon: 'success',
            //     title: 'Success!',
            //     text: midResponse
            //   }).then(() => {
            //     debugger;
            //     //location.reload();
            //     this.OpenPrimarytxType();
            //   });
            // } 
            // else if (midResponse.includes('not connected')) {
            //   Swal.fire({
            //     icon: 'warning',
            //     title: 'Warning!',
            //     text: midResponse,
            //   }).then(() => {
            //     debugger;
            //     //location.reload();
            //     this.OpenPrimarytxType();
            //   });
            // }
            // else {
            //   Swal.fire({
            //     icon: 'error',
            //     title: 'Error!',
            //     text: 'Error while printing!',
            //   }).then(() => {
            //     debugger;
            //     this.OpenPrimarytxType();
            //     //location.reload();
            //   });
            // }

            Swal.fire({
                  icon: 'success',
                  title: 'Success!',
                  text: 'Transaction completed successfully.'
                }).then(() => {
                  debugger;
                  if(latestUser!=null && latestUser!='' && latestUser!="null")
                    {
                      this.siteConfi = JSON.parse(localStorage.getItem("siteConfivalidated"));
                     var primarytrans=this.siteConfi.primaryTxType;
                     localStorage.setItem('latestUser','');
                     this.nav.CheckPrimaryTrasactionType(primarytrans);
                    }
                    else{
                  location.reload();
                    }
                  //this.OpenPrimarytxType();
                });
          }
        }
      },
      (error) => {
      //this.ShowMessages('Transaction failed');
      });
    }
    //}
  }



  RedirectToProductCart() {
    debugger;
    this.searchText = "";
    this.isShowProdLink = false;
    this.catagoryName = "";
    this.subCatagoryName = "";
    this.isSubCatShow = false;
    this.isShowProdList = false;
    this.isQuickShow = true;
    this.isvendorShow = true;
    this.iscatShow = true;
  }

  OpenPrimarytxType() {
    debugger;
    this.nav.isdisabledNav = false;
    localStorage.setItem("isdisabledNavBar", 'false');

    if (this.siteConfi.primaryTxType === 1) {
      //this.router.navigate(['../ProductCart'], { relativeTo: this.route });
      location.reload();
    }
    else if (this.siteConfi.primaryTxType === 2) {
      this.router.navigate(['../PregiftingCardProduct'], { relativeTo: this.route });
      //location.reload();
    }
    else if (this.siteConfi.primaryTxType === 3) {
      this.router.navigate(['../GiftingCardProduct'], { relativeTo: this.route });
      //location.reload();
    }
    else if (this.siteConfi.primaryTxType === 4) {
      this.router.navigate(['../OpenGiftingCardProduct'], { relativeTo: this.route });
      //location.reload();
    }
  }



  RedirectToProductSubCat() {
    this.isShowProdLink = false;
    this.isSubCatShow = true;
    this.isShowProdList = false;
    this.isQuickShow = true;
    this.isvendorShow = true;
    this.iscatShow = true;
  }

  deleteRow(index) {
    {
      this.SelectedProducts.splice(index, 1);
      return true;
    }
  }


  RefundPrevious(): Observable<any> {
    const returnData = new Subject<any>();
    let refundProducts = this.SelectedProducts.filter(function (element, index) {
      return (element.type === 'Exchange');
    });
    let transactionInfo = {
      WorkstationID: this.workstation.workStationId,
      WSIDTxID: this.TxNum,
      UserID: this.currentUser.userId,
      GuestName: this.customerName,
      TotalPrice: this.payTotal,
      ShiftNum: parseInt(this.workstation.lastShiftNum) + 1,
      TxID: refundProducts[0].txid,
      prodcutsLists: refundProducts
    }
    this._posSerive.RefundTransaction(transactionInfo).subscribe((res) => {
      if (res != 0) {
        returnData.next(res);
      }
    });
    return returnData.asObservable();
  }



  CompleteRefund() {
    debugger;
    let setthis = this;
    let refundProducts = this.SelectedProducts.filter(function (element, index) {
      return (element.type === 'Exchange');
    });
    let transactionInfo = {
      WorkstationID: this.workstation.workStationId,
      WSIDTxID: this.TxNum,
      UserID: this.currentUser.userId,
      GuestName: this.customerName,
      TotalPrice: this.payTotal,
      ShiftNum: parseInt(this.workstation.lastShiftNum) + 1,
      TxID: refundProducts[0].txid,
      prodcutsLists: refundProducts
    }
    this._posSerive.RefundTransaction(transactionInfo).subscribe((res) => {
      if (res != 0) {
        this._commonService.UpdateLastTXID(this.workstation.workStationId, this.workstation.lastTxId, this.submitted = true)
          .subscribe((res) => {
            debugger;
            this._dataSerive.setbehaviorObjData({ TxNum: res });
            this.TxNum = res;
            this.CompleteSale();
          });
      }
    });
  }

  GetTenders() {
    debugger;
    this.CheckPrinter();
    this._posSerive.GetTendersDetail(this.workstation.siteId).subscribe((res) => {
      if (res != 0) {
        this.TenderList = res;
        if(this.TenderList.length>4){
          $('.cal-area').addClass('scroll-pad');
        }
        let flagtender = false;
        this.types = [];
        for (let i = 0; i < this.TenderList.length; i++) {
          let tenderTypeValue = { tenderType: this.TenderList[i].tenderType, tenderId: this.TenderList[i].tenderId, tenderName: this.TenderList[i].tenderName }
          let type = { label: this.TenderList[i].tenderName, value: tenderTypeValue }
          this.types.push(type);
          if (tenderTypeValue.tenderType == 'Cash') {
            this.selectedCashTenderId = tenderTypeValue.tenderId;
            this.cashTender=tenderTypeValue.tenderName;
            $('#btn100').css('display', '');
            $('#btn100Emt').css('display', 'none');
            $('#btn50').css('display', '');
            $('#btn50Emt').css('display', 'none');
            $('#btn20').css('display', '');
            $('#btn20Emt').css('display', 'none');
            flagtender = true;
          }
          //else if (tenderTypeValue.tenderType != 'Cash' && flagtender == false) 
          else
          {
            if(flagtender == false)
            {
            $('#btn100').css('display', 'none');
            $('#btn100Emt').css('display', '');
            $('#btn50').css('display', 'none');
            $('#btn50Emt').css('display', '');
            $('#btn20').css('display', 'none');
            $('#btn20Emt').css('display', '');
            }
            if(tenderTypeValue.tenderType=='Room Charge')
            {
           this.roomChargeTender=tenderTypeValue.tenderName;
           }
            if(tenderTypeValue.tenderType=='Credit Card')
           {
           this.creditcardTender=tenderTypeValue.tenderName;
            }
          }
        }
      }

    });
  }


  CheckPrinter()
  {
    this._shiftService.GetPrinterStatus(this.workstation.workStationId).subscribe((res) => {
      debugger;
      if(res!=3 && res!=0)
      {
      let printerMessage=  this.authenticationService.GetPrinterMessgae(res);
        Swal.fire({
          icon: 'warning',
          title: 'warning!',
          text: printerMessage,
          timer: 10000, // 10 seconds
          timerProgressBar: true,
        })
      }
    });
  }

  SetDynamicValues()
  {

    this._commonService.GetSiteConfigrationValidated(this.workstation.siteId, this.workstation.districtId)
    .subscribe((res) => {
      debugger;
            localStorage.setItem('siteConfivalidated', JSON.stringify(res));
            this.siteConfi = JSON.parse(localStorage.getItem("siteConfivalidated"));
            this.isSRPShow= this.siteConfi.displaySRP;
            this.GetProductListNew();
            
    })
  }

  
  RestrictNumbers(key){
    if(key>=0){
    const pattern = /[0-9\+\-\ ]/;
      let inputChar = String.fromCharCode(Number(key));
      if (!pattern.test(inputChar)) {
        // invalid character, prevent input
        return false;
      }
      else{
        return true;
      }
    }
    else{
      return true;
    }

  }
  

}




