import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/Services/data.service';
import Swal from 'sweetalert2';
import * as $ from 'jquery'
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/Services/auth.service';
import { IPOSService } from 'src/app/Services/i-pos.service';
import { PortExeMsgHelper } from 'src/app/shared/helpers/port-exe-msg.helper';
import { StringHelper } from 'src/app/shared/helpers/string.helper';
import { ToastrService } from 'ngx-toastr';
import { GiftingService } from 'src/app/Services/gifting.service';
import { CommonService } from 'src/app/Services/common.service';
import { OpenGiftingService } from 'src/app/Services/open-gifting.service';

@Component({
  selector: 'app-refund-page',
  templateUrl: './refund-page.component.html',
  styleUrls: ['./refund-page.component.css']
})
export class RefundPageComponent implements OnInit {
  types: any[];
  selectedType: string;
  trDetails: any = [];
  SelectedProducts: any = [];
  refundProductsGroupList: any = [];
  TendorType: string;
  payTotal: any;
  currentUser: any;
  workstation: any;
  TxNum: any;
  isAdjust: boolean = false;
  creditcardList: any = [];
  creditcardDetailList: any = [];
  isCashOnly: boolean = true;
  isCreditCard: boolean = false;
  TenderList: any = [];
  transactionNum: number = 0;
  SelectedId: number;
  isCCSelect: boolean = false;
  customerName: string;
  RefundProductsTotal: any;
  RefundSelectedBalance: any;
  editCashAmount: string | number = 0;
  editCCAmount: string | number = 0;
  editRoomChargeAmount: string | number = 0;
  editRoomChargeNum: string = '';
  isEditAmountSelect: boolean = true;
  isRefundFinishDisabled: boolean = false;
  isRefundContinueDisabled: boolean = false;
  showTxtEditCashCC: boolean = false;
  getCreditCardInTX: boolean = false;
  getCashInTX: boolean = false;
  getRoomChargeInTX: boolean = false;
  RefundDueBalance: string | number;
  previousCashAmtValue: string | number;
  siteConfi: any;
  UserAdvancSetting: any;
  isOneCcApplied: boolean = false;
  tenderTypeName: string;
  isEditModeSelected: boolean = false;
  sumitted: boolean = false;
  isShowCCDetail: boolean = false;
  CCCheckedListAmount: string | number;
  compareRefundValue: any;
  TxType: number;

  constructor(private _dataSerive: DataService,
    private router: Router,
    private _posSerive: IPOSService,
    private route: ActivatedRoute,
    private authenticationService: AuthService,
    private _giftSerive: GiftingService,
    private _opengift: OpenGiftingService,
    private _commonService: CommonService,
    //private _middlewareService: MiddleWare,
    private toastr: ToastrService) {

    this.types = [
      { label: 'Cash', value: 'Cash' },
      { label: 'Credit Card', value: 'CreditCard' },
    ];
  }
  RedirectSalesHistory() {
    
    localStorage.setItem('BackButtonFlag', JSON.stringify(true));
    this.router.navigate(['../SalesHistory'], { relativeTo: this.route });
  }

  ngOnInit() {
    debugger;
    this._dataSerive.getbehaviorObjData().subscribe(profile => (this.TxNum = profile.TxNum,this.TxType = profile.TXType));
    this.currentUser = this.authenticationService.currentUserValue;
    this.workstation = JSON.parse(localStorage.getItem('workstation'));
    this.siteConfi = JSON.parse(localStorage.getItem("siteConfivalidated"));
    this.UserAdvancSetting = JSON.parse(localStorage.getItem("Useradvancsetting"));
    this._dataSerive.getbehaviorListData().subscribe(res => this.trDetails = res);
    this.SelectedProducts = this.trDetails;
    this._posSerive.GetTenderList(this.SelectedProducts[0].txid).subscribe((res) => {
      this.TenderList = res;
      this.showCashCCByTenderType();


      let selectProdAmt = this.SelectedProducts.reduce((a, { total }) => a + total, 0);
      let tendorAmt = this.TenderList.reduce((a, { tenderAmount }) => a + tenderAmount, 0);
      this.TendorType = this.TenderList[0].tenderType === 1 ? 'Cash' : 'CreditCard';
      if (selectProdAmt === tendorAmt) {
        this.payTotal = tendorAmt;
      } else {
        this.payTotal = selectProdAmt;
      }
      for (let i = 0; i < this.TenderList.length; i++) {
        this.TenderList[i].isSelect = true;
        this.TenderList[i].Amount = this.payTotal;
      }
    });

    this._posSerive.GetCreditCards(this.SelectedProducts[0].txid).subscribe((res) => {
      debugger;
      const ccDetails = res;
      const ccCatgory = ccDetails
        .map((m) => ({
          'account': m.account, 'issuer': m.issuer,
          'cardHolder': ccDetails.filter(x => x.account == m.account && x.tranType == 'Sale')[0].cardHolder,
          'txId': ccDetails.filter(x => x.account == m.account && x.tranType == 'Sale')[0].txId,
          'amount': ccDetails.filter(x => x.account == m.account && x.cardHolder == m.cardHolder)
            .reduce((a, { amount }) => a + parseFloat(amount), 0),
          'id': ccDetails.filter(x => x.account == m.account && x.tranType == 'Sale')[0].id,
          'ccList': ccDetails.filter(x => x.account == m.account && x.cardHolder == m.cardHolder && x.txId == m.txId)
        }));

      const ccCatgoryList = ccCatgory.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.account === value.account && t.cardHolder === value.cardHolder
        ))
      );

      ///////////////////////////////////////
      for (let i = 0; i < ccCatgoryList.length; i++) {
        ccCatgoryList[i].sNo = i + 1;
        ccCatgoryList[i].editCCRefundAmount = 0;
        ccCatgoryList[i].isSelected = false;
      }
      this.creditcardDetailList = ccCatgoryList;
    
      
      if (this.creditcardDetailList.length == 1) {
        this.isOneCcApplied = true;
        
      }
    });

    this.customerName = this.SelectedProducts[0].customerName
    this.refundProductGrouping();
  }

  showCashCCByTenderType() {
    debugger;
    const selectedRefundProds = this.SelectedProducts;
    const refundTotal = selectedRefundProds.reduce((a, { total }) => a + parseFloat(total), 0);
    let ttlRefund = refundTotal;

    const cashPay = this.TenderList.filter(x => x.tenderType == 1 || x.tenderType == 4);
    const ccPay = this.TenderList.filter(x => x.tenderType == 2);
    const roomChargePay = this.TenderList.filter(x => x.tenderType == 3);
    const moneyCertPay = this.TenderList.filter(x => x.tenderType == 4);

    const cashPaySum = cashPay.length;
    const ccPaySum = ccPay.length;
    const roomChargePaySum = roomChargePay.length;
    const moneyCertSum = moneyCertPay.length;

    var cashAmount =parseFloat(cashPay.reduce((a, { tenderAmount }) => a + tenderAmount, 0));
    const ccAmount = ccPay.reduce((a, { tenderAmount }) => a + tenderAmount, 0);
    const roomChargeAmount = roomChargePay.reduce((a, { tenderAmount }) => a + tenderAmount, 0);
    const moneyCertAmount = moneyCertPay.reduce((a, { tenderAmount }) => a + tenderAmount, 0);


    if (ccPaySum > 0) {
      if (ttlRefund < ccAmount) {
        this.editCCAmount = ttlRefund;
        ttlRefund = 0;
      }
      else {
        this.editCCAmount = ccAmount;
        ttlRefund = ttlRefund - ccAmount;
      }
      this.isEditAmountSelect = false;
      this.getCreditCardInTX = true;
      this.getCashInTX = true;
      this.tenderTypeName = 'CC'
    }
    if (roomChargePaySum > 0) {
      if (ttlRefund < roomChargeAmount) {
        this.editRoomChargeAmount = ttlRefund;
        ttlRefund = 0;
      }
      else {
        this.editRoomChargeAmount = roomChargeAmount;
        ttlRefund = ttlRefund - roomChargeAmount;
      }
      this.isEditAmountSelect = false;

      this.getCashInTX = true;
      this.getRoomChargeInTX = true;
      this.tenderTypeName = 'ROOMCHARGE'

      this.editRoomChargeNum = roomChargePay[0].roomNum;
    }
    if (cashPaySum > 0 || moneyCertAmount > 0) {
      if ((ttlRefund < cashAmount || ((this.TxType === 3||this.TxType === 4)) && cashAmount < 0) ) 
      {
        this.editCashAmount =parseFloat(ttlRefund) ;
        ttlRefund = 0;
      }
      else 
      {
        this.editCashAmount =cashAmount ;
        ttlRefund = ttlRefund - cashAmount;
      }
     
      this.getCashInTX = true;
      this.tenderTypeName = 'CASH';
    }
    if((this.TxType === 3||this.TxType === 4)&& ttlRefund >0)
      {
        this.editCashAmount += ttlRefund;
        ttlRefund = 0;
      }
   
    
    this.GetRefundBalance();
  }

  refundProductGrouping() {
    debugger;
    const selectedRefundProds = this.SelectedProducts;
   const hasProductType4 = selectedRefundProds.some(product => product.productTypeId === 4);
    this.RefundProductsTotal = selectedRefundProds.reduce((a, { total }) => a + parseFloat(total), 0);

    const refundProdCategoryDetailsList = selectedRefundProds.map((x) => {
      if (x.inventoryItem == true) {
        x.prodTypeName = x.prodTypeName.replace('- Inventory', '') + '- Inventory';
      }
      return x;
    });
    const groupByCategory = refundProdCategoryDetailsList.reduce((group, product) => {
      const { prodTypeName } = product;
      group[prodTypeName] = group[prodTypeName] ?? [];
      group[prodTypeName].push(product);
      return group;
    }, {});

    // create object
    var obj = [];
    for (const key in groupByCategory) {
  
   
      let dt = { category: key, prodList: groupByCategory[key] };
      obj.push(dt);
    }
    this.refundProductsGroupList = obj;
  }

  AdjustRefund() {
    this.isAdjust = true;
    for (let i = 0; i < this.TenderList.length; i++) {
      this.TenderList[i].isSelect = false;
      this.TenderList[i].Amount = 0;
    }
  }
  SetId(id) {
    this.SelectedId = id;
    this.TenderList[id].Amount = 0;
  }

  PopulateAmount(value) {
    debugger;
    if (this.TenderList.some(person => person.isSelect === true)) {
      for (let i = 0; i < this.TenderList.length; i++) {
        if (this.SelectedId === i) {
          if (value === 'x') {
            this.TenderList[i].Amount = 0;
          } else if (value > 9) {
            if (parseFloat(this.TenderList[i].Amount) + parseFloat(value) > this.payTotal) {
              Swal.fire({
                icon: 'success',
                title: 'Success!',
                text: 'Please check amount',
              }).then(function () {
              });
            }
            else {
              this.TenderList[i].Amount = parseFloat(this.TenderList[i].Amount) + parseFloat(value);
            }
          } else {
            if (parseFloat("" + this.TenderList[i].Amount + value) > this.payTotal) {
              Swal.fire({
                icon: 'success',
                title: 'Success!',
                text: 'Please check amount',
              }).then(function () {
              });
            }
            else {
              this.TenderList[i].Amount = "" + this.TenderList[i].Amount + value
            }
          }
        }
      }
    }
    else {
      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: 'Please select tendor type',
      }).then(function () {
      });
    }
  }
  CancilCreditcardTx() {
    this.isCreditCard = false;
    this.isCashOnly = true;
  }

  CancelRefund() {
    this.router.navigate(['../SalesHistory'], { relativeTo: this.route });
  }
  CompleteRefundTransaction1() {
    debugger;
    if (this.isRefundContinueDisabled) {
      this.toastr.error("Balance to refund is greater than 0. Please verify all values are correct.");
      return;
    }
  
    const ccSelectedListAmount = this.creditcardDetailList
      .filter(x => x.isSelected)
      .reduce((a, { editCCRefundAmount }) => a + +editCCRefundAmount, 0);
  
    this.CCCheckedListAmount = ccSelectedListAmount;
  
    if ((+this.editCCAmount > 0 || this.tenderTypeName === 'CC') && (+ccSelectedListAmount !== +this.editCCAmount)) {
      this.toastr.error(`You are trying to refund ${+ccSelectedListAmount} out of ${+this.editCCAmount}. Verify all values are correct.`);
      return;
    } else {
      const transactionInfo = this.buildTransactionInfo();
      this.submitTransaction(transactionInfo);
    }
  }
  
  buildTransactionInfo() {

    var latestUser= localStorage.getItem("latestUser");
    if(latestUser!=null && latestUser!='' && latestUser!="null")
      {
    return {
      WorkstationID: this.workstation.workStationId,
      WSIDTxID: this.TxNum,
      UserID: latestUser,
      GuestName: this.customerName,
      TotalPrice: this.payTotal,
      TotalCashAmount: parseFloat('' + this.editCashAmount),
      RoomNum: this.editRoomChargeNum,
      TotalRoomChargeAmount: parseFloat('' + this.editRoomChargeAmount),
      TotalCCChargeAmount: parseFloat('' + this.editCCAmount),
      ShiftNum: parseInt(this.workstation.lastShiftNum) + 1,
      TxID: this.SelectedProducts[0].txid,
      prodcutsLists: this.SelectedProducts,
      TransType: this.TxType
    };
  }else
  {    return {
    WorkstationID: this.workstation.workStationId,
    WSIDTxID: this.TxNum,
    UserID: this.currentUser.userId,
    GuestName: this.customerName,
    TotalPrice: this.payTotal,
    TotalCashAmount: parseFloat('' + this.editCashAmount),
    RoomNum: this.editRoomChargeNum,
    TotalRoomChargeAmount: parseFloat('' + this.editRoomChargeAmount),
    TotalCCChargeAmount: parseFloat('' + this.editCCAmount),
    ShiftNum: parseInt(this.workstation.lastShiftNum) + 1,
    TxID: this.SelectedProducts[0].txid,
    prodcutsLists: this.SelectedProducts,
    TransType: this.TxType
  };

  }
  }

  submitTransaction(transactionInfo) {
    let service;
    if (this.TxType === 1) {
      service = this._posSerive;
    } else if (this.TxType === 3) {
      service = this._giftSerive;
    } else if (this.TxType === 4) {
      service = this._opengift;
    }
  
    if (service) {
      service.RefundTransaction(transactionInfo).subscribe((res) => {
        if (res !== 0) {
          this._commonService.UpdateLastTXID(this.workstation.workStationId, this.workstation.lastTxId, this.sumitted = true)
            .subscribe((txnum) => { });
          this.transactionNum = res.value;
          this.toastr.success('Transaction completed successfully.');
            localStorage.setItem('latestUser','');
          setTimeout(() => {
            this.router.navigate(['../SalesHistory'], { relativeTo: this.route });
          }, 1000);
        }
      }, (error) => {
        Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Transaction failed.',
              }).then(function () {
                this.router.navigate(['../SalesHistory'], { relativeTo: this.route });
              });
        //this.ShowMessages('Transaction failed.');
      });
    }
  }
  


  CheckChangeCCList(isChecked: boolean, serialNo: number) {
    if (isChecked == false) {
      this.creditcardDetailList
        .filter(x => x.sNo == serialNo)
        .forEach(item => {
          if (isChecked == false) {
            item.editCCRefundAmount = 0;
          }
          item.isSelected = isChecked;
        });

      this.CCCheckedListAmount = this.creditcardDetailList
        .filter(x => x.isSelected == true)
        .reduce((a, { editCCRefundAmount }) => a + +editCCRefundAmount, 0);

    }

    const ccSelectedListAmount = this.creditcardDetailList
      .filter(x => x.isSelected == true)
      .reduce((a, { editCCRefundAmount }) => a + +editCCRefundAmount, 0);

    if (ccSelectedListAmount < this.editCCAmount) {
      this.creditcardDetailList
        .filter(x => x.sNo == serialNo)
        .forEach(item => {
          if (isChecked == false) {
            item.editCCRefundAmount = 0;
          }
          item.isSelected = isChecked;
        });
    }
    else {
      let chkId = document.getElementById("chk_" + serialNo) as HTMLInputElement;
      chkId.checked = false;
      this.toastr.error("Balance has already been met. Verify all values are correct")
    }
    this.GetRefundBalance();
  }

  EditCashCCAmount() {
    this.isEditModeSelected = true;
    this.showTxtEditCashCC = true;
    this.editCashAmount = '';
    this.editCCAmount = '';

    if (this.creditcardDetailList.length == 1) {
      this.creditcardDetailList
        .forEach(x => x.isSelected = true);
    }
    this.GetRefundBalance();
  }

  GetRefundBalance() {
    debugger;
    this.editCashAmount = this.editCashAmount || 0;
    this.editCCAmount = this.editCCAmount || 0;
    this.editRoomChargeAmount = this.editRoomChargeAmount || 0;
    const totalDue = +this.editCashAmount + +this.editCCAmount + +this.editRoomChargeAmount;
    this.RefundSelectedBalance = totalDue;
    this.RefundDueBalance = +this.RefundProductsTotal - totalDue;
    this.RefundDueBalance = this.RefundDueBalance || 0;
    if (this.RefundDueBalance == 0) {
      if(this.compareRefundValue > this.RefundProductsTotal){
        this.toastr.error("Amount can not be higher than what is available.");
      }
      this.isRefundFinishDisabled = false;
      this.isRefundContinueDisabled = false;
    }
    else {
      this.isRefundFinishDisabled = true;
      this.isRefundContinueDisabled = true;
    }
  }

  valCashAmount(event) {
    debugger;
    const cashAmount: any = StringHelper.CashAmountInputValidator(event.target.value);
    const totalRefund = +cashAmount + +this.editCCAmount + +this.editRoomChargeAmount;
    if (+totalRefund <= +this.RefundProductsTotal) {
      this.editCashAmount = cashAmount;
      this.compareRefundValue =cashAmount
    }
    else {
      this.editCashAmount = this.RefundProductsTotal - +this.editCCAmount - +this.editRoomChargeAmount;
      this.compareRefundValue =totalRefund
    }
    this.GetRefundBalance();
  }

  valCCAmount(event) {
    debugger;
    const ccAmount: any = StringHelper.CashAmountInputValidator(event.target.value);
    const ccPay = this.TenderList.filter(x => x.tenderType == 2);
    const ccListAmount = ccPay.reduce((a, { tenderAmount }) => a + tenderAmount, 0);
    const totalRefund = +ccAmount + +this.editCashAmount + +this.editRoomChargeAmount;

    if (+totalRefund <= +this.RefundProductsTotal) {
      if (ccAmount <= ccListAmount) {
        this.editCCAmount = ccAmount;
      }
      else {
        this.editCCAmount = ccListAmount;
      }
    }
    else {
      const newCCAmount = this.RefundProductsTotal - +this.editCashAmount - +this.editRoomChargeAmount;
      if (newCCAmount <= ccListAmount) {
        this.editCCAmount = newCCAmount;
      }
      else {
        this.editCCAmount = ccListAmount;
      }
    }
    this.GetRefundBalance();
  }

  valRoomChargeAmount(event) {
    debugger;
    const roomChargeAmount: any = StringHelper.CashAmountInputValidator(event.target.value);
    //const roomChargePay = this.TenderList.filter(x => x.tenderType == 3)
    //  .reduce((a, { tenderAmount }) => a + tenderAmount, 0);
    const roomChargePay = this.RefundProductsTotal;
    const totalRefund = +roomChargeAmount + +this.editCashAmount + +this.editCCAmount;
    if (+totalRefund <= +this.RefundProductsTotal) {
      if (roomChargeAmount <= roomChargePay) {
        this.editRoomChargeAmount = roomChargeAmount;
      }
      else {
        this.editRoomChargeAmount = roomChargePay;
      }
    }
    else {
      const newRoomAmount = this.RefundProductsTotal - +this.editCashAmount - +this.editCCAmount;
      if (newRoomAmount <= roomChargePay) {
        this.editRoomChargeAmount = newRoomAmount;
      }
      else {
        this.editRoomChargeAmount = roomChargePay;
      }
    }


    this.GetRefundBalance();
  }

  getCashAmountPreviousValue(event) {
    this.previousCashAmtValue = event.target.value;
  }

  valCCListAmount(event, serialNo: number) {
    debugger;
    const ccAmount: any = StringHelper.CashAmountInputValidator(event.target.value);
    const ccListAmountExceptSelected = this.creditcardDetailList.filter(x => x.isSelected == true && x.sNo != serialNo).reduce((a, { editCCRefundAmount }) => a + +editCCRefundAmount, 0);
    const ccSelectedListAmount = this.creditcardDetailList.filter(x => x.isSelected == true).reduce((a, { editCCRefundAmount }) => a + +editCCRefundAmount, 0);
    const totalRefund = +ccSelectedListAmount;

    if (+totalRefund <= +this.editCCAmount) {
      this.creditcardDetailList
        .filter(x => x.isSelected == true && x.sNo == serialNo)
        .forEach(x => {
          if (x.amount >= +ccAmount) {
            x.editCCRefundAmount = +ccAmount
          }
          else {
            x.editCCRefundAmount = x.amount
          }
        });
    }
    else {
      const ccAmt = +this.editCCAmount - +ccListAmountExceptSelected;
      this.creditcardDetailList
        .filter(x => x.isSelected == true && x.sNo == serialNo)
        .forEach(x => {
          if (x.amount >= +ccAmt) {
            x.editCCRefundAmount = +ccAmt
          }
          else {
            x.editCCRefundAmount = x.amount
          }
        }
        );
    }
    this.CCCheckedListAmount = this.creditcardDetailList.filter(x => x.isSelected == true).reduce((a, { editCCRefundAmount }) => a + +editCCRefundAmount, 0);
    this.GetRefundBalance();
  }

  ShowCreditCardDetail() {
    if (this.isRefundContinueDisabled) {
      this.toastr.error(`you are trying to refund ${+this.RefundSelectedBalance} out of ${+this.RefundProductsTotal}. Verify all values are correct.`)
      this.toastr.error("Balance to refund is greater than 0. Please verify all values are correct.")
    }
    else {
      this.isShowCCDetail = true;
      this.CCCheckedListAmount = this.editCCAmount;
      let ccAmount = this.editCCAmount;
      this.creditcardDetailList.forEach(x => {
        if (ccAmount == 0) {
          x.editCCRefundAmount = 0;
          x.isSelected = false;
        }
        else if (ccAmount <= x.amount) {
          x.editCCRefundAmount = +ccAmount;
          ccAmount = 0;
          x.isSelected = true;
        }
        else {
          x.editCCRefundAmount = x.amount;
          ccAmount = +ccAmount - x.amount;
          x.isSelected = true;
        }
      })
    }
  }
  paymentSelection() {
    this.isShowCCDetail = false;
  }
}
