<div class="right-content">
    <div class="heading-panel text-left">
        
        <h1><img src="./assets/Libs/images/enventry.png" height="16"> Inventory</h1>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12 text-center">
                <h1 class="body-heading">View Balance</h1>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="invent-back">
            <div class="row">
                <div class="col-sm-4 text-left">
                    <label> Workstation</label>
                    <select class="form-control" [(ngModel)]="workstation.workStationId" (change)="getOnHandCount(selectField.value);" #selectField>
                        <option value="0">-Select Workstation-</option>
                        <option *ngFor="let item of workstationList" [value]="item.workStationId">
                            
                            {{item.workStationName}} {{item.workStationId}}
                        </option>
                    </select>
                </div>
                <div class="col-sm-4 text-left">
                    <label> Vendor</label>
                    <select class="form-control"[(ngModel)]="selectedVendor" (ngModelChange)="getOnBasedOnVendor($event)" >
                        <option [value]="0">-Select Vendor-</option>
                        <option *ngFor="let item of vendorList; let i = index " [attr.data-index]="i" [value]="item">
                            {{item}}
                        </option>
                    </select>
                </div>
                <div class="col-sm-4">  
                    <label class="col-label-form">Search</label>    
                    <div class="invtry-srch-input">                 
                    <input type="text" [(ngModel)]="SearchOnHandVal" (keyup)="SearchOnHand($event)" placeholder="Search" class="form-control">
                    <i class="fa fa-search"></i>
                    </div>
                </div>
            </div><br />
            <div class="row">
                <div class="col-sm-12">
                    <div class="table-responsive">
                        <table class="table table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th>Vendor Name</th>
                                    <th>PLU Code</th>
                                    <th>Product Name</th>
                                    <th>Balance</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="viewData != ''">
                                <tr *ngFor="let item of viewData; let i=index">
                                    <td class="text-center">{{item.catagoryName}}</td>
                                    <td class="text-center">{{item.pluCode}}</td>
                                    <td class="text-center">{{item.productName}}</td>
                                    <td class="text-center">{{item.totalProdQTY}} </td>
                                </tr>
                            </tbody>
                        </table> 
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="row mt-4">
            <div class="col-sm-12">
                <div class="action-buttons text-center">
                    <button  class="button button-secondary" id="btn_PrintOnHand" (click)="PrintOnHand()">Print</button>    
                </div>           
            </div>
        </div> -->
        <div class="row">
            <div class="col-sm-12">
                <div class="print-bottom-button d-flex justify-content-center align-items-center">
                    <button  class="button button-secondary" id="btn_PrintOnHand" (click)="PrintOnHand()">Print</button>    
                </div>           
            </div>
        </div>
    </div>
</div>

<p-confirmDialog acceptLabel="Continue" appendTo="body">
    <ng-template pTemplate="body">
        <span class="ui-confirmdialog-message"></span>
    </ng-template>
</p-confirmDialog>