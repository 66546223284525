import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { configConstant } from '../shared/constants/config.constant';

@Injectable({
  providedIn: 'root'
})
export class InventoryService 
{
  private baseurl =  configConstant.apibaseurl;
  constructor(private http: HttpClient) { }
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  GetInventoryCount(workststionId,invflg,disId): Observable<any> {
    debugger
    let CId = localStorage.getItem("Client_Id");
    const headers = new HttpHeaders().set('Client', CId);
    return this.http.get<any>(this.baseurl + '/Shift/ViewOnHandInventoryCount/' + workststionId +"/"+invflg+"/"+disId,{headers})
      .pipe(
        retry(1),
        catchError(this.errorHandl)
      );
  }

  GetInventoryActivityCount(shiftNum,workstationId): Observable<any> {
    debugger
    let CId = localStorage.getItem("Client_Id");
    const headers = new HttpHeaders().set('Client', CId);
    return this.http.get<any>(this.baseurl + '/Shift/GetInventorytrnasferCount/'+shiftNum+"/"+workstationId,{headers})
      .pipe(
        retry(1),
        catchError(this.errorHandl)
      );
  }


  GetSoldInventoryDetails(shiftNum,workstationId): Observable<any> {
    debugger
    let CId = localStorage.getItem("Client_Id");
    const headers = new HttpHeaders().set('Client', CId);
    return this.http.get<any>(this.baseurl + '/Shift/GetSoldInventoryProductsDetails/'+shiftNum+"/"+workstationId,{headers})
      .pipe(
        retry(1),
        catchError(this.errorHandl)
      );
  }
  GetInventoryQty(wsId,qty,actionId,prodId): Observable<any> {
    debugger
    let CId = localStorage.getItem("Client_Id");
    const headers = new HttpHeaders().set('Client', CId);
    return this.http.get<any>(this.baseurl + '/Shift/GetInventoryOnHandQty/'+wsId+"/"+qty+"/"+actionId+"/" + prodId,{headers})
      .pipe(
        retry(1),
        catchError(this.errorHandl)
      );
  }
  

  GetViewTransfer(workststionId) {
    let CId = localStorage.getItem("Client_Id");
    const headers = new HttpHeaders().set('Client', CId);
    return this.http.get<any>(this.baseurl + '/Shift/ViewInventoryTransfer/' + workststionId,{headers})
      .pipe(
        retry(1),
        catchError(this.errorHandl)
      );
  }

  PrintOnHandReceipt(workstationId: any, userId: any) {
    let CId = localStorage.getItem("Client_Id");
    const headers = new HttpHeaders().set('Client', CId);
    return this.http.get<any>(this.baseurl + '/Shift/PrintOnHandReceipt/' + workstationId + '/' + userId,{headers})
      .pipe(
        retry(1),
        catchError(this.errorHandl)
      );
  }

  SaveTransferDetails(transferDetails): Observable<any> {
    debugger;
    let CId = localStorage.getItem("Client_Id");
    const headers = new HttpHeaders().set('Client', CId);
    return this.http.post<any>(this.baseurl + '/Shift/SaveTransferDetails', transferDetails,{headers})
      .pipe(
        retry(1),
        catchError(this.errorHandl)
      );
  }

  SaveAndUpdateInventoryAdjustment(adjustmentDetails): Observable<any> {
    debugger;
    let CId = localStorage.getItem("Client_Id");
    const headers = new HttpHeaders().set('Client', CId);
    return this.http.post<any>(this.baseurl + '/Shift/SaveAndUpdateInvAdjustment', adjustmentDetails,{headers})
      .pipe(
        retry(1),
        catchError(this.errorHandl)
      );
  }

  SaveInventoryOutageData(ivnOutage): Observable<any> {
    debugger;
    let CId = localStorage.getItem("Client_Id");
    const headers = new HttpHeaders().set('Client', CId);
    return this.http.post<any>(this.baseurl + '/Shift/SaveInventoryOutage',ivnOutage,{headers})
      .pipe(
        retry(1),
        catchError(this.errorHandl)
      );
  }

  //Change Inventory Status
  //ChangeInventoryTransferStstus(transferId: any, status: any, currentShiftNum: any, districtNum: any,workId:any,userId:any): Observable<any>
  ChangeInventoryTransferStstus(updateStatus): Observable<any> {
    let CId = localStorage.getItem("Client_Id");
    const headers = new HttpHeaders().set('Client', CId);
    return this.http.post<any>(this.baseurl + '/Shift/UpdateTransferDetails/' ,updateStatus,{headers})
      .pipe(
        catchError(this.errorHandl)
      );
  }
  //Delete Inventory Status
  DeleteInventoryTransferStstus(transferId: any): Observable<any> {
    // const headers = new HttpHeaders().set('Client ', '32724');
    // return this.http.get<any>(this.baseurl + '/Shift/DeleteTransferDetails/' + transferId , {headers})
    // //return this.http.get<any>(this.baseurl + '/Shift/DeleteTransferDetails/' + transferId, {headers})
    //   .pipe(
    //     retry(1),
    //     catchError(this.errorHandl)
    //   );
    let CId = localStorage.getItem("Client_Id");
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Client': CId,
      "transferId":transferId});
  let options = { headers: headers };
    //post data missing(here you pass email and password)
    let data = {
    "transferId":transferId,
    }
    return this.http.post<any>(this.baseurl + '/Shift/DeleteTransferDetails/',null, options)
        .pipe(
          retry(1),
        catchError(this.errorHandl)
        );
  }

  ViewProdDescription(transferId: any, workStationId: any): Observable<any> {
    debugger;
    let CId = localStorage.getItem("Client_Id");
    const headers = new HttpHeaders().set('Client', CId);
    return this.http.get<any>(this.baseurl + '/Shift/ViewProdDescription/' + transferId + '/' + workStationId, {headers})
      .pipe(
        retry(1),
        catchError(this.errorHandl)
      );
  }

  errorHandl(error): any {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
  }

}
