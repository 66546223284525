import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { AuthService } from 'src/app/Services/auth.service';
//import { MiddleWare } from 'src/app/Services/middleware.service';
import { ShiftService } from 'src/app/Services/shift.service';
import * as $ from 'jquery';
import Swal from 'sweetalert2';
import { StringHelper } from 'src/app/shared/helpers/string.helper';
import { PortExeMsgHelper } from 'src/app/shared/helpers/port-exe-msg.helper';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-cash',
  templateUrl: './add-cash.component.html',
  styleUrls: ['./add-cash.component.css']
})
export class AddCashComponent implements OnInit {
  currentUser: any;
  cashinDrwer: any;
  workstation: any;
  currentShiftTotals: any;
  openingCount: any = '';
  totalAmt: number;
  infoMessage: any;
  cashAdjustType: any;
  shiftOpeningAMt: any;
  currentShiftTransaction: any[];
  hunders: number = 0;
  fifities = 0;
  twnties = 0;
  tens: number = 0;
  fives: number = 0;
  once = 0;
  changeTotal = 0;
  currentshiftbalane
  siteConfi: any;
  Cashmanagement:any;
  LoginModal:boolean;
  submitted:boolean;
  TotalCash: number | string = this.changeTotal + +this.hunders + this.fifities + this.twnties + this.tens + this.fives;
  returnComponent:string;
  feature:string;
  subFeature:string;
  LoginAdjuctGroup = new FormGroup({
    UserNameLogin: new FormControl('',[Validators.required]),
    PasswordLogin: new FormControl('',[Validators.required]),
  });

  errormsg: any;
  issubmitted:boolean=false;
  constructor(private route: ActivatedRoute, private toastr: ToastrService,
    private router: Router,
    private _shiftService: ShiftService,
    private authenticationService: AuthService,
    private confirmationService: ConfirmationService,
    //private _middlewareService: MiddleWare ,
    private formBuilder: FormBuilder,) { }

/// <summary>
/// Firstly Call onload for Add Cash Component.
/// </summary>
  ngOnInit(): void {
    this.currentUser = this.authenticationService.currentUserValue;
    this.workstation = JSON.parse(localStorage.getItem('workstation'));
    this.siteConfi=JSON.parse(localStorage.getItem("siteConfivalidated"))
    this.Cashmanagement=JSON.parse(localStorage.getItem('UsercashManagement'));
    debugger;
    if(this.siteConfi.removeCash==false && this.siteConfi.addCash==false)
    {
      $('#btnRemove ,#btnadd').prop('disabled', true);
      $('#btnRemove , #btnadd').addClass('button-disable').removeClass('button-secondary');
    }
    else if(this.siteConfi.removeCash==false){
      $('#btnRemove').prop('disabled', true);
      $('#btnRemove').addClass('button-disable').removeClass('button-secondary');
    }
    else if(this.siteConfi.addCash==false) {
      $('#btnadd').prop('disabled', true);
      $('#btnadd').addClass('button-disable').removeClass('button-secondary');
    }

    
    // Getting Current Shift Details
    this._shiftService.GetCurrentShiftTotals(this.workstation.workStationId +"-"+ (Number(this.workstation.lastShiftNum + 1)), this.workstation.workStationId)
      .subscribe((currentShiftTotalRes) => {
        this.currentShiftTotals = Number(currentShiftTotalRes);
      });

    this._shiftService.GetCurrentShiftDetails(this.workstation.workStationId +"-"+ (Number(this.workstation.lastShiftNum + 1)), this.workstation.workStationId)
      .subscribe((currentShiftRes) => {
        this.currentShiftTransaction = currentShiftRes;
      });

    // Getting Previous Shift opening
    this._shiftService.GetPreviousShiftBalence(this.workstation.workStationId +"-"+ (parseInt(this.workstation.lastShiftNum)))
      .subscribe((prevRes) => {
        this.shiftOpeningAMt = Number(prevRes);
      });
      $(".cashcalcbtn").on('click',function(){
          $('.cash-calculator').toggleClass('active').promise().done(function(){
            if($(this).hasClass('active')){
              $('.pin-tab').addClass('open');
            }else{
              $('.pin-tab').removeClass('open');
            }
          });
      });
      this.LoginAdjuctGroup = this.formBuilder.group({
        UserNameLogin: ['', [Validators.required, ]],
        PasswordLogin: ['', [Validators.required,]]
      });
  
  
    this.CheckPrinter();
    $(".navigation ul li a").removeClass("active");
    $(".cashSec").addClass("active");
    $(".salesSec, .transaSec, .envSec").removeClass("active");
  }
  AdjustLogin(){
    debugger;
this.issubmitted=true;
    if (this.LoginAdjuctGroup.invalid) {
      return;
    }

    console.log(this.LoginAdjuctGroup.value);
    let GetGUIDchrowm = localStorage.getItem("MacAddress");
    let decodeGUID=atob(GetGUIDchrowm)
    let userObj= {
      UserName:this.LoginAdjuctGroup.controls.UserNameLogin.value,
      Password:this.LoginAdjuctGroup.controls.PasswordLogin.value,
      GUID: decodeGUID,
      Feature:this.feature,
      SubFeature:this.subFeature
    }
    this.authenticationService.UserLoginForOtherPermissions(userObj)
    .subscribe((res) => {
        if(res.hasAccess){
          this.LoginModal = false;
         if(this.subFeature=='Add Cash'){
          this.AddCashWithOtherUserCredentials();
         }
         else{
          this.RemoveCashWithUserCredentials();
         }
        }
        else{
          this.NavValidationModalPopup();
        }
        this.issubmitted=false;
      },
      error => {
        debugger;
        this.errormsg=error.error.message;
        this.toastr.error(this.errormsg);
         if(this.errormsg=='Not Use This Workstation'){
          this.toastr.error("This workstation is not access to you, Please Enter the Valid Credentials");
         };
      });
  }



  get Login() { return this.LoginAdjuctGroup.controls; }
  PopulateAmount(value) {
    debugger;
    if (value === 'x') {
      let openingBal = ""+ this.openingCount 
      this.openingCount = openingBal.slice(0, -1);
      this.openingCount = this.openingCount || "0";
    } else {
      this.openingCount = "" + this.openingCount + value;
    }
    this.openingCount = StringHelper.CashAmountInputValidator(this.openingCount);
  }

  onChange(value, item) {
    debugger;
    if (value !== '') {
      this.TotalCash = (((+this.hunders) * 100) + ((+this.fifities) * 50) + ((+this.twnties) * 20) + ((+this.tens) * 10) + ((+this.fives) * 5) + ((+this.once) * 1))
      var result = (this.changeTotal / 100);
      this.TotalCash = (this.TotalCash + result).toFixed(2);
      this.openingCount = this.TotalCash;
      
    } else {
      this.TotalCash = (((+this.hunders) * 100) + ((+this.fifities) * 50) + ((+this.twnties) * 20) + ((+this.tens) * 10) + ((+this.fives) * 5) + ((+this.once) * 1))
      this.openingCount = this.TotalCash.toFixed(2);
    }
  }
  
  valOpeningCount(event){
    debugger;
    this.openingCount = StringHelper.CashAmountInputValidator(event.target.value);
  }

  valCalcInput(event){
    debugger;
  }

  
  CheckCurrentShiftBalance(cashadj) {
    debugger;
    if (this.Cashmanagement.addCash===1 && cashadj === 'cashIn') {
      this.cashAdjustType = 'CashIn';
      this.totalAmt = Number(this.openingCount === '' ? 0 : this.openingCount);
      this.infoMessage = `<p class='worstation-img'><img src="./assets/Libs/images/confirmation.png" /></p> <h3 class="pop-head">Confirmation</h3> <p class="assignstyle">Add $${this.totalAmt.toFixed(2)} to the Drawer?</p>`;
      if (Number(this.openingCount) === 0) {
        Swal.fire({
          icon: 'warning',
          title: 'Warning!',
          text: 'Please enter some amount!'
        });
        return;
      }
      const messages = this.infoMessage;
      this.confirmationService.confirm({
        message: messages,
        header: 'Confirmation',
        rejectVisible: true,
        acceptLabel: 'Yes',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          if (cashadj === 'cashIn') {
            $('.ui-dialog-mask-scrollblocker').css('display', 'none');
            this.SubmitCashIn();
          } 
          else {
            if (Number(this.openingCount) <= Number(this.currentShiftTotals)) {
              $('.ui-dialog-mask-scrollblocker').css('display', 'none');
              this.SubmitCashIn();
            } 
            else {
              Swal.fire({
                icon: 'warning',
                title: 'Warning!',
                text: 'You can not remove more cash then cash in drawer balance!'
              });
            }
          }
        },
        reject: () => {
          $('.ui-dialog-mask-scrollblocker').css('display', 'none');
        }
      });
    } 
    else if (this.Cashmanagement.removeCash===1 && cashadj === 'cashOut') {
      this.cashAdjustType = 'CashOut';
      this.totalAmt = -Math.abs(Number(this.openingCount === '' ? 0 : this.openingCount));
      const ttlAmount = Math.abs(Number(this.openingCount === '' ? 0 : this.openingCount));
      this.infoMessage = `<p class='worstation-img'><img src="./assets/Libs/images/confirmation.png" /></p><h3 class="pop-head">Confirmation</h3><p class="assignstyle">Remove $${ttlAmount.toFixed(2)} from Drawer?</p>`;
     
      if (Number(this.openingCount) === 0) {
        Swal.fire({
          icon: 'warning',
          title: 'Warning!',
          text: 'Please enter some amount!'
        });
        return;
      }      
      const messages = this.infoMessage;
      this.confirmationService.confirm({
        message: messages,
        header: 'Confirmation',
        rejectVisible: true,
        acceptLabel: 'Yes',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          if (cashadj === 'cashIn') {
            $('.ui-dialog-mask-scrollblocker').css('display', 'none');
            this.SubmitCashIn();
          } 
          else {
            if (Number(this.openingCount) <= Number(this.currentShiftTotals)) {
              $('.ui-dialog-mask-scrollblocker').css('display', 'none');
              this.SubmitCashIn();
            } 
            else {
              Swal.fire({
                icon: 'warning',
                title: 'Warning!',
                text: 'You can not remove more cash then cash in drawer balance!'
              });
            }
          }
        },
        reject: () => {
          $('.ui-dialog-mask-scrollblocker').css('display', 'none');
        }
      });
    }
  else{
    // Swal.fire({
    //   text: 'You do not have permission to perform this function. Would you like to enter alternate credentials?​',
    //   icon: 'warning',
    //   showCancelButton: true,
    //   confirmButtonText: '<i class="fa fa-check"></i> Yes',
    //   cancelButtonText: '<i class="fa fa-close"></i> No',
    // }).then((result) => {
    //   if (result.value) {
    //     debugger;

     

    //     this.LoginModal=true;
    //   } else if (result.dismiss === Swal.DismissReason.cancel) {
    //     debugger;
    //   }
    // });


    if(cashadj === 'cashIn'){
    
    this.subFeature='Add Cash';
    this.returnComponent='VewCash'
    
    }
    else{
    
      this.subFeature='Remove Cash';
      this.returnComponent='VewCash'
      
      }
    this.feature='Cash Management';
    this.NavValidationModalPopup();
  }
  }

  hide(signclose:string){
if(signclose==='SignInModal'){
  this.LoginModal=false;
  this.issubmitted=false;
}
  }



  SubmitCashIn() {
    debugger;
    let cashDetails = {};
    var latestUser= localStorage.getItem("latestUser");
    let transactionInfo={};
     if(latestUser!=null && latestUser!='' && latestUser!="null")
     {

     cashDetails = {
      WorkstationName: this.workstation.workStationName,
      WorkstationId: this.workstation.workStationId,
      CashAdjType: this.cashAdjustType,
      ShiftNum: this.workstation.workStationId +"-"+(Number(this.workstation.lastShiftNum + 1)),
      UserId: latestUser,
      Amount:  Number(this.totalAmt.toFixed(2)),
      Cidtotal: Number(Number(this.currentShiftTotals) + this.totalAmt),
    }
  }
  else{
    cashDetails = {
      WorkstationName: this.workstation.workStationName,
      WorkstationId: this.workstation.workStationId,
      CashAdjType: this.cashAdjustType,
      ShiftNum: this.workstation.workStationId +"-"+(Number(this.workstation.lastShiftNum + 1)),
      UserId: this.currentUser.userId,
      Amount:  Number(this.totalAmt.toFixed(2)),
      Cidtotal: Number(Number(this.currentShiftTotals) + this.totalAmt),
    }
  }
    this._shiftService.SaveCashAdjustDetails(cashDetails)
      .subscribe((res) => {
        debugger;
        var printJobId = res.printJobId;
        if (res.message === 'Success') {
          debugger;
          this.openingCount=0;
          this._shiftService.GetCurrentShiftDetails(this.workstation.workStationId + (Number(this.workstation.lastShiftNum + 1)), this.workstation.workStationId)
            .subscribe((currentShiftRes) => {
              this.currentShiftTransaction = currentShiftRes;
            });

          this.currentShiftTotals = (this.currentShiftTotals + Number(this.totalAmt));
          if(latestUser!=null && latestUser!='' && latestUser!="null")
            {
             localStorage.setItem('latestUser','');
            }
          // Swal.fire({
          //   icon: 'success',
          //   title: 'Success!',
          //   text: 'Transaction completed successfully.'
          // })
          this.toastr.success('Adjustment completed successfully!');

      const messages =  this.infoMessage = `<p class='worstation-img'><img src="./assets/Libs/images/QuestionCircle.png" /></p> <p><h2>Confirmation</h2></p> <p class="assignstyle">Do you want another copy of the Adjustment Report?</p>`;
      this.confirmationService.confirm({
        message: messages,
        rejectVisible: true,
        acceptLabel: 'Continue',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          
        },
        reject: () => {
          //window.location.reload();
        }
      });
         
        } else {
          // Swal.fire({
          //   icon: 'error',
          //   title: 'Error!',
          //   text: 'Something went wrong!',
          // });
          this.toastr.error('Something went wrong!');
        }
      });
  }


  CheckPrinter()
  {
    this._shiftService.GetPrinterStatus(this.workstation.workStationId).subscribe((res) => {
      debugger;
      if(res!=3 && res!=0)
      {
      let printerMessage=  this.authenticationService.GetPrinterMessgae(res);
        Swal.fire({
          icon: 'warning',
          title: 'warning!',
          text: printerMessage,
          timer: 10000, // 10 seconds
          timerProgressBar: true,
        })
      }
    });
  }

  NavValidationModalPopup() {

    if (Number(this.openingCount) === 0) {
      Swal.fire({
        icon: 'warning',
        title: 'Warning!',
        text: 'Please enter some amount!'
      });
      return;
    }
    else{
    Swal.fire({
      text: 'You do not have permission to perform this function. Would you like to enter alternate credentials?​',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        debugger;
        this.LoginAdjuctGroup = this.formBuilder.group({
          UserNameLogin: ['', Validators.required],
          PasswordLogin: ['', Validators.required]
        });
        this.LoginModal = true;
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        debugger;
       // window.location.reload();
      }
    });
  }
  }

AddCashWithOtherUserCredentials()
{
 
    this.cashAdjustType = 'CashIn';
    this.totalAmt = Number(this.openingCount === '' ? 0 : this.openingCount);
    this.infoMessage = `<p class='worstation-img'><img src="./assets/Libs/images/confirmation.png" /></p> <p><h2>Confirmation</h2></p> <p class="assignstyle">Add $${this.totalAmt.toFixed(2)} to the Drawer?</p>`;
    const messages = this.infoMessage;
    this.confirmationService.confirm({
      message: messages,
      header: 'Confirmation',
      rejectVisible: true,
      acceptLabel: 'Continue',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {    
          $('.ui-dialog-mask-scrollblocker').css('display', 'none');
          this.SubmitCashIn();
        
      },
      reject: () => {
      }
    });
  } 

  RemoveCashWithUserCredentials()
  {
    
      this.cashAdjustType = 'CashOut';
      this.totalAmt = -Math.abs(Number(this.openingCount === '' ? 0 : this.openingCount));
      const ttlAmount = Math.abs(Number(this.openingCount === '' ? 0 : this.openingCount));
      this.infoMessage = `<p class='worstation-img'><img src="./assets/Libs/images/confirmation.png" /></p> <p><h2>Confirmation</h2></p> <p class="assignstyle">Remove $${ttlAmount.toFixed(2)} from Drawer?</p>`;  
      const messages = this.infoMessage;
      this.confirmationService.confirm({
        message: messages,
        header: 'Confirmation',
        rejectVisible: true,
        acceptLabel: 'Continue',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {        
            if (Number(this.openingCount) <= Number(this.currentShiftTotals)) {
              $('.ui-dialog-mask-scrollblocker').css('display', 'none');
              this.SubmitCashIn();
            } 
            else {
              Swal.fire({
                icon: 'warning',
                title: 'Warning!',
                text: 'You can not remove more cash then cash in drawer balance!'
              });
            }
          
        },
        reject: () => {
        }
      });
    
  }

}
