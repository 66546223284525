import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/Services/auth.service';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  ForgotPassword:FormGroup;
  submitted:boolean=false;
  error:any;
  constructor(private formBuilder: FormBuilder,private authenticationService: AuthService,private toastr: ToastrService ) { }

  ngOnInit() {
    console.log('origin',location.origin);
debugger;
    this.ForgotPassword = new FormGroup({
      username1: new FormControl('',Validators.required),
      email1: new FormControl('',Validators.required)
    });
  }



    ResetPassword()
{
  debugger;
  this.submitted=true;
  this.error='';
  if (this.ForgotPassword.invalid) {
    return;
  }
  if(this.isEmail(this.f.email1.value))
  {


  let GetGUIDchrowm = localStorage.getItem("MacAddress");
  let decodeGUID=atob(GetGUIDchrowm)
  let CId = localStorage.getItem("Client_Id");
 var userObj = {
  UserName: this.f.username1.value,
  Email: this.f.email1.value,
  Password: '123123',
  GUID: decodeGUID,
  ClientId:6649,
  LocationOrigin:location.origin
  }
  this.authenticationService.ChangePassword(userObj)
  .pipe(first())
  .subscribe(
    data => {
      
      this.toastr.success('Email has been sent successfully. Please check your mail and follow instructions to reset your password.');
      this.submitted=false;
      setInterval(() => {
        location.reload();
      }, 3000);
      
    },
    error => {
      debugger;
      this.error = error.error.message;
    
    });
  }
  else{
    this.error='Email entered is not valid.Please enter valid email address.'
  }
}

get f() { 
  return this.ForgotPassword.controls; }


  isEmail(search:string):boolean
    {
      debugger;
        var  serchfind:boolean;

        var regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

        serchfind = regexp.test(search);

        return serchfind
    }
}



