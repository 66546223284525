import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  public data: any;
  public dataArray: any = [];
  behaviorObj = new BehaviorSubject<any>('');
  behaviorList = new BehaviorSubject<any[]>([]);
  constructor() {
    this.behaviorObj.next(this.data);
    this.behaviorList.next(this.dataArray);
  }

  // Used to share data from one component to another component without relationship
  public setbehaviorObjData(data: any): void {
    this.data = data;
    this.behaviorObj.next(data);
  }
  public setbehaviorListData(dataList: any[]): void {
    this.dataArray = dataList;
    this.behaviorList.next(dataList);
  }
  public getbehaviorObjData() {
    return this.behaviorObj.asObservable();
  }
  public getbehaviorListData() {
    return this.behaviorList.asObservable();
  }
}
