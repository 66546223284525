import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthService } from 'src/app/Services/auth.service';
import { ConfirmationService } from 'primeng/api';
import { IPOSService } from 'src/app/Services/i-pos.service';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { ShiftService } from 'src/app/Services/shift.service';
import { CommonService } from 'src/app/Services/common.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  userName = '';
  password = '';
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  SuccessfullyEndShift:boolean;
  error = '';
  errorfalg:boolean=false;
  eyeShow: boolean = false;
  passwordTextType: string = 'password';
  AlreadyUseWorkstation: boolean;
  UseWorkstationclerkName: any;
  UserForceClose: boolean;
  AutoEODTime:string;
  constructor(private formBuilder: FormBuilder, private _shiftService: ShiftService,private _commonService: CommonService, private confirmationService: ConfirmationService, private toastr: ToastrService,private route: ActivatedRoute, private router: Router, private authenticationService: AuthService, private posSerive: IPOSService) { }
  ngOnInit() {
    debugger;
    this.loginForm = this.formBuilder.group({ username: ['', Validators.required], password: ['', Validators.required] });
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['./WorkstationList'] || '/';
    localStorage.setItem('ShiftStartFlag',JSON.stringify(false))

  }
  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
    debugger;
    this.error='';
    let WsId = JSON.parse(localStorage.getItem("WsId"));
    this.submitted = true;
    
    let userObj;
    let GetGUID
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    this.loading = true;
    let GetGUIDchrowm = localStorage.getItem("MacAddress");
    let decodeGUID=atob(GetGUIDchrowm)
    if (GetGUIDchrowm != null) {
      userObj = {
        UserName: this.f.username.value,
        Password: this.f.password.value,
        GUID: decodeGUID,
      }
    }
    else {
      //GetGUID = this.generate_uuidv4()
      userObj = {
        UserName: this.f.username.value,
        Password: this.f.password.value,
        GUID: decodeGUID,
        workstationId: WsId
      }
    }

    debugger;
    this.authenticationService.login(userObj)
      .pipe(first())
      .subscribe(
        data => {
          if (data.workStationFlag == "WorStationIsInUse") {
            this.AlreadyUseWorkstation = true
            this.UseWorkstationclerkName = data.clerkName;
            // this.WorkstationModelPopup(data.clerkName);
          }
          else if (data.workStationFlag == "UserForceClose") {
           // this.UseWorkstationclerkName = data.clerkName
           this.UseWorkstationclerkName = data.lastUser
          //  this.UserForceClose = true;
            var message = `<b> ${this.UseWorkstationclerkName} </b> has an open shift on this <br>workstation. </br>Would you like to force close the shift in progress?`;          
            Swal.fire({
              icon: 'warning',
              title: 'Warning!',
              html: message,
              confirmButtonColor: "#DD6B55",
              confirmButtonText: "Yes",
              cancelButtonText: "No",
              showCancelButton: true,
            })
            .then((isConfirm) => {
              if (isConfirm.value === true) {
                this.ForceCloseShift();
              }
              else{
                this.Reload();
              }
          });

          }
          else if (data.workStationFlag=="NOT"){
            this.toastr.error("User is not assigned to this workstation’s district. Please contact an administrator.");
          }
          else if(data.workStationFlag=="SessionOutFlag") {
            sessionStorage.setItem('isEOD',JSON.stringify(true));
            this.OpenSessionOutShift(data.workstationDetails);
          }
          else if(data.workStationFlag=="ThisWorkStationIsUsed" || data.workStationFlag=="ThisworstationIsAssignanothersystem"){
            this.toastr.error("Your System is not assigned to any workstation. Please contact your administrator.");
          }
          else{
            sessionStorage.setItem('isEOD',JSON.stringify(true));
            this.OpenHome(data.workstationDetails, GetGUID)
          }
          //this.router.navigate(['/WorkstationList']);
        },
        error => {
          debugger;
          if(error.error.printerStatus==1 || error.error.printerStatus==2)
          {
           this.PrinterStatus();
          }
          else{
          this.error = error.error.message;
          this.loading = false;
          }
        });
  }

  OpenHome(workstationObj, GUID) {
    debugger;
    let checkStatus;
    this.posSerive.GetWorkstationsOnID(workstationObj.workStationId)
      .subscribe((res) => {
        debugger;
        const workstationDetails = res;
        checkStatus = workstationDetails.isInUse;
        this._commonService.GetSiteConfigrationValidated(workstationObj.siteId, workstationObj.districtId)
          .subscribe((res) => {
            //this.siteConfival=res
            localStorage.setItem('ShiftStartFlag',JSON.stringify(true))
            localStorage.setItem('siteConfivalidated', JSON.stringify(res));
            localStorage.setItem('workstation', JSON.stringify(workstationObj));
            localStorage.setItem('StartSessionOutShift',JSON.stringify(false));

            this._shiftService.GetPrinterStatus(workstationObj.workStationId).subscribe((res) => {
              debugger;
              if(res==3 || res==0)
              {
                this.router.navigate(['/Home']);
              }
              else if(res)
              {
              let printerMessage=  this.authenticationService.GetPrinterMessgae(res);
                Swal.fire({
                  icon: 'warning',
                  title: 'warning!',
                  text: printerMessage,
                  timer: 10000, // 10 seconds
                  timerProgressBar: true,
                })
                  .then(() => {
                    this.router.navigate(['/Home']);
                  });


              }
            });
            
            let siteConfi = JSON.parse(localStorage.getItem("siteConfivalidated"))
            if(siteConfi.AutoEOD)
              {

              }
          
          });

      });
   
   
  }

  OpenSessionOutShift(workstationObj) {
    debugger;
    let checkStatus;
    this.posSerive.GetWorkstationsOnID(workstationObj.workStationId)
      .subscribe((res) => {
        debugger;
        const workstationDetails = res;
        checkStatus = workstationDetails.isInUse;
        this._commonService.GetSiteConfigrationValidated(workstationObj.siteId, workstationObj.districtId)
          .subscribe((res) => {
            //this.siteConfival=res
            localStorage.setItem('siteConfivalidated', JSON.stringify(res));
            localStorage.setItem('workstation', JSON.stringify(workstationObj));
            localStorage.setItem('StartSessionOutShift',JSON.stringify(true));
            localStorage.setItem('ShiftStartFlag',JSON.stringify(true))
            this.router.navigate(['/Home']);
          });

      });
  }

  ForceCloseShift(){
    debugger;
    let decodeGUID = localStorage.getItem("MacAddress");
    let MacAddress=atob(decodeGUID)
    this.UserForceClose = false;
    let UserId = JSON.parse(localStorage.getItem("currentUser"));
    this.authenticationService.ForceCloseShiftUser(MacAddress,UserId.userId).subscribe((forceClose)=>{
      if(forceClose){
      // this.SuccessfullyEndShift=true

       Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: 'End of Day completed successfully!'
      })
      .then(() => {
        this.Reload();
      });
      }
      else{

      }

    })
  }

  WorkstationModelPopup(UserName) {
    Swal.fire({
      icon: 'warning',
      title: 'Warning!',
      text: UserName + '  has an open shift on this workstation.​',
    });
  }

  generate_uuidv4(): any {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g,
      function (c) {
        var uuid = Math.random() * 16 | 0, v = c == 'x' ? uuid : (uuid & 0x3 | 0x8);
        return uuid.toString(16);
      });
  }
  Reload() {
    debugger;
    window.location.reload();
  }
  eyeShowHide() {
    this.eyeShow = !this.eyeShow;
    if (this.eyeShow === true) {
      this.passwordTextType = 'text';
    }
    else {
      this.passwordTextType = 'password';
    }
  }

PrinterStatus() {
  Swal.fire({
    icon: 'error',
    title: "<h1 style='color:red'>" + 'STOP!' + "</h1>",
    text:  "   Printer Not Found. Please Contact Support."
  });
}


ResetPassword()
{
  debugger;
  //this.router.navigate(['../ForgotPassword'], { relativeTo: this.route });
  //this.router.navigate(['./ForgotPassword'], { relativeTo: this.route });
  //this.router.navigate(['/ForgotPassword'], { relativeTo: this.route });
  this.router.navigate(['/forgotPassword']);
}

}
