import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { AuthService } from 'src/app/Services/auth.service';
import { IPOSService } from 'src/app/Services/i-pos.service';
import { InventoryService } from 'src/app/Services/inventory.service';
import { ShiftService } from 'src/app/Services/shift.service';
import Swal from 'sweetalert2';
import { AddInventorygrid } from '../add-inventorygrid.model';
import { InventoryModel } from '../inventory-model.model';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery';
import { PortExeMsgHelper } from 'src/app/shared/helpers/port-exe-msg.helper';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NavigationComponent } from '../navigation.component';

@Component({
  selector: 'app-new-transfer',
  templateUrl: './new-transfer.component.html',
  styleUrls: ['./new-transfer.component.css']
})
export class NewTransferComponent implements OnInit {
  position: string;
  workstationList: any = [];
  vendorList: any = [];
  currentUser: any;
  viewData: any = [];
  viewTransferData: any = [];
  viewProdDescData: any = [];
  viewProdTransferData: any = [];
  SelectedProduct = 0;
  viewInventoryTransfer: any;
  newInventoryTansfer: any;
  transferId: any;
  printJobId: 0;
  transferDate: any;
  isTransferProd = false;
  isProdSelect = true;
  totalSelectedQTY: any;
  selectedOrderId: any;
  onHandId = 0;
  TransferToWsid: any;
  dynamicArray: Array<AddInventorygrid> = [];
  transferDetails: Array<InventoryModel> = [];
  newDynamic: any = {};
  InventoryList: any = {};
  transferProdId: any;
  transferQty = "";
  SelectedTransferId: any;
  SelectedFromWsid: any;
  SelectedToWsid: any;
  showsToast : boolean=false;
  workstation = JSON.parse(localStorage.getItem('workstation'));
  LoginAdjuctGroup: FormGroup;
  returnComponent:string;
  feature:string;
  subFeature:string;
  MySingInModal: boolean;
  errormsg:any;
  issubmitted:boolean=false;
  constructor(private route: ActivatedRoute,
    private router: Router,
    private _shiftService: ShiftService,
    private authenticationService: AuthService,
    private confirmationService: ConfirmationService,
    //private _middlewareService: MiddleWare,
    private posSerive: IPOSService,
    private invService: InventoryService, private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private nav: NavigationComponent,) { }

  ngOnInit() {
    debugger;
    this.LoginAdjuctGroup = this.formBuilder.group({
      UserNameLogin: ['', Validators.required],
      PasswordLogin: ['', Validators.required]
    });
    this.CheckPrinter();
    // Getting Current Shift Details
    this.currentUser = this.authenticationService.currentUserValue;

    this.getNewTransfer();
    $(".navigation ul li a").removeClass("active");
    $(".envSec").addClass("active");
    $(".salesSec, .transaSec, .cashSec").removeClass("active");


  }


  get Login() { 
    return this.LoginAdjuctGroup.controls; }

  getNewTransfer() {
    this.isProdSelect = true;
    this.isTransferProd = false;
    let newTime = new Date().toLocaleString();
    this.transferId = this.workstation.workStationId + newTime.replace(/[#_ : APM , /]/g, '');

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    this.transferDate = mm + '/' + dd + '/' + yyyy;
    this.transferDetails = [];
    this.dynamicArray = [];

    //Get Inventory Workstation
    this.posSerive.GetWorkstations(this.currentUser.districtNum,this.workstation.workStationId)
      .subscribe((res) => {
        debugger;
        this.workstationList = res.filter(x => x.workStationId !== this.workstation.workStationId);
        console.log(res);
      });

  }

  ResetTransaction() {
    //window.location.reload();
    this.TransferToWsid = 0;
    this.nav.isdisabledNav=false;
   this.getNewTransfer();
  }

  addFieldValue(e,dynamicArray) {
    debugger;
    this.isProdSelect=false;
    let adjusmelength=0;
   
    if (this.dynamicArray.filter(x => x.ProductId === 0 ||  x.Quantity === '').length === 0) {
      for (let i = 0; i < this.dynamicArray.length; i++) {
        if (this.dynamicArray[i].ProductId === 0 || this.dynamicArray[i].ProductId === '0' && !this.isProdSelect)
        {
          this.toastr.error("Please select Product");
          this.isProdSelect = true;
  
        }
        if(this.dynamicArray[i].Quantity === '' || this.dynamicArray[i].Quantity === null && this.isProdSelect){
          if(!this.isProdSelect){
            this.toastr.error("Please Enter Quantity");
            this.isProdSelect = true;
          }
        }
        if(this.dynamicArray[i].ProductId.totalProdQTY >= this.dynamicArray[i].Quantity){
          adjusmelength=adjusmelength+1;
          if(adjusmelength==this.dynamicArray.length){
            this.newDynamic = { SerialNo: 1, ProductId: 0, Quantity: "" };
            this.dynamicArray.push(this.newDynamic);
            return true;
          }
        }
        else{
          this.toastr.error("Cannot transfer more than the onhand quantity for this product ("+this.dynamicArray[i].ProductId.totalProdQTY+")");
        }
        
      }
    }
    else{
      this.toastr.error("Please select or fill all required fields");
    }
    
  }

  deleteRow(index) {
    debugger;
    if (this.dynamicArray.length == 1) {
      // this.toastr.warning("Can't delete the row when there is only one row", 'Warning');
      this.dynamicArray[0].ProductId='0'
      this.dynamicArray[0].Quantity=''
      this.dynamicArray[0].SerialNo='0'
      this.isProdSelect=true
      return false;
    } 
    else {
      this.dynamicArray.splice(index, 1);
      return true;
    }
  }



  SaveInventory(CloseOrNew) {
    debugger;
    const messages = `<p class='worstation-img'><img src='./assets/Libs/images/QuestionCircle.png' /><p class='assignstyle'>This will remove inventory from the ${ this.workstation.workStationName +'-'+ this.workstation.workStationId } Workstation. Do you want to continue?</p>`;
    this.confirmationService.confirm({
      message: messages,
      header: 'Transfer',
      rejectVisible: true,
      acceptLabel: 'Yes',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        $('.ui-dialog-mask-scrollblocker').css('display', 'none');
        if (CloseOrNew === 'SaveClose') {
          this, this.SaveTransferAndClose();
        } else {
          this, this.SaveTransferAndNew();
        }

      },
      reject: () => {
        $('.ui-dialog-mask-scrollblocker').css('display', 'none');
        this.ResetTransaction();
      }
    });
  }

  SaveTransferAndClose() {
    debugger;
    var prod = this.dynamicArray;
    const ts = this;

    var latestUser= localStorage.getItem("latestUser");
    let transactionInfo={};
     if(latestUser!=null && latestUser!='' && latestUser!="null")
     {
    prod.forEach(function (value) {
      console.log(value);
      ts.InventoryList = {
        TransferId: ts.transferId,
        DistrictId: ts.currentUser.districtNum,
        TransferFromWsid: ts.workstation.workStationId,
        TransferFromUserId: latestUser,
        TransferToWsid: ts.TransferToWsid,
        ProductId: value.ProductId.productId,
        Qty: value.Quantity,
        Status: 'Pending',
        OnHandId: ts.onHandId
      }
      ts.transferDetails.push(ts.InventoryList);
    });
  }
  else{
    prod.forEach(function (value) {
      console.log(value);
      ts.InventoryList = {
        TransferId: ts.transferId,
        DistrictId: ts.currentUser.districtNum,
        TransferFromWsid: ts.workstation.workStationId,
        TransferFromUserId: ts.currentUser.userId,
        TransferToWsid: ts.TransferToWsid,
        ProductId: value.ProductId.productId,
        Qty: value.Quantity,
        Status: 'Pending',
        OnHandId: ts.onHandId
      }
      ts.transferDetails.push(ts.InventoryList);
    });
}
    if (this.transferDetails !== undefined && this.transferDetails.length > 0) {
      this._shiftService.SaveInventorytDetails(this.transferDetails)
        .subscribe((result) => {
          if (result.message == 'Success') {
            this.toastr.success('Transfer has been created successfully.');
           // this.PrintReport();
           if(latestUser!=null && latestUser!='' && latestUser!="null")
            {
             localStorage.setItem('latestUser','');
            }
            this.router.navigate(['../DashBoard'], { relativeTo: this.route });
            $(".navigation ul li a").removeClass("active");
            $("ul.submenu").removeClass("show");
            $(".homeSec").addClass("active");
            

          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error!',
              text: 'Something Went Wromg!'
            });
          }
        });
    }
  }

  SaveTransferAndNew() {
    debugger;
    var prod = this.dynamicArray;
    const ts = this;

    var latestUser= localStorage.getItem("latestUser");
    let transactionInfo={};
     if(latestUser!=null && latestUser!='' && latestUser!="null")
     {
    prod.forEach(function (value) {
      console.log(value);
      ts.InventoryList = {
        TransferId: ts.transferId,
        DistrictId: ts.currentUser.districtNum,
        TransferFromWsid: ts.workstation.workStationId,
        TransferFromUserId: latestUser,
        TransferToWsid: ts.TransferToWsid,
        ProductId: value.ProductId.productId,
        Qty: value.Quantity,
        Status: 'PENDING',
        OnHandId: ts.onHandId
      }
      ts.transferDetails.push(ts.InventoryList);
    });
  }
  else{
    prod.forEach(function (value) {
      console.log(value);
      ts.InventoryList = {
        TransferId: ts.transferId,
        DistrictId: ts.currentUser.districtNum,
        TransferFromWsid: ts.workstation.workStationId,
        TransferFromUserId: ts.currentUser.userId,
        TransferToWsid: ts.TransferToWsid,
        ProductId: value.ProductId.productId,
        Qty: value.Quantity,
        Status: 'PENDING',
        OnHandId: ts.onHandId
      }
      ts.transferDetails.push(ts.InventoryList);
    });
  }
    if (this.transferDetails !== undefined && this.transferDetails.length > 0) {
      //Saving New Transfer Data
      this._shiftService.SaveInventorytDetails(this.transferDetails)
        .subscribe((result) => {
          if (result.message == 'Success') {
            if(latestUser!=null && latestUser!='' && latestUser!="null")
              {
               localStorage.setItem('latestUser','');
              }
            this.printJobId = result.printJobId;
            this.toastr.success('Transfer has been created successfully.');
            //this.PrintReport();
            setInterval(() => {
              location.reload();
            }, 1500);
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error!',
              text: 'Something Went Wromg!'
            });
          }
        });
    }
    this.getNewTransfer();
  }

  getProdForTransfer(workststionId) {
    debugger;
    if(workststionId==0){
      this.isTransferProd = false;
      this.TransferToWsid = 0;
      this.dynamicArray.length=0;
      this.nav.isdisabledNav=false;
    }
    else{
      this.nav.isdisabledNav=true;
    this.isTransferProd = true;
    this.TransferToWsid = workststionId;

    this.invService.GetInventoryCount(this.workstation.workStationId,false,this.currentUser.districtNum)
      .subscribe((viewInvetory) => {
        this.viewProdTransferData = viewInvetory;
        console.log(viewInvetory);
      });
      if(this.dynamicArray.length<=0)
      {
    this.newDynamic = { SerialNo: 1, ProductId: 0, Quantity: "" };
    this.dynamicArray.push(this.newDynamic);
      }
    }
  }

  selectProdForTransfer(totalQty, e) {
    debugger;
    this.totalSelectedQTY = totalQty.totalProdQTY;
    this.transferProdId = totalQty.productId;
    this.onHandId = totalQty.onHandId;

    if (this.dynamicArray.filter(x => x.ProductId.productId === totalQty.productId).length > 1) {
      this.dynamicArray.splice(1, 1);
      this.newDynamic = { AddOrRemove: 0, SerialNo: 1, ProductId: 0, Quantity: "" };
      this.dynamicArray.push(this.newDynamic);
      this.toastr.error("You can not select same product !")
    }
    else {
      if (e.ProductId.totalProdQTY < e.Quantity) {
        this.toastr.error("Quantity can not be more than total quantity(" + this.totalSelectedQTY + ")");
        this.isProdSelect = true;
      }
    }
  }

  CheckAvailableQTY(saveClose,e,dynamicArray) {
    debugger;
    let adjusmelength=0;
    this.isProdSelect=false;
    if(this.dynamicArray.length>0){
      if (this.nav.InventoryMngmt.newTransafer === 1) {

    for (let i = 0; i < this.dynamicArray.length; i++) {
      
      if((i === (this.dynamicArray.length - 1) && this.dynamicArray.length >1 ) && (this.dynamicArray[i].ProductId === 0 || this.dynamicArray[i].ProductId === '0') && (this.dynamicArray[i].Quantity === '' || this.dynamicArray[i].Quantity === null) )
        {
          this.dynamicArray = this.dynamicArray.slice(0, -1)
          this.SaveInventory(saveClose);
          break;
        }

    if (this.dynamicArray[i].ProductId === 0 || this.dynamicArray[i].ProductId === undefined || this.dynamicArray[i].ProductId === '0') {
      this.isProdSelect = true;
      this.toastr.error("Please select Product!");
      return;
    } 
    if(this.dynamicArray[i].Quantity === '' || this.dynamicArray[i].Quantity === null && this.isProdSelect){
      if(!this.isProdSelect){
        this.toastr.error("Please Enter Quantity");
        this.isProdSelect = true;
        return;
      }
    }
    if (parseInt(this.dynamicArray[i].Quantity) > parseInt(this.dynamicArray[i].ProductId.totalProdQTY)) 
    {
        this.toastr.error("Cannot transfer more than the onhand quantity for this product ("+this.dynamicArray[i].ProductId.totalProdQTY+")");
        this.isProdSelect = true;
        return;
      } 
      else {
        adjusmelength=adjusmelength+1
        if(adjusmelength==this.dynamicArray.length && !this.isProdSelect){
          this.isProdSelect = false;
          this.SaveInventory(saveClose)
        }
      }
    }
  }
  else{
    this.feature='Inventory Management';
    this.subFeature='New Transfer';
    this.NavValidationModalPopup();
  }
  }
  else{
    this.toastr.error("Please select transfer location");
  }
  }

  
  CheckPrinter()
  {
    this._shiftService.GetPrinterStatus(this.workstation.workStationId).subscribe((res) => {
      debugger;
      if(res!=3 && res!=0)
      {
      let printerMessage=  this.authenticationService.GetPrinterMessgae(res);
        Swal.fire({
          icon: 'warning',
          title: 'warning!',
          text: printerMessage,
          timer: 10000, // 10 seconds
          timerProgressBar: true,
        })
      }
    });
  }


  AdjustLogin() {
    debugger;
    this.issubmitted=true;
    if (this.LoginAdjuctGroup.invalid) {
      return;
    }
    console.log(this.LoginAdjuctGroup.value);
    let GetGUIDchrowm = localStorage.getItem("MacAddress");
    let decodeGUID=atob(GetGUIDchrowm)
    let userObj = {
      UserName: this.LoginAdjuctGroup.controls.UserNameLogin.value,
      Password: this.LoginAdjuctGroup.controls.PasswordLogin.value,
      GUID: decodeGUID,
      Feature:this.feature,
      SubFeature:this.subFeature
      
    }
    this.authenticationService.UserLoginForOtherPermissions(userObj)
    .subscribe((res) => {
          debugger;
          this.MySingInModal = false;
          if(res.hasAccess){ 
this.TransferWithOtherUserCredentials();
        }     

        else{
          this.NavValidationModalPopup();
        }    
        this.issubmitted=false;
        },
        error => {
          debugger;
          this.errormsg = error.error.message;
          this.toastr.error(this.errormsg);
          if (this.errormsg == 'Not Use This Workstation') {
            this.toastr.error("This workstation is not access to you, please enter the different credentials");
          };
        });
  }

  hide(signclose: string) {
    debugger;
    if (signclose === 'SignInModal') {
      this.MySingInModal = false;
      this.issubmitted=false;
    }
  }

  NavValidationModalPopup() {
    Swal.fire({
      text: 'You do not have permission to perform this function. Would you like to enter alternate credentials?​',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        debugger;
        this.LoginAdjuctGroup = this.formBuilder.group({
          UserNameLogin: ['', Validators.required],
          PasswordLogin: ['', Validators.required]
        });
        this.MySingInModal = true;
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        debugger;
       // window.location.reload();
      }
    });
  }

TransferWithOtherUserCredentials()
{
  let adjusmelength=0;
  for (let i = 0; i < this.dynamicArray.length; i++) {
    if (this.dynamicArray[i].ProductId === 0 || this.dynamicArray[i].ProductId === undefined || this.dynamicArray[i].ProductId === '0') {
      this.isProdSelect = true;
      this.toastr.error("Please select Product!");
      return;
    } 
    if(this.dynamicArray[i].Quantity === '' || this.dynamicArray[i].Quantity === null && this.isProdSelect){
      if(!this.isProdSelect){
        this.toastr.error("Please Enter Quantity");
        this.isProdSelect = true;
        return;
      }
    }
    if (parseInt(this.dynamicArray[i].Quantity) > parseInt(this.dynamicArray[i].ProductId.totalProdQTY)) 
    {
        this.toastr.error("Cannot transfer more than the onhand quantity for this product ("+this.dynamicArray[i].ProductId.totalProdQTY+")");
        this.isProdSelect = true;
        return;
      } 
      else {
        adjusmelength=adjusmelength+1
        if(adjusmelength==this.dynamicArray.length && !this.isProdSelect){
          this.isProdSelect = false;
          this.SaveInventory('SaveAndNew');
        }
      }
    }
    
}

CancelTransaction(){
  debugger;
  if(this.TransferToWsid>0){
  const messages = `<p class='worstation-img'><img src="./assets/Libs/images/confirmation.png" /></p> <h3 class="pop-head">Warning!</h3> <p class="assignstyle">Would you like abandon your entries?</p>`;
  this.confirmationService.confirm({
    message: messages,
    rejectVisible: true,
    acceptLabel: 'Yes',
    icon: 'pi pi-exclamation-triangle',
    accept: () => {
      $('.ui-dialog-mask-scrollblocker').css('display', 'none');
      this.nav.isdisabledNav=false;
      window.location.reload();
    },
    reject: () => {
      $('.ui-dialog-mask-scrollblocker').css('display', 'none');
      //window.location.reload();
    }
  });
}
}

}
