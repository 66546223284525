import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/Services/auth.service';
import { IPOSService } from 'src/app/Services/i-pos.service';
import { InventoryService } from 'src/app/Services/inventory.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import Swal from 'sweetalert2';
import { AddInventorygrid } from '../add-inventorygrid.model';
import { InventoryModel } from '../inventory-model.model';
import { ShiftService } from 'src/app/Services/shift.service';

@Component({
  selector: 'app-inventorymanagement',
  templateUrl: './inventorymanagement.component.html',
  styleUrls: ['./inventorymanagement.component.css']
})

export class InventorymanagementComponent implements OnInit {
  position: string;
  workstationList: any = [];
  vendorList: any = [];
  currentUser: any;
  viewData: any = [];
  viewTransferData: any = [];
  viewProdDescData: any = [];
  viewProdTransferData: any = [];
  SelectedProduct = 0;
  viewInventoryTransfer: any;
  newInventoryTansfer: any;
  transferId: any;
  transferDate: any;
  isTransferProd = false;
  isProdSelect = true;
  totalSelectedQTY: any;
  selectedOrderId: any;
  onHandId = 0;
  TransferToWsid: any;
  dynamicArray: Array<AddInventorygrid> = [];
  transferDetails: Array<InventoryModel> = [];
  newDynamic: any = {};
  InventoryList: any = {};
  transferProdId: any;
  transferQty = "";
  SelectedTransferId: any;
  SelectedFromWsid: any;
  SelectedToWsid: any;
  workstation = JSON.parse(localStorage.getItem('workstation'));
  constructor(private InventoryService: InventoryService, private posSerive: IPOSService, private authenticationService: AuthService, private toastr: ToastrService, private confirmationService: ConfirmationService,private _shiftService: ShiftService) { }

  ngOnInit() {
    debugger;
    // Getting Current Shift Details
    this.currentUser = this.authenticationService.currentUserValue;

    //Get Inventory Workstation
    this.posSerive.GetWorkstations(this.currentUser.districtNum,'0')
      .subscribe((res) => {
        this.workstationList = res;
        console.log(res);
      });

    this.posSerive.GetVendorMenusByDist(this.currentUser.districtNum)
      .subscribe((vendors) => {
        this.vendorList = vendors;
        console.log(vendors);
      });

  }

  getOnHandCount(workststionId) {
    debugger;
    this.InventoryService.GetInventoryCount(workststionId,false,this.currentUser.districtNum)
      .subscribe((viewInvetory) => {
        this.viewData = viewInvetory;
        console.log(viewInvetory);
      });

  }

  getProdForTransfer(workststionId) {
    debugger;
    this.isTransferProd = true;
    this.TransferToWsid = workststionId;

    this.InventoryService.GetInventoryCount(this.workstation.workStationId,false,this.currentUser.districtNum)
      .subscribe((viewInvetory) => {
        this.viewProdTransferData = viewInvetory;
        console.log(viewInvetory);
      });

    this.newDynamic = { SerialNo: 1, ProductId: 0, Quantity: "" };
    this.dynamicArray.push(this.newDynamic);
  }

  getInventoryTransferList() {
    debugger;
    this.InventoryService.GetViewTransfer(this.currentUser.districtNum)
      .subscribe((viewInv) => {

        var newArr = []
        viewInv.forEach((item, index) => {
          if (newArr.findIndex(i => i.transferId == item.transferId) === -1) {
            newArr.push(item)
          }

        });
        this.viewTransferData = newArr;
        console.log(viewInv);

      })
  }

  ChangeStatus(transferId, toWs) {
    debugger;
    
    const messages = '<img src="./assets/Libs/images/WarnQues.png" class="imgconfQues" /><p>This will add Inventory to the ' + toWs + ' Workstation. Do you want to continue?</p>';
    this.confirmationService.confirm({
      message: messages,
      header: 'Confirmation',
      rejectVisible: true,
      acceptLabel: 'Yes',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        // Du here update operation
        this.ChangeTransferStstus(transferId);
      },
      reject: () => {
      }
    });
  }

  ChangeTransferStstus(transferId) {

    let updateStatus = {
      transferId:transferId,
      status:'COMPLETED',
      currentShiftNum:'',
      districtNum:0,
      workId:this.workstation.workStationId,
      userId:this.currentUser.userId
    }
    //this.InventoryService.ChangeInventoryTransferStstus(transferId, 'Completed','',0,this.workstation.workStationId,this.currentUser.userId)
    this.InventoryService.ChangeInventoryTransferStstus(updateStatus)
      .subscribe((res) => {
        debugger;
        if (res === 'Success') {
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: 'Data Save Successfully!'
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: 'Something Went Wromg!'
          });
        }
      });
  }

  ViewTransferStatus(transferId) {
    debugger;
    this.InventoryService.ViewProdDescription(transferId, this.currentUser.districtNum)
      .subscribe((viewProd) => {
        this.viewProdDescData = viewProd;
        console.log(viewProd);
        this.SelectedTransferId = this.viewProdDescData[0].transferId;
        this.SelectedFromWsid = this.viewProdDescData[0].transferFromWsid;
        this.SelectedToWsid = this.viewProdDescData[0].transferToWsid;

      })
  }
  DeleteTransfer(transferId) {
    debugger;
  }

  selectProdForTransfer(totalQty) {
    debugger;
    this.totalSelectedQTY = totalQty.totalProdQTY;
    this.transferProdId = totalQty.productId;
    this.onHandId = totalQty.onHandId
  }

  CheckAvailableQTY(e) {
    debugger;
    if (parseInt(e.target.value) > parseInt(this.totalSelectedQTY)) {
      this.toastr.error("Quantity can not be more than total quantity(" + this.totalSelectedQTY + ")");
      this.isProdSelect = true;
    } else {
      this.transferQty = "" + this.transferQty + e.target.value;
      this.isProdSelect = false;
    }
  }

  getNewTransfer() {
    this.isProdSelect = true;
    this.isTransferProd = false;
    let newTime = new Date().toLocaleString();
    this.transferId = this.workstation.workStationId + newTime.replace(/[#_ : PM , /]/g, '');

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    this.transferDate = mm + '/' + dd + '/' + yyyy;
    this.transferDetails = [];
    this.dynamicArray = [];
  }

  ResetTransaction() {
    this.getNewTransfer();
  }

  addFieldValue() {
    this.newDynamic = { SerialNo: 1, ProductId: 0, Quantity: "" };
    this.dynamicArray.push(this.newDynamic);
    return true;
  }

  deleteRow(index) {
    if (this.dynamicArray.length == 1) {
      this.toastr.warning("Can't delete the row when there is only one row", 'Warning');
      return false;
    } else {
      this.dynamicArray.splice(index, 1);
      return true;
    }
  }

  SaveTransferAndClose() {
    debugger;
    var prod = this.dynamicArray;
    const ts = this;
    prod.forEach(function (value) {
      console.log(value);
      ts.InventoryList = {
        TransferId: ts.transferId,
        DistrictId: ts.currentUser.districtNum,
        TransferFromWsid: ts.workstation.workStationId,
        TransferFromUserId: ts.currentUser.userId,
        TransferToWsid: ts.TransferToWsid,
        ProductId: value.ProductId.productId,
        Qty: value.Quantity,
        Status: 'Pending',
        OnHandId: ts.onHandId
      }
      ts.transferDetails.push(ts.InventoryList);
    });

    //Saving New Transfer Data
    this._shiftService.SaveInventorytDetails(this.transferDetails)
      .subscribe((result) => {
        if (result == 'Success') {
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: 'Data Save Successfully!'
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: 'Something Went Wromg!'
          });
        }
      });
    this.getNewTransfer();
  }

}
