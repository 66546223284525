
<div class="login-container" style="background-color: #0082D2;">
    <!-- <div class="login-container" style="background-color: #0082D2;"></div> -->
    <div class="workstation-section">
        <div class="logo mb-3 text-center">
            <img src="./assets/Libs/images/logo.png" alt="">
        </div>
        <div class="login-panel mt-0 p-5">
            <h1 class="mb-4">Select Workstation</h1>
            <div class="workstation-list ">
                <ul class="work-ul" id="div-to-scroll">
                    <li *ngFor="let work of workstationList;" [style.color]="work.isInUse?'red':''">
                        <a (click)="OpenHome(work)" class="workstation-li" [class.disabled]="work.isInUse">
                            <span>{{work.workStationName}}</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<p-confirmDialog acceptLabel="Assign" rejectLabel="Cancel" appendTo="body">
    <ng-template pTemplate="body">
        <span class="ui-confirmdialog-message">{{message}}</span>
    </ng-template>

</p-confirmDialog>