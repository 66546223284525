import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { RefundDetails } from '../Models/Refund';
import { configConstant } from '../shared/constants/config.constant';
@Injectable({
    providedIn: 'root'
})
export class ShiftService {
    private baseurl = configConstant.apibaseurl;
    constructor(private http: HttpClient) { }


    GetPreviousShiftBalence(lastShiftNum): Observable<any> {
        let CId = localStorage.getItem("Client_Id");
        const headers = new HttpHeaders().set('Client', CId);
        return this.http.get<any>(this.baseurl + '/Shift/PreviousShiftBalence/' + lastShiftNum, { headers })
            .pipe(
                retry(1),
                catchError(this.errorHandl)
            );
    }

    GetPreviousShiftOpeningBalence(lastShiftNum): Observable<any> {
        let CId = localStorage.getItem("Client_Id");
        const headers = new HttpHeaders().set('Client', CId);
        return this.http.get<any>(this.baseurl + '/Shift/GetPreviousShiftOpenBalence/' + lastShiftNum, { headers })
            .pipe(
                retry(1),
                catchError(this.errorHandl)
            );
    }

    SaveOpenShiftDetails(shiftDetails): Observable<any> {
        let CId = localStorage.getItem("Client_Id");
        const headers = new HttpHeaders().set('Client', CId);
        return this.http.post<any>(this.baseurl + '/Shift/SaveOpeningShiftDetails', shiftDetails, { headers })
            .pipe(
                retry(1),
                catchError(this.errorHandl)
            );
    }

    GetCurrentShiftTotals(currentShiftNum: any, workstationId: any): Observable<any> {
        let CId = localStorage.getItem("Client_Id");
        const headers = new HttpHeaders().set('Client', CId);
        return this.http.get<any>(this.baseurl + '/Shift/CurrentShiftBalence/' + currentShiftNum + '/' + workstationId, { headers })
            .pipe(
                retry(1),
                catchError(this.errorHandl)
            );
    }
    GetCurrentShiftTenderBalance(currentShiftNum: any, workstationId: any): Observable<any> {
        let CId = localStorage.getItem("Client_Id");
        const headers = new HttpHeaders().set('Client', CId);
        return this.http.get<any>(this.baseurl + '/Shift/CurrentShiftTenderBalance/' + currentShiftNum + '/' + workstationId, { headers })
            .pipe(
                retry(1),
                catchError(this.errorHandl)
            );
    }


    GetCashActivityCount(shiftNum): Observable<any> {
        let CId = localStorage.getItem("Client_Id");
        const headers = new HttpHeaders().set('Client', CId);
        return this.http.get<any>(this.baseurl + '/Shift/GetCashActivityCounts/' + shiftNum, { headers })
            .pipe(
                retry(1),
                catchError(this.errorHandl)
            );
    }


    GetCurrentShiftDetails(currentShiftNum: any, workstationId: any): Observable<any> {
        let CId = localStorage.getItem("Client_Id");
        const headers = new HttpHeaders().set('Client', CId);
        return this.http.get<any>(this.baseurl + '/Shift/GetCurrentShiftTransactions/' + currentShiftNum + '/' + workstationId, { headers })
            .pipe(
                retry(1),
                catchError(this.errorHandl)
            );
    }

    SaveCloseShiftDetails(shiftDetails): Observable<any> {
        let CId = localStorage.getItem("Client_Id");
        const headers = new HttpHeaders().set('Client', CId);
        return this.http.post<any>(this.baseurl + '/Shift/CloseCurrentShift', shiftDetails, { headers })
            .pipe(
                retry(1),
                catchError(this.errorHandl)
            );
    }
    SaveOpenShiftReport(shiftDetails): Observable<any> {
        let CId = localStorage.getItem("Client_Id");
        const headers = new HttpHeaders().set('Client', CId);
        return this.http.post<any>(this.baseurl + '/Shift/SaveShiftStartReceiptData', shiftDetails, { headers })
            .pipe(
                retry(1),
                catchError(this.errorHandl)
            );
    }

    SaveCashadjustReport(cashReceipt): Observable<any> {
        let CId = localStorage.getItem("Client_Id");
        const headers = new HttpHeaders().set('Client', CId);
        return this.http.post<any>(this.baseurl + '/Shift/SaveCashAdhustReceiptData', cashReceipt, { headers })
            .pipe(
                retry(1),
                catchError(this.errorHandl)
            );
    }

    SaveCloseShiftReport(shiftDetails): Observable<any> {
        let CId = localStorage.getItem("Client_Id");
        const headers = new HttpHeaders().set('Client', CId);
        return this.http.post<any>(this.baseurl + '/Shift/SaveShiftCloseReceiptData', shiftDetails, { headers })
            .pipe(
                retry(1),
                catchError(this.errorHandl)
            );
    }

    SaveCashAdjustDetails(cashDetails): Observable<any> {
        let CId = localStorage.getItem("Client_Id");
        const headers = new HttpHeaders().set('Client', CId);
        return this.http.post<any>(this.baseurl + '/Shift/AddjustCashInAndOut', cashDetails, { headers })
            .pipe(
                retry(1),
                catchError(this.errorHandl)
            );
    }

    SaveInventorytDetails(transferDetails): Observable<any> {
        debugger;
        let CId = localStorage.getItem("Client_Id");
        const headers = new HttpHeaders().set('Client', CId);
        return this.http.post<any>(this.baseurl + '/Shift/SaveTransferDetails', transferDetails, { headers })
            .pipe(
                retry(1),
                catchError(this.errorHandl)
            );
    }

    GetTxTypeListData(siteId): Observable<any> {
        let CId = localStorage.getItem("Client_Id");
        const headers = new HttpHeaders().set('Client', CId);
        return this.http.get<any>(this.baseurl + '/Shift/GetTxTypeList/' + siteId, { headers })
            .pipe(
                retry(1),
                catchError(this.errorHandl)
            );
    }

    GetPrinterStatus(workStationId): Observable<any> {
debugger;
        let CId = localStorage.getItem("Client_Id");
        const headers = new HttpHeaders().set('Client', CId);
        return this.http.get<any>(this.baseurl + '/Shift/CheckPrinterStatus/' + workStationId, { headers })
            .pipe(
                retry(1),
                catchError(this.errorHandl)
            );
    }

    GetReceiptsType(workStationId): Observable<any> {
        debugger;
                let CId = localStorage.getItem("Client_Id");
                const headers = new HttpHeaders().set('Client', CId);
                return this.http.get<any>(this.baseurl + '/Shift/CheckReceiptsType/' + workStationId, { headers })
                    .pipe(
                        retry(1),
                        catchError(this.errorHandl)
                    );
            }

    errorHandl(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
    }
}
