<div class="login-container" style="background-color: #0082D2;">
    <!-- <div class="login-container" style="background-color: #0082D2;"></div> -->

</div>

<div [style.display]="WorkstationSelectionModal? 'block' : 'none'" class="modal name-popup" id="imgmodal" tabindex="-1"
    role="dialog" aria-labelledby="myMLabel" aria-hidden="true">
    <div class="modaldialog">
        <div class="signinClass">
            <div class="modal-header SignInHeader">
                <h4><img class="giftImage" src="./assets/Libs/images/HeaderLogo.png" height="16">GiftBox Setup Wizard
                </h4>
                <!-- <button type="button" c lass="close" data-dismiss="modal">×</button> -->
            </div>
            <div class="modal-body bodyClass p-0">
                <div class="secodHeader">
                    <img class="GiftBoxLogo" src="./assets/Libs/images/GiftLog.png" height="16">
                </div>
                <div class="formClass cstm_mainFormBox">
                    <h2>Next: Select the Workstation Assignment</h2>

                    <div class="cstmInputBox">
                        <select class="form-control formdata"[(ngModel)]="WorkstationDetails" [ngModelOptions]="{standalone: true}">
                            <option value="--Select Workstation--">--Select Workstation--</option>
                            <option *ngFor="let wsdetails of WorstationList" [value]="wsdetails.workstationId">
                                {{wsdetails.workstationName}}
                            </option>
                        </select>
                    </div>
                    <div>
                        <span class="ErrorText" >{{Message}}</span>
                    </div>
                    <div class="modal_BTNBox">
                        <button (click)="CheckWorkstationAssociatedAlready()"class="btn btn-light btnSubmit">Continue</button>
                    </div>
                </div>
            </div>
            <!-- <div class="row">
            </div> -->
        </div>
    </div>
</div>


<div [style.display]="WorkstationAlreadyAssociatedModel? 'block' : 'none'" class="modal name-popup" id="imgmodal" tabindex="-1"
    role="dialog" aria-labelledby="myMLabel" aria-hidden="true">
    <div class="modaldialog">
        <div class="signinClass">
            <div class="modal-header SignInHeader">
                <h4><img class="giftImage" src="./assets/Libs/images/HeaderLogo.png" height="16">GiftBox Setup Wizard
                </h4>
                <!-- <button type="button" c lass="close" data-dismiss="modal">×</button> -->
            </div>
            <div class="row">
                <div class="modal-body bodyClass">
                    <div class="secodHeader">
                        <img class="GiftBoxLogo" src="./assets/Libs/images/GiftLog.png" height="16">
                    </div>
                    <div class="formClass">
                        <h2>Warning!</h2>
                        <h2>The selected workstation is already associated with a different device. Would you like to replace it with this device?
                        </h2>
                        <button  (click)="ReplaceGUID()" class="btn btn-light CheckWorkstation">Yes</button>
                        <button  (click)="RedirectWorkstationSelectionScreen()" class="btn btn-light CheckWorkstation CancelBtn">No</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div [style.display]="FinishWorkStationSelection? 'block' : 'none'" class="modal name-popup" id="imgmodal" tabindex="-1"
    role="dialog" aria-labelledby="myMLabel" aria-hidden="true">
    <div class="modaldialog">
        <div class="signinClass">
            <div class="modal-header SignInHeader">
                <h4><img class="giftImage" src="./assets/Libs/images/HeaderLogo.png" height="16">GiftBox Setup Wizard
                </h4>
                <!-- <button type="button" c lass="close" data-dismiss="modal">×</button> -->
            </div>
            <div class="modal-body bodyClass p-0">
                <div class="secodHeader">
                    <img class="GiftBoxLogo" src="./assets/Libs/images/GiftLog.png" height="16">
                </div>
                <div class="formClass cstm_mainFormBox">
                    <h2>Thank you for completing the GiftBox setup wizard!</h2>

                    <div class="modal_BTNBox mt-4">
                        <button  (click)="FinishSetup()" class="btn btn-light btnSubmit">Finish</button>
                    </div>
                </div>
            </div>
            <!-- <div class="row">
            </div> -->
        </div>
    </div>
</div>