<div class="right-content">
    <div class="heading-panel text-left">
        <h1><i class="fa fa-home" height="16"></i> Home</h1>
    </div>

    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <div class="white-box">
                    <img src="./assets/Libs/images/HoliDay.png" />
                    <span>{{address}}<br />{{siteAddress}}</span>
                </div>
            </div>

        </div>
    </div>
</div>