import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { AuthService } from 'src/app/Services/auth.service';
//import { MiddleWare } from 'src/app/Services/middleware.service';
import { ShiftService } from 'src/app/Services/shift.service';
import * as $ from 'jquery';
import Swal from 'sweetalert2';
import { PortExeMsgHelper } from 'src/app/shared/helpers/port-exe-msg.helper';

@Component({
  selector: 'app-cash-management',
  templateUrl: './cash-management.component.html',
  styleUrls: ['./cash-management.component.css']
})
export class CashManagementComponent implements OnInit {
  currentUser: any;
  cashinDrwer: any;
  workstation: any;
  currentShiftTotals: any;
  openingCount: any = '';
  totalAmt: number;
  infoMessage: any;
  cashAdjustType: any;
  shiftOpeningAMt: any;
  currentShiftTransaction: any[];
  constructor(private route: ActivatedRoute,
    private router: Router,
    private _shiftService: ShiftService,
    private authenticationService: AuthService,
    private confirmationService: ConfirmationService) { }

  ngOnInit() {
    debugger;
    this.currentUser = this.authenticationService.currentUserValue;
    this.workstation = JSON.parse(localStorage.getItem('workstation'));

    // Getting Current Shift Details
    this._shiftService.GetCurrentShiftTotals(this.workstation.workStationId + (Number(this.workstation.lastShiftNum + 1)), this.workstation.workStationId)
      .subscribe((currentShiftTotalRes) => {
        this.currentShiftTotals = Number(currentShiftTotalRes);
      });

    this._shiftService.GetCurrentShiftDetails(this.workstation.workStationId + (Number(this.workstation.lastShiftNum + 1)), this.workstation.workStationId)
      .subscribe((currentShiftRes) => {
        this.currentShiftTransaction = currentShiftRes;
      });

    // Getting Previous Shift opening
    this._shiftService.GetPreviousShiftBalence(this.workstation.workStationId + (parseInt(this.workstation.lastShiftNum)))
      .subscribe((prevRes) => {
        this.shiftOpeningAMt = Number(prevRes);
      });

  }

  PopulateAmount(value) {
    debugger;
    if (value === 'x') {
      this.openingCount = 0;
    } else {
      this.openingCount = "" + this.openingCount + value;
    }
  }

  SetAmountZero() {
    this.openingCount = '';
  }

  CheckCurrentShiftBalance(cashadj) {
    if (cashadj === 'cashIn') {
      this.cashAdjustType = 'CashIn';
      this.totalAmt = Number(this.openingCount === '' ? 0 : this.openingCount);
      this.infoMessage = '<p class="assignstyle">Add Cash to the Drawer</p> <h3 class="workName cashInOut"><p class="assignstyle cashInOut"> $' + this.totalAmt.toFixed(2) + '</p> </h3>';
    } else if (cashadj === 'cashOut') {
      this.cashAdjustType = 'CashOut';
      this.totalAmt = -Math.abs(Number(this.openingCount === '' ? 0 : this.openingCount));
      this.infoMessage = '<p class="assignstyle">Remove cash from Drawer</p> <h3 class="text-danger workName cashInOut"><p class="assignstyle cashInOut"> $' + this.totalAmt.toFixed(2) + '</p> </h3>'
    }
    const messages = this.infoMessage;
    this.confirmationService.confirm({
      message: messages,
      header: 'Confirmation',
      rejectVisible: true,
      acceptLabel: 'Continue',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        if (cashadj === 'cashIn') {
          this.SubmitCashIn();
        } else {
          if (Number(this.openingCount) <= Number(this.currentShiftTotals)) {
            this.SubmitCashIn();
          } else {
            Swal.fire({
              icon: 'warning',
              title: 'Warning!',
              text: 'You can not remove more cash then cash in drawer balance!'
            });
          }
        }
      },
      reject: () => {
      }
    });
  }

  SubmitCashIn() {
    debugger;
    let cashDetails = {
      WorkstationName: this.workstation.workStationName,
      WorkstationId: this.workstation.workStationId,
      CashAdjType: this.cashAdjustType,
      ShiftNum: this.workstation.workStationId + (Number(this.workstation.lastShiftNum + 1)),
      UserId: this.currentUser.userId,
      Amount:  Number(this.totalAmt),
      Cidtotal: Number(Number(this.currentShiftTotals) + this.totalAmt),
    }

    this._shiftService.SaveCashAdjustDetails(cashDetails)
      .subscribe((res) => {
        debugger;
        var printJobId = res.printJobId;
        if (res.message === 'Success') {
          debugger;
          this._shiftService.GetCurrentShiftDetails(this.workstation.workStationId + (Number(this.workstation.lastShiftNum + 1)), this.workstation.workStationId)
            .subscribe((currentShiftRes) => {
              this.currentShiftTransaction = currentShiftRes;
            });

          this.currentShiftTotals = (this.currentShiftTotals + Number(this.totalAmt));
          // let midResponse = this._middlewareService.ShiftOpenReport(printJobId, 'CashAdjustment');
          // midResponse = PortExeMsgHelper.GetPortStatusMessage(midResponse);
          // if (midResponse.includes('successful')) {
          //   this.openingCount = '';
          //   Swal.fire({
          //     icon: 'success',
          //     title: 'Success!',
          //     text: midResponse
          //   });
          // } else if (midResponse.includes('not Connected')) {
          //   this.openingCount = '';
          //   Swal.fire({
          //     icon: 'warning',
          //     title: 'Warning!',
          //     text: midResponse,
          //   });
          //  }
          // else {
          //   this.openingCount = '';
          //   Swal.fire({
          //     icon: 'error',
          //     title: 'Error!',
          //     text: 'Error while Printing!',
          //   });
          // }
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: 'Some thing Went Wrong!',
          });
        }
      });
  }

  PrintCurrentShiftCashDetails() {
    debugger;
    let cashDetails = {
      WorkstationName: this.workstation.workStationName,
      WorkstationId: this.workstation.workStationId,
      CashAdjType: '===== Cash Drawer Report =====',
      ShiftNum: this.workstation.workStationId + (Number(this.workstation.lastShiftNum + 1)),
      UserId: this.currentUser.userId,
      Amount: Number(this.shiftOpeningAMt),
      Cidtotal: Number(this.currentShiftTotals),
    }

    this._shiftService.SaveCashadjustReport(cashDetails)
      .subscribe((res) => {
        debugger;
        var printJobId = res.printJobId;
        if (res.message === 'Success') {

          this._shiftService.GetCurrentShiftDetails(this.workstation.workStationId + (Number(this.workstation.lastShiftNum + 1)), this.workstation.workStationId)
            .subscribe((currentShiftRes) => {
              this.currentShiftTransaction = currentShiftRes;
            });

          // let midResponse = this._middlewareService.ShiftOpenReport(printJobId, 'CashAdjustment');
          // midResponse = PortExeMsgHelper.GetPortStatusMessage(midResponse);
        //   if (midResponse.includes('successful')) {
        //     this.openingCount = '';
        //     Swal.fire({
        //       icon: 'success',
        //       title: 'Success!',
        //       text: midResponse
        //     });
        //   } else if (midResponse.includes('not Connected')) {
        //     this.openingCount = '';
        //     Swal.fire({
        //       icon: 'warning',
        //       title: 'Warning!',
        //       text: midResponse,
        //     });
        //   }
        //   else {
        //     this.openingCount = '';
        //     Swal.fire({
        //       icon: 'error',
        //       title: 'Error!',
        //       text: 'Error while Printing!',
        //     });
        //   }
        }
         else {
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: 'Some thing Went Wrong!',
          });
        }
      });
  }
}
