
import { Directive, ElementRef, Input, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[datoAutoFocus]'
})
export class AutoFocusDirective {

  @Input()
  public set datoAutoFocus(value) {
    if (!!value) {
      this.host.nativeElement.focus();
    }
  }

  public constructor(
    private host: ElementRef,
  ) {
  }


 }

// import { Directive, ElementRef, AfterViewInit, Input } from '@angular/core';

// @Directive({
//   selector: '[datoAutoFocus]'
// })
// export class AutoFocusDirective implements AfterViewInit {
//   @Input() datoAutoFocus: boolean;

//   constructor(private el: ElementRef) {}

//   ngAfterViewInit() {
//     if (this.datoAutoFocus) {
//       setTimeout(() => {
//        // console.log('Setting focus on element:', this.el.nativeElement);
//         this.el.nativeElement.focus();
//       }, 0);
//     }
//   }
// }
