<p-confirmDialog acceptLabel="Continue" [style]="{width: confirmWidth}" appendTo="body">
    <ng-template pTemplate="body">
        <span class="ui-confirmdialog-message">{{message}}</span>
    </ng-template>
</p-confirmDialog>

<div class="right-content sub-header" *ngIf="isnotPreview">
    <div class="heading-panel text-left">
        <h1><i class="fa fa-power-off" height="16"></i> Start Day</h1>
    </div>

    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12 text-center">
                <h1 class="body-heading">Cash Balance</h1>
            </div>
        </div>
        <!-- Calculator Screen -->
        <!-- <div class="cash-screen mt-4">
            <div class="text-center">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-4 note-tab">
                            <div class="card info-card">
                                 <div class="card-body">
                                    <span>Please Note</span>
                                    <span>Your starting cash in drawer balance should equal the preceding shifts ending cash balance.</span>
                                    <span>If you cannot balance the starting cash in drawer total, call your manager immediately for further instructions.</span> 
                                    <span>Entries made here do not adjust the cash in drawer total.</span> 
                                    <span>If you cannot balance and if you do not call your manager immediately, you accept responsibility for the cash discrepancy.</span> 
                                 </div>
                            </div>
                        </div>
                        <div class="col-4 d-flex justify-content-center align-items-center">
                            <div class="opening-count-block cash-calculator position-relative">
                                <div class="right-panel">
                                    <button class="img-btn cashcalcbtn"><img src="./assets/Libs/images/Calculator.png" alt="Calculator" title="Calculator"></button>
                                    <button class="img-btn"><img src="./assets/Libs/images/cash-register.png" alt="Register" title="Open Cash Drawer"></button>
                                </div>
                                <div class="count-input form-group">
                                    <input type="text" [(ngModel)]="openingCount" value="0.0" 
                                        (keyup)="valOpeningCount($event)"
                                        class="form-control inputTextHeight"  min="0">
                                    <img src="./assets/Libs/images/dollar.png" alt="dollar">
                                </div>
                                <div>
                                    <div class="opening-count-block">
                                        <div class="count-buttons-block mt-4">
                                            <button class="btn count-button" (click)="PopulateAmount(1)">1</button>
                                            <button class="btn count-button" (click)="PopulateAmount(2)">2</button>
                                            <button class="btn count-button mr-0" (click)="PopulateAmount(3)">3</button>
                                            <button class="btn count-button" (click)="PopulateAmount(4)"> 4</button>
                                            <button class="btn count-button" (click)="PopulateAmount(5)">5</button>
                                            <button class="btn count-button mr-0" (click)="PopulateAmount(6)">6</button>
                                            <button class="btn count-button" (click)="PopulateAmount(7)">7</button>
                                            <button class="btn count-button" (click)="PopulateAmount(8)">8</button>
                                            <button class="btn count-button mr-0" (click)="PopulateAmount(9)">9</button>
                                            <button class="btn count-button" (click)="PopulateAmount(0)">0</button>
                                            <button class="btn count-button" (click)="PopulateAmount('.')">.</button>
                                            <button class="btn count-button mr-0" (click)="PopulateAmount('x')"><i
                                                    class="fa fa-arrow-circle-o-left"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="pin-tab position-absolute">
                                    <form>
                                        <div class="tab-form">
                                            <div class="form-group row">
                                                <label for="input" class="col-form-label w-50">$100's :</label>
                                                <div class="w-50 input-div">
                                                    <input type="number" class="form-control" id="inputtext" min="0"
                                                    name="hunders" [(ngModel)]="hunders" (input)="onChange($event,'100')" min="0" placeholder="1">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="input" class="w-50 col-form-label">$50's :</label>
                                                <div class="input-div w-50">
                                                    <input type="number" class="form-control" id="inputtext" min="0"
                                                    name="fifities" [(ngModel)]="fifities" (input)="onChange($event,'50')" min="0" placeholder="New Entries">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="input" class="col-form-label w-50">$20's :</label>
                                                <div class="input-div w-50">
                                                    <input type="number" class="form-control" id="inputtext" min="0"
                                                    name="twnties" [(ngModel)]="twnties" (input)="onChange($event,'20')" min="0" placeholder="2">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="input" class="w-50 col-form-label">$10's :</label>
                                                <div class="input-div w-50">
                                                    <input type="number" class="form-control" id="inputtext" min="0"
                                                    name="tens" [(ngModel)]="tens" (input)="onChange($event,'10')" min="0" placeholder="1">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="input" class="w-50 col-form-label">$5's :</label>
                                                
                                                <div class="input-div w-50">
                                                    <input type="number" class="form-control" id="inputtext" min="0"
                                                    name="fives" [(ngModel)]="fives" (input)="onChange($event,'5')" min="0" placeholder="New Entries">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="input" class=" w-50 col-form-label">$1's :</label>
                                                
                                                <div class="input-div w-50">
                                                    <input type="number" class="form-control" id="inputtext" min="0"
                                                    name="once" [(ngModel)]="once" (input)="onChange($event,'1')" min="0" placeholder="2">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="input" class="w-50 col-form-label">Change Total :</label>
                                              
                                                <div class="input-div w-50">
                                                    <input type="text" class="form-control" id="inputtext" 
                                                    name="changeTotal" [(ngModel)]="changeTotal" maxlength="2" min="0" (input)="onChange($event,'0')" placeholder="132">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="total-cash">
                                            <div class="form-group row mb-0">
                                                <label for="input" class="w-50 col-form-label">Total :</label>
                                                
                                                <div class="input-div w-50">
                                                    <input type="text" class="form-control" id="inputtext" name="TotalCash" [(ngModel)]="TotalCash" placeholder="0.00">
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            
                        </div>
                        
                    </div>
                </div>
            </div>
        </div> -->
        <!-- <div class="action-buttons text-center mt-4 cstm_txt_btn">
            <button class="button button-secondary" (click)="CancilOpeningCount()">Cancel</button>&nbsp;
            <button class="button button-secondary" (click)="ResetOpeningCount()"><i class="fa fa-rotate-left"></i>
                Reset</button>&nbsp;
            <button class="button button-secondary" (click)="SubmitOpeningCount()">Continue</button>
        </div> -->
        <div class="cash-screen">
            <div class="text-center">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-4 note-tab">
                            <div class="card info-card">
                                <div class="card-body">
                                    <span>Please Note</span>
                                    <span>Your starting cash in drawer balance should equal the preceding shifts ending
                                        cash balance.</span>
                                    <span>If you cannot balance the starting cash in drawer total, call your manager
                                        immediately for further instructions.</span>
                                    <span>Entries made here do not adjust the cash in drawer total.</span>
                                    <span>If you cannot balance and if you do not call your manager immediately, you
                                        accept responsibility for the cash discrepancy.</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-4 d-flex justify-content-center align-items-center">
                            <div class="opening-count-block cash-calculator position-relative">
                                <div class="right-panel">
                                    <button class="img-btn cashcalcbtn"><img src="./assets/Libs/images/Calculator.png" (click)="CalButton()"
                                            alt="Calculator" title="Calculator"></button>
                                    <button class="img-btn"><img src="./assets/Libs/images/cash-register.png"
                                            alt="Register" title="Open Cash Drawer"></button>
                                </div>
                                <div class="count-input form-group">
                                    <input type="text" [(ngModel)]="openingCount" value="0.0"
                                        (keyup)="valOpeningCount($event)" class="form-control inputTextHeight" min="0">
                                    <img src="./assets/Libs/images/dollar.png" alt="dollar">
                                </div>
                                <div>
                                    <div class="opening-count-block">
                                        <div class="count-buttons-block mt-4">
                                            <button class="btn count-button" (click)="PopulateAmount(1)">1</button>
                                            <button class="btn count-button" (click)="PopulateAmount(2)">2</button>
                                            <button class="btn count-button mr-0" (click)="PopulateAmount(3)">3</button>
                                            <button class="btn count-button" (click)="PopulateAmount(4)"> 4</button>
                                            <button class="btn count-button" (click)="PopulateAmount(5)">5</button>
                                            <button class="btn count-button mr-0" (click)="PopulateAmount(6)">6</button>
                                            <button class="btn count-button" (click)="PopulateAmount(7)">7</button>
                                            <button class="btn count-button" (click)="PopulateAmount(8)">8</button>
                                            <button class="btn count-button mr-0" (click)="PopulateAmount(9)">9</button>
                                            <button class="btn count-button" (click)="PopulateAmount(0)">0</button>
                                            <button class="btn count-button" (click)="PopulateAmount('.')">.</button>
                                            <button class="btn count-button mr-0" (click)="PopulateAmount('x')"><i
                                                    class="fa fa-arrow-circle-o-left"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="pin-tab position-absolute">
                                    <form>
                                        <div class="tab-form">
                                            <div class="form-group row">
                                                <label for="input" class="col-form-label ">$100's :</label>
                                                <div class=" col-lg-7">
                                                    <input type="number" class="form-control" id="inputtext" min="0"
                                                        name="hunders" [(ngModel)]="hunders"
                                                        (input)="onChange($event,'100')" min="0" placeholder="1">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="input" class=" col-form-label">$50's :</label>
                                                <div class="col-lg-7 ">
                                                    <input type="number" class="form-control" id="inputtext" min="0"
                                                        name="fifities" [(ngModel)]="fifities"
                                                        (input)="onChange($event,'50')" min="0"
                                                        placeholder="New Entries">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="input" class="col-form-label ">$20's :</label>
                                                <div class="col-lg-7 ">
                                                    <input type="number" class="form-control" id="inputtext" min="0"
                                                        name="twnties" [(ngModel)]="twnties"
                                                        (input)="onChange($event,'20')" min="0" placeholder="2">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="input" class=" col-form-label">$10's :</label>
                                                <div class="col-lg-7 ">
                                                    <input type="number" class="form-control" id="inputtext" min="0"
                                                        name="tens" [(ngModel)]="tens" (input)="onChange($event,'10')"
                                                        min="0" placeholder="1">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="input" class=" col-form-label">$5's :</label>

                                                <div class="col-lg-7 ">
                                                    <input type="number" class="form-control" id="inputtext" min="0"
                                                        name="fives" [(ngModel)]="fives" (input)="onChange($event,'5')"
                                                        min="0" placeholder="New Entries">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="input" class="  col-form-label">$1's :</label>

                                                <div class="col-lg-7 ">
                                                    <input type="number" class="form-control" id="inputtext" min="0"
                                                        name="once" [(ngModel)]="once" (input)="onChange($event,'1')"
                                                        min="0" placeholder="2">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="input" class=" col-form-label">Change Total :</label>

                                                <div class="col-lg-7 ">
                                                    <input type="number" class="form-control" id="inputtext"
                                                        name="changeTotal" [(ngModel)]="changeTotal" maxlength="2"
                                                        min="0" (input)="onChange($event,'0')" placeholder="132">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="total-cash">
                                            <div class="form-group row mb-0">
                                                <label for="input" class=" col-form-label">Total :</label>
                                                <div class="col-lg-7 ">
                                                    <input type="number" class="form-control" id="inputtext"
                                                        name="TotalCash" [(ngModel)]="TotalCash" placeholder="0.00">
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="row mx-auto cal-bottom-button">
            <div class="col-lg-12 action-buttons d-flex justify-content-center align-items-center mt-4 cstm_txt_btn">
                <button class="button button-secondary" (click)="CancilOpeningCount()">Cancel</button>&nbsp;
                <button class="button button-secondary" (click)="ResetOpeningCount()"><i class="fa fa-rotate-left"></i>
                    Reset</button>&nbsp;
                <button class="button button-secondary" (click)="SubmitOpeningCount()">Continue</button>
            </div>
        </div>
    </div>
</div>

<div class="right-content" *ngIf="isPreview">
    <div class="shift-head text-left">
        <h5><i class="fa fa-power-off" height="16"></i> Start Day</h5>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <div class="shift-review-block">
                    <h3>Starting Cash Drawer Report</h3>
                    <div class="shift-review-table">
                        <table class="table table-borderless">
                            <tbody>
                                <tr>
                                    <td>Balance Forward</td>
                                    <td>{{prevShiftAmountt}}</td>
                                </tr>
                                <tr>
                                    <td>Opening Amount</td>
                                    <td>{{prevShiftAmountt}}</td>
                                </tr>
                                <tr>
                                    <td>Variance</td>
                                    <td>$0.00</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <h3>Starting Inventory Report</h3>
                    <div class="shift-review-table">
                        <table class="table table-borderless">
                            <tbody>
                                <tr>
                                    <td>Inventory</td>
                                    <td>No Inventory</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <br /><br /><br /><br /><br />
        <div class="row text-center">
            <div class="col-sm-6 text-right">
                <button class="btn btn-PrintReport" (click)="SaveShiftReport()">Print Report</button>
            </div>
            <div class="col-sm-6 text-left">
                <button class="btn btn-Saveshift" (click)="SaveShiftReport()">Continue</button>
            </div>
        </div>
    </div>
</div>
<div class="right-content sub-header" *ngIf="isInventory">
    <div class="heading-panel text-left">
        <h1><i class="fa fa-power-off pr-1"></i> Start Day</h1>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12 text-center">
                <h1 class="body-heading">Inventory Balance</h1>
            </div>
        </div>
        <div class="inv-balanced mt-4">
            <div class="row justify-content-center">
                <!-- <div class="col-sm-2"></div> -->
                <div class="col-xl-6 col-lg-8 col-md-10 col-12">
                    <div *ngIf="viewData != ''">
                    <div class="shift-review-block">
                        <div class="shift-review-table">
                            <table class="table table-borderless">
                                <thead class="tblProDetails">
                                    <tr class="cstm_txt_hd">
                                        <th class="text-left">Product</th>
                                        <th style="width: 22%; text-align: center;">Quantity</th>
                                        <th style="width: 20%;">Status</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="viewData != ''" class="cstm_txt">
                                    <tr *ngFor="let item of viewData; let i=index">
                                        <td class="text-left text-dark">{{item.productName}}</td>
                                        <td class="text-left text-dark">
                                            <input type="number" [(ngModel)]="item.checkQTY" min="0"
                                                [ngClass]="{'border border-danger': item.checkQTY ===''|| item.checkQTY === null}"
                                                class="form-control text-dark" (keyup)="CheckOutBal(item)"
                                                (keydown)="CheckKeys($event)" autofocus >
                                        </td>
                                        <td class="text-left"><span *ngIf="item.checkQTY === item.totalProdQTY"
                                                class="text-success" style=" text-align: right;">In Balance</span> <span
                                                *ngIf="item.isOut && item.checkQTY !== null" class="text-danger"
                                                style=" text-align: right;">Out Of Balance</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    </div>
                </div>
                <!-- <div class="col-sm-2"></div> -->
            </div>
            <div class="row mt-4">

                <div class="col-sm-12 cstm_txt_btn">
                    <div class="action-buttons text-center">
                        <button class="button button-secondary" (click)="BackToCashBal()">Back
                        </button>&nbsp;
                        <button class="button button-secondary" (click)="ResetInventory()"><i
                                class="fa fa-rotate-left"></i>
                            Reset</button>&nbsp;
                        <button class="button button-secondary" (click)="SaveInventory(1)">Continue
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>