import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { AuthService } from 'src/app/Services/auth.service';
//import { MiddleWare } from 'src/app/Services/middleware.service';
import { ShiftService } from 'src/app/Services/shift.service';
import * as $ from 'jquery';
import Swal from 'sweetalert2';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PortExeMsgHelper } from 'src/app/shared/helpers/port-exe-msg.helper';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-vew-cash',
  templateUrl: './vew-cash.component.html',
  styleUrls: ['./vew-cash.component.css']
})
export class VewCashComponent implements OnInit {
  currentUser: any;
  cashinDrwer: any;
  workstation: any;
  currentShiftTotals: any;
  openingCount: any = '';
  totalAmt: number;
  infoMessage: any;
  cashAdjustType: any;
  shiftOpeningAMt: any;
  currentShiftTransaction: any[];
  FirstCId:any;
  siteConfi: any;
  Cashmanagement:any;
  LoginAdjuctGroup = new FormGroup({
    UserNameLogin: new FormControl('',[Validators.required]),
    PasswordLogin: new FormControl('',[Validators.required]),
  });
  returnComponent:string;
  feature:string;
  subFeature:string;
  LoginModal:boolean;
  errormsg: any;
  constructor(private route: ActivatedRoute,
    private router: Router,
    private _shiftService: ShiftService,
    private authenticationService: AuthService,
    private confirmationService: ConfirmationService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder
    
    //private _middlewareService: MiddleWare
    ) { }

  ngOnInit(): void {
    debugger;
    this.currentUser = this.authenticationService.currentUserValue;
    this.workstation = JSON.parse(localStorage.getItem('workstation'));
    this.siteConfi=JSON.parse(localStorage.getItem("siteConfivalidated"))
    this.Cashmanagement=JSON.parse(localStorage.getItem('UsercashManagement'));
    if(this.siteConfi.openCashDrawer==false)
    {
      $('#btn_openCashDrawer').prop('disabled', true);
      $('#btn_openCashDrawer').addClass('button-disable').removeClass('button-secondary');
     
    }
    // Getting Current Shift Details
    this._shiftService.GetCurrentShiftTotals(this.workstation.workStationId +"-"+ (Number(this.workstation.lastShiftNum + 1)), this.workstation.workStationId)
      .subscribe((currentShiftTotalRes) => {
        this.currentShiftTotals = Number(currentShiftTotalRes);
       
      });
    this._shiftService.GetCurrentShiftDetails(this.workstation.workStationId +"-"+ (Number(this.workstation.lastShiftNum + 1)), this.workstation.workStationId)
      .subscribe((currentShiftRes) => {
        this.currentShiftTransaction = currentShiftRes;
       
      });
     
    // Getting Previous Shift opening
    this._shiftService.GetPreviousShiftBalence(this.workstation.workStationId +"-"+ (parseInt(this.workstation.lastShiftNum)))
      .subscribe((prevRes) => {
          this.shiftOpeningAMt = Number(prevRes);
         
      });
      $(".cashcalcbtn").on('click',function(){
        $('.cash-calculator').toggleClass('active').promise().done(function(){
          if($(this).hasClass('active')){
            $('.pin-tab').addClass('open');
          }else{
            $('.pin-tab').removeClass('open');
          }
        });
    });
 
    this.CheckPrinter();
    this.CheckReceiptsType();
    $(".navigation ul li a").removeClass("active");
    $(".cashSec").addClass("active");
    $(".salesSec, .transaSec, .envSec").removeClass("active");
  }


  get Login() { return this.LoginAdjuctGroup.controls; }

  AdjustLogin(){
    debugger;

    if (this.LoginAdjuctGroup.invalid) {
      return;
    }

    console.log(this.LoginAdjuctGroup.value);
    let GetGUIDchrowm = localStorage.getItem("MacAddress");
    let decodeGUID=atob(GetGUIDchrowm)
    let userObj= {
      UserName:this.LoginAdjuctGroup.controls.UserNameLogin.value,
      Password:this.LoginAdjuctGroup.controls.PasswordLogin.value,
      GUID: decodeGUID,
      Feature:this.feature,
      SubFeature:this.subFeature
    }
    this.authenticationService.UserLoginForOtherPermissions(userObj)
    .subscribe((res) => {
        if(res.hasAccess){
          this.LoginModal = false;
         if(this.subFeature=='Add Cash'){
         // this.AddCashWithOtherUserCredentials();
         }
         else{
          //this.RemoveCashWithUserCredentials();
         }
        }
        else{
          this.NavValidationModalPopup();
        }
        
      },
      error => {
        debugger;
        this.errormsg=error.error.message;
        this.toastr.error(this.errormsg);
         if(this.errormsg=='Not Use This Workstation'){
          this.toastr.error("This workstation is not access to you, Please Enter the Valid Credentials");
         };
      });
  }


  NavValidationModalPopup() {
    Swal.fire({
      text: 'You do not have permission to perform this function. Would you like to enter alternate credentials?​',
      icon: 'warning',
      title:'Warning!',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        debugger;
        this.LoginAdjuctGroup = this.formBuilder.group({
          UserNameLogin: ['', Validators.required],
          PasswordLogin: ['', Validators.required]
        });
        this.LoginModal = true;
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        debugger;
       // window.location.reload();
      }
    });
  }


  hide(signclose:string){
    if(signclose==='SignInModal'){
      this.LoginModal=false;
    }
      }

  PrintCurrentShiftCashDetails() {
    debugger;

    if (this.Cashmanagement.addCash===1) {
    let cashDetails = {
      WorkstationName: this.workstation.workStationName,
      WorkstationId: this.workstation.workStationId,
      CashAdjType: '===== Cash Drawer Report =====',
      ShiftNum: this.workstation.workStationId +"-"+ (Number(this.workstation.lastShiftNum + 1)),
      UserId: this.currentUser.userId,
      Amount: Number(this.shiftOpeningAMt),
      Cidtotal: Number(this.currentShiftTotals),
    }

    this._shiftService.SaveCashadjustReport(cashDetails)
      .subscribe((res) => {
        debugger;
        var printJobId = res.printJobId;
        if (res.message === 'Success') {

          this._shiftService.GetCurrentShiftDetails(this.workstation.workStationId +"-"+ (Number(this.workstation.lastShiftNum + 1)), this.workstation.workStationId)
            .subscribe((currentShiftRes) => {
              this.currentShiftTransaction = currentShiftRes;
            });

          // let midResponse = this._middlewareService.ShiftOpenReport(printJobId, 'CashAdjustment');
          // midResponse = PortExeMsgHelper.GetPortStatusMessage(midResponse);
          // if (midResponse.includes('successful')) {
          //   this.openingCount = '';
          //   Swal.fire({
          //     icon: 'success',
          //     title: 'Success!',
          //     text: midResponse
          //   });
          // } else if (midResponse.includes('not Connected')) {
          //   this.openingCount = '';
          //   Swal.fire({
          //     icon: 'warning',
          //     title: 'Warning!',
          //     text: midResponse,
          //   });
          // }
          // else {
          //   this.openingCount = '';
          //   Swal.fire({
          //     icon: 'error',
          //     title: 'Error!',
          //     text: 'Error while Printing!',
          //   });
          // }
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: 'Some thing Went Wrong!',
          });
        }
      });
    }
    else{
      this.subFeature='Open Cash Drawer';
      this.returnComponent='VewCash'
      this.feature='Cash Management';
      this.NavValidationModalPopup();
    }
  }

  CheckPrinter()
  {
    this._shiftService.GetPrinterStatus(this.workstation.workStationId).subscribe((res) => {
      debugger;
      if(res!=3 && res!=0)
      {
      let printerMessage=  this.authenticationService.GetPrinterMessgae(res);
        Swal.fire({
          icon: 'warning',
          title: 'warning!',
          text: printerMessage,
          timer: 10000, // 10 seconds
          timerProgressBar: true,
        })
      }
    });
  }

  CheckReceiptsType()
  {
    debugger;
    this._shiftService.GetReceiptsType(this.workstation.workStationId).subscribe((res) => {
      debugger;
      if(res!= true)
      {
        $('#btn_Print').prop('disabled', true);  
      }
    });
  }

}
