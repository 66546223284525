<div class="right-content">
    <div class="heading-panel text-left">

        <h1><img src="./assets/Libs/images/enventry.png" height="16"> Inventory</h1>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12 text-center">
                <h1 class="body-heading">View Transfers</h1>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="invent-back">
            <div class="table-responsive view-trnsfr-tbl">
                <table class="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th>Transfer Date</th>
                            <th>Transfer ID</th>
                            <th>From Location</th>
                            <th>To Location</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="viewTransferData != ''">
                        <tr *ngFor="let item of viewTransferData; let i=index">
                            <td>{{item.createdDate | date : "MM/dd/yyyy"}}</td>
                            <td>{{item.transferId}}</td>
                            <td>{{item.transferFromWsName}}</td>
                            <td>{{item.transferToWsName}}</td>
                            <td>{{item.status}}</td>
                            <td class="action-icon">
                                <a data-toggle="modal" data-target="#myModal"
                                    (click)="ViewTransferStatus(item.transferId)">
                                    <!-- <i class="fa fa-eye"></i> -->
                                    <svg width="25" height="15" viewBox="0 0 25 15" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M12.1981 14C8.59005 14 5.68359 11.1166 5.68359 7.5373C5.68359 3.95795 8.59005 1.07458 12.1981 1.07458C15.8061 1.07458 18.7125 3.95795 18.7125 7.5373C18.7125 11.1166 15.8061 14 12.1981 14Z"
                                            stroke="black" stroke-width="1.5" stroke-miterlimit="10"
                                            stroke-linecap="round" stroke-linejoin="round" />
                                        <path
                                            d="M12.201 9.72461C10.9983 9.72461 9.99609 8.73035 9.99609 7.53723C9.99609 6.34412 10.9983 5.34985 12.201 5.34985C13.4037 5.34985 14.4059 6.34412 14.4059 7.53723C14.4059 8.73035 13.4037 9.72461 12.201 9.72461Z"
                                            stroke="black" stroke-width="1.5" stroke-miterlimit="10"
                                            stroke-linecap="round" stroke-linejoin="round" />
                                        <path
                                            d="M23.1242 8.53155C16.9104 15.7897 7.58964 15.7897 1.37584 8.53155C0.874722 7.93499 0.874722 7.04015 1.37584 6.44359C7.58964 -0.814531 16.9104 -0.814531 23.1242 6.44359C23.6253 7.04015 23.6253 7.93499 23.1242 8.53155Z"
                                            stroke="black" stroke-width="1.5" stroke-miterlimit="10"
                                            stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </a>
                                <a *ngIf="item.status == 'PENDING' && item.transferToWsid == workstation.workStationId"
                                    (click)="ChangeStatus(item.transferId, item.transferToWsid)">
                                    <svg width="20" height="24" viewBox="0 0 20 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M12.3859 1.40326C9.6547 2.47853 8.23535 5.38175 9.13858 8.06992C10.773 13.0162 18.0418 13.0162 19.6762 8.06992C20.5794 5.36025 19.1171 2.41401 16.4074 1.40326C14.9665 0.865622 13.7622 0.865622 12.3859 1.40326ZM17.6332 4.32799C17.8698 4.58605 17.8483 4.71508 17.5042 5.03767C17.2891 5.23121 16.2569 6.19896 15.2246 7.1882L13.3536 8.97315L12.2784 8.04842C11.2676 7.1667 11.2246 7.10218 11.5472 6.73659C11.8913 6.34949 11.9343 6.371 12.6224 7.03767L13.3321 7.72584L15.2246 5.85487C16.2569 4.82261 17.1601 3.9839 17.2246 3.9839C17.3106 3.9839 17.4827 4.13444 17.6332 4.32799Z"
                                            fill="black" />
                                        <path
                                            d="M1.56989 11.5753L0 15.2957V18.457V21.6183H10H20V18.414C20 15.7473 19.9355 15.0591 19.5914 14.285C19.3548 13.7688 19.0323 12.9731 18.8387 12.5215L18.4946 11.7043L17.8065 12.0914C17.4194 12.3065 17.0538 12.5 17.0108 12.5215C16.9677 12.543 17.1828 13.1667 17.5054 13.8764C17.8065 14.6075 18.0645 15.2312 18.0645 15.2957C18.0645 15.3387 16.8817 15.3817 15.4194 15.3817H12.7742L12.3871 16.2419C11.957 17.2312 11.1398 17.7473 10 17.7473C8.64516 17.7473 7.89247 17.1452 7.44086 15.7473C7.33333 15.4247 7.01075 15.3817 4.60215 15.3817C2.23656 15.3817 1.91398 15.3387 2.02151 15.0376C2.08602 14.8656 2.64516 13.5538 3.24731 12.1344L4.34409 9.57528H6.3871C8.25806 9.57528 8.4086 9.53226 8.27957 9.18818C8.19355 8.99463 8.06452 8.60753 8.02151 8.32796C7.91398 7.87635 7.82796 7.85485 5.50538 7.85485H3.11828L1.56989 11.5753Z"
                                            fill="black" />
                                    </svg>
                                </a>
                                <a *ngIf="item.status == 'PENDING' && item.transferFromWsid == workstation.workStationId"
                                    (click)="DeleteTransfer(item.transferId,item.transferToWsid)" class="text-danger">
                                    <svg width="18" height="20" viewBox="0 0 18 20" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M16.875 3.125H13.657L12.3289 0.908203C11.957 0.344805 11.3828 0 10.7266 0H6.77344C6.11719 0 5.50781 0.344805 5.17187 0.908203L3.84297 3.125H0.625C0.27832 3.125 0 3.40352 0 3.75V4.375C0 4.72266 0.27832 5 0.625 5H1.25V17.5C1.25 18.8809 2.36914 20 3.75 20H13.75C15.1309 20 16.25 18.8809 16.25 17.5V5H16.875C17.2227 5 17.5 4.72266 17.5 4.375V3.75C17.5 3.40352 17.2227 3.125 16.875 3.125ZM6.71484 1.9875C6.75391 1.91914 6.83203 1.875 6.91406 1.875H10.5859C10.6689 1.875 10.7471 1.91895 10.7861 1.9873L11.4688 3.125H6.03125L6.71484 1.9875ZM13.75 18.125H3.75C3.4048 18.125 3.125 17.8452 3.125 17.5V5H14.375V17.5C14.375 17.8438 14.0938 18.125 13.75 18.125ZM8.75 16.25C9.09547 16.25 9.375 15.9705 9.375 15.625V7.5C9.375 7.15453 9.09547 6.875 8.75 6.875C8.40453 6.875 8.125 7.15625 8.125 7.5V15.625C8.125 15.9688 8.40625 16.25 8.75 16.25ZM5.625 16.25C5.96875 16.25 6.25 15.9688 6.25 15.625V7.5C6.25 7.15453 5.97047 6.875 5.625 6.875C5.27953 6.875 5 7.15625 5 7.5V15.625C5 15.9688 5.28125 16.25 5.625 16.25ZM11.875 16.25C12.2205 16.25 12.5 15.9705 12.5 15.625V7.5C12.5 7.15453 12.2205 6.875 11.875 6.875C11.5295 6.875 11.25 7.15625 11.25 7.5V15.625C11.25 15.9688 11.5312 16.25 11.875 16.25Z"
                                            fill="#FF0000" />
                                    </svg>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="myModal">
    <div class="modal-dialog cstm-modal-viewTrnfr">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h3 class="modal-title cstm-modaltxt-viewTrnfr">Transfer # {{SelectedTransferId}} From
                    {{SelectedFromWsid}} To
                    {{SelectedToWsid}}</h3>
                <button type="button" class="close" data-dismiss="modal">
                    <span aria-hidden="true" class="icon-xmark"></span>
                </button>
            </div>

            <!-- Modal body -->
            <div class="modal-body ">
                <table class="table table-bordered table-striped">
                    <thead class="tblProDetails">
                        <tr>
                            <th>Transfer Date</th>
                            <th>PLU Code</th>
                            <th>Product Name</th>
                            <th>Quantity</th>
                            <th>From Location</th>
                            <th>To Location</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="viewProdDescData != ''">
                        <tr *ngFor="let item of viewProdDescData; let i=index">
                            <td>{{item.createdDate | date : "MM/dd/yyyy"}}</td>
                            <td>{{item.pluCode}}</td>
                            <td>{{item.productName}}</td>
                            <td>{{item.qty}}</td>
                            <td>{{item.transferFromWsName}}</td>
                            <td>{{item.transferToWsName}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- Modal footer -->
            <!-- <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
            </div> -->

        </div>
    </div>
</div>
<p-confirmDialog acceptLabel="Continue" appendTo="body">
    <ng-template pTemplate="body">
        <span class="ui-confirmdialog-message"></span>
    </ng-template>
</p-confirmDialog>