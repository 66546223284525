import { Injectable } from '@angular/core';
import { merge, Observable, Subject, Subscription, fromEvent, timer } from 'rxjs';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class IdleService {

  private idle: Observable<any> = new Observable();
  private timer: Subscription = new Subscription();
  private timeOutMilliSeconds: number = 1000;
  private idleSubscription: Subscription = new Subscription();
  public expired: Subject<boolean> = new Subject<boolean>();
  public startWatching(timeOutSeconds: number): Observable<any> {
    debugger;
    this.idle = merge(
      fromEvent(document, 'mousemove'),
      fromEvent(document, 'click'),
      fromEvent(document, 'mousedown'),
      fromEvent(document, 'keypress'),
      fromEvent(document, 'DOMMouseScroll'),
      fromEvent(document, 'mousewheel'),
      fromEvent(document, 'touchmove'),
      fromEvent(document, 'MSPointerMove'),
      fromEvent(window, 'mousemove'),
      fromEvent(window, 'resize'),
    );
    this.timeOutMilliSeconds = timeOutSeconds * 1000;
    this.idleSubscription = this.idle.subscribe((res) => {
      this.resetTimer();
    });
    this.startTimer();
    return this.expired;
  }


  private startTimer() {
    this.timer = timer(this.timeOutMilliSeconds, this.timeOutMilliSeconds).subscribe((res) => {
      this.expired.next(true);
    });
  }
  public resetTimer() {
    this.timer.unsubscribe();
    this.startTimer();
  }
  public stopTimer() {
    this.timer.unsubscribe();
    this.idleSubscription.unsubscribe();
  }


  public CheckAutoEOD() {
    let siteConfi = JSON.parse(localStorage.getItem("siteConfivalidated"))
    if (siteConfi.autoEOD) {
      debugger;
      var localAutoEODDateTime = this.convertUTCToLocal(siteConfi.autoEODTime + 'Z');
      var localDateTime = new Date();
      //alert(localDateTime);
      // if (localAutoEODDateTime > localDateTime) {
      //   var milisec = localAutoEODDateTime.getTime() - localDateTime.getTime();

      //   if(milisec==60000)
      //     {
      //       //this.toastr.error("Your session will end after one minute!");

      //       Swal.fire({
      //             icon: 'warning',
      //             title: 'Warning!',
      //             text: 'Your session will end after one minute!'
      //           });
      //     }
       
       
      //   setTimeout(function () {
      //     localStorage.setItem('IsLogOut', 'true')
      //   }, 16000);
      // }
    }
  }


  convertUTCToLocal(date) {
    //const utcTime = '2024-05-08T18:00:00.000Z'; 
    const dates = new Date(date);
    return dates;
    alert(dates);
    console.log(dates);
  }

  constructor() { }
}
