import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { User } from '../Models/User';
import { configConstant } from '../shared/constants/config.constant';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  private baseurl = configConstant.apibaseurl;
  public ClientId: any;


  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }


  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(userLogin) {
    debugger;
    let CId = localStorage.getItem("Client_Id");
    const headers = new HttpHeaders().set('Client', CId);
    return this.http.post<any>(`${this.baseurl}/Account/login`, userLogin, { headers })
      .pipe(map(user => {
        localStorage.setItem('currentUser', JSON.stringify(user));
        localStorage.setItem('shiftPrimaryUser', user.userName);
        let userData = [];
        userData.push({ 'ClerkName': user.clerkName, 'EmailAddress': user.emailAddress, 'UserName': user.userName });
        localStorage.setItem('loggInUserList', JSON.stringify(userData));
        this.currentUserSubject.next(user);
        return user;
      }));
  }
  GetDistrictList(): Observable<any> {
    debugger;
    let CId = localStorage.getItem("Client_Id");
    const headers = new HttpHeaders().set('Client', CId);
    return this.http.get<any>(this.baseurl + '/Account/GetDistrictList/', { headers })
      .pipe(
        catchError(this.errorHandl)
      );
  }


  GetSiteListByDistrictId(distId): Observable<any> {
    let CId = localStorage.getItem("Client_Id");
    const headers = new HttpHeaders().set('Client', CId);
    return this.http.get<any>(this.baseurl + '/Account/GetSiteListByDisId/' + distId, { headers })
      .pipe(
        catchError(this.errorHandl)
      );
  }


  GetWorkstationListBySiteId(SiteId): Observable<any> {
    let CId = localStorage.getItem("Client_Id");
    const headers = new HttpHeaders().set('Client', CId);
    return this.http.get<any>(this.baseurl + '/Account/GetWorkstationListBySiteId/' + SiteId, { headers })
      .pipe(
        catchError(this.errorHandl)
      );
  }

  CheckWorkstationAssociated(WsId): Observable<any> {
    let CId = localStorage.getItem("Client_Id");
    const headers = new HttpHeaders().set('Client', CId);
    return this.http.get<any>(this.baseurl + '/Account/CheckWorkstationAssociatedAlreadty/' + WsId, { headers })
      .pipe(
        catchError(this.errorHandl)
      );
  }

  CheckMacAddressIspresentOrNot(macaddress): Observable<any> {
    debugger;
    let CId = localStorage.getItem("Client_Id");
    const headers = new HttpHeaders().set('Client', CId);
    return this.http.get<any>(this.baseurl + '/Account/CheckMaxAddressValue/' + macaddress, { headers })
      .pipe(
        catchError(this.errorHandl)
      );
  }

  UpdateGUIDFieldbyWsId(WsId, GUID): Observable<any> {
    let CId = localStorage.getItem("Client_Id");
    const headers = new HttpHeaders().set('Client', CId);
    return this.http.get<any>(this.baseurl + '/Account/UpdateGUIDFieldbyWsId/' + WsId + '/' + GUID, { headers })
      .pipe(
        catchError(this.errorHandl)
      );
  }
  ForceCloseShiftUser(GUID, userId): Observable<any> {
    let CId = localStorage.getItem("Client_Id");
    const headers = new HttpHeaders().set('Client', CId);
    return this.http.get<any>(this.baseurl + '/Shift/ForceCloseShiftUser/' + GUID + '/' + userId, { headers })
      .pipe(
        catchError(this.errorHandl)
      );
  }

  userLogin(userLogin) {
    debugger;
    let CId = localStorage.getItem("Client_Id");
    const headers = new HttpHeaders().set('Client', CId);
    return this.http.post<any>(`${this.baseurl}/Account/login`, userLogin, { headers })
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(user));
        //localStorage.setItem('permisdetailsbyuser',)
        let userData = [];
        userData = JSON.parse(localStorage.getItem('loggInUserList'));

        if (userData.filter(x => x.UserName === user.userName).length === 0) {
          userData.push({ 'ClerkName': user.clerkName, 'EmailAddress': user.emailAddress, 'UserName': user.userName });
        }
        localStorage.setItem('loggInUserList', JSON.stringify(userData))
        this.currentUserSubject.next(user);
        return user;
      }));
  }

  UserLoginForOtherPermissions(userLogin): Observable<any> {
    let CId = localStorage.getItem("Client_Id");
    const headers = new HttpHeaders().set('Client', CId);
    return this.http.post<any>(`${this.baseurl}/Account/userLoginForOtherPermissions`, userLogin, { headers })
    .pipe(map(user => {
      debugger;
      if(user.hasAccess && user.WorkStationFlag==null)
      localStorage.setItem('latestUser', user.userId);
    else{
      localStorage.setItem('latestUser', null);
    }
      return user;
        catchError(this.errorHandl)
  }));
  }
  

  ChangePassword(userLogin): Observable<any> {
    debugger;
    let CId = localStorage.getItem("Client_Id");
    const headers = new HttpHeaders().set('Client', CId);
    return this.http.post<any>(`${this.baseurl}/Account/forgotPassword`, userLogin, { headers })
    .pipe(map(user => {
      debugger;
      if(user.hasAccess && user.WorkStationFlag==null)
      localStorage.setItem('latestUser', user.userId);
    else{
      localStorage.setItem('latestUser', null);
    }
      return user;
        catchError(this.errorHandl)
  }));
  }



  ResetPassword(passwordViewModel): Observable<any> {
    debugger;
    let CId = localStorage.getItem("Client_Id");
    const headers = new HttpHeaders().set('Client', CId);
    return this.http.post<any>(`${this.baseurl}/Account/resetPassword`, passwordViewModel, { headers })
    .pipe(map(user => {
      debugger;
    //   if(user.hasAccess && user.WorkStationFlag==null)
    //   //localStorage.setItem('latestUser', user.userId);
    // else{
    //   localStorage.setItem('latestUser', null);
    // }
      return user;
        catchError(this.errorHandl)
  }));
  }


  GetPrinterMessgae(status) {
    debugger
    if(status !=null)
    {
      switch(status)
      {
        case 4:
    return 'Your printer is currently offline. Please bring it back online to start printing.'
    case 5:
    return 'Your printer cover is open. Securely close the cover to continue printing.'
    case 6:
    return 'Your printer is too hot. Please allow it to cool down before resuming printing to avoid any issues.'
    case 7:
    return 'A mechanical issue has been detected in your printer. Please contact Tech support.'
    case 8:
    return 'A mechanical issue has been detected in your printer. Please contact tech support.'
    case 9:
    return 'Your printer buffer memory has overflowed. Please restart the printer to avoid any issues'
    case 10:
    return 'Your printer is facing a black mark error. Please contact tech support.'
    case 11:
    return 'Your printer is reporting a paper jam. Please check the printer.'
    case 12:
    return 'Your printer detected a voltage error. Please contact Tech support.'
    case 13:
    return 'Your printer is out of paper. Please add paper to resume printing.'
    case 14:
    return 'Your printer paper level is running low. Please add more paper to avoid interruptions.'
    case 15:
    return 'An unexpected error occurred. If the issue persists, reach out to our support team for assistance.'

    
      }
    }
  }


  errorHandl(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }

}
