<div class="right-content" id="rounded-section">
    <div class="heading-panel text-left">

        <h1><img src="./assets/Libs/images/Cashmanagement.png" height="16"> Cash</h1>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12 text-center">
                <h1 class="body-heading text-center">View Balance</h1>
            </div>
        </div>
    </div>
    <!-- <div class="container-fluid">
        <div class="view-drcash">
            <h4 class="text-center">Cash Drawer Report</h4>
            <div class="shift-review-block">
                start
                <div class="shift-review-table cash-Drawer-Report">
                    <table class="table table-borderless" style="min-height: 110px;">
                        <tbody>
                            <tr>
                            <td>Shift Start</td>
                            <td>{{shiftOpeningAMt | currency}}</td>
                        </tr>
                            <tr *ngFor="let item of currentShiftTransaction" [hidden]="item.txCashType=='Shift Start'">
                                <td>{{item.transactionType}} Transaction ID:</td>
                                <td [ngClass]="(item.amount < 0)?'text-danger':''">
                                    {{item.txCashType}}</td>
                                <td *ngIf="item.transactionType !== null" [ngClass]="(item.amount < 0)?'text-danger':''">{{item.transactionType}} Transaction
                                    ID:
                                    {{item.txId}}</td>
                                <td *ngIf="item.transactionType === null" [ngClass]="(item.amount < 0)?'text-danger':''">
                                    {{item.txCashType}}</td>
                                <td [ngClass]="(item.amount < 0)?'text-danger':''">
                                    {{item.amount | currency}}</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td>Cash Drawer Balance</td>
                                <td>{{currentShiftTotals | currency}}</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>

            </div>
        </div>
        <div class="row my-5">
            <div class="col-sm-12" style="position: absolute; bottom:0;">
                <div class="action-buttons text-center">
                    <button class="button button-secondary" id="btn_Print"
                        (click)="PrintCurrentShiftCashDetails()">Print</button>
                    <button id="btn_openCashDrawer" class="button button-secondary">Open Drawer</button>
                </div>
            </div>
        </div>
    </div> -->
    <div class="container-fluid cash-center-cntnr">
        <div class="view-drcash">
            <!-- <h4 class="text-center">Cash Drawer Report</h4> -->
            <div class="shift-review-block cash-balance-details">
                <!-- start -->
                <div class="shift-review-table cash-Drawer-Report">
                    <table class="table table-borderless">
                        <tbody>
                            <tr>
                                <td>Shift Start</td>
                                <td>{{shiftOpeningAMt | currency}}</td>
                            </tr>
                            <tr *ngFor="let item of currentShiftTransaction" [hidden]="item.txCashType=='Shift Start'">
                                <td *ngIf="item.transactionType !== null && item.txCashType!='Refund'"
                                    [ngClass]="(item.amount < 0)?'text-body':''">{{item.transactionType}} Transaction 
                                    ID:
                                    {{item.txId}}</td>
                                    <td *ngIf="item.transactionType !== null && item.txCashType =='Refund'"
                                    [ngClass]="(item.amount < 0)?'text-body':''">{{item.transactionType}} Refund ID
                                    :
                                    {{item.txId}}</td>
                                <td *ngIf="item.transactionType === null"
                                    [ngClass]="(item.amount < 0)?'text-body':''">
                                    {{item.txCashType}}</td>
                                <td [ngClass]="(item.amount < 0)?'text-danger':'text-success'">
                                    {{item.amount | currency}}</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td>Cash Drawer Balance</td>
                                <td>{{currentShiftTotals | currency}}</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>

            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="two-action-buttons d-flex justify-content-center align-items-center">
                    <button class="button button-secondary" id="btn_Print" (click)="PrintCurrentShiftCashDetails()">Print</button>
                    <button id="btn_openCashDrawer" class="button button-secondary">Open Drawer</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal Popup -->
<!-- Vertically centered modal -->
<div class="component-popup calcpopup">
    <div class="modal fade" id="addCash" tabindex="-1" aria-labelledby="addCashLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-content">
                <div class="modal-body p-0">
                    <!-- Add Cash -->
                    <div class="cash-screen">
                        <div class="text-center">
                            <div class="d-flex justify-content-center">
                                <div class="opening-count-block cash-calculator">

                                    <div class="right-panel">
                                        <button class="img-btn cashcalcbtn"><img
                                                src="./assets/Libs/images/Calculator.png" alt="Calculator"></button>
                                        <button class="img-btn"><img src="./assets/Libs/images/cash-register.png"
                                                alt="Register"></button>
                                    </div>
                                    <!-- <h2>Enter Amount</h2> -->
                                    <div class="count-input form-group">
                                        <input type="number" [(ngModel)]="openingCount" value="0.00"
                                            class="form-control" min="0">
                                        <img src="./assets/Libs/images/dollar.png" alt="dollar">
                                    </div>
                                    <div class="count-buttons-block mt-4">
                                        <button class="btn count-button">1</button>
                                        <button class="btn count-button">2</button>
                                        <button class="btn count-button mr-0">3</button>
                                        <button class="btn count-button">4</button>
                                        <button class="btn count-button">5</button>
                                        <button class="btn count-button mr-0">6</button>
                                        <button class="btn count-button">7</button>
                                        <button class="btn count-button">8</button>
                                        <button class="btn count-button mr-0">9</button>
                                        <button class="btn count-button">0</button>
                                        <button class="btn count-button">.</button>
                                        <button class="btn count-button mr-0"><i
                                                class="fa fa-arrow-circle-o-left"></i></button>
                                    </div>
                                </div>
                                <div class="pin-tab">

                                    <form>
                                        <div class="tab-form">
                                            <div class="form-group row">
                                                <label for="input" class="col-sm-3 col-form-label">$100's</label>
                                                <label for="input" class="col-sm-2 col-form-label">:</label>
                                                <div class="col-sm-7">
                                                    <input type="text" class="form-control" id="inputtext" value="0">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="input" class="col-sm-3 col-form-label">$50's</label>
                                                <label for="input" class="col-sm-2 col-form-label">:</label>
                                                <div class="col-sm-7">
                                                    <input type="text" class="form-control" id="inputtext" value="0">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="input" class="col-sm-3 col-form-label">$20's</label>
                                                <label for="input" class="col-sm-2 col-form-label">:</label>
                                                <div class="col-sm-7">
                                                    <input type="text" class="form-control" id="inputtext" value="0">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="input" class="col-sm-3 col-form-label">$10's</label>
                                                <label for="input" class="col-sm-2 col-form-label">:</label>
                                                <div class="col-sm-7">
                                                    <input type="text" class="form-control" id="inputtext" value="0">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="input" class="col-sm-3 col-form-label">$5's</label>
                                                <label for="input" class="col-sm-2 col-form-label">:</label>
                                                <div class="col-sm-7">
                                                    <input type="text" class="form-control" id="inputtext" value="0">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="input" class="col-sm-3 col-form-label">$1's</label>
                                                <label for="input" class="col-sm-2 col-form-label">:</label>
                                                <div class="col-sm-7">
                                                    <input type="text" class="form-control" id="inputtext" value="0">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="input" class="col-sm-3 col-form-label">Change Total</label>
                                                <label for="input" class="col-sm-2 col-form-label">:</label>
                                                <div class="col-sm-7">
                                                    <input type="text" class="form-control" id="inputtext" value="0">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="total-cash">
                                            <div class="form-group row mb-0">
                                                <label for="input" class="col-sm-3 col-form-label">Total</label>
                                                <label for="input" class="col-sm-2 col-form-label">:</label>
                                                <div class="col-sm-7">
                                                    <input type="text" class="form-control" id="inputtext"
                                                        value="$0.00">
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Modal popup End -->

<!-- ------------------------------------------------------------------------------------------------------------------------------- -->
<div [style.display]="LoginModal? 'block' : 'none'" class="modal name-popup" id="imgmodal" tabindex="-1"
role="dialog" aria-labelledby="myMLabel" aria-hidden="true">
<div class="modal-dialog">
    <div class="modal-content signinClass">
        <div class="modal-header SignInHeader">
            <button type="button" class="close"data-dismiss="modal" (click)="hide('SignInModal')">×</button>
        </div>
        <div class="row col-md-12" >
            <div class="col-md-4 ">
                <img class="SignInImageIcon" src="../../../../assets/Libs/images/logo-icon.png">
            </div>
            <div class="modal-body col-md-8" >
                <h1 class="modal-title text-heading-color" id="myLabel">SIGN IN</h1>
                <form [formGroup]="LoginAdjuctGroup"  (ngSubmit)="AdjustLogin()">
                    <div class="form-group">

                        <input type="text" placeholder="Username" formControlName="UserNameLogin"
                            class="form-control" [ngClass]="{ 'is-invalid': Login.UserNameLogin.errors }" />
                            <div *ngIf="Login.UserNameLogin.errors" class="invalid-feedback">
                                <div *ngIf="Login.UserNameLogin.errors.required">UserName is required</div>
                            </div>
                    </div>
                    <div class="form-group">
                        <input type="password" placeholder="Password" formControlName="PasswordLogin"
                            class="form-control" [ngClass]="{ 'is-invalid': Login.PasswordLogin.errors }" />
                            <div *ngIf="Login.PasswordLogin.errors" class="invalid-feedback">
                                <div *ngIf="Login.PasswordLogin.errors.required">Password is required</div>
                            </div>
                    </div>
                    <button type="submit" id='btnSubmit'  class="btn btn-light  form-control"
                        >LOGIN</button>
                </form>
            </div>
        </div>
    </div>
</div>
</div>
<!-- --------------------------------------------------------------------------------------------------------------------------------- -->


<p-confirmDialog acceptLabel="Continue" appendTo="body">
    <ng-template pTemplate="body">
        <span class="ui-confirmdialog-message"></span>
    </ng-template>
</p-confirmDialog>