//import { Server } from "http";

export class configConstant {

    //#region NG POS API URL
    //Local connection(latest)
     // public static readonly apibaseurl = "https://localhost:44365/api";
    //public static readonly apibaseurl = "http://localhost:65131/api";
    // public static readonly apibaseurl = "https://advancedbusinessproducts-ht-setup.chetu.com/api"
     
    //Dev connection(latest)
     public static readonly apibaseurl = "https://dev-api-igift.iticketsolutions.com/api";
    //public static readonly apibaseurl = "http://dev-api-igift.iticketsolutions.com/api";
    
    //public static readonly apibaseurl = "http://data-prod/AdvancedBusinessProducts-API/api";
    //#endregion

    //#region  middleware constant 
   // public static readonly middlewareUrl = "http://localhost:60077/";
    //#endregion
}
