import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { AuthService } from 'src/app/Services/auth.service';
import { IPOSService } from 'src/app/Services/i-pos.service';
import { InventoryService } from 'src/app/Services/inventory.service';
//import { MiddleWare } from 'src/app/Services/middleware.service';
import { ShiftService } from 'src/app/Services/shift.service';
import Swal from 'sweetalert2';
import { AddInventorygrid } from '../add-inventorygrid.model';
import { InventoryModel } from '../inventory-model.model';
import * as $ from 'jquery';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationComponent } from '../navigation.component';


@Component({
  selector: 'app-inventory-adjustment',
  templateUrl: './inventory-adjustment.component.html',
  styleUrls: ['./inventory-adjustment.component.css']
})
export class InventoryAdjustmentComponent implements OnInit {
  position: string;
  workstationList: any = [];
  vendorList: any = [];
  currentUser: any;
  viewData: any = [];
  viewTransferData: any = [];
  viewProdDescData: any = [];
  viewProdTransferData: any = [];
  viewProdValidationCount: any = [];
  viewProdCount: any = [];
  SelectedProduct = 0;
  viewInventoryTransfer: any;
  newInventoryTansfer: any;
  transferId: any;
  transferDate: any;
  isTransferProd = false;
  isProdSelect = true;
  totalSelectedQTY: any;
  selectedOrderId: any;
  onHandId = 0;
  TransferToWsid: any;
  dynamicArray: Array<AddInventorygrid> = [];
  transferDetails: Array<InventoryModel> = [];
  newDynamic: any = {};
  InventoryList: any = {};
  transferProdId: any;
  transferQty = "";
  SelectedTransferId: any;
  SelectedFromWsid: any;
  SelectedToWsid: any;
  siteConfi: any;
  workstation = JSON.parse(localStorage.getItem('workstation'));
  UserInventoryMngmt: any;
  MySingInModal: boolean;
  LoginAdjuctInvent: FormGroup;
  errormsg: any;
  returnComponent:string;
  feature:string;
  subFeature:string;
  issubmitted:boolean=false;
  isInvetoryChanged:boolean=false;
  constructor(private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private _shiftService: ShiftService,
    private authenticationService: AuthService,
    private confirmationService: ConfirmationService,
    //private _middlewareService: MiddleWare,
    private posSerive: IPOSService,
    private invService: InventoryService, private toastr: ToastrService,private navbar:NavigationComponent) { }

  ngOnInit(): void {
    debugger;
    this.CheckPrinter();
    // Getting Current Shift Details
    this.isProdSelect = false;
    this.currentUser = this.authenticationService.currentUserValue;
    $("table td select input").removeClass("border border-danger");
    this.getNewTransfer();
    this.getProdForTransfer(this.workstation.workStationId);
    this.siteConfi = JSON.parse(localStorage.getItem("siteConfivalidated"));

    this.UserInventoryMngmt = JSON.parse(localStorage.getItem("UserInventoryMngmt"));
    this.LoginAdjuctInvent = this.formBuilder.group({
      UserNameInv: ['', Validators.required],
      PasswordLoginInv: ['', Validators.required]
    });
    $(".navigation ul li a").removeClass("active");
    $(".envSec").addClass("active");
    $(".salesSec, .transaSec, .cashSec").removeClass("active");
  }


  getNewTransfer() {
    this.isProdSelect = true;
    this.isTransferProd = false;
    let newTime = new Date().toLocaleString();
    this.transferId = this.workstation.workStationId + newTime.replace(/[#_ : PM , /]/g, '');

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    this.transferDate = mm + '/' + dd + '/' + yyyy;
    this.transferDetails = [];
    this.dynamicArray = [];

    //Get Inventory Workstation
    this.posSerive.GetWorkstations(this.currentUser.districtNum,'0')
      .subscribe((res) => {
        this.workstationList = res;
        console.log(res);
      });

  }

  ResetTransaction() {
    this.getNewTransfer();
    this.AddDynamicallyRow();
  }



  addFieldValue() {
    debugger;
    let adjusmelength=0;
    this.isProdSelect=false;
    if (this.dynamicArray.filter(x => x.ProductId === '0' || x.AddOrRemove === '0' || x.Quantity === '').length === 0) {
    for (let i = 0; i < this.dynamicArray.length; i++) {
      this.invService.GetInventoryQty(this.workstation.workStationId,this.dynamicArray[i].Quantity,this.dynamicArray[i].AddOrRemove,this.dynamicArray[i].ProductId.productId)
        .subscribe((viewInvetory) => {
          debugger
          if(this.dynamicArray[i].AddOrRemove == '2' &&  !this.isProdSelect){
            if (viewInvetory == 0 || viewInvetory < this.dynamicArray[i].Quantity) {
              this.toastr.error("Cannot subtract more than the onhand quantity for this product ("+viewInvetory+")");
              this.isProdSelect = true;
            }
            else {
                adjusmelength++;
                if(adjusmelength==this.dynamicArray.length){
                  this.AddDynamicallyRow();
                }
              }

           
          }
          else{
              adjusmelength++;
              if(adjusmelength==this.dynamicArray.length){
                this.AddDynamicallyRow();
              }
            
          }
        });
    }
  }
  else{
      this.toastr.error("Please select or fill all required fields");
      this.isProdSelect = true;
  }
  }

  AddDynamicallyRow(){
    if (this.dynamicArray.filter(x => x.ProductId === 0 || x.AddOrRemove === 0 || x.Quantity === '').length === 0) {
      this.newDynamic = { AddOrRemove: 0, SerialNo: 1, ProductId: 0, Quantity: "" };
      this.dynamicArray.push(this.newDynamic);
      this.isProdSelect = true;
    } else {
      this.toastr.error("Please select or fill all required fields");
      this.isProdSelect = true;
    }
  }

  deleteRow(index) {
    if (this.dynamicArray.length == 1) {
      //this.toastr.warning("Can't delete the row when there is only one row", 'Warning');
      this.dynamicArray[0].AddOrRemove = '0'
      this.dynamicArray[0].ProductId = '0'
      this.dynamicArray[0].Quantity = ''
      return false;
    } else {
      this.dynamicArray.splice(index, 1);
      return true;
    }
  }

   SaveAdjustmentTransaction(e, dynamicArray) {
    debugger;
    this.CheckAvailableQTY(e)
  }

  SaveTransferAndClose(e) {
    debugger;
    var prod = this.dynamicArray;
    const ts = this;
    var latestUser= localStorage.getItem("latestUser");
    let transactionInfo={};
     if(latestUser!=null && latestUser!='' && latestUser!="null")
     {
    prod.forEach(function (value) {
      console.log(value);
      ts.InventoryList = {
        WorkstationId: ts.workstation.workStationId,
        DistrictID: ts.currentUser.districtNum,
        ProductId: value.ProductId.productId,
        QTY: value.Quantity,
        CurrentShiftNum: ts.workstation.workStationId + '-' + (parseInt(ts.workstation.lastShiftNum) + 1),
        UserId: latestUser,
        ActionId: Number(value.AddOrRemove)
      }
      ts.transferDetails.push(ts.InventoryList);
    });
  }

  else{
    prod.forEach(function (value) {
      console.log(value);
      ts.InventoryList = {
        WorkstationId: ts.workstation.workStationId,
        DistrictID: ts.currentUser.districtNum,
        ProductId: value.ProductId.productId,
        QTY: value.Quantity,
        CurrentShiftNum: ts.workstation.workStationId + '-' + (parseInt(ts.workstation.lastShiftNum) + 1),
        UserId: ts.currentUser.userId,
        ActionId: Number(value.AddOrRemove)
      }
      ts.transferDetails.push(ts.InventoryList);
    });
  }
    if (this.transferDetails !== undefined && this.transferDetails.length > 0) {
      //Saving New Transfer Data
      this.invService.SaveAndUpdateInventoryAdjustment(this.transferDetails)
        .subscribe((result) => {
          if (result.message == 'Success') {
            if(latestUser!=null && latestUser!='' && latestUser!="null")
              {
               localStorage.setItem('latestUser','');
              }
            this.toastr.success('Adjustment has been successfully saved.');
            setInterval(() => {
              location.reload();
            }, 1500);
           // this.PrintReport();
          }
          else if (result.message == 'Warning1') {
            this.toastr.error('There is not quantity for the product on the district!!');
            setInterval(() => {
              location.reload();
            }, 1500);
          }
          else if (result.message == 'Warning2') {
            this.toastr.error('There is not quantity for the product on the workstation!!');
            setInterval(() => {
              location.reload();
            }, 1500);
          }
          else {
            Swal.fire({
              icon: 'error',
              title: 'Error!',
              text: 'Something Went Wromg!'
            });
          }
        });
    }
  }

  SaveTransferAndNew() {
    this.router.navigate(['../DashBoard'], { relativeTo: this.route });
    $(".navigation ul li a").removeClass("active");
    $(".homeSec").addClass("active");
  }

  getProdForTransfer(workststionId) {
    debugger;
    this.isTransferProd = true;
    this.TransferToWsid = workststionId;
    let invefalg = true
    this.invService.GetInventoryCount(this.workstation.workStationId, invefalg, this.currentUser.districtNum)
      .subscribe((viewInvetory) => {
        debugger
        let prodId = [];
        let flg = true;
        this.viewProdValidationCount = viewInvetory;
        for (let item of viewInvetory) {
          const productCount = prodId.filter(x => x.productId == item.productId).length
          if (productCount == 0) {
            this.viewProdTransferData.push(item)
            prodId.push(item);
          }

        }
        console.log(viewInvetory);
      });

    this.newDynamic = { AddOrRemove: 0, SerialNo: 1, ProductId: 0, Quantity: "" };
    this.dynamicArray.push(this.newDynamic);
  }

  selectProdForTransfer(totalQty, e) {
    debugger;
   this.ValidateInvetoryInitialStage();
    this.totalSelectedQTY = totalQty.totalProdQTY;
    this.transferProdId = totalQty.productId;
    this.onHandId = totalQty.onHandId;
    if (this.dynamicArray.filter(x => x.ProductId.productId === totalQty.productId).length > 1) {
      this.dynamicArray.splice(1, 1);
      this.newDynamic = { AddOrRemove: 0, SerialNo: 1, ProductId: 0, Quantity: "" };
      this.dynamicArray.push(this.newDynamic);
      this.toastr.error("You can not add or subtract same product !")
      this.isProdSelect = true;
    }
  }

  CheckAvailableQTY(e) {
    debugger;
    let adjusmelength=0;
    this.isProdSelect=false;
    for (let i = 0; i < this.dynamicArray.length; i++) {
     
        if((i === (this.dynamicArray.length - 1) && this.dynamicArray.length >1 ) && (this.dynamicArray[i].AddOrRemove === 0 || this.dynamicArray[i].AddOrRemove === '0') && (this.dynamicArray[i].ProductId === 0 || this.dynamicArray[i].ProductId === '0') && this.dynamicArray[i].Quantity === '' || this.dynamicArray[i].Quantity === null)
        {
          this.dynamicArray = this.dynamicArray.slice(0, -1)
          this.SaveInventoryadjusment(e);
          break;
        }
      
      if (this.dynamicArray[i].AddOrRemove === 0 || this.dynamicArray[i].AddOrRemove === '0')
      {

        this.toastr.error("Please select an Action!");
        this.isProdSelect = true;

      }
      if (this.dynamicArray[i].ProductId === 0 || this.dynamicArray[i].ProductId === '0' && this.isProdSelect) {
        if(!this.isProdSelect){
          this.toastr.error("Please select Product");
          this.isProdSelect = true;

        }

      }
       if (this.dynamicArray[i].Quantity === '' || this.dynamicArray[i].Quantity === null && this.isProdSelect) {
        if(!this.isProdSelect){
          this.toastr.error("Please Enter Quantity");
          this.isProdSelect = true;
        }
      }

      if (this.dynamicArray[i].AddOrRemove == '2' && !this.isProdSelect) {

          this.invService.GetInventoryQty(this.workstation.workStationId,this.dynamicArray[i].Quantity,this.dynamicArray[i].AddOrRemove,this.dynamicArray[i].ProductId.productId)
            .subscribe((viewInvetory) => {
              debugger

              if (viewInvetory == 0 || viewInvetory < this.dynamicArray[i].Quantity) {
                this.toastr.error("Cannot subtract more than the onhand quantity for this product ("+viewInvetory+")");
                this.isProdSelect = true;

              }
              else {
                if(!this.isProdSelect){
                  adjusmelength=adjusmelength+1
                  this.transferQty = "" + this.dynamicArray[i].ProductId.totalProdQTY;
                  this.isProdSelect = false;
                  if(adjusmelength==this.dynamicArray.length){
                    this.SaveInventoryadjusment(e)
                  }
                }

              }
            });
          }
        else {
          if(!this.isProdSelect){
            adjusmelength=adjusmelength+1
            this.transferQty = "" + this.dynamicArray[i].ProductId.totalProdQTY;
            this.isProdSelect = false;
            if(adjusmelength==this.dynamicArray.length){
              this.SaveInventoryadjusment(e)
          }
          }
        }

    }

  }


  SaveInventoryadjusment(e){

    const messages = `<p class='worstation-img'><img src="./assets/Libs/images/confirmation.png" /></p> <Div class="saveInvtAdj"><h2>Confirmation</h2><p class="assignstyle">Do you want to save this adjustment</p></Div> `;
    this.confirmationService.confirm({
      message: messages,
      header: 'Adjustment',
      rejectVisible: true,
      acceptLabel: 'Yes',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        $('.ui-dialog-mask-scrollblocker').css('display', 'none');
        this.SaveTransferAndClose(e);
      },
      reject: () => {
        $('.ui-dialog-mask-scrollblocker').css('display', 'none');
      }
    });
  }

  PrintReport() {
    let midResponse = 'success';
    setTimeout(() => {
      if (midResponse.includes('success')) {
        const messages = `<p class='worstation-img'><img src="./assets/Libs/images/QuestionCircle.png" /></p><p class="assignstyle">Print another copy?</p>`;
        this.confirmationService.confirm({
          message: messages,
          header: 'Print another copy?',
          rejectVisible: true,
          acceptLabel: 'Yes',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            $('.ui-dialog-mask-scrollblocker').css('display', 'none');
            this.ResetTransaction();
          },
          reject: () => {
            $('.ui-dialog-mask-scrollblocker').css('display', 'none');
            this.ResetTransaction();
          }
        });
      } else if (midResponse.includes('not Connected')) {
        Swal.fire({
          icon: 'warning',
          title: 'Warning!',
          text: midResponse,
        });
      }
      else {
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'Error while Printing!',
        });
      }
    }, 500);
  }



  SelectAddOrRemove(totalQty, e) {
    debugger;
    this.ValidateInvetoryInitialStage();
    if (totalQty == 1 && this.siteConfi.addInvertory == true) {
      if (this.UserInventoryMngmt.addInventory === 1) {
      } else {
        this.feature='Inventory Management';
        this.subFeature='Add Inventory';
        this.ValidationModalPopup();
      }
      
    }
    else if (totalQty == 2 && this.siteConfi.subtractInventory == true) {
      if (this.UserInventoryMngmt.removeInventory === 1) {
      } else {
        this.feature='Inventory Management';
        this.subFeature='Remove Inventory';
        
        this.ValidationModalPopup();
      }
    
    }
    else if (totalQty == '0') {

    }
    else {
      this.toastr.error("This workstation is not configured to perform this function.");
    }
  }
  AdjustLogin() {
    debugger;
this.issubmitted=true;
    if (this.LoginAdjuctInvent.invalid) {
      return;
    }
    console.log(this.LoginAdjuctInvent.value);
    let GetGUIDchrowm = localStorage.getItem("MacAddress");
    let decodeGUID=atob(GetGUIDchrowm)
    let userObj = {
      UserName: this.LoginAdjuctInvent.controls.UserNameInv.value,
      Password: this.LoginAdjuctInvent.controls.PasswordLoginInv.value,
      GUID: decodeGUID,
      Feature:this.feature,
      SubFeature:this.subFeature
    }
    this.authenticationService.UserLoginForOtherPermissions(userObj)
    .subscribe((res) => {
      this.MySingInModal = false;
      if(res.hasAccess){
      if(this.subFeature=='Add Inventory')
      {

      }
      else{

      }
    }     
    else{
      this.ValidationModalPopup();
    }   
    this.issubmitted=false;
        },
        error => {
          debugger;
          this.errormsg = error.error.message;
          this.toastr.error(this.errormsg);
          if (this.errormsg == 'Not Use This Workstation') {
            this.toastr.error("This workstation is not access to you, Please Enter the Valid Credentials");
          };
        });
  }
  get Login() { return this.LoginAdjuctInvent.controls; }

  hide(signclose: string) {
    if (signclose === 'SignInModal') {
      this.MySingInModal = false;
      this.issubmitted=false;
      window.location.reload();
    }
  }


  ValidationModalPopup() {
    Swal.fire({
      text: 'You do not have permission to perform this function. Would you like to enter alternate credentials?​',
      icon: 'warning',
      title:'Warning!',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        debugger;
        this.LoginAdjuctInvent = this.formBuilder.group({
          UserNameInv: ['', Validators.required],
          PasswordLoginInv: ['', Validators.required]
        });
        this.MySingInModal = true;
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        debugger;
        window.location.reload();
      }
    });
  }

  CheckPrinter()
  {
    this._shiftService.GetPrinterStatus(this.workstation.workStationId).subscribe((res) => {
      debugger;
      if(res!=3 && res!=0)
      {
      let printerMessage=  this.authenticationService.GetPrinterMessgae(res);
        Swal.fire({
          icon: 'warning',
          title: 'warning!',
          text: printerMessage,
          timer: 10000, // 10 seconds
          timerProgressBar: true,
        })
      }
    });
  }


  ValidateInvAdjustWhenNavIsClicked(){
   if ((this.dynamicArray[0].AddOrRemove !== 0 || this.dynamicArray[0].AddOrRemove !== '0') || (this.dynamicArray[0].ProductId !== 0 || this.dynamicArray[0].ProductId !== '0' && this.isProdSelect)||(this.dynamicArray[0].Quantity !== '' || this.dynamicArray[0].Quantity !== null && this.isProdSelect)){
    const messages = `<p class='worstation-img'><img src="./assets/Libs/images/QuestionCircle.png" /></p> <p><h2>Confirmation</h2></p> <p class="assignstyle">Do you want another copy of the Adjustment Report?</p>`;
    return true;
   }
  }

  CancelTransaction(){
    debugger;
    if(this.isInvetoryChanged){
    const messages = `<p class='worstation-img'><img src="./assets/Libs/images/confirmation.png" /></p> <h3 class="pop-head">Warning!</h3> <p class="assignstyle">Would you like abandon your entries?</p>`;
    this.confirmationService.confirm({
      message: messages,
      rejectVisible: true,
      acceptLabel: 'Yes',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        $('.ui-dialog-mask-scrollblocker').css('display', 'none');
        this.navbar.isdisabledNav=false;
        window.location.reload();
      },
      reject: () => {
        $('.ui-dialog-mask-scrollblocker').css('display', 'none');
        //window.location.reload();
      }
    });
  }
  }

CheckQuanity(qty){
    debugger;
    this.ValidateInvetoryInitialStage();
  }

  ValidateInvetoryInitialStage(){
    debugger;
      if(this.dynamicArray[0].AddOrRemove=="0" && this.dynamicArray[0].ProductId=='0' && this.dynamicArray[0].Quantity==""){
        this.navbar.isdisabledNav=false;
       this.isInvetoryChanged=false;
      }
      else if(this.dynamicArray[0].AddOrRemove==0 && this.dynamicArray[0].ProductId==0 && this.dynamicArray[0].Quantity==null){
        this.navbar.isdisabledNav=false;
       this.isInvetoryChanged=false;
      }
      else{
        this.navbar.isdisabledNav=true;
        this.isInvetoryChanged=true;
      }
  }
}
