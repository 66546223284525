import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { RefundDetails } from '../Models/Refund';
import { configConstant } from '../shared/constants/config.constant';
import { PrintJobModel } from '../Models/shift.model';
@Injectable({
  providedIn: 'root'
})
export class IPOSService {
  private baseurl =  configConstant.apibaseurl;
  constructor(private http: HttpClient) { }
 
  GetWorkstations(DistrictId,WorkStationId): Observable<any> {
    let CId = localStorage.getItem("Client_Id");
    const headers = new HttpHeaders().set('Client', CId);
    return this.http.get<any>(this.baseurl + '/Retail/WorkStations/' + DistrictId+'/'+ WorkStationId,{headers})
      .pipe(
        retry(1),
        catchError(this.errorHandl)
      );
  }

  // GetSiteConfigrationValidated(siteId , distId): Observable<any> {
  //   let CId = localStorage.getItem("Client_Id");
  //   const headers = new HttpHeaders().set('Client', CId);
  //   return this.http.get<any>(this.baseurl + '/Retail/GetSiteConfigrationValidation/' + siteId +'/'+distId,{headers})
  //     .pipe(
  //       retry(1),
  //       catchError(this.errorHandl)
  //     );
  // }
  GetQuickMenus(disNum): Observable<any> {
    let CId = localStorage.getItem("Client_Id");
    const headers = new HttpHeaders().set('Client', CId);
    return this.http.get<any>(this.baseurl + '/Retail/QuickMenues/' + disNum , {headers})
      .pipe(
        retry(1),
        catchError(this.errorHandl)
      );
  }

  GetVendorMenus(): Observable<any> {
    let CId = localStorage.getItem("Client_Id");
    const headers = new HttpHeaders().set('Client', CId);
    return this.http.get<any>(this.baseurl + '/Retail/GetVendorMenus' ,{headers})
      .pipe(
        retry(1),
        catchError(this.errorHandl)
      );
  }

  GetVendorMenusByDist(workstationId): Observable<any> {
    let CId = localStorage.getItem("Client_Id");
    const headers = new HttpHeaders().set('Client', CId);
    return this.http.get<any>(this.baseurl + '/Retail/GetVendorMenusByDistId/' + workstationId,{headers})
      .pipe(
        retry(1),
        catchError(this.errorHandl)
      );
  }

  GetProductCategories(): Observable<any> {
    let CId = localStorage.getItem("Client_Id");
    const headers = new HttpHeaders().set('Client', CId);
    return this.http.get<any>(this.baseurl + '/Retail/ProductCatagories', {headers})
      .pipe(
        retry(1),
        catchError(this.errorHandl)
      );
  }
  GetProductSubCategories(categoryId: any): Observable<any> {
    let CId = localStorage.getItem("Client_Id");
    const headers = new HttpHeaders().set('Client', CId);
    return this.http.get<any>(this.baseurl + '/Retail/ProductSubCatagories/' + categoryId, {headers})
      .pipe(
        retry(1),
        catchError(this.errorHandl)
      );
  }
  GetDayCounts(categoryId, subcategoryId): Observable<any> {
    let CId = localStorage.getItem("Client_Id");
    const headers = new HttpHeaders().set('Client', CId);
    return this.http.get<any>(this.baseurl + '/Retail/GetDayCount/' + categoryId + '/' + subcategoryId, {headers})
      .pipe(
        retry(1),
        catchError(this.errorHandl)
      );
  }
  GetProductsList(categoryId, subcategoryId, dayCount, WorkstationId,Maxdiscountfactor,displaySRP):
    Observable<any> {
      let CId = localStorage.getItem("Client_Id");
    const headers = new HttpHeaders().set('Client', CId);
    return this.http.get<any>(this.baseurl + '/Retail/GetProductList/' + categoryId + '/' + subcategoryId + '/' + dayCount + '/' + WorkstationId +'/'+ Maxdiscountfactor + '/' + displaySRP,{headers})
      .pipe(
        retry(1),
        catchError(this.errorHandl)
      );
  }

  GetProductsListByName(WorkstationId, seachName,DisCountFactor,displaySRP): Observable<any> {
    let CId = localStorage.getItem("Client_Id");
    const headers = new HttpHeaders().set('Client', CId);
    return this.http.get<any>(this.baseurl + '/Retail/GetProductsByName/' + WorkstationId + '/' + seachName+'/'+DisCountFactor+ '/' + displaySRP,{headers})
      .pipe(
        retry(1),
        catchError(this.errorHandl)
      );
  }
  // UpdateLastTXID(wsid, TXID,submitted): Observable<any> {
  //   let CId = localStorage.getItem("Client_Id");
  //   const headers = new HttpHeaders().set('Client', CId);
  //   return this.http.get<any>(this.baseurl + '/Retail/UpdateLastTXID/' + wsid + '/' + TXID +'/'+ submitted,{headers})
  //     .pipe(
  //       retry(1),
  //       catchError(this.errorHandl)
  //     );
  // }
  addCustomer(customer): Observable<any> {
    let CId = localStorage.getItem("Client_Id");
    const headers = new HttpHeaders().set('Client', CId);
    return this.http.post<any>(this.baseurl + '/Retail/SaveCustomer', customer, {headers})
      .pipe(
        retry(1),
        catchError(this.errorHandl)
      );
  }
  SaveTransaction(transactions): Observable<PrintJobModel> {
    // debugger;
    let CId = localStorage.getItem("Client_Id");
    const headers = new HttpHeaders().set('Client', CId);
    return this.http.post<PrintJobModel>(this.baseurl + '/Retail/SaveTransaction', transactions, {headers})
      .pipe(
        retry(1),
        catchError(this.errorHandl)
      );
  }


  SaveTransactionExchange(transactions): Observable<PrintJobModel> {
    // debugger;
    let CId = localStorage.getItem("Client_Id");
    const headers = new HttpHeaders().set('Client', CId);
    return this.http.post<PrintJobModel>(this.baseurl + '/Retail/SaveTransactionExchange', transactions, {headers})
      .pipe(
        retry(1),
        catchError(this.errorHandl)
      );
  }
  SavePrintQuote(printQuote): Observable<any> {
    // debugger;
    let CId = localStorage.getItem("Client_Id");
    const headers = new HttpHeaders().set('Client', CId);
    return this.http.post<any>(this.baseurl + '/Retail/SavePrintQuote', printQuote, {headers})
      .pipe(
        retry(1),
        catchError(this.errorHandl)
      );
  }
 
  GetTourDepositproductDetails(TourPLUCode): Observable<any> {
    // debugger;
    let CId = localStorage.getItem("Client_Id");
    const headers = new HttpHeaders().set('Client', CId);
    return this.http.get<any>(this.baseurl + '/Retail/GetTourDepositProduct/'+TourPLUCode, {headers})
      .pipe(
        retry(1),
        catchError(this.errorHandl)
      );
  }
 

  RefundTransaction(transactions): Observable<any> {
   debugger;
   let CId = localStorage.getItem("Client_Id");
    const headers = new HttpHeaders().set('Client', CId);
    let res= this.http.post<any>(this.baseurl + '/Retail/RefundTransaction', transactions, {headers})
      .pipe(
        retry(1),
        catchError(this.errorHandl)
      );
      return res;
  }
  RefundTransactionForExchange(transactions): Observable<any> {
    debugger;
    let CId = localStorage.getItem("Client_Id");
    const headers = new HttpHeaders().set('Client', CId);
     let res= this.http.post<any>(this.baseurl + '/Retail/RefundTransactionForExchange', transactions, {headers})
       .pipe(
         retry(1),
         catchError(this.errorHandl)
       );
       return res;
   }
  TourDeposit(tourobj): Observable<any> {
    let CId = localStorage.getItem("Client_Id");
    const headers = new HttpHeaders().set('Client', CId);
    return this.http.post<any>(this.baseurl + '/Retail/SaveTourDeposit', tourobj, {headers})
      .pipe(
        retry(1),
        catchError(this.errorHandl)
      );
  }
  // RefundDetailsByTxId(txid, distNUM): Observable<RefundDetails> {
  //   let CId = localStorage.getItem("Client_Id");
  //   const headers = new HttpHeaders().set('Client', CId);
  //   return this.http.get<RefundDetails>(this.baseurl + '/Retail/RefundDetailsByTxId/' + txid + '/' + distNUM,{headers})
  //     .pipe(
  //       retry(1),
  //       catchError(this.errorHandl)
  //     );
  // }
  GetWorkstationListById(siteId: number): Observable<any[]> {
    let CId = localStorage.getItem("Client_Id");
    const headers = new HttpHeaders().set('Client', CId);
    return this.http.get<any>(this.baseurl + '/Retail/GetWorKStationFromSite/' + siteId,{headers})
      .pipe(
        retry(1),
        catchError(this.errorHandl)
      );
  }
  // GetTransactionForRefund(): Observable<any> {
  //   let CId = localStorage.getItem("Client_Id");
  //   const headers = new HttpHeaders().set('Client', CId);
  //   return this.http.get<any>(this.baseurl + '/Retail/GetTxDetailsRefund',{headers})
  //     .pipe(
  //       retry(1),
  //       catchError(this.errorHandl)
  //     );
  // }
  GetSites(distNum,WsId): Observable<any> {
    let CId = localStorage.getItem("Client_Id");
    const headers = new HttpHeaders().set('Client', CId);
    return this.http.get<any>(this.baseurl + '/Retail/GetSites/' + distNum+'/'+WsId,{headers})
      .pipe(
        retry(1),
        catchError(this.errorHandl)
      );
  }
  GetCreditCards(txNum): Observable<any> {
    let CId = localStorage.getItem("Client_Id");
    const headers = new HttpHeaders().set('Client', CId);
    return this.http.get<any>(this.baseurl + '/Retail/GetCreditCardList/' + txNum,{headers})
      .pipe(
        retry(1),
        catchError(this.errorHandl)
      );
  }
  GetTenderList(txNum): Observable<any> {
    let CId = localStorage.getItem("Client_Id");
    const headers = new HttpHeaders().set('Client', CId);
    return this.http.get<any>(this.baseurl + '/Retail/GetPaymentList/' + txNum,{headers})
      .pipe(
        retry(1),
        catchError(this.errorHandl)
      );
  }

  CLoseShiftOnBrowser(currentShiftNum, WorkstationId): Observable<any> {
    let CId = localStorage.getItem("Client_Id");
    const headers = new HttpHeaders().set('Client', CId);
    return this.http.get<any>(this.baseurl + '/Shift/GetCurrentShiftDetails/' + currentShiftNum + '/' + WorkstationId,{headers})
      .pipe(
        retry(1),
        catchError(this.errorHandl)
      );
  }

  GetWorkstationsOnID(wsId): Observable<any> {
    let CId = localStorage.getItem("Client_Id");
    const headers = new HttpHeaders().set('Client', CId);
    return this.http.get<any>(this.baseurl + '/Retail/GetWorkStationBasedOnId/' + wsId,{headers})
       .pipe(
         retry(1),
         catchError(this.errorHandl)
       );
   }

   GetTendersDetail(siteId): Observable<any> {
    let CId = localStorage.getItem("Client_Id");
    const headers = new HttpHeaders().set('Client', CId);
    return this.http.get<any>(this.baseurl + '/Retail/GetTenderName/' + siteId,{headers})
       .pipe(
         retry(1),
         catchError(this.errorHandl)
       );
   }

  errorHandl(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
