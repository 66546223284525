<div class="right-content">
    <div class="heading-panel">
        <h1><i class="fa fa-home"></i>Inventory Management</h1>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12 text-center">
                <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-view" role="tab"
                            aria-controls="pills-home" aria-selected="true">View</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-viewTransfer"
                            role="tab" aria-controls="pills-profile" aria-selected="false"
                            (click)="getInventoryTransferList()">View Transfers</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-NewTransfers"
                            role="tab" aria-controls="pills-contact" aria-selected="false"
                            (click)="getNewTransfer()">New Transfers</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-cashOut" role="tab"
                            aria-controls="pills-contact" aria-selected="false">Adjustments</a>
                    </li>
                </ul>
                <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="pills-view" role="tabpanel"
                        aria-labelledby="pills-home-tab">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-sm-4 text-left">
                                    <label> Workstation</label>
                                    <select class="form-control wsDrpDownSize"
                                        (change)="getOnHandCount(selectField.value);" #selectField>
                                        <option value="0">-Select Workststion-</option>
                                        <option *ngFor="let item of workstationList" [value]="item.workStationId">
                                            {{item.workStationName}} {{item.workStationId}}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-sm-4 text-left">
                                    <label> Vendor</label>
                                    <select class="form-control wsDrpDownSize">
                                        <option value="0">-Select Vendor-</option>
                                        <option *ngFor="let item of vendorList" [value]="item.id">
                                            {{item.buttonName}}
                                        </option>
                                    </select>
                                </div>
                            </div><br />
                            <div class="row">
                                <div class="col-sm-12">
                                    <table
                                        class="table table-bordered table-striped custom-table table-scroll view-table">
                                        <thead class="tblProDetails">
                                            <tr>
                                                <th>Category</th>
                                                <th>Product Name</th>
                                                <th>Balance</th>
                                            </tr>
                                        </thead>
                                        <tbody *ngIf="viewData != ''">
                                            <tr *ngFor="let item of viewData; let i=index">
                                                <td class="text-left">{{item.catagoryName}}</td>
                                                <td class="text-left">{{item.productName}}</td>
                                                <td>{{item.totalProdQTY}} </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="pills-viewTransfer" role="tabpanel"
                        aria-labelledby="pills-profile-tab">
                        <div class="container-fluid">
                            <table class="table table-bordered table-striped custom-table table-scroll">
                                <thead class="tblProDetails">
                                    <tr>
                                        <th>Transfer Date</th>
                                        <th>Transfer ID</th>
                                        <th>From Location</th>
                                        <th>To Location</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody class="scroll-height" *ngIf="viewTransferData != ''">
                                    <tr *ngFor="let item of viewTransferData; let i=index">
                                        <td class="text-left">{{item.createdDate | date :  "dd/MM/yyyy"}}</td>
                                        <td class="text-left">{{item.transferId}}</td>
                                        <td class="text-left">{{item.transferFromWsid}}</td>
                                        <td class="text-left">{{item.transferToWsid}}</td>
                                        <td class="text-left">{{item.status}}</td>
                                        <td>
                                            <a *ngIf="item.status == 'Pending' && item.transferToWsid == workstation.workStationId"
                                                (click)="ChangeStatus(item.viewTransferId, item.transferToWsid)"><i
                                                    class="fa fa-warning"></i></a> &nbsp;
                                            <a data-toggle="modal" data-target="#myModal" (click)="ViewTransferStatus(item.transferId)">
                                                <i class="fa fa-eye"></i></a> &nbsp;
                                            <a *ngIf="item.status == 'Pending' && item.transferFromWsid == workstation.workStationId"
                                                (click)="DeleteTransfer(item.viewTransferId)" class="text-danger">
                                                <i class="fa fa-trash"></i></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="pills-NewTransfers" role="tabpanel"
                        aria-labelledby="pills-contact-tab">
                        <div class="container-fluid">
                            <div class="row">
                                <table class="table table-bordered table-striped">
                                    <thead class="tblProDetails">
                                        <tr>
                                            <th>Transfer Date</th>
                                            <th>Transfer ID</th>
                                            <th>TO Location</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{{transferDate}}</td>
                                            <td>{{transferId}}</td>
                                            <td style="width: 300px;">
                                                <select class="form-control"
                                                    (change)="getProdForTransfer(selectWS.value);" #selectWS>
                                                    <option value="0">-Select Workststion-</option>
                                                    <option *ngFor="let item of workstationList"
                                                        [value]="item.workStationId">
                                                        {{item.workStationName}} {{item.workStationId}}
                                                    </option>
                                                </select>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="table table-bordered table-striped" *ngIf="isTransferProd">
                                    <thead class="tblProDetails">
                                        <tr>
                                            <th>#</th>
                                            <th>Product</th>
                                            <th>Quantity</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let dynamic of dynamicArray; let i = index;">
                                            <td><label [(ngModel)]="dynamicArray[i].SerialNo"
                                                    ngDefaultControl>{{i+1}}</label>
                                            </td>
                                            <td style="width: 300px;">
                                                <select class="form-control" [(ngModel)]="dynamicArray[i].ProductId"
                                                    ngDefaultControl (ngModelChange)="selectProdForTransfer($event);"
                                                    #selectProd>
                                                    <option value="0">-Select Product-</option>
                                                    <option *ngFor="let item of viewProdTransferData" [ngValue]="item">
                                                        {{item.productName}}/{{item.totalProdQTY}}
                                                    </option>
                                                </select>
                                            </td>
                                            <td style="width: 300px;">
                                                <input type="number" [(ngModel)]="dynamicArray[i].Quantity"
                                                    ngDefaultControl (keyup.enter)="CheckAvailableQTY($event)"
                                                    class="form-control rounded">
                                            </td>
                                            <td class="text-danger"> <i class="fa fa-trash fa-2x " ngDefaultControl
                                                    (click)="deleteRow(i)"></i></td>
                                        </tr>
                                        <tr>
                                            <td class="text-success" style="font-size: x-large;">
                                                <i class="fa fa-plus-circle" ngDefaultControl
                                                    (click)="addFieldValue()"></i>
                                            </td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6"></div>
                            <div class="col-sm-6 text-right">
                                <button [disabled]="isProdSelect" class="button button-secondary"
                                    (click)="SaveTransferAndClose()">Save & Close</button>&nbsp;
                                <button [disabled]="isProdSelect" class="button button-secondary"
                                    (click)="SaveTransferAndClose()">Save & New</button>&nbsp;
                                <button class="button button-secondary" (click)="ResetTransaction()"><i
                                        class="fa fa-rotate-left"></i> Reset</button>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="pills-cashOut" role="tabpanel" aria-labelledby="pills-contact-tab">
                        <div class="container-fluid">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<p-confirmDialog acceptLabel="Continue" appendTo="body">
    <ng-template pTemplate="body">
        <span class="ui-confirmdialog-message"></span>
    </ng-template>
</p-confirmDialog>



<div class="modal" id="myModal">
    <div class="modal-dialog">
      <div class="modal-content">
  
        <!-- Modal Header -->
        <div class="modal-header">
          <h6 class="modal-title text-white">Transfer # {{SelectedTransferId}} From {{SelectedFromWsid}} To {{SelectedToWsid}}</h6>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
  
        <!-- Modal body -->
        <div class="modal-body">
            <table class="table table-bordered table-striped">
                <thead class="tblProDetails">
                    <tr>
                        <th>Transfer Date</th>
                        <th>PLU Code</th>
                        <th>Product Description</th>
                        <th>QTY</th>
                        <th>From Location</th>
                    </tr>
                </thead>
                <tbody class="scroll-height" *ngIf="viewProdDescData != ''">
                    <tr *ngFor="let item of viewProdDescData; let i=index">
                        <td>{{item.createdDate | date :  "dd/MM/yyyy"}}</td>
                        <td>{{item.pluCode}}</td>
                        <td>{{item.productDescription}}</td>
                        <td>{{item.qty}}</td>
                        <td>{{item.transferFromWsid}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
  
        <!-- Modal footer -->
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
        </div>
  
      </div>
    </div>
  </div>