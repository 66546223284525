export class DateHelper {
    public static Yesterday() {
      let thePreviousDay = new Date();
      thePreviousDay.setFullYear(thePreviousDay.getFullYear(), thePreviousDay.getMonth(), thePreviousDay.getDate() - 1);
      return thePreviousDay;
    }

    public static LastWeekStartDate() {
      let lastWeek = new Date();
      lastWeek.setFullYear(lastWeek.getFullYear(), lastWeek.getMonth(), lastWeek.getDate() - (lastWeek.getDay() + 7));
      return lastWeek;
    }

    public static LastWeekEndDate() {
      let lastWeek = new Date();
      lastWeek.setFullYear(lastWeek.getFullYear(), lastWeek.getMonth(), lastWeek.getDate() - (lastWeek.getDay() + 1));
      return lastWeek;
    }

    public static LastMonthStartDate() {
      let lastMonth = new Date();
      lastMonth.setFullYear(lastMonth.getFullYear(), lastMonth.getMonth() - 1, 1);
      return lastMonth;
    }
    
    public static LastMonthEndDate() {
      let lastMonth = new Date();
      lastMonth.setFullYear(lastMonth.getFullYear(), lastMonth.getMonth(), lastMonth.getDate() - lastMonth.getDate());
      return lastMonth;
    }

    public static TodayAdd(dayNumber: number) {
      let theTodayAdded = new Date();
      theTodayAdded.setFullYear(theTodayAdded.getFullYear(), theTodayAdded.getMonth(), theTodayAdded.getDate() + dayNumber);
      return theTodayAdded;
    }

    public static TodaySubtract(dayNumber: number) {
      let theTodaySubtracted = new Date();
      theTodaySubtracted.setFullYear(theTodaySubtracted.getFullYear(), theTodaySubtracted.getMonth(), theTodaySubtracted.getDate() - dayNumber);
      return theTodaySubtracted;
    }
}