import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/Services/auth.service';

@Component({
  selector: 'app-load-component',
  templateUrl: './load-component.component.html',
  styleUrls: ['./load-component.component.css']
})
export class LoadComponentComponent implements OnInit {

  constructor(private router: Router,private authenticationService: AuthService,) { }

  ngOnInit(): void {
    debugger;
    let url = new URL(window.location.href);
    let clId = url.searchParams.get("Client_Id");
    var macaddress = url.searchParams.get("macaddress");
    if(macaddress!=null && clId!=null){
      localStorage.setItem("Client_Id",clId);
      let endcode=btoa(macaddress);
      localStorage.setItem("MacAddress",endcode);
      this.authenticationService.CheckMacAddressIspresentOrNot(macaddress)
      .subscribe((res) => {
        debugger;
        if(res){
          this.router.navigate(['/Login']);
        }
        else{
          
          this.router.navigate(['/DistrictComponent']);
        }
  
      });
    }
    else{
      this.router.navigate(['/Login']);
    }
   

  }

}
