<!-- <div class="login-container" style="background: url(./assets/Libs/images/login-background.png);"> -->
    <div class="login-container" style="background-color: #0082D2;">
        <div class="container" style="height: 95vh;">
            <div class="row h-100 align-items-center">
                <div class="col-sm-12">
                    <div class="mb-4 text-center">
                        <img src="./assets/Libs/images/logo-giftbox.png" alt="" class="logo-size">
                         <!-- <h4 class="fgt-pswd-txt">Reset Password</h4> -->
                    </div>
                    <div  class="login-box">
                        <div class="reset-pswdTxt text-center">Reset Password</div>
                        <form [formGroup]="SetPassword" (ngSubmit)="SetNewPassword()">
                            <div class="form-box mb-4">
                                <div class="grp-style d-flex align-items-center">
                                    <div style="width: 10%;">
                                        <img _ngcontent-yca-c51="" src="./assets/Libs/images/lock-icon-new.png" class="icon-size">
                                    </div>
                                    <fieldset class="form-group w-100 mb-0">
                                        <input class="input-rk" [type]="passwordTextType" autocomplete="off" readonly
                                            onfocus="this.removeAttribute('readonly');" formControlName="newpassword1"
                                            [ngClass]="{'is-invalid': submitted && f.newpassword1.errors }"
                                            placeholder="Password">
                                        <div *ngIf="submitted && f.newpassword1.errors" class="invalid-feedback">
                                            <div *ngIf="f.newpassword1.errors.required">Password is required</div>
                                        </div>
                                    </fieldset>
                                    
                                </div>
                            </div>
                            <div class="form-box mb-4">
                                <div class="grp-style d-flex align-items-center">
                                    <div style="width: 10%;">
                                        <img _ngcontent-yca-c51="" src="./assets/Libs/images/lock-icon-new.png" class="icon-size">
                                    </div>
                                    <fieldset class="form-group w-100 mb-0">
                                        <input class="input-rk" [type]="passwordTextType" autocomplete="off" readonly
                                            onfocus="this.removeAttribute('readonly');" formControlName="newpassword2"
                                            [ngClass]="{ 'is-invalid': submitted && f.newpassword1.errors }"
                                            placeholder="Confirm Password">
                                        <div *ngIf=" submitted && f.newpassword2.errors" class="invalid-feedback">
                                            <div *ngIf="f.newpassword2.errors.required">Confirm Password is required</div>
                                        </div>
                                    </fieldset>
                                    <!-- <img *ngIf="eyeShow == true" src="./assets/Libs/images/eye-show-icon.png"
                                        (click)="eyeShowHide()" class="icon-size">
                                    <img *ngIf="eyeShow == false" src="./assets/Libs/images/eye-hide-icon.png"
                                        (click)="eyeShowHide()" class="icon-size"> -->
                                </div>
                            </div>
                            <fieldset>
                                <button class="btn btn-submit">Submit</button>
                            </fieldset>
                            <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="container d-flex justify-content-center" style="height: 5vh;">
            <div class="row align-items-center justify-content-center">
                <div class="col-sm-12">
                    <div class="text-center">
                        <span class="Copyright-text">Copyright © 2024 GiftBox v1.04.00 All rights reserved.</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    
    
    
    




