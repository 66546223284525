<div class="login-container" style="background-color: #0082D2;">
    <!-- <div class="login-container" style="background-color: #0082D2;"> -->
</div>

<div [style.display]="SiteSelectionModal? 'block' : 'none'" class="modal name-popup" id="imgmodal" tabindex="-1"
    role="dialog" aria-labelledby="myMLabel" aria-hidden="true">
    <div class="modaldialog">
        <div class="signinClass">
            <div class="modal-header SignInHeader">
                <h4><img class="giftImage" src="./assets/Libs/images/HeaderLogo.png" height="16">GiftBox Setup Wizard
                </h4>
                <!-- <button type="button" c lass="close" data-dismiss="modal">×</button> -->
            </div>
            <div class="modal-body bodyClass p-0">
                <div class="secodHeader">
                    <img class="GiftBoxLogo" src="./assets/Libs/images/GiftLog.png" height="16">
                </div>
                <form>
                    <div class="formClass cstm_mainFormBox">
                        <h2>Next: Select the Site Assignment</h2>
                        
                        <div class="cstmInputBox">
                            <select class="form-control formdata" [(ngModel)]="sitedetails" [ngModelOptions]="{standalone: true}">
                                <option value="--Select Site--">--Select Site--</option>
                                <option *ngFor="let Site of SiteList" [value]="Site.siteId">
                                    {{Site.siteName}}
                                </option>
                            </select>
                        </div>
                        <div>
                            <span class="ErrorText" >{{Message}}</span>
                        </div>
                        <div class="modal_BTNBox">
                            <button type="submit" (click)="selectSite()"class="btn btn-light btnSubmit">Continue</button>
                        </div>
                    </div>
                </form>
            </div>
            <!-- <div class="row">
            </div> -->
        </div>
    </div>
</div>