import { AfterViewInit, Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as $ from 'jquery';
import { CartValues, ICustomer } from 'src/app/Models/Shared/Product.Cart';
import { PrintJobModel } from 'src/app/Models/shift.model';
import { AuthService } from 'src/app/Services/auth.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { NavigationComponent } from '../navigation.component';
import { IPOSService } from 'src/app/Services/i-pos.service';
import { GiftInfo, ProductMenuDetails } from 'src/app/Models/Shared/GiftCard.Product';
import { ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OpenGiftingService } from 'src/app/Services/open-gifting.service';
import { CommonService } from 'src/app/Services/common.service';
import { DataService } from 'src/app/Services/data.service';
import { ShiftService } from 'src/app/Services/shift.service';
import { ConfirmationService } from 'primeng';


@Component({
  selector: 'app-opengifting-card-products',
  templateUrl: './opengifting-card-products.component.html',
  styleUrls: ['./opengifting-card-products.component.css']
})
export class OpengiftingCardProductsComponent implements OnInit {
  //Variables for OpengiftingCardProductsComponent

 //Array ,list variable
 responsiveOptions: any[];
 SelectedProducts: any = [];
 SelectProducts: any = [];
 SplitTransaction: any = [];
 types: any = [];
 NewProducts: ProductMenuDetails[];
 CalculateTotalMoneyCertAmounts: any = [];
 exchangeDetails: any = [];
 TenderList: any = [];
 TenderListForExchange: any = [];
 exchangeProductsDetails: any = [];
 giftInfoList: GiftInfo[] = [];
 formValuesList: { [key: string]: any }[] = [];
 //-----------------------------------------------------
 //User Difine Datatype
 user = {} as ICustomer;
 cartValues = {} as CartValues;
 form = new FormGroup({})
 registerForm: FormGroup;
 TourIdModel : GiftInfo;
//--------------------------------------------
//Bool variable
showModal: boolean = false;
ShowModalPopup: boolean;
submitted: boolean = false;
isShowPSP: boolean = true;
isSRPShow: boolean = true;
isDiscountShow: boolean = true;
isShowPrCount: boolean = false;
isMoneyShow: boolean = false;
isTxPrinting: boolean = false;
isTransCancil: boolean = false;
isCCPrinting: boolean = false;
isTendorDisable: boolean = false;
isBackDisable: boolean = false;
tenderFlg: boolean = false;
isExhange: boolean = false;;
isShowCredit: boolean  = false;
isShowProdList: boolean  = false;
 isQuickShow: boolean  = true;
isvendorShow: boolean  = true;
iscatShow: boolean  = true;
isFinish: boolean  = true;
NoBalanceDue: boolean  = false;
isProcessCancel: boolean = false;
tourModal: boolean = true;
LastTxNo: boolean = false;
showModalforName: boolean;
isAutofocus: boolean = true;
isLead: boolean = false;
isshowRoomModal: boolean = false;
showKeypadModal: boolean = false;
isTourDepo: boolean;
isPrintCopy : boolean = false;
//----------------------------------------------

//Any Type variable
TxTotal: any;
change: any = 0;
  selectedTender: any;
  selectedTenderId: any;
  ExchangeTenderType: any;
  selectedCashTenderId: any;
  selectedCCTenderId: any;
  selectedRoomTenderId: any;
  amountTendered:any = 0;
  creditAmont: any = 0;
  currentUser: any;
  workstation: any;
  catagoryName: any;
  payTotal: any = 0;
  exchangeAmout: any;
  CalculateTotalCashAmount: any = 0;
  CalculateTotalRoomAmount: any = 0;
  CalculateTotalMoneyCertAmount: any = 0;
  siteConfi: any;
  
  TenderId: any;
  cashTender: any;
  roomChargeTender: any;
  creditcardTender: any;
  ListOfMediaNum: any;
//------------------------------------------------------------

//String Type Variable
customerName: string ="";
tenderTypeName: string ;
roomNumber: string='';
selectedType: string;
TourId: string = '';
SoldForAmount: string;
searchText: string;
OptionSelect: string='OpenGift';
//---------------------------------------------------------------------

// Number Type
  tourId:number = 0;
  TxNum = 0;
  changeDue: number;
  currentProdQty = 0;
  CustId = 0;
  selectedCCAmount = 0;
  totalCardCalc = 0;
  Trantype: number =4;
  summaryTotal: number = 0;
  exchangeProductAmount: number = 0;
  currentPage = 1;
  itemsPerPage = 8;
  depositRefundValue: number | null = null; // Variable to store Deposit Refund value
  cashGiftValue: number | null = null;

  //--------------------------------------------------------------



  constructor(
    private formBuilder: FormBuilder,
    private host: ElementRef, 
    private _giftService: OpenGiftingService,
    private _commonService: CommonService,
    private toastr: ToastrService,
    private authenticationService: AuthService,
    private nav: NavigationComponent,
    private _posSerive: IPOSService,
    private cdr: ChangeDetectorRef,
    private _dataService: DataService,
    private router: Router,
    private route: ActivatedRoute,
    private _dataSerive: DataService,
    private _shiftService: ShiftService,
    private confirmationService: ConfirmationService
  ) {
    this.SoldForAmount = '0.00';
  }


  ngOnInit() {
   $(".navigation ul li a").removeClass("active");
   $(".transaSec").addClass("active");
   $(".salesSec, .cashSec, .envSec").removeClass("active");
    this.currentUser = this.authenticationService.currentUserValue;
    this.workstation = JSON.parse(localStorage.getItem('workstation'));
    this.SetDynamicValues();
    localStorage.setItem('OutSessionShift', JSON.stringify(true));
debugger;
    this.nav.isdisabledNav = false;
    localStorage.setItem("isdisabledNavBar", 'false');
    this._dataSerive.getbehaviorListData().subscribe(res => this.exchangeDetails = res);
    this._dataService.getbehaviorObjData().subscribe(profile => 
      this.OptionSelect = profile?.OptionSelect === undefined || profile?.OptionSelect === null ? 'Gifting' : profile?.OptionSelect
  );
  this._commonService.UpdateLastTXID(this.workstation.workStationId, this.workstation.lastTxId, this.submitted)
  .subscribe((res) => {
    this.TxNum = res + 1;
    this._dataService.setbehaviorObjData({ TxNum: this.TxNum });
    this._dataService.getbehaviorObjData().subscribe(profile => this.TxNum = profile.TxNum);
   
  });
  
    if (this.OptionSelect !== 'Exchange') {
      this.resetCartValues();
      this.isTourIdPresent();
    }
    else{
     
      this.exchangeFunction();
    }
    this.registerForm = this.formBuilder.group({
      FirstName: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      LastName: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),]]
    });

  }

  
  exchangeFunction() {
    debugger;

      this.handleExchangeOption();
    this.setupResponsiveOptions();
    this.setupRegisterForm();
    this.nav.isdisabledNav = false;
    localStorage.setItem("isdisabledNavBar", 'false');

    this._posSerive.GetTenderList(this.SelectedProducts[0].txid).subscribe((res) => {
      this.TenderListForExchange = res;
      this.showCashCCByTenderType();
      this.calculatePayTotal();
      this.markAllTendersAsSelected();
    });
   
  }
  
  resetCartValues() {
    this.cartValues = {
      SRPTotal: 0.00,
      Discounts: 0.00,
      TxTotal: 0.00,
      BalanceDue: 0.00
    };
  }
  
  handleExchangeOption() {
    this.tourModal = false;
    this.exchangeDetails.forEach(currentRow => {
      currentRow.fastButtonId = 0;
      this.ListOfMediaNum += `,${currentRow.mediaNumber}`;
      const index = this.SelectedProducts.findIndex(pro => 
        pro.plucode ? pro.plucode === currentRow.plucode : pro.productId === currentRow.productId);
  
      if (index < 0) {
        this.SelectedProducts.push(currentRow);
        currentRow.Total = currentRow.total;
      } else {
        this.updateSelectedProduct(currentRow);
      }
    });
  
    if (this.SelectedProducts.length > 0) {
      this.updateCartForExchange();
    }
  }
  
  updateSelectedProduct(currentRow) {
    this.SelectedProducts.forEach(latestRow => {
      if (latestRow.productId === currentRow.productId) {
        latestRow.qty += currentRow.qty;
        latestRow.mediaNumber += `,${currentRow.mediaNumber}`;
        const total = latestRow.srp < latestRow.total ? latestRow.total : latestRow.srp;
        latestRow.Total = (total * latestRow.qty) - (Math.abs(latestRow.discount) * latestRow.qty);
      }
    });
  }
  
  updateCartForExchange() {
    const firstProduct = this.SelectedProducts[0];
    this.customerName = firstProduct.customerName;
    this.CustId = firstProduct.custId;
    this.cartValues.TxTotal = 0;
    this.cartValues.BalanceDue = 0;
    this.cartValues.SRPTotal = 0;
    this.isShowCredit = true;
    this.LastTxNo = true;
  
    this.SelectedProducts.forEach(product => {
      product.fastButtonName = product.productName;
      product.price = -product.total;
      product.srp = -product.srp;
      product.discount = 0;
      product.type = 'Exchange';
      product.qty = -product.qty;
      product.Total = -product.Total;
      product.minimumDiscount = -product.minimumDiscount;
      product.maximumDiscount = -product.maximumDiscount;
      this.cartValues.BalanceDue += product.Total;
      this.creditAmont += product.Total;
    });
  
    this.tourId = firstProduct.tourId;
    this.payTotal = 0;
    this.cartValues.Discounts = 0;
    this.isExhange = true;
    this.cartValues.BalanceDue = -Math.abs(this.creditAmont);
    this.payTotal = this.cartValues.TxTotal;
  }

  trackBySection(index: number, section: any): string {
    return section.sectionName; // or another unique identifier for the section
  }
  
  trackByProduct(index: number, product: any): string {
    return product.productId; // or another unique identifier for the product
  }
  
  setupResponsiveOptions() {
    this.responsiveOptions = [
      { breakpoint: '1024px', numVisible: 3, numScroll: 3 },
      { breakpoint: '768px', numVisible: 2, numScroll: 2 },
      { breakpoint: '560px', numVisible: 1, numScroll: 1 }
    ];
  }
  
  setupRegisterForm() {
    this.registerForm = this.formBuilder.group({
      FirstName: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      LastName: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]]
    });
  }
  
  calculatePayTotal() {
    const selectProdAmt = this.SelectedProducts.reduce((a, { total }) => a + total, 0);
    const tendorAmt = this.TenderListForExchange.reduce((a, { tenderAmount }) => a + tenderAmount, 0);
 
    this.payTotal = selectProdAmt === tendorAmt ? tendorAmt : selectProdAmt;
  }
  
  determineTenderType(tenderType) {
    switch (tenderType) {
      case 1: return 'Cash';
      case 2: return 'CreditCard';
      case 3: return 'Room Charge';
      case 4: return 'Money Cert';
      default: return '';
    }
  }
  
  markAllTendersAsSelected() {
    this.TenderListForExchange.forEach(tender => {
      tender.isSelect = true;
      tender.Amount = this.payTotal;
    });
  }
  showCashCCByTenderType() {
    debugger;
    const refundTotal = this.SelectedProducts.reduce((a, { total }) => a + parseFloat(total), 0);
    this.exchangeAmout = refundTotal;
    let ttlRefund = refundTotal;
  
    const tenderTypes = {
      cash: { type: [1, 4], amount: 0, sum: 0 },
      cc: { type: [2], amount: 0, sum: 0 },
      roomCharge: { type: [3], amount: 0, sum: 0 },
      moneyCert: { type: [4], amount: 0, sum: 0 },
    };
  
    Object.keys(tenderTypes).forEach(key => {
      const tender = tenderTypes[key];
      const filtered = this.TenderListForExchange.filter(x => tender.type.includes(x.tenderType));
      tender.sum = filtered.length;
      tender.amount = filtered.reduce((a, { tenderAmount }) => a + tenderAmount, 0);
    });
  
    const allocateRefund = (tenderKey, tenderAmount) => {
      if (tenderTypes[tenderKey].sum > 0) {
        if (ttlRefund < tenderAmount) {
          this[`edit${tenderKey.charAt(0).toUpperCase() + tenderKey.slice(1)}Amount`] = ttlRefund;
          ttlRefund = 0;
        } else {
          this[`edit${tenderKey.charAt(0).toUpperCase() + tenderKey.slice(1)}Amount`] = tenderAmount;
          ttlRefund -= tenderAmount;
        }
        if (tenderKey === 'cc') {
          this.tenderTypeName = 'CC';
        }
      }
    };
  
    allocateRefund('cc', tenderTypes.cc.amount);
    allocateRefund('roomCharge', tenderTypes.roomCharge.amount);
    allocateRefund('cash', tenderTypes.cash.amount + tenderTypes.moneyCert.amount);
  
    if (tenderTypes.cash.sum > 0 || tenderTypes.moneyCert.amount > 0) {
      this.tenderTypeName = 'CASH';
    }
  }
  //Calculate the items to display for the current page
  get paginatedItems() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    return this.giftInfoList.slice(startIndex, startIndex + this.itemsPerPage);
  }

  keytab(event) {
    debugger;
    const key = event.key;
   if(!this.RestrictNumbers(Number(key)))
   {
    event.preventDefault();
   }
    if (event.keyCode == 13) {
      $('#lastName').focus();
    }
  }

  FocusSubmit(event) {
    debugger;
    if (event.keyCode == 13) {
      $('#btnSubmit').focus();
    }
  }


  prevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }


  nextPage() {
    if (this.validatePage() && this.currentPage < this.totalPages) {
      this.currentPage++;
    }
    else {
      this.toastr.error('Please fill out all required fields.');
    }
  }


isTourIdPresent(){
  this._giftService.GetGiftingInformation(this.workstation.districtId).subscribe((res) => {
    res.forEach(info => {
      
      if (info.giftCodeName === 'Tour ID') {
        this.TourIdModel = info;
         this.tourModal = true;
      }
    });
    debugger;
    
    if(this.TourIdModel == null || this.TourIdModel == undefined){
      this.tourModal = false;
      this.form = new FormGroup({})
      this.GetGiftingIn();
      this.tourModal = false;
      this.showModal = true;
    }
  });
}
  validatePage(): boolean {
    let isValid = true;
    this.paginatedItems.forEach(control => {
        if (control.isRequired) {
            const formControl = this.form.get(control.giftCodeName);

            // Exclude specific fields with value 0.00 from validation
            if (control.giftCodeName === 'Cash Gift' || control.giftCodeName === 'Deposit Refund') {
                if (formControl?.value === '0.00') {
                  isValid = false;
                    return isValid; // Skip validation for this control
                }
            }

            formControl?.markAsTouched();
            if (formControl?.invalid) {
                isValid = false;
            }
        }
    });
    return isValid;
}
  get totalPages() {
    return Math.ceil(this.giftInfoList.length / this.itemsPerPage);
  }


  tourContinue() {
    debugger
    if (this.TourIdModel.isRequired && this.TourId == '') {
      this.isLead = true;
    }
    else {
      if(this.TourId == ''){
        this.TourId = null;
      }
      this.form = new FormGroup({})
      this.GetGiftingIn()
      this.tourModal = false;
      this.showModal = true;
      this.nav.isdisabledNav = true;
    }
  }

  cancelgiftingInfo() {
    Swal.fire({
      icon: 'warning',
      title: 'Warning!',
      text: 'Are you sure you would like to cancel your entries?',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'No',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {

        location.reload();
      }
    })
  }
  ongiftingInfoFormSubmit() {
    debugger;
    this.form.get('Tour ID')?.enable();
    if (this.form.valid) {
      const messages = `<p class='worstation-img'><img src="./assets/Libs/images/confirmation.png" /></p><h3 class="pop-head">Warning!</h3><p class="assignstyle">Information here is critical to the accounting department.<br>Please verify your entries before continuing.<br>Have you verified your entries?</p>`
      this.confirmationService.confirm({
        message:  messages,
        header: 'Confirmation',
        rejectVisible: true,
        acceptLabel: 'Yes',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.nav.isdisabledNav = true;
          this.showModal = false;
          this.showModalforName = true;
          let values = this.form.value;
          debugger;
          this.formValuesList = Object.keys(values).map(controlName => {
            return { [controlName]: values[controlName] };
          });
          // Find the Deposit Refund value
          const depositRefundEntry = this.formValuesList.find(entry => 'Deposit Refund' in entry);
          if (depositRefundEntry) {
            this.depositRefundValue = depositRefundEntry['Deposit Refund'];
            if(this.depositRefundValue == 0){
              this.depositRefundValue = null;
            }
           
          } else {
            this.depositRefundValue = null;
          }
          const cashGiftEntry = this.formValuesList.find(entry => 'Cash Gift' in entry);
          if (cashGiftEntry) {
            this.cashGiftValue = cashGiftEntry['Cash Gift'];
            if(this.cashGiftValue == 0){
              this.cashGiftValue = null;
            }
            
          } else {
            this.cashGiftValue = null;
          }
          this.cartValues.BalanceDue = this.summaryTotal - this.depositRefundValue - this.cashGiftValue ;
        },
        reject: () => {
          this.form.get('Tour ID')?.disable();
        }
    })
  } else {

    this.toastr.error('Please fill out all required fields.');
  }
}
  //   if (this.form.valid) {
  //     Swal.fire({
  //       icon: 'warning',
  //       title: 'Warning!',
  //       html: 'Information here is critical to the accounting department.<br>Please verify your entries before continuing.<br>Have you verified your entries?',
  //       width: '30%',
  //       showCancelButton: true,
  //       confirmButtonColor: '#3085d6',
  //       cancelButtonColor: '#d33',
  //       cancelButtonText: 'No',
  //       confirmButtonText: 'Yes'
  //     }).then((result) => {
  //       if (result.value) {
  //         this.nav.isdisabledNav = true;
  //         this.showModal = false;
  //         this.showModalforName = true;
  //         let values = this.form.value;
  //         debugger;
  //         this.formValuesList = Object.keys(values).map(controlName => {
  //           return { [controlName]: values[controlName] };
  //         });
  //         // Find the Deposit Refund value
  //         const depositRefundEntry = this.formValuesList.find(entry => 'Deposit Refund' in entry);
  //         if (depositRefundEntry) {
  //           this.depositRefundValue = depositRefundEntry['Deposit Refund'];
  //           if(this.depositRefundValue == 0){
  //             this.depositRefundValue = null;
  //           }
           
  //         } else {
  //           this.depositRefundValue = null;
  //         }
  //         const cashGiftEntry = this.formValuesList.find(entry => 'Cash Gift' in entry);
  //         if (cashGiftEntry) {
  //           this.cashGiftValue = cashGiftEntry['Cash Gift'];
  //           if(this.cashGiftValue == 0){
  //             this.cashGiftValue = null;
  //           }
            
  //         } else {
  //           this.cashGiftValue = null;
  //         }
  //         this.cartValues.BalanceDue = this.summaryTotal - this.depositRefundValue - this.cashGiftValue ;
  //       }
  //       else{
  //         this.form.get('Tour ID')?.disable();
  //       }
  //     })

  //   } else {

  //     this.toastr.error('Please fill out all required fields.');
  //   }
  // }


  ResetgiftingInfo() {
    this.form.reset();
    this.form.get('Tour ID')?.setValue(this.TourId);
    this.form.get('Deposit Refund')?.setValue('0.00');
      this.form.get('Cash Gift')?.setValue('0.00');
    this.currentPage = 1;
  }

  GetGiftingIn() {
    debugger;
    this._giftService.GetGiftingInformation(this.workstation.districtId).subscribe((res) => {
      this.giftInfoList = res;
      this.giftInfoList.forEach(info => {
        if (info.isRequired) {
          this.form.addControl(info.giftCodeName, new FormControl('', Validators.required));
        }
        else {
          this.form.addControl(info.giftCodeName, new FormControl(''));
        }
        if (info.giftCodeName === 'Deposit Refund' || info.giftCodeName === 'Cash Gift') {
          this.form.get(info.giftCodeName).setValue('0.00');
        }

      });
      this.form.reset();
      this.form.get('Tour ID')?.setValue(this.TourId);
      this.form.get('Tour ID')?.disable();
      this.form.get('Deposit Refund')?.setValue('0.00');
      this.form.get('Cash Gift')?.setValue('0.00');
    });
  }

  formatCurrencyValue(controlName: string, value: string) {

    value = value.replace(/\D/g, '');
    if (value.length > 12) {
      value = value.substring(0, 12);
    }
    while (value.length < 3) {
      value = '0' + value;
    }
    let newValue = value.slice(0, -2) + '.' + value.slice(-2);
    if (newValue.charAt(0) === '0') {
      newValue = newValue.substring(1);
    }
    if (newValue.charAt(0) === '.') {
      newValue = '0' + newValue;
    }
    this.form.get(controlName).setValue(newValue);
  }

  onInputChange(controlName: string, event: any) {
    if (controlName == 'Deposit Refund' || controlName == 'Cash Gift') {
      this.formatCurrencyValue(controlName, event.target.value);
    }
    else {
      this.form.get(controlName).setValue(event.target.value);
    }
  }

  getErrorMessage(errorKey: string): string {
    switch (errorKey) {
      case 'required':
        return 'This field is required.';
      case 'pattern':
        return 'Invalid format.';
      default:
        return 'Validation error.';
    }
  }
  CheckPrinter()
  {
    this._shiftService.GetPrinterStatus(this.workstation.workStationId).subscribe((res) => {
      debugger;
      if(res!=3 && res!=0)
      {
      let printerMessage=  this.authenticationService.GetPrinterMessgae(res);
        Swal.fire({
          icon: 'warning',
          title: 'warning!',
          text: printerMessage,
          timer: 10000, // 10 seconds
          timerProgressBar: true,
        })
      }
    });
  }
  onSubmit() {
    debugger
    this.submitted = false;
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      this.submitted = true;
      return;
    }
    if (!this.submitted) {     

      if(this.customerName==""){
        this.CheckPrinter();
        this.SetDynamicValues();
        this.isShowProdList=false;
        this.LastTxNo = true;
        this._commonService.UpdateLastTXID(this.workstation.workStationId, this.workstation.lastTxId, this.submitted)
          .subscribe((res) => {
            this.TxNum = res + 1;
          });
        }
      this.showModalforName = false;
      this.customerName = this.user.firstName + ' ' + this.user.lastName;
      this.nav.isdisabledNav = true;
      //}
    }
  }
  get f() { return this.registerForm.controls; }
  OpenModel() {
    this.currentPage = 1;
    this.showModal = true;
  }


  SetDynamicValues() {

    this._commonService.GetSiteConfigrationValidated(this.workstation.siteId, this.workstation.districtId)
      .subscribe((res) => {
        debugger;
        localStorage.setItem('siteConfivalidated', JSON.stringify(res));
        this.siteConfi = JSON.parse(localStorage.getItem("siteConfivalidated"));
        this.isSRPShow = this.siteConfi.displaySRP;
        this.GetProductListNew();

      })
  }

  ShowProAddCart(data): void {

    this.PushAndAddProduct(data);
  }
  PushAndAddProduct(data: any) {
    debugger;
    if (data.inventoryItem == true) {
      this.PushAndAddInventoryProducts(data);
    }
    else {
      if (data.qty == null || data.qty == 0) {

        this.nav.isdisabledNav = true;
        localStorage.setItem("isdisabledNavBar", 'true');
        if (this.customerName === '') {
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: 'Customer Required!',
          })
        }

        else {
          if (this.isExhange) {

            const index = this.SelectProducts.findIndex(pro => ((pro.plucode === undefined || pro.plucode === null) ? pro.productId === data.productId : pro.plucode === data.plucode));
            const i = this.SelectedProducts.findIndex(pro => (pro.productId === data.productId));

            if (i > -1) {
              this.isShowPrCount = false;
            }

            if (index > -1) {
              this.SelectProducts[index].prodTotalQty = this.SelectProducts[index].qty;
              data.qty = 1;
              this.SelectProducts[index].prodTotalQty = this.SelectProducts[index].prodTotalQty + data.qty;
              this.isShowPrCount = false;
             
            } else {
              this.currentProdQty = data.qty
              data.qty = 1;
              data.isActive = true;
              data.fastButtonId = 0;
              data.Total = data.price * (parseInt(data.qty));
              this.SelectProducts.push(data);
              this.isShowPrCount = true;
            }
            data.isActive = true;
            if (index > -1 || this.SelectProducts.length > 0) {
              this.isShowPrCount = true;
            } else {
              this.isShowPrCount = false;
            }

            const cartQty = parseInt(this.getTotalQty());

            // data.qty = 1; // change in future
            if ((cartQty + parseInt(data.qty)) > 50) {
              Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'You can not save more than 25 product quantities.',
              })
            } else {
              data.type = 'Sale';

              if (data.productName) {
                data.fastButtonName = data.productName;
                //data.discount = 0;
              }
            }
          }
          else {
            const index = this.SelectProducts.findIndex(pro => ((pro.plucode === undefined || pro.plucode === null) ? pro.productId === data.productId : pro.plucode === data.plucode));
            const i = this.SelectedProducts.findIndex(pro => (pro.productId === data.productId));
            const machinmultiIdVoucher = this.SelectProducts.filter(pro => pro.productId === data.productId && data.consolidatedId > 0 || (pro.consolidatedId === data.consolidatedId && data.consolidatedId > 0))

            let totalcout = machinmultiIdVoucher.map(x => { return x.prodTotalQty; }).reduce((a, qty) => a + parseInt(qty), 0);
            if (i > -1) {
              // this.SelectedProducts[i].qty = this.SelectProducts[index].qty
              this.isShowPrCount = false;
            }
            if (data.totalPQty === 0 && data.inventoryItem === true) {
              Swal.fire({
                icon: 'error',
                title: 'Warning!',
                //text: 'You can not sale more than, you have onhand quantities',
                text: 'Cannot sell more than the onhand quantity for this product.',
              });
              return false;
            }

            if (index > -1) {
              let giftCartTotalQty = this.SelectProducts.map(x => x.qty).reduce((a, qty) => a + qty);
              this.SelectProducts[index].prodTotalQty = this.SelectProducts[index].qty;
              if ((this.SelectProducts[index].totalPQty <= this.SelectProducts[index].prodTotalQty) && this.SelectProducts[index].inventoryItem === true) {
                Swal.fire({
                  icon: 'error',
                  title: 'Warning!',
                  text: 'Products are not available in stocks.',
                });
                return false;
              }
              else if (this.SelectProducts[index].qty >= 25 || giftCartTotalQty >= 25) {
                Swal.fire({
                  icon: 'error',
                  title: 'Warning!',
                  text: 'Can not add more than 25 product quantities.',
                });
                return false;
              }
              if (data.productType == 1 && machinmultiIdVoucher.length > 0) {
                if (totalcout >= machinmultiIdVoucher[0].maxAddmissionCount) {

                  Swal.fire({
                    icon: 'warning',
                    title: 'Warning!',
                    text: "You can not select more than" + " " + machinmultiIdVoucher[0].maxAddmissionCount + " " + "quantity for this item.",
                  });
                  return false;
                }
              }

              data.qty = 1;
              this.SelectProducts[index].prodTotalQty = this.SelectProducts[index].prodTotalQty + data.qty;
              this.isShowPrCount = false;
            }
            else {

              if (data.productType == 1 && machinmultiIdVoucher.length > 0) {
                if (totalcout >= machinmultiIdVoucher[0].maxAddmissionCount) {

                  Swal.fire({
                    icon: 'warning',
                    title: 'Warning!',
                    text: "You can not select more than" + " " + machinmultiIdVoucher[0].maxAddmissionCount + " " + "quantity for this item.",
                  });
                  return false;
                }
              }
              this.currentProdQty = data.qty
              data.qty = 1;
              data.isActive = true;
              data.fastButtonId = 0;
              data.Total = data.price * (parseInt(data.qty));
              this.SelectProducts.push(data);
              this.isShowPrCount = true;
            }
            data.isActive = true;
            if (index > -1 || this.SelectProducts.length > 0) {
              this.isShowPrCount = true;
            }
            else {
              this.isShowPrCount = false;
            }
            const cartQty = parseInt(this.getTotalQty());
            // data.qty = 1; // change in future
            if ((cartQty + parseInt(data.qty)) > 50) {
              Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'You can not save more than 50 product quantities.',
              })
            }
            else {
              data.type = 'Sale';
              if (data.productName) {
                data.fastButtonName = data.productName;
              }
            }
          }
        }
      }
      else 
      {
        this.DoubleproQty(data, 'minus')
      }
    }
  }


  PushAndAddInventoryProducts(data: any) {
    debugger;
    if (data.qty == null || data.qty == 0) {
      this.nav.isdisabledNav = true;
      localStorage.setItem("isdisabledNavBar", 'true');
      if (this.customerName === '') {
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'Customer Required!',
        })
      } else {
        if (this.isExhange) {

             const index = this.SelectProducts.findIndex(pro => ((pro.plucode === undefined || pro.plucode === null) ? pro.productId === data.productId : pro.plucode === data.plucode));
          const i = this.SelectedProducts.findIndex(pro => (pro.productId === data.productId));
          const matchedProducts = this.SelectedProducts.filter(pro => pro.productId === data.productId && pro.inventoryItem === true);
          const totalQtyOfMatchedProducts = matchedProducts.reduce((sum, prod) => sum + prod.qty, 0);
  
          if (i > -1) {
            this.isShowPrCount = false;
          }
          if ( data.inventoryItem === true&& totalQtyOfMatchedProducts >= data.totalPQty) {
            Swal.fire({
              icon: 'error',
              title: 'Warning!',
              //text: 'There is no inventory available for selected product.',
              text: 'Cannot sell more than the onhand quantity for this product.'
            });
            return false;
          }
          if (index > -1) {
            this.SelectProducts[index].prodTotalQty = this.SelectProducts[index].qty;
            data.qty = 1;
            this.SelectProducts[index].prodTotalQty = this.SelectProducts[index].prodTotalQty + data.qty;
            this.isShowPrCount = false;
            
          } else {
            this.currentProdQty = data.qty
            data.qty = 1;
            data.isActive = true;
            data.fastButtonId = 0;
            // data.discount=data.cost-data.price;
            data.Total = data.price * (parseInt(data.qty));
            this.SelectProducts.push(data);
            this.isShowPrCount = true;
          }
          data.isActive = true;
          if (index > -1 || this.SelectProducts.length > 0) {
            this.isShowPrCount = true;
          } else {
            this.isShowPrCount = false;
          }

          const cartQty = parseInt(this.getTotalQty());

          // data.qty = 1; // change in future
          if ((cartQty + parseInt(data.qty)) > 50) {
            Swal.fire({
              icon: 'error',
              title: 'Error!',
              text: 'You can not save more than 25 product quantities.',
            })
          } else {
            data.type = 'Sale';

            if (data.productName) {
              data.fastButtonName = data.productName;
            }
          }
        } else {
          debugger;
          const index = this.SelectProducts.findIndex(pro => ((pro.plucode === undefined || pro.plucode === null) ? pro.productId === data.productId : pro.plucode === data.plucode));
          const i = this.SelectedProducts.findIndex(pro => (pro.productId === data.productId));
          const matchedProducts = this.SelectedProducts.filter(pro => pro.productId === data.productId && pro.inventoryItem === true);
          const totalQtyOfMatchedProducts = matchedProducts.reduce((sum, prod) => sum + prod.qty, 0);
  
          if (i > -1) {
            this.isShowPrCount = false;
          }
          if ( data.inventoryItem === true&& totalQtyOfMatchedProducts >= data.totalPQty) {
            Swal.fire({
              icon: 'error',
              title: 'Warning!',
              //text: 'There is no inventory available for selected product.',
              text: 'Cannot sell more than the onhand quantity for this product.'
            });
            return false;
          }
          if (index > -1) {
            let giftCartTotalQty = this.SelectProducts.map(x => x.qty).reduce((a, qty) => a + qty);
            this.SelectProducts[index].prodTotalQty = this.SelectProducts[index].qty;
            if ((this.SelectProducts[index].totalPQty <= this.SelectProducts[index].prodTotalQty) && this.SelectProducts[index].inventoryItem === true) {
              Swal.fire({
                icon: 'error',
                title: 'Warning!',
                text: 'products are not available in stocks.',
              });
              return false;
            }
            else if (this.SelectProducts[index].qty >= 25 || giftCartTotalQty >= 25) {
              Swal.fire({
                icon: 'error',
                title: 'Warning!',
                text: 'Can not add more than 25 product quantities.',
              });
              return false;
            }
            data.qty = 1;
            this.SelectProducts[index].prodTotalQty = this.SelectProducts[index].prodTotalQty + data.qty;
            this.isShowPrCount = false;
          } else {
            this.currentProdQty = data.qty
            data.qty = 1;
            data.isActive = true;
            data.fastButtonId = 0;
            data.Total = data.price * (parseInt(data.qty));
            this.SelectProducts.push(data);
            this.isShowPrCount = true;
          }
          data.isActive = true;
          if (index > -1 || this.SelectProducts.length > 0) {
            this.isShowPrCount = true;
          } else {
            this.isShowPrCount = false;
          }

          const cartQty = parseInt(this.getTotalQty());
          // data.qty = 1; // change in future
          if ((cartQty + parseInt(data.qty)) > 50) {
            Swal.fire({
              icon: 'error',
              title: 'Error!',
              text: 'You can not save more than 25 product quantities.',
            })
          } else {
            data.type = 'Sale';

            if (data.productName) {
              data.fastButtonName = data.productName;
              
            }
          }
        }
      }
    }
    else {
      this.DoubleproQty(data, 'minus')

    }
  }

  getTotalQty() {
    return this.SelectedProducts.reduce((a, { qty }) => a + parseInt(qty), 0);
  }

  DoubleproQty(item, oprator: string) {
    debugger;
    if (item.productType === 1) {
      this.DoubleClickvoucharValidation(item, oprator);
    }
    else {
      const i = this.SelectProducts.findIndex(pro => ((pro.plucode === undefined || pro.plucode === null) ? pro.productId === item.productId : pro.plucode === item.plucode));

      if (oprator === 'minus') {
        if (this.SelectProducts[i].qty > 0) {
         
          item.qty = item.qty = 0
          this.SelectProducts[i].prodTotalQty = this.SelectProducts[i].prodTotalQty - 1;
        }
        if (item.qty === 0) {
          this.SelectProducts[i].prodTotalQty = item.qty = item.qty = null;
          var index = this.SelectProducts.indexOf(item);
          this.SelectProducts.splice(index, 1);
          item.isActive = false;
        }
      }
      else {
        
        let cartTotalQuntities = this.SelectProducts.map(x => {
          return x.prodTotalQty || x.qty;
        }).reduce((a, qty) => a + parseInt(qty), 0);
        if ((this.SelectProducts[i].totalPQty <= this.SelectProducts[i].qty && this.SelectProducts[i].inventoryItem === true) || (this.SelectProducts[i].totalPQty <= this.SelectProducts[i].prodTotalQty && this.SelectProducts[i].inventoryItem === true)) {
          Swal.fire({
            icon: 'error',
            title: 'Warning!',
            //text: 'You can not sale more than, you have onhand quantities',
            text: 'Cannot sell more than the onhand quantity for this product.',
          });
        }
        else if (this.SelectProducts[i].qty >= 25 || item.qty >= 25 || this.SelectProducts[i].prodTotalQty >= 25 || cartTotalQuntities >= 25 || cartTotalQuntities >= 25) {
          Swal.fire({
            icon: 'error',
            title: 'Warning!',
            text: 'Can not add more than 25 product quantities.',
          });
        }
        else {
          item.qty = item.qty + 1
          this.SelectProducts[i].prodTotalQty = this.SelectProducts[i].prodTotalQty + 1;
        }

        this.SelectProducts[i].isActive = true;
        this.isShowPrCount = true;
      }
    }

  }
  DoubleClickvoucharValidation(item: any, oprator: string) {
    debugger;
    const i = this.SelectProducts.findIndex(pro => ((pro.plucode === undefined || pro.plucode === null) ? pro.productId === item.productId : pro.plucode === item.plucode));
    const machinmultiIdVoucher = this.SelectProducts.filter(pro => pro.productId === item.productId || pro.consolidatedId === item.consolidatedId)
    if (oprator === 'minus') {
      if (this.SelectProducts[i].qty > 0) {
        item.qty = item.qty = 0
        this.SelectProducts[i].prodTotalQty = this.SelectProducts[i].prodTotalQty - 1;
        var index = this.SelectProducts.indexOf(item);
        this.SelectProducts.splice(index, 1);
        item.isActive = false;
      }
      if (item.qty === 0) {
        // this.SelectProducts[i].prodTotalQty = item.qty = item.qty = null;
        // var index = this.SelectProducts.indexOf(item);
        // this.SelectProducts.splice(index, 1);
        // item.isActive = false;
      }
    }
    else {
      let totalcout = machinmultiIdVoucher.map(x => {
        return x.prodTotalQty || x.qty;
      }).reduce((a, qty) => a + parseInt(qty), 0);
      if (totalcout < machinmultiIdVoucher[0].maxAddmissionCount) {
        item.qty = item.qty + 1
        this.SelectProducts[i].prodTotalQty = this.SelectProducts[i].prodTotalQty + 1;
        this.SelectProducts[i].isActive = true;
        this.isShowPrCount = true;
      } else if (machinmultiIdVoucher[0].maxAddmissionCount === 0) {
        item.qty = item.qty + 1
        this.SelectProducts[i].prodTotalQty = this.SelectProducts[i].prodTotalQty + 1;
        this.SelectProducts[i].isActive = true;
        this.isShowPrCount = true;
      } else {
        let maxcount = machinmultiIdVoucher[0].maxAddmissionCount;
        Swal.fire({
          icon: 'warning',
          title: 'Warning!',
          text: "You can not select more than a quantity of" + " " + maxcount + " " + "for this item",
        });
      }

    }
  }

  AddtoCart() {
    debugger;
    if(this.SelectProducts.length > 0)
    {
      this.SoldForAmount = '0.00';
      this.showKeypadModal = true;
    }
    else{
      Swal.fire({
        icon: 'warning',
        title: 'Warning!',
        text: 'Please select products before add to cart.',
      })
    }
   
  }
  CancelTran() {
    debugger;
    if (this.SelectedProducts.length > 0) {
      // Swal.fire({
      //   icon: 'warning',
      //   title: 'Warning!',
      //   text: 'Are you sure that you would like to cancel this transaction?',
      //   showCancelButton: true,
      //   confirmButtonColor: '#3085d6',
      //   cancelButtonColor: '#d33',
      //   cancelButtonText: 'NO',
      //   confirmButtonText: 'YES'
      // }).then((result) => {
      //   if (result.value) {
      //     location.reload();
      //   }
      // })
      const messages = `<p class='worstation-img'><img src="./assets/Libs/images/confirmation.png" /></p><h3 class="pop-head">Warning!</h3><p class="assignstyle">Are you sure that you would like to cancel this transaction?</p>`
      this.confirmationService.confirm({
        message:  messages,
        header: 'Confirmation',
        rejectVisible: true,
        acceptLabel: 'Yes',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          location.reload();
        },
        reject: () => {
        }
    })
    }
    else {
      location.reload();
    }
  }




  AddNormalTrnasactionProduct() {
    debugger;
    this.summaryTotal = 0;
    this.exchangeProductAmount = 0;
    let summarySRPTotal = 0, summaryDiscount = 0;
    let isLastProduct: boolean = false;
    let totalQty: number =0;
    let remainingDisount=0;

    if (this.SelectProducts != null) {
      var totalProducts = this.SelectProducts.length;
      this.SelectProducts.forEach(currentRow => {
        totalQty += currentRow.qty;
      });
    }
    var baseAmount = Math.floor((parseFloat(this.SoldForAmount) / totalQty) * 100) / 100;
    const remainder = Number((parseFloat(this.SoldForAmount) - baseAmount * totalQty).toFixed(2));
    var i = 1;
    this.SelectProducts.forEach(currentRow => {
      
      currentRow.fastButtonId = 0;
       for (let j = 1; j <= currentRow.qty; j++) 
        {
          if(i === totalProducts && j === currentRow.qty)
          {
            baseAmount += remainder
          }
          const index = this.SelectedProducts.findIndex(pro => {
            if (pro.plucode === undefined || pro.plucode === null) {
              return pro.productId === currentRow.productId && pro.price === baseAmount;
            } else {
              return pro.plucode === currentRow.plucode && pro.price === baseAmount;
            }
          });
  
          if (index >= 0) {
            this.SelectedProducts[index].qty += 1;
            this.SelectedProducts[index].Total = this.SelectedProducts[index].price * this.SelectedProducts[index].qty;
          }
          else {
            const newProduct = { ...currentRow };
            newProduct.qty = 1;
            newProduct.Total = baseAmount;
            newProduct.price = baseAmount;
            newProduct.discount = newProduct.srp - parseFloat(newProduct.price);
            if (newProduct.discount < 0) {
              newProduct.discount = 0;
            }
            this.SelectedProducts.push(newProduct);
          }
        }
      i++;
    });
    this.SelectedProducts.forEach(currentRow => {
      currentRow.fastButtonId = 0;
      if(currentRow.qty >0)
      {
        this.summaryTotal += currentRow.Total;
        summarySRPTotal += currentRow.srp * currentRow.qty;
        summaryDiscount += currentRow.discount * currentRow.qty;
        var hiddenDiscount= currentRow.srp - parseFloat(currentRow.price);
        if(hiddenDiscount<0)
        {
          remainingDisount +=hiddenDiscount;
        }
      }
      else
      {
          this.exchangeProductAmount += Math.abs(currentRow.Total);
      }
      
    });
    
    if (this.isExhange) {
      this.cartValues.TxTotal = this.summaryTotal;
      this.cartValues.BalanceDue = this.cartValues.TxTotal - this.exchangeProductAmount;
      
    } else {
      this.cartValues.TxTotal = this.summaryTotal;
      this.cartValues.BalanceDue = this.summaryTotal - this.depositRefundValue - this.cashGiftValue;
     
    }

    this.cartValues.SRPTotal = summarySRPTotal;
    if(this.cartValues.SRPTotal<this.cartValues.TxTotal)
      {
        this.cartValues.Discounts = 0;
      }
      else{
        this.cartValues.Discounts = summaryDiscount-Math.abs(remainingDisount);
      }
    this.isShowProdList = false;
    this.isShowPSP = true;
    this.isTransCancil = false;
    this.isShowPrCount = false;
    this.GetProductListNew();
    this.SelectProducts = [];

  }


  SoldForKeypadCross(): void {
    this.showKeypadModal = false;
    this.SoldForAmount = '0.00';
  }
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    const key = event.key;
    if (key === 'Enter') {
     // this.SoldForContinue();
    } else if (key === 'Backspace') {
      this.handleKeyPress('backspace');
    } else if (key === 'Escape') {
      this.SoldForKeypadCross();
    } else if (!isNaN(Number(key))) {
      this.handleKeyPress(key);
    } else if (key === 'c' || key === 'C') {
      this.handleKeyPress('C');
    }
  }
  handleKeyPress(key: string) {
    if (key === 'C') {
      this.SoldForAmount = '0.00';
    } else if (key === 'backspace') {
      // Handle backspace
      let amount = this.SoldForAmount.replace('.', '').slice(0, -1);
      this.SoldForAmount = this.formatAmount(amount);
    } else {
      let amount = this.SoldForAmount.replace('.', '') + key;
      this.SoldForAmount = this.formatAmount(amount);
    }
  }

  formatAmount(amount: string): string {
    while (amount.length < 3) {
      amount = '0' + amount;
    }
    let beforePoint = amount.slice(0, -2);
    let afterPoint = amount.slice(-2);
    return `${parseInt(beforePoint, 10)}.${afterPoint}`;
  }

  SoldForContinue() {
    debugger;
    this.showKeypadModal = false;
      this.AddNormalTrnasactionProduct();
  }

  proQty(item, oprator: string) {
    debugger;
    if (item.productType === 1) {
      this.voucharValidation(item, oprator);
    }
    else {
      const i = this.SelectProducts.findIndex(pro => ((pro.plucode === undefined || pro.plucode === null) ? pro.productId === item.productId : pro.plucode === item.plucode));

      if (oprator === 'minus') {
        if (this.SelectProducts[i].qty > 0) {
          item.qty = item.qty - 1
          this.SelectProducts[i].prodTotalQty = this.SelectProducts[i].prodTotalQty - 1;
        }
        if (item.qty === 0) {
          this.SelectProducts[i].prodTotalQty = item.qty = item.qty = null;
          var index = this.SelectProducts.indexOf(item);
          this.SelectProducts.splice(index, 1);
          item.isActive = false;
          this.cdr.detectChanges();
        }
      }
      else {
         const matchedProducts = this.SelectedProducts.filter(pro => pro.productId === item.productId && pro.inventoryItem === true);
         const QtyOfMatchedProducts = matchedProducts.reduce((sum, prod) => sum + prod.qty, 0);
         const totalQtyofproduct = item.qty +QtyOfMatchedProducts;
        debugger;
        // let cartTotalQuntities = this.SelectProducts.map(x => {
        //   return x.prodTotalQty || x.qty;
        // }).reduce((a, qty) => a + parseInt(qty), 0);
       
        //if ((this.SelectProducts[i].totalPQty <= this.SelectProducts[i].qty && this.SelectProducts[i].inventoryItem === true) || (this.SelectProducts[i].totalPQty <= this.SelectProducts[i].prodTotalQty && this.SelectProducts[i].inventoryItem === true)) {
          if ( item.inventoryItem === true && totalQtyofproduct >= item.totalPQty) 
          { 
          Swal.fire({
            icon: 'error',
            title: 'Warning!',
            //text: 'You can not sale more than, you have onhand quantities',
            text: 'Cannot sell more than the onhand quantity for this product.',
          });
        }
         else if (item.qty >= 25) {
          Swal.fire({
            icon: 'error',
            title: 'Warning!',
            text: 'Can not add more than 25 product quantities.',
          });
        }
        else {
          item.qty = item.qty + 1
          this.SelectProducts[i].prodTotalQty = this.SelectProducts[i].prodTotalQty + 1;
        }

        this.SelectProducts[i].isActive = true;
        this.isShowPrCount = true;
        this.cdr.detectChanges();
      }
    }

  }
  voucharValidation(item: any, oprator: string) {
    debugger;
    const i = this.SelectProducts.findIndex(pro => ((pro.plucode === undefined || pro.plucode === null) ? pro.productId === item.productId : pro.plucode === item.plucode));
    const machinmultiIdVoucher = this.SelectProducts.filter(pro => pro.productId === item.productId || pro.consolidatedId === item.consolidatedId)
    if (oprator === 'minus') {
      if (this.SelectProducts[i].qty > 0) {
        item.qty = item.qty - 1
        this.SelectProducts[i].prodTotalQty = this.SelectProducts[i].prodTotalQty - 1;
      }
      if (item.qty === 0) {
        this.SelectProducts[i].prodTotalQty = item.qty = item.qty = null;
        var index = this.SelectProducts.indexOf(item);
        this.SelectProducts.splice(index, 1);
        item.isActive = false;
        this.cdr.detectChanges();
      }
      return true;
    }
    else {
      let totalcout = machinmultiIdVoucher.map(x => { return x.qty; }).reduce((a, qty) => a + parseInt(qty), 0);
      if (machinmultiIdVoucher.length > 0) {
        if (totalcout < machinmultiIdVoucher[0].maxAddmissionCount) {
          item.qty = item.qty + 1
          this.SelectProducts[i].prodTotalQty = this.SelectProducts[i].prodTotalQty + 1;
          this.SelectProducts[i].isActive = true;
          this.isShowPrCount = true;
          return true;
        } else if (machinmultiIdVoucher[0].maxAddmissionCount === 0) {
          item.qty = item.qty + 1
          this.SelectProducts[i].prodTotalQty = this.SelectProducts[i].prodTotalQty + 1;
          this.SelectProducts[i].isActive = true;
          this.isShowPrCount = true;
          return true;
        }
        else {
          let maxcount = machinmultiIdVoucher[0].maxAddmissionCount;
          Swal.fire({
            icon: 'warning',
            title: 'Warning!',
            text: "You can not select more than" + " " + maxcount + " " + "quantity for this item.",
          });
          return false;
        }
      }

    }
  }


  GetProductListNew() {
    debugger;
    this._commonService.GetProductsListNew(this.workstation.workStationId, this.siteConfi.maxDiscountFactor, this.siteConfi.displaySRP).subscribe((res) => {
      this.NewProducts = res;
     

      this.NewProducts.forEach(row => {
        row.products.forEach(currentRow => {
          const index = this.SelectProducts.findIndex(pro => ((pro.plucode === undefined || pro.plucode === null) ? pro.productId === currentRow.productId : pro.plucode === currentRow.pLUCode));
          if (index > -1) {
           
            currentRow.totalPQty = this.SelectProducts[index].prodTotalQty = this.SelectProducts[index].qty;
          }
        })

      });
 
    })
  }

  ChangeValue(value, item) {
    debugger;
      this.ProductChangeValue(value, item);
  }

  ProductChangeValue(value, item) {
    debugger;
    let remainingDisount=0;
    const i = this.SelectedProducts.findIndex(pro => ((pro.plucode === undefined || pro.plucode === null) ? pro.productId === item.productId : pro.plucode === item.plucode) && pro.type === item.type && pro.price === item.price );
     if (parseInt(this.getTotalQty()) > 25 && value !== "0") {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'You can not save more than 25 product quantities.',
      });
    }
    else {
      if (value === '0') {
        this.SelectedProducts[i].qty = this.SelectedProducts[i].totalPQty;
      }

      if (value === '0') {
        if (i !== -1) {
          this.SelectedProducts.splice(i, 1);
        }
       
      }
      let  summarySRPTotal = 0, summaryDiscount = 0;
      this.cartValues.TxTotal =0;
      this.cartValues.BalanceDue = 0;
      this.summaryTotal = 0;
      this.exchangeProductAmount = 0;
      this.SelectedProducts.forEach(currentRow => {
        currentRow.fastButtonId = 0;
        if(currentRow.qty >0)
        {
          this.summaryTotal += currentRow.Total;
          summarySRPTotal += currentRow.srp * currentRow.qty;
          summaryDiscount += currentRow.discount * currentRow.qty;
          var hiddenDiscount= currentRow.srp - parseFloat(currentRow.price);
        if(hiddenDiscount<0)
        {
          remainingDisount +=hiddenDiscount;
        }
        }
        else
        {
            this.exchangeProductAmount += Math.abs(currentRow.Total);
        }
        
      });
      
      if (this.isExhange) {
        this.cartValues.TxTotal = this.summaryTotal;
        this.cartValues.BalanceDue = this.cartValues.TxTotal - this.exchangeProductAmount;
        
      } else {
        this.cartValues.TxTotal = this.summaryTotal;
        this.cartValues.BalanceDue = this.summaryTotal - this.depositRefundValue - this.cashGiftValue;
        this.cartValues.SRPTotal = summarySRPTotal;
        if(this.cartValues.SRPTotal<this.cartValues.TxTotal)
          {
            this.cartValues.Discounts = 0;
          }
          else{
            this.cartValues.Discounts = summaryDiscount-Math.abs(remainingDisount);
          }
      }
    }
  }


  pay() {
    debugger;
    this.NoBalanceDue = false;
    this.isTendorDisable = false;
    
    const DepositRefund = this.form.controls['Deposit Refund']?.value;
    const CashGift = this.form.controls['Cash Gift']?.value;
  
    const hasProductsOrBalance = this.SelectedProducts.length > 0 || DepositRefund > 0 || CashGift > 0;
    const isNegativeBalance = this.isExhange && this.cartValues.BalanceDue < 0;
    const isBalanceDueZeroOrLess = this.cartValues.BalanceDue <= 0;
  
    if (!hasProductsOrBalance) {
      if (this.SelectedProducts.length === 0) {
        Swal.fire({
          icon: 'warning',
          title: 'Warning!',
          text: 'Please add products to cart.',
        });
      }
      return;
    }
  
    if (isNegativeBalance) {
      this.toastr.error("Cannot finalize transaction with a negative amount.");
      return;
    }
  
    if (isBalanceDueZeroOrLess) {
      this.NoBalanceDue = true;
      this.isFinish = false;
      this.isTendorDisable = true;
    } else {
      this.isFinish = true;
    }
  
    this.GetTenders();
    this.isShowPSP = false;
    this.isSRPShow = false;
    this.isDiscountShow = false;
    this.isMoneyShow = true;
    this.isTxPrinting = false;
    this.isTransCancil = true;
    this.isProcessCancel = false;
  
    setTimeout(() => {
      $("#AmountItem").focus();
    }, 500);
  }

  GetTenders() {
    debugger;
    
    this._posSerive.GetTendersDetail(this.workstation.siteId).subscribe((res) => {
      if (res != 0) {
        this.TenderList = res;
        if(this.TenderList.length>4){
          $('.cal-area').addClass('scroll-pad');
        }
        let flagtender = false;
        this.types = [];
        for (let i = 0; i < this.TenderList.length; i++) {
          let tenderTypeValue = { tenderType: this.TenderList[i].tenderType, tenderId: this.TenderList[i].tenderId, tenderName: this.TenderList[i].tenderName }
          let type = { label: this.TenderList[i].tenderName, value: tenderTypeValue }
          this.types.push(type);
          if (tenderTypeValue.tenderType == 'Cash') {
            this.selectedCashTenderId = tenderTypeValue.tenderId;
            this.cashTender = tenderTypeValue.tenderName;
            $('#btn100').css('display', '');
            $('#btn100Emt').css('display', 'none');
            $('#btn50').css('display', '');
            $('#btn50Emt').css('display', 'none');
            $('#btn20').css('display', '');
            $('#btn20Emt').css('display', 'none');
            flagtender = true;
          }
          else {
            if (flagtender == false) {
              $('#btn100').css('display', 'none');
              $('#btn100Emt').css('display', '');
              $('#btn50').css('display', 'none');
              $('#btn50Emt').css('display', '');
              $('#btn20').css('display', 'none');
              $('#btn20Emt').css('display', '');
            }
            if (tenderTypeValue.tenderType == 'Room Charge') {
              this.roomChargeTender = tenderTypeValue.tenderName;
            }
            if (tenderTypeValue.tenderType == 'Credit Card') {
              this.creditcardTender = tenderTypeValue.tenderName;
            }
          }
        }
      }

    });
  }

  isDollarExist(event: any) {
    debugger;
    let payamountkey = event.key;
    let Amountvalue = Number(payamountkey);
    if (payamountkey == "Backspace") {
      this.PopulateAmount('<');
    }
    else if (payamountkey == "ArrowLeft" || payamountkey == "ArrowRight" || payamountkey == "Enter") {

    } else if (payamountkey == ".") {
    }
    else if (Amountvalue >= 0) {
      this.PopulateAmount(Amountvalue);
    }
    else {
    }
   

  }
  PopulateAmount(value, flag?) {
    debugger;
    $('.tenderType-scroll').removeClass('addPadding'); 
    let calculateAmt = this.totalCardCalc;
    if (value === 'x') {

      let exchangeProducts = this.SelectedProducts.filter(function (element, index) {
        return (element.type === 'Exchange');
      })
      if (exchangeProducts.length > 0) {
        this.roomNumber = '';
        this.isTendorDisable = false;
        this.isFinish = true;
        this.amountTendered = 0;
        this.change = 0;
        this.changeDue = 0
        this.cartValues.BalanceDue = this.cartValues.TxTotal + this.creditAmont;
        this.CalculateTotalCashAmount = 0;
        this.CalculateTotalRoomAmount = 0;
        this.CalculateTotalMoneyCertAmount = 0;
        this.CalculateTotalMoneyCertAmounts.length = 0;
      }
      else {
        this.roomNumber = '';
        this.isTendorDisable = false;
        this.amountTendered = 0;
        this.change = 0;
        this.changeDue = 0
        this.cartValues.BalanceDue = this.cartValues.TxTotal - this.depositRefundValue -this.cashGiftValue;
        if (this.cartValues.BalanceDue > 0) {
          this.isFinish = true;
        }
        this.CalculateTotalCashAmount = 0;
        this.CalculateTotalRoomAmount = 0;
        this.CalculateTotalMoneyCertAmount = 0;
        this.CalculateTotalMoneyCertAmounts.length = 0;
        this.totalCardCalc = 0;
        this.isShowCredit = false;
      }
    }
    
    else if (value === 'C') {
      this.amountTendered = 0;
      this.change = 0;
    }

    else if (value == 'B') 
	{
      this.isTendorDisable = false;
      this.roomNumber = '';
      this.isFinish = true;
      this.amountTendered = 0;
      this.change = 0;
      this.changeDue = 0
      this.totalCardCalc = 0;
      this.isShowCredit = false;
      if(this.isExhange){
        this.isShowCredit = true;
      }
      let valu = this.cartValues.TxTotal + this.creditAmont;
      let refundProducts = this.SelectedProducts.filter(function (element, index) {
        return (element.type == 'Exchange');
      });

      if (refundProducts.length > 0) {
        this.cartValues.BalanceDue = valu;
      }
      else {
        this.cartValues.BalanceDue = this.cartValues.TxTotal - this.depositRefundValue - this.cashGiftValue;
      }
      this.CalculateTotalCashAmount = 0;
      this.CalculateTotalRoomAmount = 0;
      this.CalculateTotalMoneyCertAmount = 0;
      this.CalculateTotalMoneyCertAmounts.length = 0;
      this.isMoneyShow = false;
      this.isShowPSP = true;
      this.isTransCancil = false;
      this.isTendorDisable = false;
      this.isSRPShow = true;
      this.isDiscountShow = true;
    }
    else if ((value === 100||value === 50||value === 20) && this.tenderFlg == false) {
      
      this.cartValues.BalanceDue = parseFloat(this.cartValues.BalanceDue).toFixed(2);
      this.selectedType = 'Cash';
      this.selectedTenderId = this.TenderId;
      const tenderName = 'Cash';
        this.amountTendered = parseFloat(value);
        let balanceDue = this.cartValues.BalanceDue - parseFloat(this.amountTendered);
        
      this.selectedCashTenderId = this.selectedTenderId;
      let calculateAmt = this.totalCardCalc;
      this.CalculateTotalCashAmount = parseFloat(this.CalculateTotalCashAmount) + parseFloat(value);

      this.changeDue = this.cartValues.TxTotal - (+this.CalculateTotalCashAmount + +this.CalculateTotalRoomAmount + +this.CalculateTotalMoneyCertAmount + calculateAmt)- this.depositRefundValue - this.cashGiftValue - Math.abs(this.creditAmont);
      if(this.changeDue > 0){
        this.changeDue = 0;
      }
      else {
      this.changeDue = Math.abs(this.changeDue);
      }
      this.amountTendered = 0;
      this.cartValues.BalanceDue = balanceDue;
      if (this.cartValues.BalanceDue <= 0) {
        this.isFinish = false;
        this.isTendorDisable = true;
        this.cartValues.BalanceDue = 0;
      } else {
        this.isFinish = true;
        this.isTendorDisable = false;
      }
      this.isShowPSP = false;
      this.isSRPShow = false;
      this.isDiscountShow = false;
      this.isMoneyShow = true;
      this.isTxPrinting = false;
      this.isTransCancil = true;
      this.isProcessCancel = false;
    }
   
    else if (value > 9 && !flag) {
      this.amountTendered = parseFloat(this.amountTendered) + parseFloat(value);
      this.change = parseFloat(this.cartValues.BalanceDue) < parseFloat(this.amountTendered) ? parseFloat(this.cartValues.BalanceDue) - parseFloat(this.amountTendered) : 0.00;
    }
    else if (value === '<') {
      const intNum = this.amountTendered.toString().split('.');
      let amount = 0
      if (intNum.length === 1) {
        amount = intNum[0];
        amount = Number(amount.toString().slice(0, amount.toString().length - 1));
      }
      if (intNum.length === 2) {
        if (intNum[1] === "") {
          amount = Number(amount.toString().slice(0, amount.toString().length - 2));
        }
        else {
          let position = intNum[0].length;
          const lent = position - 1;
          intNum[1] = intNum[1].substring(0, intNum[1].length - 1);
          if (intNum[1].length == 1) {
            const next: string = [intNum[0].slice(0, lent), '.', intNum[0].slice(lent)].join('');
            this.amountTendered = next + intNum[1];
          }
        }
      }
      

    }
    else {
      
      if (!flag) {
        if (this.amountTendered == 0) {
          this.amountTendered = value / 100;
          this.amountTendered = this.amountTendered.toString();
          this.change = parseFloat(this.cartValues.BalanceDue) < parseFloat(this.amountTendered) ? parseFloat(this.cartValues.BalanceDue) - parseFloat(this.amountTendered) : 0.00;
        }
        else {
          if (value != 0) {
            this.amountTendered = this.amountTendered + value.toString();
            this.amountTendered = this.amountTendered.replace(/[^\w\s]/gi, "");
            this.amountTendered = this.amountTendered / 100;
            this.amountTendered = parseFloat(this.amountTendered);
            this.change = parseFloat(this.cartValues.BalanceDue) < parseFloat(this.amountTendered) ? parseFloat(this.cartValues.BalanceDue) - parseFloat(this.amountTendered) : 0.00;
          }
          else {
            let mainpart = this.amountTendered.toString();
            let amt = mainpart.split('.');
            mainpart = mainpart + value.toString();
            this.amountTendered = mainpart.replace(/[^\w\s]/gi, "");
            this.amountTendered = this.amountTendered / 100;
            let checkDecimal = this.amountTendered.toString().split('.');
            if (checkDecimal.length == 1) {
              this.amountTendered = this.amountTendered.toString() + ".00";
            }
            else {
              this.amountTendered = this.amountTendered.toString() + "0";
            }
          }
        }
      }
    }
  }

  ExchangeProducts () {
    if (this.cartValues.BalanceDue == 0) {
      debugger;
      this.changeDue = this.changeDue || 0
        this.ExchangeProductTx();
    }
    else {
      this.toastr.error("Cannot finalize transaction with a negative amount.")
    }
  }

  FinishTx() {
    debugger;
    this.ShowModalPopup = false;
   // this.Trantype = JSON.parse(localStorage.getItem("Transtype"));
    if (this.isExhange) {
      this.ExchangeProducts()
    }
    else {
      this.NormalTransaction();
    }
  }
  NormalTransaction() {
    debugger;
   
    this.currentUser = this.authenticationService.currentUserValue;
    this.changeDue = this.changeDue || 0
    let transactionInfo = this.SelectedProducts.filter(function (element, index) {
      return (element.type !== 'Exchange');
    });
    var latestUser= localStorage.getItem("latestUser");
   if(latestUser!=null && latestUser!='' && latestUser!="null")
   {
      transactionInfo = {
        WorkstationID: this.workstation.workStationId,
        WSIDTxID: this.TxNum,
        UserID: latestUser,
        GuestName: this.customerName,
        CustId: this.CustId,
        Amount: parseFloat(this.CalculateTotalCashAmount) - this.changeDue,
        TotalPrice: this.cartValues.TxTotal,
        ShiftNum: parseInt(this.workstation.lastShiftNum) + 1,
        TendorType: this.selectedType,
        TotalCashAmount: this.CalculateTotalCashAmount - this.changeDue,
        TotalCCChargeAmount: this.totalCardCalc,
        TotalRoomChargeAmount: this.CalculateTotalRoomAmount,
        RoomNum: this.roomNumber,
        TenderId: this.selectedTenderId,
        CashTenderId: this.selectedCashTenderId,
        CCTenderId: this.selectedCCTenderId,
        RoomTenderId: this.selectedRoomTenderId,
        prodcutsLists: this.GetSelectedProducts(),
        moneyCertTenders: this.CalculateTotalMoneyCertAmounts,
        TransType: this.Trantype,
        giftingInfo: this.getGiftInfoByForm()
      }
    }
    else{
    transactionInfo = {
      WorkstationID: this.workstation.workStationId,
      WSIDTxID: this.TxNum,
      UserID: this.currentUser.userId,
      GuestName: this.customerName,
      CustId: this.CustId,
      Amount: parseFloat(this.CalculateTotalCashAmount) - this.changeDue,
      TotalPrice: this.cartValues.TxTotal,
      ShiftNum: parseInt(this.workstation.lastShiftNum) + 1,
      TendorType: this.selectedType,
      TotalCashAmount: this.CalculateTotalCashAmount - this.changeDue,
      TotalCCChargeAmount: this.totalCardCalc,
      TotalRoomChargeAmount: this.CalculateTotalRoomAmount,
      RoomNum: this.roomNumber,
      TenderId: this.selectedTenderId,
      CashTenderId: this.selectedCashTenderId,
      CCTenderId: this.selectedCCTenderId,
      RoomTenderId: this.selectedRoomTenderId,
      prodcutsLists: this.GetSelectedProducts(),
      moneyCertTenders: this.CalculateTotalMoneyCertAmounts,
      TransType: this.Trantype,
      giftingInfo: this.getGiftInfoByForm()
    }
  }
    if (transactionInfo != null) {
      this.isMoneyShow = true;
      this._giftService.SaveTransaction(transactionInfo).subscribe((res) => {
        debugger;
        
        if (res != null) {
          this.submitted = true;
          this._commonService.UpdateLastTXID(this.workstation.workStationId, this.workstation.lastTxId, this.submitted)
            .subscribe((responce) => {
              this.TxNum = responce;
            });
        }
          if (res != null) {
          
            let midResponse: any;
            this.isTendorDisable = false;
            if (this.CalculateTotalCashAmount > 0 && this.SplitTransaction.length > 0) {
              this.selectedType = 'Split'
            }
            this.selectedType = 'FUllAmount' + this.selectedType;
            Swal.fire({
              icon: 'success',
              title: 'Success!',
              text: 'Transaction completed successfully.'
            }).then(() => {
              if(latestUser!=null && latestUser!='' && latestUser!="null")
                {
                  this.siteConfi = JSON.parse(localStorage.getItem("siteConfivalidated"));
                 var primarytrans=this.siteConfi.primaryTxType;
                 localStorage.setItem('latestUser','');
                 this.nav.CheckPrimaryTrasactionType(primarytrans);
                }
                else{
              location.reload();
                }
            });
          }
      },
        (error) => {
          this.toastr.warning("Transaction Failed: ", error);
        });
    }
  }
  ExchangeProductTx() {
    debugger;
    let setthis = this;
    this.currentUser = this.authenticationService.currentUserValue;
    //this.Trantype = JSON.parse(localStorage.getItem("Transtype"));
    let prodcutsLists = this.SelectedProducts.filter(function (element, index) {
      //element.SoldFor = element.Total;
      return (element.type == 'Exchange');
    });
    let ExchnageProduct = this.GetSelectedProducts()
    for (let data of ExchnageProduct) {
      prodcutsLists.push(data);
    }
    let totalPrice =0;
    if (this.CalculateTotalMoneyCertAmounts.length > 0) {
      for (let i = 0; i < this.CalculateTotalMoneyCertAmounts.length; i++) {
        
        totalPrice += this.CalculateTotalMoneyCertAmounts[i].tenderAmount;
      }
    }
    totalPrice += this.CalculateTotalCashAmount + this.CalculateTotalRoomAmount + this.totalCardCalc - Math.abs(this.changeDue);
    var latestUser= localStorage.getItem("latestUser");
  let transactionInfo={};
   if(latestUser!=null && latestUser!='' && latestUser!="null")
   {
    transactionInfo = {
      WorkstationID: this.workstation.workStationId,
      WSIDTxID: this.TxNum,
      UserID: latestUser,
      GuestName: this.customerName,
      CustId: this.CustId,
      Amount: parseFloat(this.CalculateTotalCashAmount) - this.changeDue,
      TotalPrice: totalPrice,
      ShiftNum: parseInt(this.workstation.lastShiftNum) + 1,
      TendorType: this.selectedType,
      ExchangeTenderType: this.ExchangeTenderType,
      RoomNum: this.roomNumber,
      TransType: this.Trantype,
      TenderId: this.selectedTenderId,
      TotalCCChargeAmount: this.totalCardCalc,
      TotalRoomChargeAmount: this.CalculateTotalRoomAmount,
      TotalCashAmount: this.CalculateTotalCashAmount - this.changeDue,
      CashTenderId: this.selectedCashTenderId,
      CCTenderId: this.selectedCCTenderId,
      RoomTenderId: this.selectedRoomTenderId,
      prodcutsLists: prodcutsLists,
      moneyCertTenders: this.CalculateTotalMoneyCertAmounts,
      TxID: this.TenderListForExchange[0].txId,
      giftingInfo: this.getGiftInfoByForm()
    }
  }
  else{
    transactionInfo = {
      WorkstationID: this.workstation.workStationId,
      WSIDTxID: this.TxNum,
      UserID: this.currentUser.userId,
      GuestName: this.customerName,
      CustId: this.CustId,
      Amount: parseFloat(this.CalculateTotalCashAmount) - this.changeDue,
      TotalPrice: totalPrice,
      ShiftNum: parseInt(this.workstation.lastShiftNum) + 1,
      TendorType: this.selectedType,
      ExchangeTenderType: this.ExchangeTenderType,
      RoomNum: this.roomNumber,
      TransType: this.Trantype,
      TenderId: this.selectedTenderId,
      TotalCCChargeAmount: this.totalCardCalc,
      TotalRoomChargeAmount: this.CalculateTotalRoomAmount,
      TotalCashAmount: this.CalculateTotalCashAmount - this.changeDue,
      CashTenderId: this.selectedCashTenderId,
      CCTenderId: this.selectedCCTenderId,
      RoomTenderId: this.selectedRoomTenderId,
      prodcutsLists: prodcutsLists,
      moneyCertTenders: this.CalculateTotalMoneyCertAmounts,
      TxID: this.TenderListForExchange[0].txId,
      giftingInfo: this.getGiftInfoByForm()
    }
  }
    if (transactionInfo != null) {
      this.isMoneyShow = true;
      this._giftService.SaveTransactionExchange(transactionInfo).subscribe((res) => {
        debugger;
        if (res != null) {
          this._commonService.UpdateLastTXID(this.workstation.workStationId, this.workstation.lastTxId, true)
            .subscribe((responce) => {
              this.TxNum = responce;
            });
        }
        if (this.isTourDepo) {
          this.selectedType = 'FUllAmount' + this.selectedType;
          debugger;
          Swal.fire({
            icon: 'success',
            title: '',
            text: 'Tour Deposit Completed',
          }).then(() => {
            setthis.isTourDepo = false;
            location.reload();
          });
        }
        else {
          if (res != null) {
            this.isTendorDisable = false;
            if (this.CalculateTotalCashAmount > 0 && this.SplitTransaction.length > 0) {
              this.selectedType = 'Split'
            }
            this.selectedType = 'FUllAmount' + this.selectedType;
            Swal.fire({
              icon: 'success',
              title: 'Success!',
              text: 'Transaction successfully completed!'
            }).then(() => {
              if(latestUser!=null && latestUser!='' && latestUser!="null")
                {
                 localStorage.setItem('latestUser','');
                 this.nav.isdisabledNav=false;
                 this.router.navigate(['../SalesHistory'], { relativeTo: this.route });
                }
                else{
              location.reload();
                }
            });
          }
        }
      });
    }
    //}
    else {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Something went wrong!',
      })
    }
  }
  GetSelectedProducts() {
    debugger;
    let saleProducts = this.SelectedProducts.filter(function (element, index) {
      return (element.type === 'Sale');
    });
    let ProdList = [];
    for (let i = 0; i < saleProducts.length; i++) {
      let prodObj = {
        ProductId: saleProducts[i].plucode === null || saleProducts[i].plucode === undefined ? saleProducts[i].productId : saleProducts[i].plucode,
        ProductName: saleProducts[i].fastButtonName,
        SRP: saleProducts[i].srp,
        Qty: parseInt(saleProducts[i].qty),
        Cost: saleProducts[i].cost,
        SoldFor: saleProducts[i].price,
        Discount: parseFloat(saleProducts[i].discount || 0.0),
        WholesaleCost: parseFloat(saleProducts[i].wholesaleCost || 0.0),
        TourId: this.tourId === 0 ? null : this.tourId,
        FastButtonId: saleProducts[i].fastButtonId,
      }
      ProdList.push(prodObj);
    }
    return ProdList;
  }

  OpenPrimarytxType() {
    debugger;
    this.nav.isdisabledNav = false;
    localStorage.setItem("isdisabledNavBar", 'false');
  
    const routes = {
      1: '../ProductCart',
      2: '../PregiftingCardProduct',
      3: '../GiftingCardProduct',
      4: '../OpenGiftingCardProduct'
    };
  
    const primaryTxType = this.siteConfi.primaryTxType;
    if (primaryTxType === 1) {
      location.reload();
    } else if (routes[primaryTxType]) {
      this.router.navigate([routes[primaryTxType]], { relativeTo: this.route });
    }
  }
  BtnSelected() {
    debugger;
    this.cartValues.BalanceDue = parseFloat(this.cartValues.BalanceDue).toFixed(2);
      this.selectedType = this.selectedTender.tenderType;
      this.selectedTenderId = this.selectedTender.tenderId;
      const tenderName = this.selectedTender.tenderName;
      this.amountTendered = (this.amountTendered === 0 ? this.cartValues.BalanceDue : this.amountTendered);
      let balanceDue = this.cartValues.BalanceDue - parseFloat(this.amountTendered);
      this.isFinish = true;
      if (this.selectedType === "Credit Card") {
        this.selectedCCTenderId = this.selectedTenderId;
        this.selectedCCAmount = this.amountTendered;
        if (balanceDue <= 0) {

          this.isFinish = false;
          this.cartValues.BalanceDue = 0;
        }
        this.isCCPrinting = true;
        this.isBackDisable = false;
        this.isProcessCancel = true;
      }
      else if (this.selectedType === "Cash") {
        this.selectedCashTenderId = this.selectedTenderId;
        let calculateAmt = this.totalCardCalc;
        this.CalculateTotalCashAmount = parseFloat(this.CalculateTotalCashAmount) + parseFloat(this.amountTendered);
        this.changeDue = this.cartValues.TxTotal - (+this.CalculateTotalCashAmount + +this.CalculateTotalRoomAmount + +this.CalculateTotalMoneyCertAmount + calculateAmt)- this.depositRefundValue - this.cashGiftValue -  Math.abs(this.creditAmont);
        if(this.changeDue > 0){
          this.changeDue = 0;
        }
        else {
          this.changeDue = Math.abs(this.changeDue);
        }
        this.amountTendered = 0;
        this.cartValues.BalanceDue = balanceDue;
        if (this.cartValues.BalanceDue <= 0) {
          this.isFinish = false;
          this.isTendorDisable = true;
          this.cartValues.BalanceDue = 0;
        } else {
          this.isFinish = true;
          this.isTendorDisable = false;
        }
        this.isShowPSP = false;
        this.isSRPShow = false;
        this.isDiscountShow = false;
        this.isMoneyShow = true;
        this.isTxPrinting = false;
        this.isTransCancil = true;
        this.isProcessCancel = false;
      }
      else if (this.selectedType === "Room Charge") {
        this.isshowRoomModal = true;
        setTimeout(() => { // this will make the execution after the above boolean has changed
          $("#roomChargeId").focus();
        }, 500);
      }
      else if (this.selectedType === "Money Cert") {
        let calculateAmt = this.totalCardCalc;
        let moneyCertAmt = parseFloat(this.CalculateTotalMoneyCertAmount) + parseFloat(this.amountTendered);

        if (+moneyCertAmt > 0) {
          let mod = +moneyCertAmt % 1;
          if (+this.amountTendered > this.cartValues.BalanceDue) {
            this.toastr.error(`Cannot accept a money certificate(s) for more than the amount due.
        The total face value of the certificate(s) must be less than or equal to the amount due.`);
            this.amountTendered = 0;
            return;
          }
          else if (mod != 0) 
          {
            this.toastr.error(`The amount tendered of ${moneyCertAmt} is invalid. Money certificates must be a whole dollar amount.`);
            this.amountTendered = 0;
            return;
          }
        }
        let objMoneyCert = { tenderId: this.selectedTenderId, moneyCertName: tenderName, tenderAmount: +this.amountTendered };
        this.upsert(this.CalculateTotalMoneyCertAmounts, objMoneyCert);

        this.CalculateTotalMoneyCertAmount = +moneyCertAmt;
         this.changeDue = this.cartValues.TxTotal - (+this.CalculateTotalCashAmount + +this.CalculateTotalRoomAmount + +this.CalculateTotalMoneyCertAmount + calculateAmt)- this.depositRefundValue - this.cashGiftValue-Math.abs(this.creditAmont);
         if(this.changeDue > 0){
          this.changeDue = 0;
        }
        else {
        this.changeDue = Math.abs(this.changeDue);
        }
        this.amountTendered = 0;
        this.cartValues.BalanceDue = balanceDue;
        if (this.cartValues.BalanceDue <= 0) {
          this.isFinish = false;
          this.isTendorDisable = true;
          this.cartValues.BalanceDue = 0
        } else {
          this.isFinish = true;
          this.isTendorDisable = false;
        }
        this.isShowPSP = false;
        this.isSRPShow = false;
        this.isDiscountShow = false;
        this.isMoneyShow = true;
        this.isTxPrinting = false;
        this.isTransCancil = true;
        this.isProcessCancel = false;
      }
      this.showHideBtnCancelProcess();
      var selectedTenders= document.querySelectorAll(".tenderType-scroll li").length;
      if(selectedTenders > 2)
      {
        $('.tenderType-scroll').addClass('addPadding'); 
      }
   
  }

  upsert(array, element) {
    const i = array.findIndex(_element => _element.tenderId === element.tenderId);
    if (i > -1) {
      array[i].tenderName = element.tenderName;
      array[i].tenderAmount = array[i].tenderAmount + element.tenderAmount;
    }
    else array.push(element);
  }
  showHideBtnCancelProcess() {
    if (this.totalCardCalc > 0) {
      this.isProcessCancel = true;
    }
    else {
      this.isProcessCancel = false;
    }
  }

  getGiftInfoByForm() {
    const giftInfo = {
      TourID: this.form.controls['Tour ID']?.value,
      OPC: this.form.controls['OPC']?.value,
      DepositRefund: this.form.controls['Deposit Refund']?.value,
      CashGift: this.form.controls['Cash Gift']?.value,
      CustomField1: this.form.controls['CustomField1']?.value,
      CustomField2: this.form.controls['CustomField2']?.value,
      CustomField3: this.form.controls['CustomField3']?.value,
      CustomField4: this.form.controls['CustomField4']?.value,
      CustomField5: this.form.controls['CustomField5']?.value,
      CustomField6: this.form.controls['CustomField6']?.value,
      CustomField7:this.form.controls['CustomField7']?.value,
      CustomField8: this.form.controls['CustomField8']?.value,
      CustomField9: this.form.controls['CustomField9']?.value,
      CustomField10: this.form.controls['CustomField10']?.value
    };
    return giftInfo;
  }

  PopulateRoomNumber(roomNo) {
    if (roomNo === 'x') {
      let roomNums = "" + this.roomNumber;
      this.roomNumber = roomNums.slice(0, -1);
      this.roomNumber = this.roomNumber;
    }else if(roomNo === 'c'){
      this.roomNumber = "";
    }
     else {
      this.roomNumber = "" + this.roomNumber + roomNo;
    }

  }
  PopulateRoomNumberContinue() {
    debugger;
    if (this.roomNumber === '') {
      this.PopulateAmount('x')
      this.isshowRoomModal = false;
    }
    else {
      this.RoomChargeBtn()
      this.isshowRoomModal = false;
    }
  }
  RoomChargeBtn() {
    debugger;
    let balanceDue = this.cartValues.BalanceDue - parseFloat(this.amountTendered);
    this.selectedRoomTenderId = this.selectedTenderId;
     let calculateAmt = this.totalCardCalc; 
    this.CalculateTotalRoomAmount = parseFloat(this.CalculateTotalRoomAmount) + parseFloat(this.amountTendered);
    this.changeDue = this.cartValues.TxTotal - (+this.CalculateTotalCashAmount + +this.CalculateTotalRoomAmount + +this.CalculateTotalMoneyCertAmount + calculateAmt)- this.depositRefundValue - this.cashGiftValue - Math.abs(this.creditAmont);
    if(this.changeDue > 0){
      this.changeDue = 0;
    }
    else {
      this.changeDue = Math.abs(this.changeDue);
    }
   
    this.amountTendered = 0;
    this.cartValues.BalanceDue = balanceDue;
    if (this.cartValues.BalanceDue <= 0) {
      this.isFinish = false;
      this.isTendorDisable = true;
      this.cartValues.BalanceDue = 0;
    } else {
      this.isFinish = true;
      this.isTendorDisable = false;
    }
    this.isShowPSP = false;
    this.isSRPShow = false;
    this.isDiscountShow = false;
    this.isMoneyShow = true;
    this.isTxPrinting = false;
    this.isTransCancil = true;
    // var selectedTenders= document.querySelectorAll(".tenderType-scroll li").length;
    // if(selectedTenders > 2)
    // {
    //   $('.tenderType-scroll').addClass('addPadding'); 
    // }
  }

  PopulateRoomNumberCancel() {
    this.isshowRoomModal = false;
    this.roomNumber = '';
    this.PopulateAmount('x')

  }
  valRoomNumber(event) 
  {
    debugger;
    this.roomNumber = event.target.value;
  }

  
  Searchproduct() {
    debugger;

    if(this.searchText !=""){
    this._posSerive.GetProductsListByName(this.workstation.workStationId, this.searchText, this.siteConfi.maxDiscountFactor,this.siteConfi.displaySRP).subscribe((res) => {
      //this.ProductList = res;
      this.NewProducts = res;
      console.log('OldProduct',res);
      if (res[0].products.length === 0) {
        Swal.fire({
          icon: 'error',
          title: 'Warning!',
          text: 'Product Count is 0 for searched item.',
        })
      }
      else {
        this.isShowProdList = true;
        this.isQuickShow = false;
        this.isvendorShow = false;
        this.iscatShow = false;
        //this.isSubCatShow = false;

        // this.ProductList.forEach(currentRow => {
        //   const index = this.SelectProducts.findIndex(pro => ((pro.plucode === undefined || pro.plucode === null) ? pro.productId === currentRow.productId : pro.plucode === currentRow.plucode));
        //   if (index > -1) {
        //     // currentRow.isActive = true;
        //     currentRow.prodTotalQty = this.SelectProducts[index].prodTotalQty = this.SelectProducts[index].qty;
        //   }
        // });
        this.NewProducts.forEach(row => {
          row.products.forEach(currentRow => {
            const index = this.SelectProducts.findIndex(pro => ((pro.plucode === undefined || pro.plucode === null) ? pro.productId === currentRow.productId : pro.plucode === currentRow.pLUCode));
            if (index > -1) {
              currentRow.totalPQty = this.SelectProducts[index].prodTotalQty = this.SelectProducts[index].qty;
            }
          })
  
        });
      } 
     
    })
  }
  else{
    this.GetProductListNew();
  }
  }
  RestrictNumbers(key){
    if(key>=0){
    const pattern = /[0-9\+\-\ ]/;
      let inputChar = String.fromCharCode(Number(key));
      if (!pattern.test(inputChar)) {
        // invalid character, prevent input
        return false;
      }
      else{
        return true;
      }
    }
    else{
      return true;
    }

  }

  
}


