import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './Components/login/login.component';
import { FormsModule } from '@angular/forms';
import { WorkstationsComponent } from './Components/workstations/workstations.component';
import { ButtonModule } from 'primeng/button';
import { MessagesModule } from 'primeng/messages';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CarouselModule } from 'primeng/carousel';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfirmationService } from 'primeng/api';
import { LoaderComponent } from './Helpers/loader/loader.component';
import { LoaderService } from './Services/loader.service';
import { LoaderInterceptor } from './Helpers/loader-interceptor.service';
import { ToastrModule } from 'ngx-toastr';
import { MaterialModule } from './material.module';
import { ViewOnHandComponent } from './Components/navigation/view-on-hand/view-on-hand.component';
import { DatePipe } from '@angular/common';
import { SelectdistrictComponent } from './Components/selectdistrict/selectdistrict.component';
import { SelectSitesComponent } from './Components/select-sites/select-sites.component';
import { SelectworkstationComponent } from './Components/selectworkstation/selectworkstation.component';
import { LoadComponentComponent } from './Components/load-component/load-component.component';
import { ForgotPasswordComponent } from './Components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './Components/reset-password/reset-password.component';
//import { AutoFocusDirective } from './Components/navigation/directives/autofocus.directive';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    WorkstationsComponent,
    LoaderComponent,
    SelectdistrictComponent,
    SelectSitesComponent,
    SelectworkstationComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    LoadComponentComponent,
    //AutoFocusDirective
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    BrowserAnimationsModule,
    CarouselModule,
    HttpClientModule,
    AppRoutingModule,
    ConfirmDialogModule,
    ButtonModule,
    MessagesModule,
    ToastrModule.forRoot()
  ],
  entryComponents: [ViewOnHandComponent],
 
  providers: [ConfirmationService,   LoaderService,DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
