<div class="right-content">
    <div class="heading-panel text-left">        
        <h1><img src="./assets/Libs/images/enventry.png" height="16"> Inventory</h1>
        <!-- <h2>Home</h2> -->
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12 text-center">
                <h1 class="body-heading">New Transfers</h1>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="invent-back new-trans invent-new-trnfr">
            <div class="row">
                <div class="col-sm-12">
                    <div class="table-responsive">
                        <table class="table table-bordered table-striped mb-3">
                            <thead>
                                <tr>
                                    <th>Transfer Date</th>
                                    <th>Transfer ID</th>
                                    <th style="width:53%">To Location</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{{transferDate}}</td>
                                    <td>{{transferId}}</td>
                                    <td >
                                        <select class="form-control"  (change)="getProdForTransfer(selectWS.value);" #selectWS>
                                            <option value="0">-Select Workstation-</option>
                                            <option  *ngFor="let item of workstationList" [value]="item.workStationId">
                                                {{item.workStationName}} {{item.workStationId}}
                                            </option>
                                        </select>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="row">
                    <div class="col-sm-12">
                    <div class="table-responsive scnd-tbl-rspnsv">
                        <table class="table table-bordered table-striped" *ngIf="isTransferProd">
                            <thead>
                                <tr>
                                    <th style="width:5%"></th>
                                    <th style="width:65%">Product</th>
                                    <th class="text-center">Quantity</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let dynamic of dynamicArray; let i = index;">
                                    <td>
                                    </td>
                                    <td  >
                                        <select class="form-control" [(ngModel)]="dynamicArray[i].ProductId" ngDefaultControl
                                        [ngClass]="{'border border-danger': dynamicArray[i].ProductId=='0' || dynamicArray[i].ProductId.totalProdQTY < dynamicArray[i].Quantity}"    
                                        (ngModelChange)="selectProdForTransfer($event,dynamicArray[i]);" #selectProd>
                                            <option value="0">-Select Product-</option>
                                            <option *ngFor="let item of viewProdTransferData" [ngValue]="item">
                                                {{item.productName}}/{{item.totalProdQTY}}
                                            </option>
                                        </select>
                                    </td>
                                    <td>
                                        <!-- <input type="number" [(ngModel)]="dynamicArray[i].Quantity" ngDefaultControl
                                        [ngClass]="{'border border-danger': dynamicArray[i].Quantity===''||dynamicArray[i].Quantity === null || dynamicArray[i].Quantity > totalSelectedQTY}"    
                                        (blur)="CheckAvailableQTY(dynamicArray[i])" class="form-control"> -->
                                        <input type="number" [(ngModel)]="dynamicArray[i].Quantity" ngDefaultControl
                                        [ngClass]="{'border border-danger': dynamicArray[i].Quantity===''||dynamicArray[i].Quantity === null || dynamicArray[i].Quantity > totalSelectedQTY}"    
                                         class="form-control  text-center">
                                    </td>
                                    <td class="text-danger text-center"> <i class="fa fa-trash cstm-invtNewTrnfr-trshIcon" ngDefaultControl
                                            (click)="deleteRow(i)"></i></td>
                                </tr>
                                <tr>
                                    <td class="text-success">
                                        <i class="fa fa-plus-circle addIcon-invtNewTrnfr" ngDefaultControl (click)="addFieldValue($event,dynamicArray)"></i>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td> 
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="action-buttons d-flex justify-content-center align-items-center">
                    <!-- <button [disabled]="isProdSelect" class="button button-secondary" (click)="SaveInventory('SaveClose',selectWS.value)">Save &
                        Close</button>&nbsp; -->
                    <!-- <button [disabled]="isProdSelect" class="button button-secondary" (click)="SaveInventory('SaveNew',selectWS.value)">Save &
                        New</button>&nbsp; -->
                        <button class="button button-secondary" (click)="CheckAvailableQTY('SaveNew',$event,dynamicArray)">Save &
                            New</button>&nbsp;
                    <button class="button button-secondary" (click)="ResetTransaction()"><i class="fa fa-rotate-left"></i>
                    Reset</button>
                    <button class="button button-secondary" (click)="CancelTransaction()">
                        Cancel</button>
                </div>
            </div>
        </div>
    </div>

</div>
<!-- ------------------------------------------------------------------------------------------------------------------------------- -->
<!-- <div [style.display]="MySingInModal? 'block' : 'none'" class="modal name-popup" id="imgmodal" tabindex="-1"
role="dialog" aria-labelledby="myMLabel" aria-hidden="true">
<div class="modal-dialog">
    <div class="modal-content signinClass">
        <div class="modal-header SignInHeader">
            <button type="button" class="close"data-dismiss="modal" (click)="hide('SignInModal')">×</button>
        </div>
        <div class="row col-md-12" >
            <div class="col-md-4 ">
                <img class="SignInImageIcon" src="../../../../assets/Libs/images/logo-icon.png">
            </div>
            <div class="modal-body col-md-8" >
                <h1 class="modal-title text-heading-color" id="myLabel">SIGN IN</h1>
                <form [formGroup]="LoginAdjuctGroup"  (ngSubmit)="AdjustLogin()">
                    <div class="form-group">

                        <input type="text" placeholder="Username" formControlName="UserNameLogin"
                            class="form-control" [ngClass]="{ 'is-invalid':issubmitted && Login.UserNameLogin.errors }" />
                            <div *ngIf="issubmitted && Login.UserNameLogin.errors" class="invalid-feedback">
                                <div *ngIf="Login.UserNameLogin.errors.required">UserName is required</div>
                            </div>
                    </div>
                    <div class="form-group">
                        <input type="password" placeholder="Password" formControlName="PasswordLogin"
                            class="form-control" [ngClass]="{ 'is-invalid':issubmitted && Login.PasswordLogin.errors }" />
                            <div *ngIf="issubmitted && Login.PasswordLogin.errors" class="invalid-feedback">
                                <div *ngIf="Login.PasswordLogin.errors.required">Password is required</div>
                            </div>
                    </div>
                    <button type="submit" id='btnSubmit'  class="btn btn-light  form-control"
                        >LOGIN</button>
                </form>
            </div>
        </div>
    </div>
</div>
</div> -->

<div [style.display]="MySingInModal? 'block' : 'none'" class="modal name-popup" id="imgmodal" tabindex="-1"
    role="dialog" aria-labelledby="myMLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-cstmSign">
        <div class="modal-content signinClass">
            <div class="modal-header SignInHeader">
                <h4 class="modal-title" id="myLabel">Authorization Required</h4>
            </div>
            <div class="modal-body text-right">      
                <form [formGroup]="LoginAdjuctGroup" (ngSubmit)="AdjustLogin()">
                    <div class="form-group">

                        <input type="text" placeholder="Username" formControlName="UserNameLogin" autocomplete="off"
                            class="form-control"
                            [ngClass]="{ 'is-invalid':issubmitted && Login.UserNameLogin.errors }" />
                        <div *ngIf="issubmitted && Login.UserNameLogin.errors" class="invalid-feedback">
                            <div *ngIf="Login.UserNameLogin.errors.required">UserName is required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <input type="password" placeholder="Password" formControlName="PasswordLogin" autocomplete="off"
                            class="form-control"
                            [ngClass]="{ 'is-invalid':issubmitted && Login.PasswordLogin.errors }" />
                        <div *ngIf="issubmitted && Login.PasswordLogin.errors" class="invalid-feedback">
                            <div *ngIf="Login.PasswordLogin.errors.required">Password is required</div>
                        </div>
                    </div>
                    <div class="signX-buttons d-flex justify-content-between align-items-center">
                        <button type="button" class="close btnsignX" data-dismiss="modal"
                            (click)="hide('SignInModal')">Cancel</button>
                        <button type="submit" id='btnSubmit' class="btn btnsignN  form-control">Sign In</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- --------------------------------------------------------------------------------------------------------------------------------- -->
<p-confirmDialog acceptLabel="Continue" appendTo="body">
    <ng-template pTemplate="body">
        <span class="ui-confirmdialog-message"></span>
    </ng-template>
</p-confirmDialog>