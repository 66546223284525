//Backup by chandan Before view-sales.component.ts custom date implementation
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { IPOSService } from 'src/app/Services/i-pos.service';
import { AuthService } from 'src/app/Services/auth.service';
import { DataService } from 'src/app/Services/data.service';
import { RefundDetails, RefundGroupedDetails } from 'src/app/Models/Refund';
import { formatDate } from '@angular/common';
import { DateHelper } from 'src/app/shared/helpers/date.helper';
import { query } from '@angular/animations';
import { DatePipe } from '@angular/common';
import * as $ from 'jquery';
import Swal from 'sweetalert2';
import { FormBuilder,FormControl, FormGroup ,Validators} from '@angular/forms';
import { TxTypeList } from 'src/app/Models/shift.model';
import { ShiftService } from 'src/app/Services/shift.service';
import { ConfirmationService } from 'primeng/api';
import { CommonService } from 'src/app/Services/common.service';

@Component({
  selector: 'app-view-sales',
  templateUrl: './view-sales.component.html',
  styleUrls: ['./view-sales.component.css']
})
export class ViewSalesComponent implements OnInit {
  currentUser: any;
  saleTransAllData: any = [];
  saleTrans: any = [];
  sites: any = [];
  selectedOption: string;
  isSelectItem: boolean = false;
  isSaleHistory: boolean = true;
  isShowTX: boolean = false;
  refundDetailsList: any = [];
  refundProductsGroupList: any = [];
  refundVoucherProdGrpList: any = [];
  payoutList: any = [];
  dataList: any = [];
  wokstationList: any = [];
  selectedRow: boolean = false;
  workstation: any;
  isProductSelected: boolean = true;
  isSelectedTransaction: false;
  customDateField: boolean;
  customDate: any;
  //ProdTotelRefunded: any;
  isProdSelect: boolean = true;
  refundDetails = {} as RefundDetails;
  refundGroupDetails = {} as RefundGroupedDetails;
  pickerMinDate: Date;
  pickerMaxDate: Date;
  selectedSite: number;
  selectedWorkstation: string;
  sumitted: boolean;
  modelrefundList: any;
  siteConfi: any;
  isProductExchange: boolean = true;
  TXTypeList: any
  TxList: Array<TxTypeList> = [];
  selectedTranDate: any = "TO";
  selectedTranType: any = "";
  selectedCustName: any;
  selectedTxId: any;
  advancesetting: any;
  ViewSaleModal: boolean;
  errormsg: any;
  toastr: any;
  Allselected:boolean;
  currentRefundName:any="Previously Refunded";
  printDisable:boolean=true;
  returnComponent:string;
  feature:string;
  subFeature:string;
  headerText:any="Transaction History";
ViewSaleLogin:FormGroup
  // ViewSaleLogin = new FormGroup({
  //   UserNameLogin: new FormControl('',Validators.required),
  //   PasswordLogin: new FormControl('',Validators.required),
  // });
  isdisabledNav = false;
  issubmitted:boolean=false;
  constructor(private router: Router, private route: ActivatedRoute, private posSerive: IPOSService, private authenticationService: AuthService, private dataSerive: DataService, private datepipe: DatePipe,private _shiftService: ShiftService,
    private confirmationService: ConfirmationService,private _commonService: CommonService,private formBuilder: FormBuilder) { }

  RedirectToHome() {
    this.router.navigate(['../DashBoard'], { relativeTo: this.route });
  }
  RedirectSalesHistory() {
    this.router.navigate(['../SalesHistory'], { relativeTo: this.route });
    location.reload();
  }
  ngOnInit() {
    debugger;
    this.ViewSaleLogin = this.formBuilder.group({
      UserNameLogin1: ['', Validators.required],
      PasswordLogin1: ['', Validators.required]
    });

    this.pickerMinDate = DateHelper.TodaySubtract(365);
    this.pickerMaxDate = new Date();

    debugger;
    this.customDateField = false;
    this.workstation = JSON.parse(localStorage.getItem('workstation'));

    this.siteConfi = JSON.parse(localStorage.getItem("siteConfivalidated"))
    this.TXTypeList = JSON.parse(localStorage.getItem("TxTypesList"))
    this.advancesetting = JSON.parse(localStorage.getItem("Useradvancsetting"))
    this.TXTypeList = this.TXTypeList.filter(x => x.flag == true)


    let navDesible = localStorage.getItem('isdisabledNavBar');
    navDesible === 'false' ? this.isdisabledNav = false : this.isdisabledNav = true;

  
    this.currentUser = this.authenticationService.currentUserValue;
    const workStationId = this.workstation.workStationId;
    this.selectedSite = this.workstation.siteId;
    this.selectedWorkstation = this.workstation.workStationId;

    let refunndFlag = JSON.parse(localStorage.getItem("BackButtonFlag"));
    if (refunndFlag) {
      let BackRefunRecord = JSON.parse(localStorage.getItem("BackRefunRecord"));
      let Event = JSON.parse(localStorage.getItem("BackRefunRecordEvent"));
      if (BackRefunRecord != null) {
       this.BackshowTxDetails(Event, BackRefunRecord);
        
      }
    }
    else {
      debugger;
      this.posSerive.GetSites(this.currentUser.districtNum, this.workstation.workStationId)
        .subscribe((res) => {
          this.sites = res;
       
          this.posSerive.GetWorkstationListById(this.selectedSite)
            .subscribe((res) => {
              this.wokstationList = res;
            });
        });
      let tranDate = this.selectedTranDate || null;

      if (workStationId != null) {

        if (tranDate != null) {
          debugger;
          this._commonService.GetTransactionForRefund()
            .subscribe((res) => {
              for (let i = 0; i < res.length; i++) {
                res[i].isSelected = false;
              }
            
              this.saleTrans = res.sort(function (a, b) {
                return b.wsIdTxNo - a.wsIdTxNo;
              });

              this.saleTransAllData = this.saleTrans;
              this.saleTrans = this.saleTrans.filter(x => x.workStationId == workStationId);
              this.saleTrans = this.FilterDataWithDateFilter(this.saleTrans, tranDate);
           
            });
        }
      }
      // Set the minimum 365 Days in the min Date and Today in the max Date.

    }
    this.CheckPrinter();
 
    $(".navigation ul li a").removeClass("active");
    $(".salesSec").addClass("active");
    $(".envSec, .transaSec, .cashSec").removeClass("active");
  }


  get Login() { 
    return this.ViewSaleLogin.controls; }

  

  onChange(email: string, isChecked: boolean) {
    if (isChecked) {
      this.dataList.push(email);
    } else {
      let index = this.dataList.indexOf(email);
      this.dataList.splice(index, 1);
    }
  }
  checkUncheckAllItem(isChecked: boolean) {
    debugger;
    this.refundVoucherProdGrpList.forEach(item => {
      item.isSelected = isChecked;
      item.prodList.forEach(prod => {
        prod.isSelected = isChecked;
      });
    });
    this.refundProductsGroupList.forEach(item => {
      item.prodList.forEach(prod => {
        prod.isSelected = isChecked;
      });
    });
    if (isChecked) {
      this.isProdSelect = false;
    }
    else {
      this.isProdSelect = true;
    }

  }


  CheckChangeVoucherProductList(isChecked: boolean, category: number, mediaNumber: string) {
    debugger;
   
    this.modelrefundList = this.refundVoucherProdGrpList

    let filterdata = this.modelrefundList
      .filter(obj => obj.prodList.filter(p => p.sNo == category)
      );
    let count = 0;
  
    this.modelrefundList
      .filter(obj => obj.category == 'Voucher')
      .forEach(item => {
        item.isSelected = isChecked;
        item.prodList.forEach(prod => {
          if (prod.mediaNumber === mediaNumber) {
            prod.isSelected = isChecked;

          }
          else if (count == 0 && prod.mediaNumber === mediaNumber) {
            prod.isSelected = isChecked;
            count++
          }

        })
      });
    this.ShowHideContinueProductCount();
    if(!isChecked)
    {
      this.Allselected = false;
    }
    else{
      this.Allselected=this.SetSelectAllCheckBoxByChild();
    }
    this.isProdSelect=(this.EnableDisbaleContinueButton());
  }

  CheckChangeProductList(isChecked: boolean, serialNo: number) {
    debugger;

    let filterdata = this.refundProductsGroupList
      .filter(obj => obj.prodList.filter(p => p.sNo == serialNo)
      );

    this.refundProductsGroupList
      .filter(obj => obj.prodList.filter(p => p.sNo == serialNo)
        .forEach(item => {
          item.isSelected = isChecked;
        })
      )
    this.ShowHideContinueProductCount();
    if(!isChecked)
    {
      this.Allselected = false;
    }
    else{
      this.Allselected=this.SetSelectAllCheckBoxByChild();
    }

   this.isProdSelect=(this.EnableDisbaleContinueButton());
  }

  ShowHideContinueProductCount() {
    debugger;
    let selectedReturnArray: any;
   
    selectedReturnArray = this.refundProductsGroupList
      .map((m) => {
        return m.prodList.filter(p => p.isSelected == true).length
       
      })
   

    let selectedVoucherReturnArray: any;
  
    selectedVoucherReturnArray = this.refundVoucherProdGrpList
      .map((m) => {
        return m.prodList.filter(p => p.isSelected == true).length
       
      })
   

    let sum1 = selectedReturnArray.reduce(function (previousValue, currentValue) {
      return previousValue + currentValue;
    }, 0);
    let sum2 = selectedVoucherReturnArray.reduce(function (previousValue, currentValue) {
      return previousValue + currentValue;
    }, 0);
    if (sum1 >= 0 || sum2 >= 0) {
      this.isProdSelect = false;
    }
    else {
      this.isProdSelect = true;
    }
  }

  AddToList(e, obj) {
    debugger;
    const checked = e.target.checked;
    if (checked) {
      this.dataList.push(obj);
      this.dataSerive.setbehaviorListData(this.dataList);
      this.isProdSelect = false;
    } else {
      this.isProdSelect = true;
    }
  }

  AddVoucherToList(e, obj) {
    debugger;
    const checked = e.target.checked;
    if (checked) {
      this.dataList.push.apply(this.dataList, obj);
      this.dataSerive.setbehaviorListData(this.dataList);
      this.isProdSelect = false;
    } else {
      this.isProdSelect = true;
    }
  }
  hide(type: string) {

    if (type === 'SignInModal') {
      this.ViewSaleModal = false;
    }
  }
  AdjustLogin() {
    debugger;
    this.issubmitted=true;
    if (this.ViewSaleLogin.invalid) {
      return;
    }
    let GetGUIDchrowm = localStorage.getItem("MacAddress");
    let decodeGUID=atob(GetGUIDchrowm)
    let userObj = {
      UserName: this.ViewSaleLogin.controls.UserNameLogin1.value,
      Password: this.ViewSaleLogin.controls.PasswordLogin1.value,
      GUID: decodeGUID,
      Feature:this.feature,
      SubFeature:this.subFeature
    }
    this.authenticationService.UserLoginForOtherPermissions(userObj)
    .subscribe((res) => {
      this.ViewSaleModal=false;
      if(res.hasAccess){
        this.issubmitted=false;
        if(this.subFeature=="Refund Transactions")
        {
       this.RefundWithOtherValidUser();
        }
        else{
this.ExchangeWithOtherValidUser();
        }
        }
        else{
          this.AskForOtherUserCredentials();
        }
      },
        error => {
          debugger;
          this.errormsg = error.error.message;
          this.toastr.error(this.errormsg);
          if (this.errormsg == 'Not Use This Workstation') {
            this.toastr.error("This workstation is not access to you, Please Enter the Valid Credentials");
          }
        });
        
  }



  Refund() {
    debugger;
    if (this.advancesetting.refundTransaction === 1) {

      if (this.refundDetailsList.refundGroupProdList != 'undefined' && this.refundDetailsList.refundGroupProdList != null) {
        debugger;
        this.isSaleHistory = false;
        this.isSelectItem = true;
        this.selectedOption = 'Refund';
       
        this.refundProductGrouping();
      }
      else if (this.refundDetailsList.transactionDetail != null) {
        this.isSaleHistory = false;
        this.isSelectItem = true;
        this.selectedOption = 'Refund';
        this.refundProductGrouping();
      }
      this.CheckSelectAllCheckBox();
    }
    else {
      this.feature="Advanced Settings";
      this.subFeature="Refund Transactions";
      this.AskForOtherUserCredentials();
    }
    this.headerText="Refund";
  }
  refundProductGrouping() {
    debugger;
  

    var obj_arr_appended = this.refundDetailsList.refundGroupProdList.map((currentValue, Index) => {
      currentValue.sNo = Index + 1;
      return currentValue;
    });
 
    const refundProdCategoryDetailsList = this.refundDetailsList.refundGroupProdList.map((x, index) => {
      if (x.inventoryItem == true) {
        x.prodTypeName = x.prodTypeName + '- Inventory';
      }
      x.isSelected = false;
      x.sNo = index + 1;
      return x;
    });

    const groupByCategory = refundProdCategoryDetailsList.reduce((group, product) => {
      const { prodTypeName } = product;
      group[prodTypeName] = group[prodTypeName] ?? [];
      group[prodTypeName].push(product);
      return group;
    }, {});

    // create object
    var obj = [];

    // Loop to insert key & value in this object one by one
    for (const key in groupByCategory) {
      
      let dt = { category: key, prodList: groupByCategory[key] };
      obj.push(dt);
    }
   
    const voucherGrpProd = obj.filter(x => x.category == 'Voucher');
    debugger;
    const nonVoucherGrpProd = obj.filter(x => x.category != 'Voucher' && x.category != 'PayOut');

    const payOut = obj.filter(x => x.category == 'PayOut');
  
    this.refundProductsGroupList = nonVoucherGrpProd;
    if(payOut.length > 0){
      this.payoutList = payOut[0].prodList;
    }
   
    if (voucherGrpProd.length > 0) {
      this.refundVoucherProdGrouping(voucherGrpProd);
    }
  }


  refundVoucherProdGrouping(voucherProducts: any) {
    debugger;
    const voucherProdList = voucherProducts[0].prodList;
    const voucherFilterProd = voucherProdList
      .map((m, index) => (
        {
          'category': m.prodTypeName, 'voucherTypeId': m.voucherTypeId, 'consolidationId': m.consolidationId, 'voucherDescription': m.voucherDescription, 
          'voucherNum': m.mediaNumber,
          'sNo': index + 1, 'isSelected': false,
          'total': voucherProdList.filter(x => x.prodTypeName == m.prodTypeName && x.voucherTypeId == m.voucherTypeId && x.consolidationId == m.consolidationId)
            .reduce((a, { total }) => a + parseFloat(total), 0),
          'prodList': voucherProdList.filter(x => x.prodTypeName == m.prodTypeName && x.voucherTypeId == m.voucherTypeId && x.consolidationId == m.consolidationId)
        }));

   

    const voucherGrpProd = voucherFilterProd.filter((value, index, self) =>
      index === self.findIndex((t) => (
        t.voucherTypeId === value.voucherTypeId && t.prodTypeName === value.prodTypeName && t.consolidationId === value.consolidationId
      ))
    );

    this.refundVoucherProdGrpList = voucherProducts;

  }

  Exchange() {
    debugger;
    this.headerText="Exchange";
    if (this.advancesetting.exchangeTransactions === 1) {
    const messages = `<p class='worstation-img'><img src='./assets/Libs/images/confirmation.png'></p> <h3 class='pop-head'>Warning! </h3> <p class="assignstyle"> All exchanges are final. Products sold in an exchange cannot be refunded or exchanged for another product. Are you sure you would like to continue?</p>`;
    this.confirmationService.confirm({
      message: messages,
      header: 'Confirmation!',
      rejectVisible: true,
      acceptLabel: 'Yes',
      accept: () => {
        debugger;
    this.isSaleHistory = false;
    this.isSelectItem = true;
    this.selectedOption = 'Exchange';
    this.refundProductGrouping();
    this.CheckSelectAllCheckBox();

      },
      reject: () => {
      }
    });
  }
  else{
    this.feature="Advanced Settings";
      this.subFeature="Exchange Transactions";
    this.AskForOtherUserCredentials();
  }
    
  }


  
  PopulateProducts() {
    debugger;
    
    let selectedReturnArray: any;
    var selectedReturnDt: any;
    let selectedVoucherReturnArray: any;
    let paymentist=this.refundDetailsList.paymentList;
    const payouts = this.payoutList;

    if (this.refundDetails.transactionDetail.txType == 3 && this.refundDetailsList.refundProdList.length == 0) {
      const allProductsSelected = this.refundProductsGroupList.every(obj => obj.prodList.every(p => p.isSelected == true));
      const allVouchersSelected = this.refundVoucherProdGrpList.every(obj => obj.prodList.every(p => p.isSelected == true));
  
      if (allProductsSelected && allVouchersSelected && payouts.length > 0 && this.selectedOption != 'Exchange') {
          this.dataList.push.apply(this.dataList, payouts);
      }
  }
    const selectedReturnList = this.refundProductsGroupList.filter(obj => (obj.prodList = obj.prodList.filter(p => p.isSelected == true)).length > 0);
    const selectedVoucherReturnList = this.refundVoucherProdGrpList.filter(obj => (obj.prodList = obj.prodList.filter(p => p.isSelected == true)).length > 0);
    
     selectedReturnArray = selectedReturnList.map((m) => {
        return m.prodList;
    });
    
     selectedVoucherReturnArray = selectedVoucherReturnList.map((m) => {
        return m.prodList;
    });
    
    const merged1 = [].concat.apply([], selectedReturnArray);
    const merged2 = [].concat.apply([], selectedVoucherReturnArray);
    this.dataList.push.apply(this.dataList, merged1);
    this.dataList.push.apply(this.dataList, merged2);
    
    this.dataSerive.setbehaviorListData(this.dataList);

    this._commonService.UpdateLastTXID(this.workstation.workStationId, this.workstation.lastTxId, this.sumitted = false)
      .subscribe((res) => {
        res = res + 1;
       
        if (this.selectedOption === 'Refund') {
          this.dataSerive.setbehaviorObjData({ OptionSelect: this.selectedOption, TxNum: res,TXType: this.refundDetails.transactionDetail.txType });
          this.router.navigate(['../Refund'], { relativeTo: this.route });
        
        } else if (this.selectedOption === 'Exchange') {
          
          this.dataSerive.setbehaviorObjData({ OptionSelect: this.selectedOption });
          if(this.refundDetails.transactionDetail.txType == 1)
          {
             
            this.router.navigate(['../ProductCart'], { relativeTo: this.route });
          }
          else if(this.refundDetails.transactionDetail.txType == 2)
            {
              this.router.navigate(['../PregiftingCardProduct'], { relativeTo: this.route });
            }
            else if(this.refundDetails.transactionDetail.txType == 3){
              this.router.navigate(['../GiftingCardProduct'], { relativeTo: this.route });
            }
            else if(this.refundDetails.transactionDetail.txType == 4)
              {
                this.router.navigate(['../OpenGiftingCardProduct'], { relativeTo: this.route });
              }
        }
      });
  }
  GetWorkstationById(event: any) {
    debugger
    let siteid = event.target.value
    this.posSerive.GetWorkstationListById(siteid)
      .subscribe((res) => {
        this.wokstationList = res;

      });
  }

  GetWorkstationByDistrictId(distcrictid: number) {
    this.posSerive.GetWorkstations(distcrictid, this.workstation.workStationId)
      .subscribe((res) => {
        this.wokstationList = res;
      });
  }

  FilterData(event) {
    debugger;
    this._commonService.GetTransactionForRefund()
      .subscribe((res) => {
        for (let i = 0; i < res.length; i++) {
          res[i].isSelected = false;
        }
        this.saleTrans = res.sort(function (a, b) {
          return b.wsIdTxNo - a.wsIdTxNo;
        });
        if (event.target.value !== '0') {
          this.saleTrans = this.saleTrans.filter(function (element, index) {
            return (element.workStationId === event.target.value);
          });
        }
      });
  }

  FilterDataWithDate(event) {
    debugger;
    let dateType = event.target.value || "";

    this._commonService.GetTransactionForRefund()
      .subscribe((res) => {
        for (let i = 0; i < res.length; i++) {
          res[i].isSelected = false;
        }

        this.saleTrans = res.sort(function (a, b) {
          return b.wsIdTxNo - a.wsIdTxNo;
        });
        let startDate;
        let endDate;

        if (dateType === "TO") {
          startDate = new Date(new Date().toLocaleDateString());
          endDate = new Date(new Date().toLocaleDateString());
        }
        else if (dateType === "YE") {
          startDate = new Date(DateHelper.Yesterday().toLocaleDateString());
          endDate = new Date(DateHelper.Yesterday().toLocaleDateString());
        }
        else if (dateType === "LW") {
          startDate = new Date(DateHelper.LastWeekStartDate().toLocaleDateString());
          endDate = new Date(DateHelper.LastWeekEndDate().toLocaleDateString());

        }
        else if (dateType === "LM") {
          startDate = new Date(DateHelper.LastMonthStartDate().toLocaleDateString());
          endDate = new Date(DateHelper.LastMonthEndDate().toLocaleDateString());
        }
        else if (dateType === "CUS") {
          this.customDateField = true;
          this.customDate = "";
        }
        if (dateType === "") {
          return this.saleTrans;
        }
        else {
          this.saleTrans = this.saleTrans.filter(function (element, index) {
            let elementDate = new Date(element.date);
            
            return (startDate <= elementDate && elementDate <= endDate);
          });
        }
      });
  }

  
  hideCustomDate() {
    debugger;
    this.customDateField = false;
    this._commonService.GetTransactionForRefund()
      .subscribe((res) => {
        for (let i = 0; i < res.length; i++) {
          res[i].isSelected = false;
        }
        
       this.selectedTranDate='TO';
     
        this.FilterTransactionHistory();
        return this.saleTrans;
      
      });
      
  }

  //#region Date Functions

  GetDatePart() {
    let theTodaysDay = new Date();
   
    let dateTimeInParts = theTodaysDay.toISOString().split("T");

    let date = dateTimeInParts[0]; // "2021-08-31"
    let time = dateTimeInParts[1]; // "15:15:41.886Z"



    let dateString = `${theTodaysDay.getFullYear()} / ${theTodaysDay.getMonth()} / ${theTodaysDay.getDate()}`;
    return dateString;
  }

  //#endregion

  FilterDataWithDateFilter(saleTransHis, dateTypeFilter) {
    debugger;
    if (this.siteConfi.refund == false) {
      $('#btnAbR').prop('disabled', true);
      $('#btnAbR').addClass('button-disable').removeClass('button-secondary');

    }
    if (this.siteConfi.exchangeTransaction == false) {
      $('#btnExchage').prop('disabled', true);
      $('#btnExchage').addClass('button-disable').removeClass('button-secondary');
    }
    let dateType = dateTypeFilter;
    let startDate;
    let endDate;
   
    if (dateType === "TO") {
     
      startDate=  this.datepipe.transform(new Date(),'MM/dd/yyyy');
      endDate = this.datepipe.transform(new Date(),'MM/dd/yyyy');
    }
    else if (dateType === "YE") {
      
      startDate = this.datepipe.transform(DateHelper.Yesterday(),'MM/dd/yyyy');
      endDate = this.datepipe.transform(DateHelper.Yesterday(),'MM/dd/yyyy');
    }
    else if (dateType === "LW") {
    
      startDate = this.datepipe.transform(DateHelper.LastWeekEndDate(),'MM/dd/yyyy');
      endDate = this.datepipe.transform(DateHelper.LastWeekEndDate(),'MM/dd/yyyy');

    }
    else if (dateType === "LM") {
     
      startDate = this.datepipe.transform(DateHelper.LastMonthEndDate(),'MM/dd/yyyy');
      endDate = this.datepipe.transform(DateHelper.LastMonthEndDate(),'MM/dd/yyyy');
    }
    else if (dateType === "CUS") {
      this.customDateField = true;
    
    }

    if (dateType === "") {
      return saleTransHis;
    }
    else {
      if (this.customDate == null) {
        saleTransHis = saleTransHis.filter(function (element, index) {
          let elementDate = new Date(element.date);
         

         if(element.date > startDate)
         {
         }
         else if(element.date < startDate)
           {
 
           }
           else
           {
             return(element.date);
           }
        });
      }

      return saleTransHis;
    }
  }


  EnableCustomDate() {
    debugger;
    let tranDate = this.selectedTranDate || null;
    if (tranDate === "CUS") {
      this.customDateField = true;
    }

  }
  FilterTransactionHistory() {
    debugger;
    let workstationId = this.selectedWorkstation || null;
    let tranType = this.selectedTranType || null;
    let tranDate = this.selectedTranDate || null;
    let custName = this.selectedCustName || null;
    let txId = this.selectedTxId || null;
   
    let startDate;
    let endDate;
    let saleTrans: any = [] = this.saleTransAllData;

    if (workstationId != null) {
      saleTrans = saleTrans.filter(x => x.workStationId == workstationId);
     // saleTrans = saleTrans.filter(x => x.workStationId == 36);
    }
    if (tranType != null) {
      saleTrans = saleTrans.filter(x => x.tranType == tranType);
    }

    if (custName != null) {
      saleTrans = saleTrans.filter(x => x.customerName.toLowerCase().includes(custName.toLowerCase()));
    }
    if (txId != null) {
      saleTrans = saleTrans.filter(x => x.txId.toLowerCase().includes(txId.toLowerCase()));
    }
    if (tranDate === "CUS") {
      debugger
     
      startDate = this.datepipe.transform(this.customDate,'MM/dd/yyyy');
      endDate = this.datepipe.transform(this.customDate,'MM/dd/yyyy');
    
      saleTrans = saleTrans.filter(function (element, index) {
        if(element.date > startDate)
        {
        }
        else if(element.date < startDate)
          {

          }
          else
          {
            return(element.date) ;
          }
  
        });

    
    }


    if (tranDate === "TO") {
      startDate = this.datepipe.transform(new Date(),'MM/dd/yyyy');
      endDate = this.datepipe.transform(new Date(),'MM/dd/yyyy');
      saleTrans = saleTrans.filter(function (element, index) {
        if(element.date > startDate)
        {
        }
        else if(element.date < startDate)
          {

          }
          else
          {
            return(element.date) ;
          }

      });
    }

    else if (tranDate === "YE") {
      
      startDate = this.datepipe.transform(DateHelper.Yesterday(),'MM/dd/yyyy');
      endDate = this.datepipe.transform(DateHelper.Yesterday(),'MM/dd/yyyy');

      saleTrans = saleTrans.filter(function (element, index) {
        if(element.date > startDate)
        {
        }
        else if(element.date < startDate)
          {

          }
          else
          {
            return(element.date) ;
          }

      });
    }
    else if (tranDate === "LW") {

      startDate = this.datepipe.transform(DateHelper.LastWeekStartDate(),'MM/dd/yyyy');
      endDate = this.datepipe.transform(DateHelper.LastWeekEndDate(),'MM/dd/yyyy');
      saleTrans = saleTrans.filter(function (element, index) {
        return (startDate <= element.date && element.date <= endDate);
      });

    }


    else if (tranDate === "LM") {
      startDate = this.datepipe.transform(DateHelper.LastMonthStartDate(),'MM/dd/yyyy');
      endDate = this.datepipe.transform(DateHelper.LastMonthEndDate(),'MM/dd/yyyy');
      saleTrans = saleTrans.filter(function (element, index) {
        return (startDate <= element.date && element.date <= endDate);

      });
    }
    this.saleTrans = saleTrans;

  }

  Resetdropvalue(Dropval) {
    debugger;
    if (Dropval === 'x') {
      location.reload();
    }

  }
  FilterDataWithType(event) {
    debugger;
    this._commonService.GetTransactionForRefund()
      .subscribe((res) => {
        for (let i = 0; i < res.length; i++) {
          res[i].isSelected = false;
        }
        this.saleTrans = res.sort(function (a, b) {
          return b.wsIdTxNo - a.wsIdTxNo;
        });
        if (!(event.target.value === "All")) {
          this.saleTrans = this.saleTrans.filter(function (element, index) {
            return (element.tranType === event.target.value);
          });
        }

      });
  }

  FilterDataBySearch(e, type) {
    debugger
    let field = e.target.value;
    if (type === 'Name') {
      this._commonService.GetTransactionForRefund()
        .subscribe((res) => {
          for (let i = 0; i < res.length; i++) {
            res[i].isSelected = false;
          }
          this.saleTrans = res.sort(function (a, b) {
            return b.wsIdTxNo - a.wsIdTxNo;
          });
          if (field !== "") {
            this.saleTrans = this.saleTrans.filter(function (element, index) {
              return (element.customerName.toLowerCase().includes(field.toLowerCase()));
            });
          }
        });
    } else {
      debugger;
      this._commonService.GetTransactionForRefund()
        .subscribe((res) => {
          for (let i = 0; i < res.length; i++) {
            res[i].isSelected = false;
          }
          this.saleTrans = res.sort(function (a, b) {
            return b.wsIdTxNo - a.wsIdTxNo;
          });
          if (field !== "") {
            this.saleTrans = this.saleTrans.filter(function (element, index) {
              return (element.txId.includes(field));
            });

          }
        });
    }
  }

  ShowandHineBtn(flag: boolean) {
    debugger;
    if (this.siteConfi.refund == false && flag == false) {
      $('#btnAbR').prop('disabled', true);
      $('#btnAbR').addClass('button-disable').removeClass('button-secondary');
      //this.isProductSelected=true
    }
    else {
      this.isProductSelected = false
    }
    if (this.siteConfi.exchangeTransaction == false && flag == false) {
      $('#btnExchage').prop('disabled', true);
      $('#btnExchage').addClass('button-disable').removeClass('button-secondary');
      //this.isProductExchange=true;
    }
    else {
      this.isProductExchange = false
    }
  }

  showTxDetails(e, tx) {
    debugger;
    this.currentUser;
    const checked = e.target.checked;
    localStorage.setItem('BackRefunRecord', JSON.stringify(tx));
    localStorage.setItem('BackRefunRecordEvent', JSON.stringify(e));
    this.CheckReceiptsType();
    if (checked) {
      this.printDisable=false;
      this.isSelectedTransaction = false;
      this.saleTrans.filter(x => x.txId !== tx.txId).filter(x => x.isSelected = false);
      this._commonService.RefundDetailsByTxId(tx.txId, this.currentUser.districtNum)
        .subscribe((res) => {
          if (res != null) {
            debugger;
            this.refundDetailsList = res;
            if (this.refundDetailsList.refundGroupProdList != null && this.refundDetailsList.refundGroupProdList.length > 0) {
              this.refundGroupDetails = res.refundGroupProdList[0];
              this.refundGroupDetails.date = new Date(this.refundGroupDetails.date).toLocaleDateString();
              $('.beforeRefund').addClass('right-soldtablescrl');
            }

            if (this.refundDetailsList.prodList != null && this.refundDetailsList.prodList.length > 0) {
              
              this.refundGroupDetails.productsTotal = this.refundDetailsList.prodList.reduce((a, { amount }) => a + parseFloat(amount), 0);
              this.isProductSelected = false;
              this.ShowandHineBtn(this.isProductSelected);

              this.isProductSelected = true;
              this.refundDetailsList.prodList .forEach(item => {
               if( item.amount!=0){
                this.isProductSelected = false;
               }
              
              });

              // if(this.refundDetailsList.prodList[0].amount==0)
              // {
              //   this.isProductSelected = true;
              // }

            }
            else {
              this.isProductSelected = true;
              this.isProductExchange = true
            }

            if (this.refundDetailsList.refundProdList != null && this.refundDetailsList.refundProdList.length > 0) {
              this.refundDetails.refundProductsTotal = this.refundDetailsList.refundProdList.reduce((a, { amount }) => a + parseFloat(amount), 0);
            }
            debugger;
            this.refundDetails.transactionDetail = this.refundDetailsList.transactionDetail;

            this.isShowTX = true;
            
          var exchangeRefundDetails= this.refundDetailsList.paymentList.filter(x=>x.tenderTypeName=="Exchange Refund");


          if(exchangeRefundDetails.length >0)
          {
            this.isProductSelected = true;
            this.isProductExchange = true;
            this.currentRefundName="Previously Exchanged";
           
           // $('.right-soldtablescrl').removeClass('beforeRefund');
            $('.beforeRefund').addClass('right-soldtablescrl');
          }
          else{
            this.currentRefundName="Previously Refunded";
           
           // $('.right-soldtablescrl').removeClass('beforeRefund');
            $('.beforeRefund').addClass('right-soldtablescrl');
          }
 
       
          }
          else {
            this.isShowTX = false;
            this.isProductSelected = true;
          }
        });
    } else {
      this.isShowTX = false;
      this.isProductSelected = true;
      this.isProductExchange = true;
      this.printDisable=true;
      $('.beforeRefund').removeClass('right-soldtablescrl');
      
    }
      
  }



  BackshowTxDetails(e, tx){
    debugger;
    this.currentUser;
    const checked = e;
    //this.CheckReceiptsType();
    if (checked) {
      $('.beforeRefund').addClass('right-soldtablescrl');
      this.isSelectedTransaction = false;
      this.saleTrans.filter(x => x.txId !== tx.txId).filter(x => x.isSelected = false);
      this._commonService.RefundDetailsByTxId(tx.txId, this.currentUser.districtNum)
        .subscribe((res) => {
          if (res != null) {
            this.refundDetailsList = res;
            if (this.refundDetailsList.refundGroupProdList != null && this.refundDetailsList.refundGroupProdList.length > 0) {
              this.refundGroupDetails = res.refundGroupProdList[0];
              this.refundGroupDetails.date = new Date(this.refundGroupDetails.date).toLocaleDateString();
            }

            if (this.refundDetailsList.prodList != null && this.refundDetailsList.prodList.length > 0) {
              this.refundGroupDetails.productsTotal = this.refundDetailsList.prodList.reduce((a, { amount }) => a + parseFloat(amount), 0);
              this.isProductSelected = false;
              this.ShowandHineBtn(this.isProductSelected);
            }
            else {
              this.isProductSelected = true;
              this.isProductExchange = true
            }

            if (this.refundDetailsList.refundProdList != null && this.refundDetailsList.refundProdList.length > 0) {
              this.refundDetails.refundProductsTotal = this.refundDetailsList.refundProdList.reduce((a, { amount }) => a + parseFloat(amount), 0);
            }

            this.refundDetails.transactionDetail = this.refundDetailsList.transactionDetail;

            this.isShowTX = true;
           
            this.Refund();
            localStorage.removeItem('BackButtonFlag');
          }
          else {
            this.isShowTX = false;
            this.isProductSelected = true;
            
          }
        });
    }
    else {
      $('.beforeRefund').removeClass('right-soldtablescrl');
      this.isShowTX = false;
      this.isProductSelected = true;
      this.isProductExchange = true;
      
    }
   
  }

  CheckPrinter()
  {
    debugger;
    this._shiftService.GetPrinterStatus(this.workstation.workStationId).subscribe((res) => {
      debugger;
      if(res!=3 && res!=0)
      {
      let printerMessage=  this.authenticationService.GetPrinterMessgae(res);
        Swal.fire({
          icon: 'warning',
          title: 'warning!',
          text: printerMessage,
          timer: 10000, // 10 seconds
          timerProgressBar: true,
        })
      }
    });
  }

  CheckReceiptsType()
  {
    debugger;
    this._shiftService.GetReceiptsType(this.workstation.workStationId).subscribe((res) => {
      debugger;
      if(res!= true)
      {
        $('#btnPrintReceipt').prop('disabled', true);  
      }
    });
  }

// Using for checking SelectAll Checkbox when there is only item to refund/exchange
  CheckSelectAllCheckBox()
  {
    if(this.refundDetailsList.refundGroupProdList.length==1)
    {
      this.Allselected = true;
      this.isProdSelect=false;
      this.refundVoucherProdGrpList.forEach(item => {
        item.isSelected = true;
        item.prodList.forEach(prod => {
          prod.isSelected = true;
        });
      });
      this.refundProductsGroupList.forEach(item => {
         item.isSelected = true;
        item.prodList.forEach(prod => {
          prod.isSelected = true;
        });
      });


    }
  }


  //using for select/unselect the SelectAll checkbox on basis of child checkbox click

  SetSelectAllCheckBoxByChild()
  {
    let isNotChecked=0;
    this.refundVoucherProdGrpList.forEach(item => {
      item.prodList.forEach(prod => {
        if(!prod.isSelected)  
        {
          isNotChecked++;
        };
      });
    });

    this.refundProductsGroupList.forEach(item => {
     
     item.prodList.forEach(prod => {
      if(!prod.isSelected)  
        {
          isNotChecked++;
        };
     });
   });

   if(isNotChecked==0)
   {
    return true;
   }
   
  else
  {
    return false;

  }
  

  }


   //using for enable/disable continue button based on checkbox click

   EnableDisbaleContinueButton()
   {
     let isChecked=0;
     this.refundVoucherProdGrpList.forEach(item => {
       item.prodList.forEach(prod => {
        if(prod.isSelected)  
        {
          isChecked++;
        };
       });
     });
 
     this.refundProductsGroupList.forEach(item => {
       
      item.prodList.forEach(prod => {
        if(prod.isSelected)  
        {
          isChecked++;
        };
      });
    });
 
    if(isChecked==0)
   {
    return true;
   }
   
  else
  {
    return false;

  }
   }

   AskForOtherUserCredentials()
{
  Swal.fire({
    text: 'You do not have permission to perform this function. Would you like to enter alternate credentials?',
    icon: 'warning',
    title:'Warning!',
    showCancelButton: true,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No',
  }).then((result) => {
    if (result.value) {
      debugger;
      this.ViewSaleLogin = new FormGroup({
        UserNameLogin1: new FormControl('',Validators.required),
        PasswordLogin1: new FormControl('',Validators.required),
      });
      this.ViewSaleModal = true;
      
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      debugger;
    
    }
  });
}
   RefundWithOtherValidUser()
   {
    if (this.refundDetailsList.refundGroupProdList != 'undefined' && this.refundDetailsList.refundGroupProdList != null) {
      debugger;
      this.isSaleHistory = false;
      this.isSelectItem = true;
      this.selectedOption = 'Refund';
    
      this.refundProductGrouping();
    }
    else if (this.refundDetailsList.transactionDetail != null) {
      this.isSaleHistory = false;
      this.isSelectItem = true;
      this.selectedOption = 'Refund';
      this.refundProductGrouping();
    }
    this.CheckSelectAllCheckBox();
   }
   ExchangeWithOtherValidUser()
   {
    const messages = `<p class='worstation-img'><img src='./assets/Libs/images/confirmation.png'></p> <h3 class='pop-head'>Warning! </h3>All exchanges are final. Products sold in an exchange cannot be refunded or exchanged for another product. Are you sure you would like to continue?</p>`;
    this.confirmationService.confirm({
      message: messages,
      header: 'Confirmation!',
      rejectVisible: true,
      acceptLabel: 'Yes',
      accept: () => {
        debugger;
    this.isSaleHistory = false;
    this.isSelectItem = true;
    this.selectedOption = 'Exchange';
    this.refundProductGrouping();
    this.CheckSelectAllCheckBox();
      },
      reject: () => {
      }
    });
   }
   rePrintTransection()
   {
     debugger;
     const messages = `<p class='worstation-img'><img src='./assets/Libs/images/confirmation.png'></p> <h3 class='pop-head'>Warning! </h3>Re-Print all receipt for selected transection.</br> Would you like to continue?</p>`;
     this.confirmationService.confirm({
       message: messages,
       header: 'Confirmation!',
       rejectVisible: true,
       acceptLabel: 'Yes',
       accept: () => {
         debugger;
    // if Yse then process 
          this._commonService.reprint(this.refundDetails.transactionDetail.txId).subscribe((res) => {

          });
       },
       reject: () => {
        //  on reject logic
       }
     });

   }

  
}
