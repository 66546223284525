<div class="fix-l"></div>
<header class="shad">
    <div class="container-fluid">
        <div class="d-flex justify-content-between align-items-center hdr-styl">
            <div class="tgl-btn">
                <span class="nav-right-text">{{workstationName}}</span>
            </div>
            <div class="logo">
                <img src="./assets/Libs/images/logo-giftbox.png" />
            </div>

            <div class="resp-col text-right">
                <div class="header-notif">
                    <div class="profile" [class.hyperlink]="isdisabledNav">
                        <div class="dropdown">
                            <a href="" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
                                aria-expanded="false">

                                <span class="profile-name" style="text-transform:uppercase;">
                                    {{currentUser.clerkName}}
                                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                                </span>
                            </a>
                            <ul class="dropdown-menu">
                                <!-- <li *ngFor="let item of allLoggedUserList">
                                    <a (click)="SignInSelectedUser(item.UserName)" data-toggle="modal"
                                        [class.hyperlink]="item.UserName=== currentUser.userName"
                                        data-target="#loginModel">
                                        <h6 style="text-transform:capitalize;">{{item.ClerkName}}</h6>
                                    </a>
                                </li> -->

                                <!-- <li class="sign-different">
                                    <a data-toggle="modal" data-target="#loginModel" (click)="SignInSelectedUser('')">
                                        <i class="fa fa-user-circle-o" aria-hidden="true"></i> Sign in with different
                                        account</a>
                                </li> -->
                                <li>
                                    <a (click)="LogOut(1)"> 
                                        <!-- <i class="fa fa-sign-out" aria-hidden="true"></i> -->
                                        <div class="d-flex align-items-center drpdwn-session">
                                            <svg class="mr-3" width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M14.9286 7.85714H14.1429V5.5C14.1429 4.04131 13.5634 2.64236 12.5319 1.61091C11.5005 0.579463 10.1015 0 8.64285 0C7.18416 0 5.78522 0.579463 4.75377 1.61091C3.72232 2.64236 3.14286 4.04131 3.14286 5.5V7.85714H2.35714C1.7322 7.85783 1.13305 8.10639 0.691149 8.54829C0.249248 8.99019 0.000686305 9.58934 0 10.2143V19.6429C0.000686305 20.2678 0.249248 20.8669 0.691149 21.3089C1.13305 21.7508 1.7322 21.9993 2.35714 22H14.9286C15.5535 21.9993 16.1527 21.7508 16.5946 21.3089C17.0365 20.8669 17.285 20.2678 17.2857 19.6429V10.2143C17.285 9.58934 17.0365 8.99019 16.5946 8.54829C16.1527 8.10639 15.5535 7.85783 14.9286 7.85714ZM4.71428 5.5C4.71428 4.45808 5.12819 3.45883 5.86494 2.72208C6.60169 1.98533 7.60093 1.57143 8.64285 1.57143C9.68478 1.57143 10.684 1.98533 11.4208 2.72208C12.1575 3.45883 12.5714 4.45808 12.5714 5.5V7.85714H4.71428V5.5ZM15.7143 19.6429C15.714 19.8512 15.6312 20.0509 15.4839 20.1982C15.3366 20.3455 15.1369 20.4283 14.9286 20.4286H2.35714C2.14883 20.4283 1.94912 20.3455 1.80182 20.1982C1.65452 20.0509 1.57167 19.8512 1.57143 19.6429V10.2143C1.57164 10.006 1.65448 9.80624 1.80179 9.65893C1.94909 9.51163 2.14882 9.42878 2.35714 9.42857H14.9286C15.1369 9.42878 15.3366 9.51163 15.4839 9.65893C15.6312 9.80624 15.7141 10.006 15.7143 10.2143V19.6429Z" fill="black"/>
                                                <path d="M8.65344 12.1787C8.30795 12.1775 7.97179 12.2907 7.69728 12.5005C7.42278 12.7103 7.22533 13.0049 7.13568 13.3386C7.04603 13.6722 7.06921 14.0262 7.20161 14.3453C7.33401 14.6644 7.56821 14.9308 7.86773 15.103V16.8929C7.86773 17.1013 7.95051 17.3012 8.09786 17.4485C8.24521 17.5959 8.44506 17.6787 8.65344 17.6787C8.86183 17.6787 9.06168 17.5959 9.20903 17.4485C9.35638 17.3012 9.43916 17.1013 9.43916 16.8929V15.103C9.73868 14.9308 9.97287 14.6644 10.1053 14.3453C10.2377 14.0262 10.2609 13.6722 10.1712 13.3386C10.0816 13.0049 9.88411 12.7103 9.6096 12.5005C9.3351 12.2907 8.99893 12.1775 8.65344 12.1787Z" fill="black"/>
                                            </svg>
                                           <span>Lock Session</span>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a (click)="CloseShift()" [class.hyperlink]="isdisabledNav">
                                        <!-- <i class="fa fa-power-off" aria-hidden="true"></i> End of Day -->
                                        <div class="d-flex align-items-center drpdwn-session">
                                            <svg class="mr-3" width="34" height="20" viewBox="0 0 34 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16.8244 5.56694C16.4362 5.56694 16.1211 5.25187 16.1211 4.86365V0.703297C16.1211 0.315077 16.4362 0 16.8244 0C17.2126 0 17.5277 0.315077 17.5277 0.703297V4.86365C17.5277 5.25187 17.2126 5.56694 16.8244 5.56694Z" fill="black"/>
                                                <path d="M5.58551 15.6818L1.04744 14.4658C0.623975 14.3523 0.372382 13.9166 0.485849 13.4931C0.599317 13.0696 1.03509 12.818 1.45856 12.9315L5.99662 14.1475C6.42009 14.2609 6.67168 14.6967 6.55822 15.1202C6.44475 15.5437 6.00898 15.7952 5.58551 15.6818Z" fill="black"/>
                                                <path d="M32.7163 14.7398L28.1783 15.9558C27.7548 16.0693 27.319 15.8177 27.2056 15.3942C27.0921 14.9708 27.3437 14.535 27.7671 14.4215L32.3052 13.2055C32.7287 13.0921 33.1645 13.3437 33.2779 13.7671C33.3914 14.1906 33.1398 14.6264 32.7163 14.7398Z" fill="black"/>
                                                <path d="M24.5743 8.77717C24.3943 8.77717 24.2146 8.7086 24.0771 8.5711C23.8024 8.29646 23.8024 7.85128 24.0771 7.57664L27.0186 4.63475C27.2932 4.36011 27.7384 4.36011 28.0131 4.63475C28.2877 4.90939 28.2877 5.35457 28.0131 5.62921L25.0715 8.5711C24.934 8.7086 24.7543 8.77717 24.5743 8.77717Z" fill="black"/>
                                                <path d="M9.07479 8.77717C8.89474 8.77717 8.7147 8.7086 8.57755 8.5711L5.63567 5.62921C5.36103 5.35457 5.36103 4.90939 5.63567 4.63475C5.90995 4.36011 6.35584 4.36011 6.63013 4.63475L9.57202 7.57664C9.84665 7.85128 9.84665 8.29646 9.57202 8.5711C9.43487 8.7086 9.25483 8.77717 9.07479 8.77717Z" fill="black"/>
                                                <path d="M23.6656 20.5637H9.98575C9.73714 20.5637 9.50681 20.4325 9.38021 20.2184C8.59498 18.8905 8.17969 17.3707 8.17969 15.8245C8.17969 11.0572 12.058 7.17889 16.8253 7.17889C21.5926 7.17889 25.4709 11.0572 25.4709 15.8245C25.4709 17.3711 25.0556 18.8905 24.2708 20.2184C24.1445 20.4322 23.9142 20.5637 23.6656 20.5637ZM10.3996 19.1571H23.2517C23.7848 18.131 24.0647 16.9871 24.0647 15.8242C24.0647 11.8326 20.8176 8.58514 16.8257 8.58514C12.8341 8.58514 9.58663 11.8326 9.58663 15.8242C9.58663 16.9867 9.86654 18.131 10.3996 19.1571Z" fill="black"/>
                                                <path d="M33.0902 20.6625H0.910296C0.407813 20.6625 0 20.3032 0 19.8604C0 19.4176 0.407813 19.0583 0.910296 19.0583H33.0897C33.5922 19.0583 34 19.4176 34 19.8604C34 20.3032 33.5926 20.6625 33.0902 20.6625Z" fill="black"/>
                                            </svg> 
                                            <span>End of Day</span>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>

<div *ngIf="router.url != '/Home/ShiftOpening'">
    <section class="l-nav" id="sidenav" *ngIf="router.url != '/Home/ShiftClosing'">

        <div class="navigation">
            <ul id="navUL" [class.hyperlink]="isdisabledNav">
                <li class="management-img limenu2">
                    <a class="transaSec" [class.hyperlink]="isdisabledNav" (click)="transactionType()"
                        data-toggle="collapse" href="#ulmenu1">
                        <span class=""><img src="./assets/Libs/images/retal.png" href="#ulmenu1"
                                height="18"></span>&nbsp;
                    </a>
                </li>

                <li class="management-img liMenu">
                    <a class="salesSec" [class.hyperlink]="isdisabledNav" (click)="ViewSales()">
                        <span class=""><img src="./assets/Libs/images/TransactionHistory.png" height="21"></span>&nbsp;
                    </a>
                </li>
                <li class="management-img liMenu">
                    <a class="cashSec" [class.hyperlink]="isdisabledNav" (click)="CasshManagement()"
                        data-toggle="collapse" href="#submenu1">
                        <span class=""><img src="./assets/Libs/images/Cashmanagement.png" height="16"></span>&nbsp;
                    </a>
                </li>
                <li class="management-img liMenu1">

                    <a class="envSec" [class.hyperlink]="isdisabledNav" (click)="InventoryManagement()"
                        data-toggle="collapse" href="#submenu2">
                        <span class=""><img src="./assets/Libs/images/enventry.png" height="16"></span>&nbsp;
                    </a>
                </li>
            </ul>
        </div>

        <!-- ritik -->
        <div id="sidebarflyout" class="sidebar-flyout d-none">
            <div id="NewTransactionFO">
                <div class="heading"><span>New Transaction</span>
                    <hr>
                </div>
                <ul>
                    <span *ngFor="let txtype of  TXTypeList; let i=index">
                        <li *ngIf="txtype.flag==true" (click)="OpenProductCartPage(txtype.txId)">
                            <a class="tranType{{txtype.txId}}" class="Sub_menu">{{txtype.txtypeName}}</a>
                        </li>
                    </span>
                </ul>
            </div>
        </div>
        <div id="sidebarflyout2" class="sidebar-flyout d-none">
            <div id="CashManagementFO">
                <div class="heading"><span>Cash Management</span>
                    <hr>
                </div>
                <ul>
                    <li *ngIf="viewCashCheck"><a class="viewCashSec" (click)="ViewCash()">View Balance</a></li>
                    <li><a class="addCashSec" (click)="AddCash()">Adjustments</a></li>
                </ul>
            </div>
        </div>
        <div id="sidebarflyout3" class="sidebar-flyout d-none">
            <div id="InventoryFO">
                <div class="heading"><span>Inventory</span>
                    <hr>
                </div>
                <ul>
                    <li *ngIf="siteConfi?.viewInventory==true"><a class="onHandenvSec" (click)="ViewOnHand()">View
                            Balance</a></li>
                    <li *ngIf="siteConfi?.viewInvTrans==true"><a class="viewEnvSec" (click)="ViewTransfer()">View
                            Transfers</a></li>
                    <li *ngIf="siteConfi?.newInvTrans==true"><a class="newEnvSec" (click)="NewTransfer()">New
                            Transfer</a></li>
                    <li><a class="adjEnvSec" (click)="InvAdjustment()">Adjustments</a></li>
                </ul>
            </div>
        </div>
        <!--  -->

    </section>

</div>

<!-- ------------------------------------------------------------------------------------------------------------------------------- -->

<!-- ReLogin Pop up for enterting different credentials to use the disbale functionality for current logged in user -->
<div [style.display]="MySingInModal? 'block' : 'none'" class="modal name-popup" id="imgmodal" tabindex="-1"
    role="dialog" aria-labelledby="myMLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-cstmSign">
        <div class="modal-content signinClass">
            <div class="modal-header SignInHeader">
                <h4 class="modal-title" id="myLabel">Authorization Required</h4>
            </div>
            <div class="modal-body text-right">      
                <form [formGroup]="LoginAdjuctGroup" (ngSubmit)="AdjustLogin()">
                    <div class="form-group">

                        <input type="text" placeholder="Username" formControlName="UserNameLogin" autocomplete="off"
                            class="form-control"
                            [ngClass]="{ 'is-invalid':issubmitted && Login.UserNameLogin.errors }" />
                        <div *ngIf="issubmitted && Login.UserNameLogin.errors" class="invalid-feedback">
                            <div *ngIf="Login.UserNameLogin.errors.required">UserName is required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <input type="password" placeholder="Password" formControlName="PasswordLogin" autocomplete="off"
                            class="form-control"
                            [ngClass]="{ 'is-invalid':issubmitted && Login.PasswordLogin.errors }" />
                        <div *ngIf="issubmitted && Login.PasswordLogin.errors" class="invalid-feedback">
                            <div *ngIf="Login.PasswordLogin.errors.required">Password is required</div>
                        </div>
                    </div>
                    <div class="signX-buttons d-flex justify-content-between align-items-center">
                        <button type="button" class="close btnsignX" data-dismiss="modal"
                            (click)="hide('SignInModal')">Cancel</button>
                        <button type="submit" id='btnSubmit' class="btn btnsignN  form-control">Sign In</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>


<!--Authorization Required(Signin popup)-Start(07-28-24)-->

<!-- <div [style.display]="MySingInModal? 'block' : 'none'" class="modal name-popup" id="imgmodal" tabindex="-1"
    role="dialog" aria-labelledby="myMLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-cstmSign">
        <div class="modal-content signinClass">
            <div class="modal-header SignInHeader">
                <h4 class="modal-title" id="myLabel">Authorization Required</h4>
            </div>
            <div class="modal-body text-right">
                <form [formGroup]="LoginAdjuctGroup" (ngSubmit)="AdjustLogin()">
                    <div class="form-group">
                        <input type="text" placeholder="Username" formControlName="UserNameLogin" class="form-control"
                            [ngClass]="{ 'is-invalid': Login.UserNameLogin.errors }" />
                        <div *ngIf="Login.UserNameLogin.errors" class="invalid-feedback">
                            <div *ngIf="Login.UserNameLogin.errors.required">UserName is required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <input type="password" placeholder="Password" formControlName="PasswordLogin"
                            class="form-control" [ngClass]="{ 'is-invalid': Login.PasswordLogin.errors }" />
                        <div *ngIf="Login.PasswordLogin.errors" class="invalid-feedback">
                            <div *ngIf="Login.PasswordLogin.errors.required">Password is required</div>
                        </div>
                    </div>
                </form>
                <div class="signX-buttons d-flex justify-content-between align-items-center">
                    <button type="button" class="close btnsignX" data-dismiss="modal" (click)="hide('SignInModal')">Cancel</button>
                    <button type="submit" id='btnsubmit' class="btn btnsignN form-control">Sign In</button>
                </div>
            </div>

        </div>
    </div>
</div> -->
<!--Authorization Required(Signin popup)-End-->
<!-- --------------------------------------------------------------------------------------------------------------------------------- -->




<!-- session popup -->

<div [style.display]="SessionModal ? 'block' : 'none'" class="modal name-popup" id="imagemodal" tabindex="-1"
    role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog-session">
        <div class="modal-content cstm-modal-session">
            <div class="modal-body">
                <div class="row">
                    <div class="col-sm-12 d-flex justify-content-center align-items-center">
                        <img src="../../../../assets/Libs/images/confirmation.png" class="sessionTimer">
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 text-center sessionOut-info">
                        <h3>Warning!</h3>
                        <p>You will be signed out in: <br><span class="sessionOut-sec">{{timeLeft}} seconds.</span>
                            <br>
                            Do you want to stay signed in?
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 respnse-button d-flex justify-content-center align-items-center">
                        <button type="submit" (click)="UserStay()" class="btn respnse-yes">Yes</button>
                        <button type="submit" (click)="LogOut('1')" class="btn respnse-no">No</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<!-- Auto EOD popup -->

<div [style.display]="AutoEODModal ? 'block' : 'none'" class="modal name-popup" id="autoeodmodal" tabindex="-1"
    role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div style="width: 650px!important; margin: 1.75rem auto;">
        <div class="modal-content Session">
            <div class="modal-body">
                <div class="row">
                    <div class="col-sm-3">
                        <img src="../../../../assets/Libs/images/warn.PNG" style="width: 100%;">
                    </div>
                    <div class="col-sm-9 text-center">
                        <h4 style="font-size: 23px!important; font-weight: 500!important;">You will be auto log out from
                            this application
                            after {{timeLeft}} seconds.
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div [style.display]="SessionModalExpired ? 'block' : 'none'" class="modal name-popup" id="imagemodal" tabindex="-1"
    role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog-session">
        <div class="modal-content  cstm-modal-session">
            <div class="modal-body">
                <div class="row">
                    <div class="col-sm-12 d-flex justify-content-center align-items-center">
                        <img src="../../../../assets/Libs/images/Timer.png" class="sessionOut-timer">
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 text-center sessionOut-info">
                        <h3>
                            Your Session expired!
                        </h3>
                        <p>Any completed transactions are saved. You will need to sign back in to continue or end your day.
                        </p>
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-sm-12 d-flex justify-content-center align-items-center">
                        <button type="submit" (click)="LogOut('2')" class="btn respnse-cntn">Continue</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- End -->
<p-confirmDialog acceptLabel="Yes" rejectLabel="No" appendTo="body">
    <ng-template pTemplate="body">
        <span class="ui-confirmdialog-message">{{message}}</span>
    </ng-template>

</p-confirmDialog>
<router-outlet></router-outlet>