import { Component, DebugElement, OnInit } from '@angular/core';
import { AuthService } from 'src/app/Services/auth.service';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { IPOSService } from 'src/app/Services/i-pos.service';
import { DataService } from 'src/app/Services/data.service';
import * as $ from 'jquery';
import { first } from 'rxjs/operators';
import { ProductsCartComponent } from './products-cart/products-cart.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AdvancedSetting, CashMamagement, InventoryManagementClass, TransaTypes, TxTypeList } from 'src/app/Models/shift.model';
import { ToastrService } from 'ngx-toastr';
import { ShiftService } from 'src/app/Services/shift.service';
import { InventoryService } from 'src/app/Services/inventory.service';
import { IdleService } from 'src/app/idle.service';
import { CommonService } from 'src/app/Services/common.service';
import { ConfirmationService } from 'primeng/api';

//const MINUTES_UNITL_AUTO_LOGOUT = 15 // in mins
const MINUTES_UNITL_AUTO_LOGOUT = 15 // in mins
const CHECK_INTERVAL = 30000 // in ms
const STORE_KEY = 'lastAction';
export const environment = {
  //idleTimeInMinutes: 15
  idleTimeInMinutes: 15
};
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
  [x: string]: any;
  ClerkName: string;
  workstationName: string;
  currentUser: any;
  allLoggedUserList: any[];
  workstation: any;
  isdisabledNav = true;
  currentShiftNum: any;
  userName: string;
  password: string;
  IsDesibleUsername = false;
  message: any;
  IsNavigationShow: boolean = false;
  siteConfi: any;
  MySingInModal: boolean;
  public routeURL: string;
  LoginAdjuctGroup: FormGroup
  TransaType: number;
  SessionModalExpired: boolean;
  SessionModal: boolean;
  TXTypeList: any
  ClearlocalFlg: boolean;
  count: boolean = false;
  viewCashCheck: boolean = true;
  TxList: Array<TxTypeList> = [];
  transactType = {} as TransaTypes;
  cashManagement = {} as CashMamagement;
  InventoryMngmt = {} as InventoryManagementClass;
  advancsetting = {} as AdvancedSetting;
  returnComponent:string;
  feature:string;
  subFeature:string;
  printerStatus: number = 0;
  issubmitted:boolean=false;
  constructor(private authenticationService: AuthService, public router: Router, private invService: InventoryService, private _shiftService: ShiftService, private route: ActivatedRoute, private posSerive: IPOSService, private idleService: IdleService, private dataService: DataService,private _commonService: CommonService, private toastr: ToastrService, private formBuilder: FormBuilder,private confirmationService: ConfirmationService) {

  }

  ngOnInit() {
    debugger;
    this.currentUser = this.authenticationService.currentUserValue;
    this.workstation = JSON.parse(localStorage.getItem('workstation'));
    this._commonService.GetSiteConfigrationValidated(this.workstation.siteId, this.workstation.districtId)
      .subscribe((res) => {
        localStorage.setItem('siteConfivalidated', JSON.stringify(res));
        this.siteConfi = JSON.parse(localStorage.getItem("siteConfivalidated"));
       // console.log("siteconfig: ", this.siteConfi);
        this.viewCashCheck = this.siteConfi.viewCash;
        //console.log('SettingOnNavigation', JSON.stringify(res));
        this.CheckPrinter();
      })

    this.setallpermisvalue(this.currentUser.permissionDetails);
    this.workstationName = this.workstation.workStationName + ' - ' + this.workstation.workStationId;

    let navDesible = localStorage.getItem('isdisabledNavBar');

    this.allLoggedUserList = []
    this.allLoggedUserList = JSON.parse(localStorage.getItem('loggInUserList'));

    navDesible === 'false' ? this.isdisabledNav = false : this.isdisabledNav = true;

    this.GetTxTypeAndPermissionList(this.workstation.siteId);

    
    this.LoginAdjuctGroup = this.formBuilder.group({
      UserNameLogin: ['', Validators.required],
      PasswordLogin: ['', Validators.required]
    });
    console.log(JSON.parse(localStorage.getItem("OutSessionShift")));

    if (JSON.parse(localStorage.getItem("OutSessionShift")) == true) {
      // this.check();
      // this.initListener();
      // this.initInterval();
      // localStorage.setItem(STORE_KEY, Date.now().toString());
      this.initialIdleSettings();
    }
    const isEOD = Boolean(sessionStorage.getItem('isEOD'));
    if(isEOD){
      sessionStorage.setItem('isEOD',JSON.stringify(false));
      this.AutoEOD();
    }
  }

  //Save Shift On Browser Close
  SaveShiftOnBrowserClose() {
    debugger;
    this.posSerive.CLoseShiftOnBrowser(this.currentShiftNum, this.workstation.workStationId)
      .subscribe((res) => {
      });
  }

  private initialIdleSettings() {
    const idleTimeoutInSeconds: number = environment.idleTimeInMinutes * 60;
    this.idleService.startWatching(idleTimeoutInSeconds).subscribe((isTimeOut: boolean) => {
      if (isTimeOut) {
        debugger;
        if (this.count == false) {
          this.SessionTimeOutModalPoppup(1);
          this.count = true;
        }
      }
    });
  }


  check() {
    debugger;
    if (JSON.parse(localStorage.getItem("ShiftStartFlag"))) {
      const now = Date.now();
      const timeleft = this.getLastAction() + MINUTES_UNITL_AUTO_LOGOUT * 15 * 1000;
      const diff = timeleft - now;
      const isTimeout = diff < 0;
      //alert("your session time out countdown (diff)")
      if (isTimeout) {
        debugger;
        if (this.count == false) {
          this.SessionTimeOutModalPoppup(1);
          this.count = true;
        }
      }
    }

  }
  public getLastAction() {
    return parseInt(localStorage.getItem(STORE_KEY));
  }
  // Get last  Action.
  public setLastAction(lastAction: number) {
    localStorage.setItem(STORE_KEY, lastAction.toString());
  }
  initListener() {
    debugger;
    if (JSON.parse(localStorage.getItem("ShiftStartFlag"))) {
      document.body.addEventListener('click', () => this.reset());
      document.body.addEventListener('mouseover', () => this.reset());
      document.body.addEventListener('mouseout', () => this.reset());
      document.body.addEventListener('keydown', () => this.reset());
      document.body.addEventListener('keyup', () => this.reset());
      document.body.addEventListener('keypress', () => this.reset());
    }
  }
  reset() {

    this.setLastAction(Date.now());
  }
  initInterval() {
    debugger;
    if (JSON.parse(localStorage.getItem("ShiftStartFlag"))) {
      setInterval(() => {
        this.check();
      }, CHECK_INTERVAL);
    }
  }

  AdjustLogin() {
    debugger;
    this.issubmitted=true;
    if (this.LoginAdjuctGroup.invalid) {
      return;
    }
    console.log(this.LoginAdjuctGroup.value);
    let GetGUIDchrowm = localStorage.getItem("MacAddress");
    let decodeGUID=atob(GetGUIDchrowm)
    let userObj = {
      UserName: this.LoginAdjuctGroup.controls.UserNameLogin.value,
      Password: this.LoginAdjuctGroup.controls.PasswordLogin.value,
      GUID: decodeGUID,
      Feature:this.feature,
      SubFeature:this.subFeature
    }
    this.authenticationService.UserLoginForOtherPermissions(userObj)
    .pipe(first())
    .subscribe((res) => {
          debugger;
          this.MySingInModal = false;
          if(res.hasAccess){
            this. CheckPrinter();
            this.PrinterSwal(this.printerStatus);
          this.router.navigate(['../Home/'+this.returnComponent+''], { relativeTo: this.route });  
        }     
        else{
          this.NavValidationModalPopup();
        }    
        this.issubmitted=false;
        },
        error => {
          debugger;
          this.errormsg = error.error.message;
          this.toastr.error(this.errormsg);
          if (this.errormsg == 'Not Use This Workstation') {
            this.toastr.error("This workstation is not access to you, please enter the different credentials");
          };
        });
  }


  get Login() { 
    return this.LoginAdjuctGroup.controls; }

  AddRemoveClass() {
    $('body').toggleClass("ToggelCalss");
  }

  NavSubMenuHide() {
    $('#submenu1').removeClass('show');
    $('#submenu2').removeClass('show');
  }

  setallpermisvalue(details: any[]) {
    debugger;
    for (var detls of details) {
      if (detls.featureName === 'Transaction Types') {
        if (detls.subFeatureName === 'Retail') {
          this.transactType.retails = 1
        }
        else if (detls.subFeatureName === 'Pregifting') {
          this.transactType.preGifting = 1;
        }
        else if (detls.subFeatureName === 'Gifting') {
          this.transactType.Gifting = 1;
        }
        else if (detls.subFeatureName === 'Open Gifting') {
          this.transactType.openGifting = 1;
        }

      }
      else if (detls.featureName === 'Cash Management') {
        if (detls.subFeatureName === 'View Cash') {
          this.cashManagement.viewCash = 1
        }
        else if (detls.subFeatureName === 'Add Cash') {
          this.cashManagement.addCash = 1;
        }
        else if (detls.subFeatureName === 'Remove Cash') {
          this.cashManagement.removeCash = 1;
        }
        else if (detls.subFeatureName === 'Open Cash Drawer') {
          this.cashManagement.OepnCashDrawer = 1;
        }
      }
      else if (detls.featureName === 'Inventory Management') {
        if (detls.subFeatureName === 'View Inventory') {
          this.InventoryMngmt.viewInventory = 1
        }
        else if (detls.subFeatureName === 'Add Inventory') {
          this.InventoryMngmt.addInventory = 1;
        }
        else if (detls.subFeatureName === 'Remove Inventory') {
          this.InventoryMngmt.removeInventory = 1;
        }
        else if (detls.subFeatureName === 'View Transfer') {
          this.InventoryMngmt.viewTransafer = 1;
        }
        else if (detls.subFeatureName === 'Accept Transfer') {
          this.InventoryMngmt.acceptTransfer = 1;
        }
        else if (detls.subFeatureName === 'New Transfer') {
          this.InventoryMngmt.newTransafer = 1;
        }
      }
      else if (detls.featureName === 'Advanced Settings') {
        if (detls.subFeatureName === 'Apply Discount') {
          this.advancsetting.applyDiscount = 1
        }
        else if (detls.subFeatureName === 'Refund Transactions') {
          this.advancsetting.refundTransaction = 1;
        }
        else if (detls.subFeatureName === 'Edit Refund Amounts') {
          this.advancsetting.editRefundTran = 1;
        }
        else if (detls.subFeatureName === 'Tour Deposit') {
          this.advancsetting.tourDeposit = 1;
        }
        else if (detls.subFeatureName === 'Exchange Transactions') {
          this.advancsetting.exchangeTransactions = 1;
        }
        else if (detls.subFeatureName === 'Force Close Shift') {
          this.advancsetting.forceCloseShift = 1;
        }
      }
    }
    localStorage.setItem('UsertransactType', JSON.stringify(this.transactType));
    localStorage.setItem('UsercashManagement', JSON.stringify(this.cashManagement));
    localStorage.setItem('UserInventoryMngmt', JSON.stringify(this.InventoryMngmt));
    localStorage.setItem('Useradvancsetting', JSON.stringify(this.advancsetting));
  }

  GetTxTypeAndPermissionList(siteId: number) {
    debugger;
    this._shiftService.GetTxTypeListData(siteId).subscribe((res) => {
      debugger;
      this.TXTypeList = res;

      localStorage.setItem('TxTypesList', JSON.stringify(this.TXTypeList));
    });
  }

  OpenProductCartPage(tratype: number) {
    debugger;
    $("#sidebarflyout").addClass("d-none").removeClass("d-block").animate({ width: '0px', 'opacity': 0, transition: 'all .5s' }, "slow");
    $("#sidebarflyout2").addClass("d-none").removeClass("d-block").animate({ width: '0px', 'opacity': 0, transition: 'all .5s' }, "slow");
    $("#sidebarflyout3").addClass("d-none").removeClass("d-block").animate({ width: '0px', 'opacity': 0, transition: 'all .5s' }, "slow");
    $(".navigation ul li a").removeClass("active");
    $(".transaSec").addClass("active");
    this.CheckPrinter();
    if (tratype === 1 && this.transactType.retails === 1) {
      this.TransaType = 1;
      this.router.navigate(['./ProductCart'], { relativeTo: this.route });
      this.PrinterSwal(this.printerStatus)
      $(".tranType1").addClass("active");
    }
    else if (tratype === 2 && this.transactType.preGifting === 1) {
      this.TransaType = 2;
      this.router.navigate(['./PregiftingCardProduct'], { relativeTo: this.route });
      this.PrinterSwal(this.printerStatus)
      $(".tranType2").addClass("active");
    }
    else if (tratype === 3 && this.transactType.Gifting === 1) {
      this.TransaType = 3;
      this.router.navigate(['./GiftingCardProduct'], { relativeTo: this.route });
      this.PrinterSwal(this.printerStatus)
      $(".tranType3").addClass("active");
    }
    else if (tratype === 4 && this.transactType.openGifting === 1) {
      this.TransaType = 4;
      this.router.navigate(['./OpenGiftingCardProduct'], { relativeTo: this.route });
      this.PrinterSwal(this.printerStatus)
      $(".tranType4").addClass("active");
    }
    else {
    this.feature='Transaction Types'
      if(tratype==1){
        this.returnComponent='ProductCart';
        this.subFeature='Retail'
      }
      if(tratype==2)
      {
        this.returnComponent='PregiftingCardProduct';
        this.subFeature='Pregifting'
      }
      if(tratype==3)
      {
        this.returnComponent='GiftingCardProduct';
        this.subFeature='Gifting'
      }
      if(tratype==4)
      {
         this.returnComponent='OpenGiftingCardProduct';
         this.subFeature='Open Gifting'
      }
       
      this.NavValidationModalPopup();
    }
    localStorage.setItem('Transtype', JSON.stringify(this.TransaType));
    
  }

  AutoEOD() {
    debugger;
    console.log('autoEOD')
    const siteConfi = JSON.parse(localStorage.getItem("siteConfivalidated"));
    const localAutoEODDateTime = this.convertUTCToLocal(siteConfi.autoEODTime + 'Z');
    const localDateTime = new Date();
    const milisec = localAutoEODDateTime.getTime() - localDateTime.getTime();
    if(localAutoEODDateTime>localDateTime)
  {
      
   // const logoutTime = 120000;
    const logoutTime = milisec;
    const popupTime = 60000*10;

    const logoutTimeout = setTimeout(() => {
      this.LogOut('1');
      Swal.close();
      this.router.navigate(['../LoginComponent'], { relativeTo: this.route });
    }, logoutTime);

    const popupTimeout = setTimeout(() => {
     const countdown = (logoutTime - popupTime)/60000;
     const countdownnew=Math.round(milisec/1000);
     //const countdown = (1);
      // Swal.fire({
      //   icon: 'warning',
      //   title: 'warning!',
      //   text: 'You will be logout from the application after '+ countdown + ' minute because of Auto EOD.',
      // })
      this.toastr.info('You will automatically logout after '+ countdownnew + ' seconds.');
    }, logoutTime - popupTime);
  }
  }

  convertUTCToLocal(date) {
    const dates = new Date(date);
    return dates;
  }

  ViewSales() {
    debugger
    $(".navigation ul li a").removeClass("active");
    $(".salesSec").addClass("active");
    $("#sidebarflyout").addClass("d-none").removeClass("d-block").animate({ width: '0px', 'opacity': 0, transition: 'all .5s' }, "slow");
    $("#sidebarflyout2").addClass("d-none").removeClass("d-block").animate({ width: '0px', 'opacity': 0, transition: 'all .5s' }, "slow");
    $("#sidebarflyout3").addClass("d-none").removeClass("d-block").animate({ width: '0px', 'opacity': 0, transition: 'all .5s' }, "slow");

    this.router.navigate(['./SalesHistory'], { relativeTo: this.route });
  }

  ViewCash() {
    debugger;
    $("#sidebarflyout").addClass("d-none").removeClass("d-block").animate({ width: '0px', 'opacity': 0, transition: 'all .5s' }, "slow");
    $("#sidebarflyout2").addClass("d-none").removeClass("d-block").animate({ width: '0px', 'opacity': 0, transition: 'all .5s' }, "slow");
    $("#sidebarflyout3").addClass("d-none").removeClass("d-block").animate({ width: '0px', 'opacity': 0, transition: 'all .5s' }, "slow");
    if (this.cashManagement.viewCash === 1) {
      this.router.navigate(['./VewCash'], { relativeTo: this.route });
      // $(".navigation ul li a ").removeClass("active");
      // $(".cashSec").addClass("active");
      // $("ul li .viewCashSec ").addClass("active");
      // $('#submenu1').addToggle('show');
    }
    else {
       this.feature='Cash Management';
       this.subFeature='View Cash';
       this.returnComponent='VewCash'
      this.NavValidationModalPopup();
    }
  }

  AddCash() {
    $("#sidebarflyout").addClass("d-none").removeClass("d-block").animate({ width: '0px', 'opacity': 0, transition: 'all .5s' }, "slow");
    $("#sidebarflyout2").addClass("d-none").removeClass("d-block").animate({ width: '0px', 'opacity': 0, transition: 'all .5s' }, "slow");
    $("#sidebarflyout3").addClass("d-none").removeClass("d-block").animate({ width: '0px', 'opacity': 0, transition: 'all .5s' }, "slow");
    this.router.navigate(['./AddCash'], { relativeTo: this.route });
    // $(".navigation ul li a").removeClass("active");
    $(".cashSec").addClass("active");
    $(".addCashSec").addClass("active");
  }
  RemoveCash() {
    this.router.navigate(['./RemoveCash'], { relativeTo: this.route });
    $(".navigation ul li a").removeClass("active");
    $(".cashSec").addClass("active");
    $(".removeCashSec").addClass("active");
  }

  ViewOnHand() {
    $("#sidebarflyout").addClass("d-none").removeClass("d-block").animate({ width: '0px', 'opacity': 0, transition: 'all .5s' }, "slow");
    $("#sidebarflyout2").addClass("d-none").removeClass("d-block").animate({ width: '0px', 'opacity': 0, transition: 'all .5s' }, "slow");
    $("#sidebarflyout3").addClass("d-none").removeClass("d-block").animate({ width: '0px', 'opacity': 0, transition: 'all .5s' }, "slow");
    debugger;
    if (this.InventoryMngmt.viewInventory === 1) {
      this.router.navigate(['./ViewOnHand'], { relativeTo: this.route });
      // $(".navigation ul li a").removeClass("active");
      $(".envSec").addClass("active");
      $(".onHandenvSec").addClass("active");
    }
    else {
      this.feature='Inventory Management';
      this.subFeature='View Inventory';
      this.returnComponent='ViewOnHand';
      this.NavValidationModalPopup();
    }

  }

  ViewTransfer() {
    $("#sidebarflyout").addClass("d-none").removeClass("d-block").animate({ width: '0px', 'opacity': 0, transition: 'all .5s' }, "slow");
    $("#sidebarflyout2").addClass("d-none").removeClass("d-block").animate({ width: '0px', 'opacity': 0, transition: 'all .5s' }, "slow");
    $("#sidebarflyout3").addClass("d-none").removeClass("d-block").animate({ width: '0px', 'opacity': 0, transition: 'all .5s' }, "slow");
    if (this.InventoryMngmt.viewTransafer === 1) {
      this.router.navigate(['./ViewTransfer'], { relativeTo: this.route });
      // $(".navigation ul li a").removeClass("active");
      $(".envSec").addClass("active");
      $(".viewEnvSec").addClass("active");
    }
    else {
      this.feature='Inventory Management';
      this.subFeature='View Transfer';
      this.returnComponent='ViewTransfer';
      this.NavValidationModalPopup();
    }

  }
  NewTransfer() {
    $("#sidebarflyout").addClass("d-none").removeClass("d-block").animate({ width: '0px', 'opacity': 0, transition: 'all .5s' }, "slow");
    $("#sidebarflyout2").addClass("d-none").removeClass("d-block").animate({ width: '0px', 'opacity': 0, transition: 'all .5s' }, "slow");
    $("#sidebarflyout3").addClass("d-none").removeClass("d-block").animate({ width: '0px', 'opacity': 0, transition: 'all .5s' }, "slow");
    // if (this.InventoryMngmt.newTransafer === 1) {
    //   this.router.navigate(['./NewTransfer'], { relativeTo: this.route });
    //   // $(".navigation ul li a").removeClass("active");
    //   $(".envSec").addClass("active");
    //   $(".newEnvSec").addClass("active");
    // }
    // else {
    //   this.feature='Inventory Management';
    //   this.subFeature='New Transfer';
    //   this.returnComponent='NewTransfer';
    //   this.NavValidationModalPopup();
    // }

    this.router.navigate(['./NewTransfer'], { relativeTo: this.route });
    // $(".navigation ul li a").removeClass("active");
    $(".envSec").addClass("active");
    $(".newEnvSec").addClass("active");
  }

  InvAdjustment() {
    $("#sidebarflyout").addClass("d-none").removeClass("d-block").animate({ width: '0px', 'opacity': 0, transition: 'all .5s' }, "slow");
    $("#sidebarflyout2").addClass("d-none").removeClass("d-block").animate({ width: '0px', 'opacity': 0, transition: 'all .5s' }, "slow");
    $("#sidebarflyout3").addClass("d-none").removeClass("d-block").animate({ width: '0px', 'opacity': 0, transition: 'all .5s' }, "slow");

    this.router.navigate(['./InventoryAdjust'], { relativeTo: this.route });
    // $(".navigation ul li a").removeClass("active");
    $(".envSec").addClass("active");
    $(".adjEnvSec").addClass("active");
  }

  CloseShift() {

    this.CheckPrinter();
    debugger
    this.invService.GetViewTransfer(this.workstation.workStationId)
      .subscribe((viewInv) => {
        console.log('viewInv', viewInv);
        const pendingView = viewInv.filter(x => x.status == 'PENDING' && x.transferFromWsid == this.workstation.workStationId);
        if (pendingView.length > 0) {
          //this.isPendingView = true;
          this.router.navigate(['./ViewTransfer'], { relativeTo: this.route });
          this.toastr.error("You have pending transfers that must be received or canceled before proceeding to End of Day.");
        }
        else {
          this._shiftService.GetCurrentShiftTotals(this.workstation.workStationId + "-" + (Number(this.workstation.lastShiftNum + 1)), this.workstation.workStationId)
            .subscribe((currentShiftTotalRes) => {
              this.currentShiftTotals = Number(currentShiftTotalRes);
              if (this.currentShiftTotals >= 0) {
                localStorage.setItem('true', 'IsNavigationShow');
                $(".navigation ul li a").removeClass("active");
                $("ul.submenu").removeClass("show");
                $(".closeSec").addClass("active");
                if (localStorage.getItem('shiftPrimaryUser') === this.currentUser.userName) {
                  this.router.navigate(['./ShiftClosing'], { relativeTo: this.route });
                } else {
                  Swal.fire({
                    icon: 'warning',
                    title: 'Warning!',
                    text: 'Only primary user can close the shift!'
                  });
                }
              }
              else {
                this.toastr.error("Cannot close shift with a negative cash balance.");
              }

            });
        }
      });
  }
  hide(signclose: string) {
    debugger;
    if (signclose === 'SignInModal') {
      this.MySingInModal = false;
      this.issubmitted=false;
    }
  }
  CloseShiftAlert() {
    debugger;
    if (localStorage.getItem('shiftPrimaryUser') === this.currentUser.userName) {
      Swal.fire({
        icon: 'warning',
        title: 'Warning!',
        text: 'You need to close the shift before logout!'
      });
      this.router.navigate(['./ShiftClosing'], { relativeTo: this.route });
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Warning!',
        text: 'Only primary user can close the shift!'
      });
    }

  }

  transactionType() {
    var display = $("#sidebarflyout").prop('class');
    if (display == "sidebar-flyout d-none") {
      $("#sidebarflyout").animate({ width: '250px', 'opacity': 1, transition: 'all .5s' }, "slow");
      $("#sidebarflyout").removeClass("d-none").addClass("d-block").animate({ 'opacity': 1, transition: 'all .5s' }, "slow");
      $("#sidebarflyout3, #sidebarflyout2").removeClass("d-block").addClass("d-none").animate({ 'opacity': 0, transition: 'all .5s' }, "slow").hide(2000);
      $("#sidebarflyout3, #sidebarflyout2").animate({ width: '0px', 'opacity': 0, transition: 'all .5s' }, "slow").hide(2000);
      $("#NewTransactionFO").removeClass("d-none").addClass("d-block").animate({ 'opacity': 1, transition: 'all .5s' }, "slow");
      $(".sidebar-flyout .heading,.sidebar-flyout ul").animate({ width: '250px', 'opacity': 1, transition: 'all .5s' }, "slow");
    }
    else {
      $("#sidebarflyout").animate({ width: '0px', 'opacity': 0, transition: 'all .5s' }, "slow").hide(2000);;
      $(".sidebar-flyout .heading,.sidebar-flyout ul").animate({ width: '0px', 'opacity': 0, transition: 'all .5s' }, "slow");
      $("#sidebarflyout").removeClass("d-block").addClass("d-none").animate({ 'opacity': 0, transition: 'all .5s' }, "slow").hide(2000);
    }


  }

  CasshManagement() {
    var display = $("#sidebarflyout2").prop('class');
    if (display == "sidebar-flyout d-none") {
      $("#sidebarflyout2").animate({ width: '250px', 'opacity': 1, transition: 'all .5s' }, "slow");
      $("#sidebarflyout2").removeClass("d-none").addClass("d-block").animate({ 'opacity': 1, transition: 'all .5s' }, "slow");
      $("#sidebarflyout3, #sidebarflyout").removeClass("d-block").addClass("d-none").animate({ 'opacity': 0, transition: 'all .5s' }, "slow").hide(2000);
      $("#sidebarflyout3, #sidebarflyout").animate({ width: '0px', 'opacity': 0, transition: 'all .5s' }, "slow").hide(2000);;
      $("#CashManagementFO").removeClass("d-none").addClass("d-block").animate({ 'opacity': 1, transition: 'all .5s' }, "slow");
      $(".sidebar-flyout .heading,.sidebar-flyout ul").animate({ width: '250px', 'opacity': 1, transition: 'all .5s' }, "slow");
    }
    else {
      $("#sidebarflyout2").animate({ width: '0px', 'opacity': 0, transition: 'all .5s' }, "slow").hide(2000);;
      $(".sidebar-flyout .heading,.sidebar-flyout ul").animate({ width: '0px', 'opacity': 0, transition: 'all .5s' }, "slow");
      $("#sidebarflyout2").removeClass("d-block").addClass("d-none").animate({ 'opacity': 0, transition: 'all .5s' }, "slow").hide(2000);
    }

  }

  InventoryManagement() {
    var display = $("#sidebarflyout3").prop('class');
    if (display == "sidebar-flyout d-none") {
      $("#sidebarflyout3").animate({ width: '250px', 'opacity': 1, transition: 'all .5s' }, "slow");
      $("#sidebarflyout3").removeClass("d-none").addClass("d-block").animate({ 'opacity': 1, transition: 'all .5s' }, "slow");
      $("#sidebarflyout2, #sidebarflyout").removeClass("d-block").addClass("d-none").animate({ 'opacity': 0, transition: 'all .5s' }, "slow").hide(2000);
      $("#sidebarflyout2, #sidebarflyout").animate({ width: '0px', 'opacity': 0, transition: 'all .5s' }, "slow").hide(2000);
      $("#InventoryFO").removeClass("d-none").addClass("d-block").animate({ 'opacity': 1, transition: 'all .5s' }, "slow");
      $(".sidebar-flyout .heading,.sidebar-flyout ul").animate({ width: '250px', 'opacity': 1, transition: 'all .5s' }, "slow");
    }
    else {
      $("#sidebarflyout3").animate({ width: '0px', 'opacity': 0, transition: 'all .5s' }, "slow").hide(2000);
      $(".sidebar-flyout .heading,.sidebar-flyout ul").animate({ width: '0px', 'opacity': 0, transition: 'all .5s' }, "slow");
      $("#sidebarflyout3").removeClass("d-block").addClass("d-none").animate({ 'opacity': 0, transition: 'all .5s' }, "slow").hide(2000);
    }


  }


  onSubmit() {
    const userObj = {
      UserName: this.userName,
      Password: this.password
    }

    this.authenticationService.userLogin(userObj)
      .pipe(first())
      .subscribe(
        data => {
          window.location.reload();
        },
        error => {
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: 'Something Went Wrong!',
          })
        });
  }
  UserStay() {
    window.location.reload();
  }
  LogOut(num) {
    debugger
    this.ClearlocalFlg = true;
    if (num == '1') {
      //this.SessionClearKeyValues();
      this.ClearlocalFlg = false;
      localStorage.setItem('ShiftStartFlag', JSON.stringify(false));
      localStorage.setItem('OutSessionShift', JSON.stringify(false));
      this.router.navigate(['../LoginComponent'], { relativeTo: this.route });

    }
    else {
      this.SessionClearKeyValues();
      this.ClearlocalFlg = false;
      localStorage.setItem('ShiftStartFlag', JSON.stringify(false))
      localStorage.setItem('OutSessionShift', JSON.stringify(false));
      this.router.navigate(['../LoginComponent'], { relativeTo: this.route });
    }
  }

  SessionClearKeyValues() {
    debugger
    if (this.ClearlocalFlg == true) {
      localStorage.removeItem('siteConfivalidated');
      localStorage.removeItem('SiteId');
      localStorage.removeItem('shiftPrimaryUser');
      localStorage.removeItem('lastAction');
      localStorage.removeItem('UserInventoryMngmt');
      localStorage.removeItem('DistrictId');
      localStorage.removeItem('IsNavigationShow');
      localStorage.removeItem('WsId');
      localStorage.removeItem('Useradvancsetting');
      localStorage.removeItem('loggInUserList');
      localStorage.removeItem('UsertransactType');
      localStorage.removeItem('workstation');
      localStorage.removeItem('isdisabledNavBar');
      localStorage.removeItem('currentUser');
      localStorage.removeItem('UsercashManagement');
      localStorage.removeItem('Transtype');
      localStorage.removeItem('StartSessionOutShift');
      localStorage.removeItem('viewTransferRecord');
    }
  }


  Nav(seconds: any): number {
    this.timeLeft = seconds
    this.ClearlocalFlg = true;
    this.timerInterval = setInterval(() => {
      if (this.ClearlocalFlg) {
        if (this.timeLeft > 0) {
          this.timeLeft--;
        }
        else {
          this.wsId = this.workstation.workStationId;
          this.SessionClearKeyValues();
          this.SessionModal = false;
          this.SessionModalExpired = true;
          this.ClearlocalFlg = false;

        }
      }
    }, 1000)
    console.log(seconds)
    return this.timeLeft;

  }
  SessionTimeOutModalPoppup(minute) {
    debugger;
    this.showModal = false;
    this.SessionModal = true;
    let seconds: number = minute * 60;
    this.Nav(seconds);

  }

  NavValidationModalPopup() {
    Swal.fire({
      text: 'You do not have permission to perform this function. Would you like to enter alternate credentials?​',
      icon: 'warning',
      title:'Warning!',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        debugger;
        this.LoginAdjuctGroup = this.formBuilder.group({
          UserNameLogin: ['', Validators.required],
          PasswordLogin: ['', Validators.required]
        });
        this.MySingInModal = true;
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        debugger;
       // window.location.reload();
      }
    });
  }

  CheckPrinter() {
    this._shiftService.GetPrinterStatus(this.workstation.workStationId).subscribe((res) => {
      debugger;
      this.printerStatus = res;
    });
  }

PrinterSwal(printerStatusCode:number)
{
  if (printerStatusCode != 3 && printerStatusCode != 0) {
    let printerMessage = this.authenticationService.GetPrinterMessgae(printerStatusCode);
    Swal.fire({
      icon: 'warning',
      title: 'warning!',
      text: printerMessage,
      timer: 10000, // 10 seconds
      timerProgressBar: true,
    })

    
  }
}


  CheckPrimaryTrasactionType(type: number) {
    debugger;
    if (type === 1) {
      localStorage.setItem('Transtype', JSON.stringify(type));
      this.router.navigate(['../Home/ProductCart'], { relativeTo: this.route });     
    }
    else if (type === 2) {
      localStorage.setItem('Transtype', JSON.stringify(type));
      this.router.navigate(['../Home/PregiftingCardProduct'], { relativeTo: this.route });
    }
    else if (type === 3) {
      localStorage.setItem('Transtype', JSON.stringify(type));
      this.router.navigate(['../Home/GiftingCardProduct'], { relativeTo: this.route });
      
    }
    else if (type === 4) {
      localStorage.setItem('Transtype', JSON.stringify(type));
      this.router.navigate(['../Home/OpenGiftingCardProduct'], { relativeTo: this.route });
      
    }

  }

  SignInSelectedUser(selectedUser) {
    if (selectedUser === '' || selectedUser === undefined) {
      this.IsDesibleUsername = false;
      this.userName = '';
    } else {
      this.IsDesibleUsername = true;
      this.userName = selectedUser;
    }
  }

   Test(event: any){
    debugger;
    let url = new URL(window.location.href);
    let newurl=window.location.href;
    if(newurl.includes('InventoryAdjust'))
    {
    const messages = `<p class='worstation-img'><img src="./assets/Libs/images/confirmation.png" /></p> <Div class="saveInvtAdj"><p class="assignstyle">Would you like abandon your entries?</p></Div> `;
    this.confirmationService.confirm({
      message: messages,
      header: 'Warning!',
      rejectVisible: true,
      acceptLabel: 'Yes',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        
      },
      reject: () => {
        event.stopPropagation();
      }
    });
     
    }
    if(newurl.includes('NewTransfer'))
      {
        
      }
      else{
       
      }
  
    
   }



 
  
}


$('.dropdown-menu a.dropdown-toggle').on('click', function (e) {
  debugger;
  if (!$(this).next().hasClass('show')) {
    $(this).parents('.dropdown-menu').first().find('.show').removeClass('show');
  }
  var $subMenu = $(this).next('.dropdown-menu');
  $subMenu.toggleClass('show');


  $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function (e) {
    $('.dropdown-submenu .show').removeClass('show');
  });


  return false;

});
$('#sidenav').on('click', function (e) {
  debugger;
alert('Hi');
});


// end

//===========================added=============================
