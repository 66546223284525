<!-- <div class="login-container" style="background: url(./assets/Libs/images/login-background.png);"> -->
    <div class="login-container" style="background-color: #0082D2;">
        <div class="container" style="height: 95vh;">
            <div class="row h-100 align-items-center">
                <div class="col-sm-12">
                    <div class="mb-4 text-center">
                        <img src="./assets/Libs/images/logo-giftbox.png" alt="" class="logo-size">
                         <!-- <h4 class="fgt-pswd-txt">Forgot Password</h4> -->
                    </div>
                    <div  class="login-box">
                        <div class="reset-pswdTxt text-center">Forgot Password</div>
                        <form [formGroup]="ForgotPassword" (ngSubmit)="ResetPassword()">
                            <div class="form-box mb-4">
                                <div class="grp-style  d-flex align-items-center">
                                    <div style="width: 10%;">
                                        <img src="./assets/Libs/images/user-icon-2.png" class="icon-size" />
                                    </div>
                                    <fieldset class="form-group w-100 mb-0">
                                        <input class="input-rk" type="text" autocomplete="off" readonly
                                            onfocus="this.removeAttribute('readonly');" formControlName="username1"
                                            [ngClass]="{'is-invalid': submitted && f.username1.errors }"
                                            placeholder="Username">
                                        <div *ngIf="submitted && f.username1.errors" class="invalid-feedback">
                                            <div *ngIf="f.username1.errors.required">Username is required</div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                            <div class="form-box mb-4">
                                <div class="grp-style d-flex align-items-center">
                                    <div style="width: 10%;">
                                        <!-- <img src="./assets/Libs/images/.png" class="icon-size" /> -->
                                        <!-- <svg width="31" height="24" viewBox="0 0 31 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M30.6735 3.25343C30.5457 2.60758 30.2576 1.99717 29.8372 1.48399C29.7518 1.37625 29.6625 1.2804 29.5648 1.18317C28.8169 0.431264 27.7765 0 26.7103 0H4.03843C2.96036 0 1.94643 0.420394 1.18341 1.18359C1.08684 1.27992 0.997295 1.37673 0.908652 1.48789C0.49006 1.99891 0.203171 2.60849 0.0784336 3.25523C0.0263047 3.50921 0 3.77238 0 4.03861V19.9618C0 20.5157 0.112546 21.0551 0.335655 21.568C0.528736 22.0234 0.821691 22.4551 1.18317 22.8164C1.27422 22.907 1.36466 22.9906 1.46123 23.0721C2.18395 23.6707 3.09909 24 4.03843 24H26.7103C27.6557 24 28.5699 23.6694 29.2907 23.0655C29.3871 22.9876 29.4764 22.9057 29.5657 22.8164C29.9151 22.4674 30.1928 22.0663 30.3924 21.6241L30.4187 21.561C30.6377 21.0579 30.7488 20.5202 30.7488 19.9618V4.03861C30.7488 3.77586 30.7235 3.51095 30.6735 3.25343ZM2.09134 2.57738C2.14846 2.49378 2.22136 2.40796 2.31439 2.31421C2.77616 1.85268 3.38856 1.5987 4.03837 1.5987H26.7102C27.3657 1.5987 27.9783 1.8531 28.435 2.31511C28.5138 2.39481 28.5892 2.48327 28.6549 2.57167L28.8282 2.80469L16.7269 13.3515C16.3538 13.6786 15.8735 13.8586 15.3742 13.8586C14.8799 13.8586 14.4 13.679 14.0225 13.352L1.93321 2.80769L2.09134 2.57738ZM1.60681 20.1023C1.60026 20.059 1.59876 20.0108 1.59876 19.9618V4.36832L10.9898 12.5607L1.69353 20.6659L1.60681 20.1023ZM27.9201 22.0798C27.5567 22.2895 27.1381 22.4008 26.7103 22.4008H4.03843C3.61041 22.4008 3.19199 22.2895 2.82877 22.0798L2.44874 21.8595L12.0539 13.4889L13.1066 14.4046C13.738 14.9528 14.5432 15.255 15.3744 15.255C16.2085 15.255 17.0151 14.9528 17.6461 14.4046L18.6984 13.4885L28.3002 21.86L27.9201 22.0798ZM29.1497 19.9618C29.1497 20.01 29.1488 20.0577 29.1427 20.1001L29.0595 20.669L19.7593 12.565L29.1497 4.37228V19.9618Z" fill="#696969"/>
                                            </svg> -->
                                            <svg width="29" height="22" viewBox="0 0 29 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M28.2727 1H1V21H28.2727V1Z" stroke="#696969" stroke-width="1.4" stroke-miterlimit="10" stroke-linejoin="round"/>
                                                <path d="M1 1L14.6364 14.6364L28.2727 1" stroke="#696969" stroke-width="1.4" stroke-miterlimit="10" stroke-linejoin="round"/>
                                                <path d="M1 21L11 11" stroke="#696969" stroke-width="1.4" stroke-miterlimit="10" stroke-linejoin="round"/>
                                                <path d="M28.2773 21L18.2773 11" stroke="#696969" stroke-width="1.4" stroke-miterlimit="10" stroke-linejoin="round"/>
                                            </svg>
                                    </div>
                                    <fieldset class="form-group w-100 mb-0">
                                        <input class="input-rk" autocomplete="off" readonly  type="email"
                                            onfocus="this.removeAttribute('readonly');" formControlName="email1"
                                            [ngClass]="{ 'is-invalid': submitted && f.email1.errors }"
                                            placeholder="Email">
                                        <div *ngIf=" submitted && f.email1.errors" class="invalid-feedback">
                                            <div *ngIf="f.email1.errors.required">Email is required</div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                            <fieldset>
                                <button class="btn btn-submit">Submit</button>
                            </fieldset>
                            <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="container d-flex justify-content-center" style="height: 5vh;">
            <div class="row align-items-center justify-content-center">
                <div class="col-sm-12">
                    <div class="text-center">
                        <span class="Copyright-text">Copyright © 2024 GiftBox v1.05.00 All rights reserved.</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    
    
    
    <p-confirmDialog acceptLabel="Continue" appendTo="body">
        <ng-template pTemplate="body">
            <span class="ui-confirmdialog-message"></span>
        </ng-template>
    </p-confirmDialog>




