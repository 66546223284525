import { Injectable } from '@angular/core';
import { configConstant } from '../shared/constants/config.constant';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError, tap } from 'rxjs/operators';
import { PrintJobModel } from '../Models/shift.model';
import { RefundDetails } from '../Models/Refund';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private cachedProducts: any[] = [];
  private baseurl = configConstant.apibaseurl;
  constructor(private http: HttpClient) {  }
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  errorHandl(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
        // Get client-side error
        errorMessage = error.error.message;
    } else {
        // Get server-side error
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
//Remove from I-POS,Gift,OpenGift
  UpdateLastTXID(wsid, TXID,submitted): Observable<any> {
    let CId = localStorage.getItem("Client_Id");
    const headers = new HttpHeaders().set('Client', CId);
    return this.http.get<any>(this.baseurl + '/Common/UpdateLastTXID/' + wsid + '/' + TXID +'/'+ submitted,{headers})
      .pipe(
        retry(1),
        catchError(this.errorHandl)
      );
  }
  //remove from i-POS service
  GetTransactionForRefund(): Observable<any> {
    let CId = localStorage.getItem("Client_Id");
    const headers = new HttpHeaders().set('Client', CId);
    return this.http.get<any>(this.baseurl + '/Common/GetTxDetailsRefund',{headers})
      .pipe(
        retry(1),
        catchError(this.errorHandl)
      );
  }
  //Remove From I-Pos service
  RefundDetailsByTxId(txid, distNUM): Observable<RefundDetails> {
    let CId = localStorage.getItem("Client_Id");
    const headers = new HttpHeaders().set('Client', CId);
    return this.http.get<RefundDetails>(this.baseurl + '/Common/RefundDetailsByTxId/' + txid + '/' + distNUM,{headers})
      .pipe(
        retry(1),
        catchError(this.errorHandl)
      );
  }
  //Remove from I-POS,Gift,OpenGift
  GetSiteConfigrationValidated(siteId , distId): Observable<any> {
    let CId = localStorage.getItem("Client_Id");
    const headers = new HttpHeaders().set('Client', CId);
    return this.http.get<any>(this.baseurl + '/Common/GetSiteConfigrationValidation/' + siteId +'/'+distId,{headers})
      .pipe(
        retry(1),
        catchError(this.errorHandl)
      );
  }
 

GetProductsListNew(WorkstationId: string, Maxdiscountfactor: number, displaySRP: boolean): Observable<any> {
  debugger;
  

  const CId = localStorage.getItem("Client_Id");
  const headers = new HttpHeaders().set('Client', CId);

  return this.http.get<any>(`${this.baseurl}/Common/GetProductListNew/${WorkstationId}/${Maxdiscountfactor}/${displaySRP}`, { headers })
    .pipe(
      retry(1),
      tap(products => this.cachedProducts = products),
      catchError(this.errorHandl)
    );
}


reprint(txid): Observable<any> {
  let CId = localStorage.getItem("Client_Id");
  const headers = new HttpHeaders().set('Client', CId);
  return this.http.get<any>(this.baseurl + '/Common/RePrint/' + txid,{headers})
    .pipe(
      retry(1),
      catchError(this.errorHandl)
    );
}

}
