<div class="right-content saleshistory">
    <div class="heading-panel text-left">
        <h1><img src="./assets/Libs/images/TransactionHistory.png" height="16"> {{headerText}}</h1>
    </div>
    <div class="container-fluid">
        <!-- <h2 class="text-center mb-3 mt-4" *ngIf="isSelectItem">Select Items For Return</h2> -->
        <div class="transaction-details-main transaction-details-maincstm">
            <div class="d-flex">
                <div class="transaction-details-left" *ngIf="isSaleHistory">
                    <div class="transaction-inner">
                        <div class="search-filter-m">
                            <div class="row transtionHistry">                  
                                <div class="col-lg-3">
                                    <div class="row saleHisrty">
                                        <div class="col-sm-12">
                                            <label class="col-form-label">Site</label>
                                            <select class="form-control" [(ngModel)] = "selectedSite"
                                                (change)="GetWorkstationById($event)" id="Qty">
                                                <option value="0">--Select site--</option>
                                                <option *ngFor="let site of sites" value="{{site.siteId}}">
                                                    {{site.siteName}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <label class="col-form-label">Workstation</label>
                                            <select [(ngModel)] = "selectedWorkstation" 
                                                class="form-control" >
                                                <option value="0">--Select Workstation--</option>
                                                <option *ngFor="let work of wokstationList"
                                                    value="{{work.workStationId}}">
                                                    {{work.workStationName}}
                                                </option>

                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3">
                                    <label class="col-form-label">Transaction Type:</label>
                                    <select 
                                          [(ngModel)] = "selectedTranType"
                                        class="form-control">
                                        <option value="">--Select Type--</option>
                                        <!-- <option value="">All</option> -->
                                        <option *ngFor="let txtype of  TXTypeList; let i=index">
                                            {{txtype.txtypeName}}</option>
                                        <!-- <option value="Gift">Gift</option> -->
                                    </select>
                                </div>
                             
                                <div class="col-lg-3">
                                    <label class="col-form-label w-100">Date Range:</label>
                                    <ng-container *ngIf="!customDateField; then thenBlock; else elseBlock;"></ng-container>
                                    <ng-template #thenBlock>
                                        <select id="ddlDate" class="form-control" [(ngModel)] = "selectedTranDate"
                                        (change)="EnableCustomDate()">
                                        <option value="">--Select Date--</option>
                                        <option value="TO">Today</option>
                                        <option value="YE">Yesterday</option>
                                        <option value="LW">Last Week</option>
                                        <option value="LM">Last Month</option>
                                        <option value="CUS">Custom</option>
                                    </select>
                                    </ng-template>
                                    <ng-template #elseBlock>
                                        <mat-form-field appearance="standard" class="matdatepicker_div mb-0 pb-0 dateSet">
                                          <input [(ngModel)]="customDate" matInput [min]="pickerMinDate" [max]="pickerMaxDate" [matDatepicker]="picker" placeholder="MM/DD/YYYY" readonly id="textCustomDate"> 
                                          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                          <mat-datepicker #picker></mat-datepicker> 
                                          <button class="btn closeBt" type="button" (click)="hideCustomDate()">
                                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M19.4081 4.34137C20.189 3.56048 20.189 2.29233 19.4081 1.51144C18.6272 0.73056 17.359 0.73056 16.5782 1.51144L9.99999 8.09587L3.41558 1.51769C2.6347 0.736807 1.36654 0.736807 0.58566 1.51769C-0.19522 2.29858 -0.19522 3.56673 0.58566 4.34761L7.17007 10.9258L0.591914 17.5102C-0.188973 18.2911 -0.188973 19.5592 0.591914 20.3401C1.3728 21.121 2.64095 21.121 3.42183 20.3401L9.99999 13.7557L16.5844 20.3339C17.3653 21.1148 18.6334 21.1148 19.4143 20.3339C20.1952 19.553 20.1952 18.2848 19.4143 17.5039L12.8299 10.9258L19.4081 4.34137Z" fill="black"/>
                                            </svg>
                                        </button>
                                        </mat-form-field>
                                    </ng-template>
                                </div>
                                <div class="col-lg-3">
                                    <label class="col-form-label">Customer Name:</label>
                                    <div class="customer-tdx-input">
                                    <input type="text" [(ngModel)] = "selectedCustName"
                                        class="form-control">
                                        <i class="fa fa-search"></i>
                                    </div>
                                </div>
                                <div class="col-lg-3">
                                    <label class="col-form-label">Transaction ID:</label>
                                    <div class="customer-tdx-input">
                                        <input type="text" [(ngModel)] = "selectedTxId"
                                        class="form-control">
                                            <i class="fa fa-search"></i>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6">
                                <div class="action-buttons trnctnH-btn-rst-srch mt-0 justify-content-start" *ngIf="isSaleHistory">
                                    <button  class="button button-secondary SearchbtnClass"  id="SearchbtnId" (click)="FilterTransactionHistory()">Search</button>
                                    <button class="button button-secondary SearchbtnClass" id="resetbtnId"(click)="Resetdropvalue('x')">Reset</button>
                                </div>
                                </div>
                            </div>

                            
                           
                        </div>
                        <br/>
                        <div class="table-data">
                            <div class="table-responsive">
                                <table class="table table-bordered table-striped ">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Date</th>
                                            <th>Time</th>
                                            <th>Customer</th>
                                            <th>Tran Type</th>
                                            <th>TX ID</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="saleTrans != ''">
                                        <tr *ngFor="let item of saleTrans; let i=index">
                                            <td><input [(ngModel)]="item.isSelected" (change)="showTxDetails($event,item)" type="checkbox"></td>
                                            <td>{{item.date}}</td>
                                            <td>{{item.time}} </td>
                                            <td>{{item.customerName}}</td>
                                            <td>{{item.tranType }}</td>
                                            <td>{{item.txId }}</td>
                                            <td>{{item.txtotal | currency}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="transaction-details-left" *ngIf="isSelectItem">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive table-responsivecstm mb-2">
                                <table class="table table-striped m-0">
                                    <tr>
                                        <td class="textcheck"><input type="checkbox" name="chkAllReturnItem" (change)="checkUncheckAllItem($event.target.checked)" id="chkAllItem" [(ngModel)]="Allselected" [checked]="Allselected"></td>	
                                        <td class="text-prdctname"><label for="chkAllItem">Select All</label></td>
                                        <td></td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="items-list-to-return" *ngIf="isSelectItem">
                        <div class="return-item" *ngIf="refundDetailsList.refundProdList.length>0">
                            <div class="vchr-rfnd-heading mb-2">
                                <h4>Previously Refunded</h4>
                            </div>
                            <div class="table-responsive cstm-table-scrl mb-2">
                                <table class="table table-striped">
                                    <tr class="disable" *ngFor="let prod of refundDetailsList.refundProdList; let i=index">
                                        <td class="textcheck"> </td>
                                        <td class="text-prdctname">{{prod.prodName}}</td>
                                        <td class="text-left">{{prod.amount | currency}}</td>
                                        <td class="text-left">x{{prod.qty}}</td>
                                        <td></td>
                                    </tr> 
                                </table>
                            </div>
                        </div>

                        <div class="return-item" *ngIf="payoutList.length>0">
                            <div class="vchr-rfnd-heading mb-2">
                                <h4>Payout</h4>
                            </div>
                            <div class="table-responsive cstm-table-scrl mb-2 Pay-out-Tab">
                                <table class="table table-striped">
                                    <tr  *ngFor="let prod of payoutList; let i=index">
                                        <td class="text-prdctname">{{prod.productName}}</td>
                                        <td class="text-left">{{prod.total | currency}}</td>
                                        <td class="text-left"> </td>
                                        <td></td>
                                    </tr> 
                                </table>
                            </div>
                        </div>

                        <div class="return-item" *ngIf="refundVoucherProdGrpList != null && refundVoucherProdGrpList.length>0">
                            <div class="return-item" *ngFor="let item of refundVoucherProdGrpList">
                                <!-------Voucher------->
                            <div class="vchr-rfnd-heading mb-2">
                                <h4>{{item.category}}</h4>
                            </div>
                            <div class="table-responsive cstm-table-scrl mb-2">
                                <table class="table table-striped">
                                    <tr *ngFor="let voucher of item.prodList; let i=index">
                                        <td class="textcheck">	
                                            <input type="checkbox" [checked]="voucher.isSelected" (change)="CheckChangeVoucherProductList($event.target.checked, voucher.sNo, voucher.mediaNumber)">	
                                        </td>
                                        <td class="text-prdctname">{{voucher.productName}}</td>
                                        <td class="text-left">{{voucher.total | currency}}</td>
                                        <td class="text-left"> {{voucher.mediaNumber}}</td>
                                        <td></td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        </div>

                        <div class="return-item" *ngFor="let item of refundProductsGroupList">
                            <div class="merch-invt-heading mb-2">
                                <h4>{{item.category}}</h4>
                            </div>
                            <div class="table-responsive merch-invt-cstmscrl mb-2">
                                <table class="table table-striped">
                                    <tr *ngFor="let prod of item.prodList; let i=index">
                                        <td class="textcheck" *ngIf="item.category != 'PayOut'" >	
                                            <input class="chkReturnItem" [checked]="prod.isSelected" type="checkbox" (change)="CheckChangeProductList($event.target.checked, prod.sNo)">	
                                        </td>
                                        <td class="text-prdctname">{{prod.productName}}</td>
                                        <td class="text-left">{{prod.total | currency}}</td>
                                        <td class="text-left"> </td>
                                        <td></td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="transaction-details-right" *ngIf="isShowTX">
                    <div class="card fix-h">
                        <div class="card-body">
                            <div class="trnsctn-heading text-center mb-2">
                                <h4>Transaction Details</h4>
                            </div>
                            <div class="table-responsive">
                                <div class="mb-2" *ngIf="refundDetails.transactionDetail != null">
                                    <table class="table mt-0 mb-0">
                                        <tbody>
                                            <tr>
                                                <td>TX ID</td>
                                                <td class="text-right">{{refundDetails.transactionDetail.txId}}</td>
                                            </tr>
                                            <tr>
                                                <td>Tour ID</td>
                                                <td class="text-right">{{refundDetails.transactionDetail.tourId != 0 && refundDetails.transactionDetail.tourId || "None"}}</td>
                                            </tr>
                                            <tr>
                                                <!-- <td>TX Type</td>
                                                <td class="text-right">{{refundDetails.transactionDetail.tranType}}</td> -->
                                                <td>User</td>
                                                <td class="text-right">{{refundDetails.transactionDetail.clerkName}}</td>
                                            </tr>
                                            <tr>
                                                <td>Date</td>
                                                <td class="text-right">{{refundDetails.transactionDetail.date}}</td>
                                            </tr>
                                            <tr>
                                                <td>Time</td>
                                                <td class="text-right">{{refundDetails.transactionDetail.time}}</td>
                                            </tr>
                                            <!-- <tr>
                                                <td>User</td>
                                                <td class="text-right">{{refundDetails.transactionDetail.clerkName}}</td>
                                            </tr>
                                            <tr>
                                                <td>Guest Name</td>
                                                <td class="text-right">{{refundDetails.transactionDetail.customerName}}</td>
                                            </tr> -->
                                        </tbody>
                                    </table>
                                </div>

                                <div *ngIf="refundDetailsList.soldProductDetails.length > 0">
                                    <div class="sold-items-heading mb-2">
                                        <h4>Items Sold</h4>
                                    </div>
                                    <div class="beforeRefund mb-2">
                                        <table class="table mb-0">
                                            <tbody>
                                                <tr *ngFor="let item of refundDetailsList.soldProductDetails; let i=index">
                                                    <td>
                                                        <span> {{item.prodName}}</span>
                                                    </td>
                                                    <td>
                                                        <span class="products-quantity">x{{item.qty}}</span>
                                                    </td>
                                                    <td class="text-right">
                                                        {{item.amount | currency}}
                                                    </td>
                                                </tr>
                                            </tbody>   
                                        </table>
                                    </div>
                                    
                                    <div class="totaltr">
                                        <table class="table mb-0">
                                            <tbody>
                                                <tr>
                                                    <td class="p-0"><strong>Total</strong></td>
                                                    <!-- <td class="text-right p-0"><strong>{{refundGroupDetails.productsTotal | currency}}</strong></td> -->
                                                    <td class="text-right p-0"><strong>{{refundDetailsList.transactionDetail.txtotal | currency}}</strong></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div *ngIf="!isSelectItem && (refundDetailsList.refundProdList.length > 0)">
                                    <div class="prev-rfnd-heading  text-center mb-2">
                                        <!-- <h4>Previously Refunded:</h4> -->
                                        <h4>{{currentRefundName}}</h4>
                                    </div>
                                    <div class="right-prev-rfndablescrl mb-2">
                                    <table class="table mb-0">
                                        <tbody>
                                            <tr *ngFor="let item of refundDetailsList.refundProdList; let i=index">
                                                <td>
                                                    <div class="d-flex justify-content-between">
                                                        <span> {{item.prodName}}</span>
                                                        <span class="products-quantity">x{{item.qty}}</span>
                                                    </div>
                                                </td>
                                                <td class="text-right">
                                                    {{item.amount | currency}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>

                                    <div class="totaltr">
                                        <table class="table mb-0">
                                            <tbody>
                                                <tr>
                                                    <td class="p-0"><strong>Total</strong></td>
                                                    <td class="text-right p-0"><strong>{{ refundDetails.refundProductsTotal | currency }}</strong></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <!-- payment section -->
                                <div *ngIf="isSelectItem">
                                    <div class="pymtDetails-heading pytmtablescrl mb-2">
                                        <h4>Payments</h4>
                                    </div>
                                    <div class="right-pytmtablescrl mb-3">
                                    <table class="table" *ngIf="refundDetailsList.paymentList.length>0">
                                        <tbody>
                                            <tr *ngFor="let item of refundDetailsList.paymentList; let i=index">
                                                <td>
                                                    <div class="d-flex justify-content-between">
                                                        <span> {{item.tenderTypeName}}</span>
                                                    </div>
                                                </td>
                                                <td class="text-right">
                                                    {{item.tenderAmount | currency}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="three-bottom-buttons d-flex justify-content-center align-items-center" *ngIf="isSaleHistory">
            <button [disabled]="printDisable" id="btnPrintReceipt"class="button button-secondary" (click)="rePrintTransection()" disabled>Print Receipt</button>
            <button [disabled]="isProductSelected" id="btnAbR" class="button button-secondary" (click)="Refund();" disabled>Refund</button>
            <button [disabled]="isProductExchange" id="btnExchage" class="button button-secondary"(click)="Exchange()" disabled>Exchange</button>
            <!-- <button class="button button-secondary" (click)="RedirectToHome()">Cancel</button> -->
        </div>
        <div class="two-bottom-buttons d-flex justify-content-center align-items-center" *ngIf="isSelectItem">
            <button class="button button-secondary  cncl-btn" (click)="RedirectSalesHistory()" id="cncl-btn-trnstnH">Cancel</button>
            <button [disabled]="isProdSelect" class="button button-secondary"
                (click)="PopulateProducts()" id="cntn-btn-trnstnH" disabled>Continue</button>
        </div>
    </div>
</div>

<!-- ------------------------------------------------------------------------------------------------------------------------------- -->
<!-- <div [style.display]="ViewSaleModal? 'block' : 'none'" class="modal name-popup" id="imgmodal" tabindex="-1"
role="dialog" aria-labelledby="myMLabel" aria-hidden="true">
<div class="modal-dialog">
    <div class="modal-content signinClass">
        <div class="modal-header SignInHeader">
            <button type="button" class="close"data-dismiss="modal" (click)="hide('SignInModal')">×</button>
        </div>
        <div class="row col-md-12" >
            <div class="col-md-4 ">
                <img class="SignInImageIcon" src="../../../../assets/Libs/images/logo-icon.png">
            </div>
            <div class="modal-body col-md-8" >
                <h1 class="modal-title text-heading-color" id="myLabel">SIGN IN</h1>
                <form [formGroup]="ViewSaleLogin" >
                    <div class="form-group">
                        
                        <input type="text" placeholder="Username" formControlName="UserNameLogin"
                            class="form-control" />
                    </div>
                    <div class="form-group">                               
                        <input type="password" placeholder="Password" formControlName="PasswordLogin"
                            class="form-control" />
                    </div>
                    <button type="submit" id='btnSubmit' (click)='AdjustLogin()' class="btn btn-light  form-control"
                        >LOGIN</button>
                </form>
            </div>
        </div>
    </div>
</div>
</div> -->

<!-- --------------------------------------------------------------------------------------------------------------------------------- -->


<div [style.display]="ViewSaleModal? 'block' : 'none'" class="modal name-popup" id="imgmodal" tabindex="-1"
    role="dialog" aria-labelledby="myMLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-cstmSign">
        <div class="modal-content signinClass">
            <div class="modal-header SignInHeader">
                <h4 class="modal-title" id="myLabel">Authorization Required</h4>
                </div>
                       <div class="modal-body text-right">
                               <form [formGroup]="ViewSaleLogin" (ngSubmit)="AdjustLogin()">
                    <div class="form-group">

                        <input type="text" placeholder="Username" formControlName="UserNameLogin1" autocomplete="off"
                            class="form-control"
                            [ngClass]="{ 'is-invalid':issubmitted && Login.UserNameLogin1.errors }" />
                        <div *ngIf="issubmitted && Login.UserNameLogin1.errors" class="invalid-feedback">
                            <div *ngIf="Login.UserNameLogin1.errors.required">UserName is required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <input type="password" placeholder="Password" formControlName="PasswordLogin1" autocomplete="off"
                            class="form-control"
                            [ngClass]="{ 'is-invalid':issubmitted && Login.PasswordLogin1.errors }" />
                        <div *ngIf="issubmitted && Login.PasswordLogin1.errors" class="invalid-feedback">
                            <div *ngIf="Login.PasswordLogin1.errors.required">Password is required</div>
                        </div>
                    </div>
                    <div class="signX-buttons d-flex justify-content-between align-items-center">
                        <button type="button" class="close btnsignX" data-dismiss="modal"
                            (click)="hide('SignInModal')">Cancel</button>
                        <button type="submit" id='btnSubmit' class="btn btnsignN  form-control">Sign In</button>
                    </div>
                </form>
            </div>
            <!-- </div> -->
        </div>
    </div>
</div>